export const registredAs = [
  { value: "", label: "Select One" },
  { value: "PROPRIETORSHIP", label: "Proprietorship" },
  { value: "PARTNERSHIP", label: "Partnership" },
  { value: "PVT. LTD", label: "Pvt. Ltd" },
  { value: "LLP", label: "LLP" },
  { value: "LIMITED COMPANY", label: "Limited Company" },
  {
    value: "ONE PERSON COMPANY",
    label: "One Person Company",
  },
  { value: "NOT REGISTERED", label: "Not Registered" },
];
export const businessRunBy = [
  { value: "", label: "Select One" },
  { value: "SELF", label: "Self" },
  { value: "SPOUSE", label: "Spouse" },
  { value: "RELATIVE", label: "Relative" },
  { value: "PARENT", label: "Parent" },
  { value: "OTHERS", label: "Others" },
];
export const natureOfBusiness = [
  { value: "", label: "Select One" },
  { value: "Importer", label: "Importer" },
  { value: "Trader", label: "Trader" },
  { value: "Exporter", label: "Exporter" },
  { value: "Service", label: "Service" },
  { value: "Others", label: "Others" },
  { value: "Manufacturer", label: "Manufacturer" },
  { value: "Retailer", label: "Retailer" },
  { value: "Distributor", label: "Distributor" },
  { value: "Online Seller", label: "Online Seller" },
  { value: "Offline Seller", label: "Offline Seller" },
  { value: "CSC/VLE", label: "CSC/VLE" },
];
export const productCategory = [
  {
    value: "",
    label: "Select One",
  },
  {
    value:
      "Advisory services (legal, business, educational, psychological etc.)",
    label:
      "Advisory services (legal, business, educational, psychological etc.)",
  },
  {
    value: "Aircel distributor / R-com distributor",
    label: "Aircel distributor / R-com distributor",
  },
  {
    value: "Apparel, Readymade garments",
    label: "Apparel, Readymade garments",
  },
  {
    value: "Arms and ammunition dealers",
    label: "Arms and ammunition dealers",
  },
  {
    value: "Arts, design and other creative services",
    label: "Arts, design and other creative services",
  },
  {
    value: "Automobile accessories / service centre",
    label: "Automobile accessories / service centre",
  },
  { value: "Automotive parts", label: "Automotive parts" },
  {
    value: "BPO/KPO & facility management",
    label: "BPO/KPO & facility management",
  },
  { value: "Bar / Liquor", label: "Bar / Liquor" },
  {
    value:
      "CSC, photocopying and other documents and ID (Aadhaar, PAN) related services, Kisan seva kendra",
    label:
      "CSC, photocopying and other documents and ID (Aadhaar, PAN) related services, Kisan seva kendra",
  },
  { value: "Cab Services", label: "Cab Services" },
  {
    value: "Camera, CCTV and related accessories",
    label: "Camera, CCTV and related accessories",
  },
  {
    value: "Cargo and retail transport service",
    label: "Cargo and retail transport service",
  },
  {
    value: "Chain restaurant / verified income",
    label: "Chain restaurant / verified income",
  },
  {
    value: "Chemist, Retail health accessories, medicines and supplements",
    label: "Chemist, Retail health accessories, medicines and supplements",
  },
  {
    value: "Clinics, hospital, nursing home, lab, gym, pet clinic etc.",
    label: "Clinics, hospital, nursing home, lab, gym, pet clinic etc.",
  },
  {
    value: "Computer, Mobile and related accessories",
    label: "Computer, Mobile and related accessories",
  },
  {
    value: "Computer, mobile and camera repairing service",
    label: "Computer, mobile and camera repairing service",
  },
  {
    value: "Construction equipment including building, road, sewage etc.",
    label: "Construction equipment including building, road, sewage etc.",
  },
  {
    value: "Construction material like cement, bricks, sand etc",
    label: "Construction material like cement, bricks, sand etc",
  },
  {
    value: "Corporate services / Corporate bookings",
    label: "Corporate services / Corporate bookings",
  },
  {
    value: "Cotton bales, Textile manufacturing",
    label: "Cotton bales, Textile manufacturing",
  },
  {
    value: "Countdown Timers, Electronic Scoreboards and Digital Clocks",
    label: "Countdown Timers, Electronic Scoreboards and Digital Clocks",
  },
  { value: "Courier", label: "Courier" },
  {
    value: "Design, Fabrication & Painting Services",
    label: "Design, Fabrication & Painting Services",
  },
  {
    value: "Distributor of cooking/commercial Gas",
    label: "Distributor of cooking/commercial Gas",
  },
  {
    value:
      "Eco-friendly solutions for industry (solar, greenhouse, biomass, recycling, etc.)",
    label:
      "Eco-friendly solutions for industry (solar, greenhouse, biomass, recycling, etc.)",
  },
  { value: "Edible Oil business", label: "Edible Oil business" },
  {
    value:
      "Educational Institute (Pre-school, school, college, coaching center, library etc.)",
    label:
      "Educational Institute (Pre-school, school, college, coaching center, library etc.)",
  },
  {
    value:
      "Educational products (books, educational toys, stationeries, e-learning etc.)",
    label:
      "Educational products (books, educational toys, stationeries, e-learning etc.)",
  },
  {
    value: "Electronic sensors, devices",
    label: "Electronic sensors, devices",
  },
  { value: "Entertainment Event", label: "Entertainment Event" },
  {
    value: "Equipment used in agriculture",
    label: "Equipment used in agriculture",
  },
  { value: "Event Management", label: "Event Management" },
  {
    value: "Fabric (woven and non-woven) & textiles",
    label: "Fabric (woven and non-woven) & textiles",
  },
  {
    value: "Facility Management Services",
    label: "Facility Management Services",
  },
  {
    value:
      "Fibres, threads, buttons and other raw materials supplier to textile industry",
    label:
      "Fibres, threads, buttons and other raw materials supplier to textile industry",
  },
  { value: "Film Producer", label: "Film Producer" },
  {
    value:
      "Financial service providers (Chit funds/ small finance companies/Stock broking Companies)",
    label:
      "Financial service providers (Chit funds/ small finance companies/Stock broking Companies)",
  },
  {
    value: "Financial service providers (DSA,agents and other intermediators)",
    label: "Financial service providers (DSA,agents and other intermediators)",
  },
  {
    value: "Financial service providers (NBFCs/ Other money lending companies)",
    label: "Financial service providers (NBFCs/ Other money lending companies)",
  },
  {
    value: "Fire fighting equipment, safety materials",
    label: "Fire fighting equipment, safety materials",
  },
  {
    value: "Footwear, bags, cosmetics and other fashion accessories",
    label: "Footwear, bags, cosmetics and other fashion accessories",
  },
  {
    value: "Freight forwarding services",
    label: "Freight forwarding services",
  },
  {
    value: "Furniture & Fixtures (including on rent)",
    label: "Furniture & Fixtures (including on rent)",
  },
  {
    value: "Grocery/ Kirana store / Departmental Store",
    label: "Grocery/ Kirana store / Departmental Store",
  },
  {
    value: "Hardware & Fittings (ceramics, tiles, cables, lights etc.)",
    label: "Hardware & Fittings (ceramics, tiles, cables, lights etc.)",
  },
  {
    value: "Hardware provider to telecom industry",
    label: "Hardware provider to telecom industry",
  },
  {
    value: "Heavy machineries & Robotics",
    label: "Heavy machineries & Robotics",
  },
  { value: "Home & Kitchen Appliances", label: "Home & Kitchen Appliances" },
  {
    value:
      "Home Decor, interior items, kitchen accessories, toys, gift articles",
    label:
      "Home Decor, interior items, kitchen accessories, toys, gift articles",
  },
  { value: "Hotels, resorts and club", label: "Hotels, resorts and club" },
  { value: "Housekeeping products", label: "Housekeeping products" },
  {
    value: "Housekeeping, security & industrial labour supply",
    label: "Housekeeping, security & industrial labour supply",
  },
  { value: "Imitation jewellery", label: "Imitation jewellery" },
  {
    value: "Industrial and customized software, applications and ITES",
    label: "Industrial and customized software, applications and ITES",
  },
  {
    value: "Installation and repair services for telecom products",
    label: "Installation and repair services for telecom products",
  },
  {
    value: "Intelligence Agencies/ Private Security Firms",
    label: "Intelligence Agencies/ Private Security Firms",
  },
  { value: "Interior Designers", label: "Interior Designers" },
  {
    value: "Jewellery, precious metals and stones",
    label: "Jewellery, precious metals and stones",
  },
  { value: "Live Stock Trading", label: "Live Stock Trading" },
  {
    value: "Law services, astrological services, etc.",
    label: "Law services, astrological services, etc.",
  },
  { value: "Leather Trading", label: "Leather Trading" },
  {
    value: "Material handling service provider",
    label: "Material handling service provider",
  },
  {
    value: "Medical, non-medical & wellness equipments",
    label: "Medical, non-medical & wellness equipments",
  },
  { value: "Mining & Mining Products", label: "Mining & Mining Products" },
  {
    value: "Mobile and related accessories",
    label: "Mobile and related accessories",
  },
  {
    value:
      "Mobile recharge, Telecom, DTH, data service provider (telephone, internet, broadband, ISP, leased line etc.)",
    label:
      "Mobile recharge, Telecom, DTH, data service provider (telephone, internet, broadband, ISP, leased line etc.)",
  },
  {
    value: "Musical Instruments, bulbs, tubelights",
    label: "Musical Instruments, bulbs, tubelights",
  },
  {
    value: "Networking, storage, data management and other support services",
    label: "Networking, storage, data management and other support services",
  },
  {
    value: "Non-metal products like wood, paper, plastic, glass, etc",
    label: "Non-metal products like wood, paper, plastic, glass, etc",
  },
  {
    value: "Oils, Paint, Chemicals and petroleum products",
    label: "Oils, Paint, Chemicals and petroleum products",
  },
  {
    value: "Other metals, alloys, minerals and their scraps",
    label: "Other metals, alloys, minerals and their scraps",
  },
  { value: "Others", label: "Others" },
  {
    value: "Outdoor furniture (swings etc.)",
    label: "Outdoor furniture (swings etc.)",
  },
  {
    value:
      "Outsourced consultancy (recruitment, research, placement, transcription, visa etc.)",
    label:
      "Outsourced consultancy (recruitment, research, placement, transcription, visa etc.)",
  },
  {
    value: "Pet Shop, Pet Clinic & Pet Grooming Parlour",
    label: "Pet Shop, Pet Clinic & Pet Grooming Parlour",
  },
  { value: "Petrol pump & Gas station", label: "Petrol pump & Gas station" },
  { value: "Power", label: "Power" },
  {
    value:
      "Professional services (branding, media, architecture, saloon, beauty parlour etc.)",
    label:
      "Professional services (branding, media, architecture, saloon, beauty parlour etc.)",
  },
  {
    value: "Property dealing & management (Rent, lease or Sell)",
    label: "Property dealing & management (Rent, lease or Sell)",
  },
  {
    value: "Raw materials and parts used for manufacturing",
    label: "Raw materials and parts used for manufacturing",
  },
  {
    value: "Real estate developer, Civil contractor",
    label: "Real estate developer, Civil contractor",
  },
  { value: "Renewal (other than Via)", label: "Renewal (other than Via)" },
  {
    value: "Repair and maintenance services",
    label: "Repair and maintenance services",
  },
  {
    value: "Repair and servicing of household appliances",
    label: "Repair and servicing of household appliances",
  },
  {
    value: "Restaurants, Cafes, food outlets & catering",
    label: "Restaurants, Cafes, food outlets & catering",
  },
  {
    value: "Retail and standard softwares, applications and ITES",
    label: "Retail and standard softwares, applications and ITES",
  },
  {
    value:
      "Seeds, Fertilizer, Pesticides, Cattle feeds and agricultural products",
    label:
      "Seeds, Fertilizer, Pesticides, Cattle feeds and agricultural products",
  },
  {
    value: "Shares/Bitcoins/Old coins trading or Other speculative activities",
    label: "Shares/Bitcoins/Old coins trading or Other speculative activities",
  },
  { value: "Shipping Services", label: "Shipping Services" },
  {
    value: "Supplier for switches, cables etc",
    label: "Supplier for switches, cables etc",
  },
  {
    value: "Support services (facilitators, intermediators, agents, etc.)",
    label: "Support services (facilitators, intermediators, agents, etc.)",
  },
  {
    value:
      "Support services to industries like industrial design, warehousing, testing, repairing etc.",
    label:
      "Support services to industries like industrial design, warehousing, testing, repairing etc.",
  },
  { value: "Tax planners / Auditors", label: "Tax planners / Auditors" },
  {
    value:
      "Tea / coffee vending machine & Tea / Coffee powder to Government / corporates",
    label:
      "Tea / coffee vending machine & Tea / Coffee powder to Government / corporates",
  },
  {
    value: "Ticket, Travel & Tour Packages",
    label: "Ticket, Travel & Tour Packages",
  },
  { value: "Tobacco Products", label: "Tobacco Products" },
  {
    value:
      "Tools and equipments including electricals, transformers, inverters and batteries",
    label:
      "Tools and equipments including electricals, transformers, inverters and batteries",
  },
  {
    value:
      "Vegetables, Fruits, Milk and other dairy products, Spices, Sea-food and food-processing",
    label:
      "Vegetables, Fruits, Milk and other dairy products, Spices, Sea-food and food-processing",
  },
  {
    value: "Vehicles (New/second hand)",
    label: "Vehicles (New/second hand)",
  },
  { value: "Via cases", label: "Via cases" },
];
