import React, { useEffect, useRef, useState } from "react";
import GenerateURL from "../../../utils/ApiUrlProvider";
import properties from "../../../Properties/Properties";
import { renderInput } from "../../Common/FormComponents";
import { PutData } from "../../../utils/ApiInvoker";

const EditPartnerModal = ({ onClose, initialpartnerData }) => {
  const [formData, setFormData] = useState({
    firstName: initialpartnerData.first_name || "",
    middleName: initialpartnerData.middle_name || "",
    lastName: initialpartnerData.last_name || "",
  });

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
  });
  const modalRef = useRef();

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  const handleInteractionInsideModal = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleSaveClick = () => {
    updateUserInfo();
    onClose();
  };

  const validateForm = () => {
    let valid = true;
    const errors = {};

    if (!formData.firstName.trim()) {
      errors.formData.firstName = "First name is required";
      valid = false;
    }

    if (!formData.lastName.trim()) {
      errors.formData.lastName = "Last name is required";
      valid = false;
    }

    setFormErrors(errors);
    return valid;
  };

  function saveResposneSucess(response) {
    console.log(response);
    window.location.reload();
  }

  function saveResposneFailure(response) {
    console.log(response);
  }

  function updateUserInfo(e) {
    const authData = JSON.parse(localStorage.getItem("authData"));

    if (validateForm()) {
      var url = GenerateURL(
        { id: authData.userDetails.id },
        properties.api.partnerInfoUpdate
      );
      var data = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        middle_name:
          formData.middleName.trim() !== "" ? formData.middleName : null,
      };
    }
    PutData(url, data, saveResposneSucess, saveResposneFailure, false);
  }

  const handlechange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60"
      onClick={onClose}
    >
      <div
        className="w-[80%] sm:w-[55%] md:1/2  sm:ml-60 p-6 bg-white rounded-lg shadow-lg mt-48 mb-80 lg:w-1/2  "
        onClick={handleInteractionInsideModal}
        ref={modalRef}
      >
        <div className="flex justify-between border-b-2 pb-4 text-2xl md:text-3xl  font-bold text-gray-800 mb-4 md:mb-6">
          <h1 className="font-medium text-xl ">Edit Information</h1>
          <div className="flex flex-row relative">
            <button className="rounded-lg px-1  hover:scale-110">
              <img
                src="https://cdn-icons-png.flaticon.com/128/2997/2997911.png"
                alt=""
                className="h-5"
                onClick={onClose}
              />
            </button>
          </div>
        </div>
        <div className="p-8 gap-5 flex flex-col items-center justify-center">
          <>
            <div className="w-full flex flex-col">
              {renderInput("STRING", {
                label: "First Name",
                name: "firstName",
                value: formData.firstName,
                onChange: handlechange,
                error: formErrors.firstName,
              })}
              {renderInput("STRING", {
                label: "Middle Name",
                name: "middleName",
                value: formData.middleName,
                onChange: handlechange,
                error: formErrors.middleName,
              })}
              {renderInput("STRING", {
                label: "Last Name",
                name: "lastName",
                value: formData.lastName,
                onChange: handlechange,
                error: formErrors.lastName,
              })}
              <div className="flex justify-center items-center">
                <button
                  className="bg-blue-500 text-white px-1 py-1 md:px-4 md:py-2   rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300  "
                  onClick={handleSaveClick}
                >
                  Save
                </button>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default EditPartnerModal;
