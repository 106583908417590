import React, { useEffect, useState } from "react";
import GenerateURL, { GenerateSearchURL } from "../../../utils/ApiUrlProvider";
import properties from "../../../Properties/Properties";
import { GetData } from "../../../utils/ApiInvoker";
import { GetTimeAgo } from "../../../utils/Healpers";
import ListTable from "../../Common/ListTable";
import { useNavigate } from "react-router-dom";
import DashboardBanner from "../../Common/DashboardBanner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchBar from "../../Common/SearchBar";

const PartnerList = () => {
  const [tableData, setTableData] = useState(null);
  const [pagination, setPagination] = useState(null);
  const navigate = useNavigate();
  const clickOn = (e) => {
    navigate(`/admin/partner/${e.id}`);
  };
  const clickableOn = {
    on: ["Partner Code"],
    funct: { "Partner Code": clickOn },
  };
  const convertDataToTableFormat = (data) => {
    const tableHeaders = [
      "Partner Code",
      "Name",
      "Phone Number",
      "E-mail",
      "User-Type",
      "Time",
    ];

    function checkUserType(isSuperUser, isPartner) {
      if (isSuperUser) {
        return "Admin";
      } else if (isPartner) {
        return "Partner";
      } else {
        return "User";
      }
    }

    const tableContent = data.map((user) => {
      return {
        "Partner Code": user.user_profile.username || "N/A",
        Name: user.first_name + " " + user.last_name || "--",
        "Phone Number": user.mobile_number || "--",
        "E-mail": user.email || "--",
        "User-Type": checkUserType(user.is_superuser, user.is_partner),
        Time: GetTimeAgo(user.user_profile.created_at),
        id: user.id,
      };
    });
    return {
      tableHeaders,
      tableContent,
    };
  };

  const HandleSearchSucess = (res) => {
    if (res.results.length === 0) {
      toast.error("No Data found");
      GetPartnerList();
    } else {
      HandleSucess(res);
    }
  };

  const searchParameters = [
    { name: "Name", parm: "name" },
    { name: "Email", parm: "email" },
    { name: "Phone Number", parm: "mobile_number" },
    { name: "Partner Code", parm: "user_code" },
  ];
  const SearchClick = (e) => {
    const url = GenerateSearchURL(e, properties.api.adminUsers);
    GetData(url, HandleSearchSucess, HandleError, false, false);
  };
  const EmptyCall = () => {
    GetPartnerList();
  };

  const HandleSucess = (response) => {
    const data = convertDataToTableFormat(response.results);
    setTableData(data);
    setPagination(response);
  };

  const HandleError = (response) => {
    console.log(response);
  };

  const GetNextFilledServices = (nexturl) => {
    const url = GenerateURL({}, nexturl);
    GetData(url, HandleSucess, HandleError, false, false);
  };

  const GetPrevFilledServices = (prevurl) => {
    const url = GenerateURL({}, prevurl);
    GetData(url, HandleSucess, HandleError, false, false);
  };

  const GetPartnerList = () => {
    const url = GenerateSearchURL(
      { is_partner: true },
      properties.api.adminUsers
    );
    GetData(url, HandleSucess, HandleError, false, false);
  };

  useEffect(() => {
    GetPartnerList();
  }, []);

  const view = (e) => {
    navigate(`/admin/partner/${e.id}`);
  };

  return (
    <div className="w-11/12">
      <DashboardBanner />
      {tableData && (
        <div className="w-full flex flex-col  gap-2 ">
          <SearchBar
            searchParameters={searchParameters}
            SearchClick={SearchClick}
            EmptyCall={EmptyCall}
          />
          <ListTable
            heading="Total Users"
            tableData={tableData}
            pagination={Boolean(pagination.next || pagination.previous)}
            next={pagination.next}
            prev={pagination.previous}
            onNextClick={() => GetNextFilledServices(pagination.next)}
            onPrevClick={() => GetPrevFilledServices(pagination.previous)}
            actions={{ view: true, edit: false, delete: false }}
            onView={view}
            clickabaleOn={clickableOn}
          />
        </div>
      )}
    </div>
  );
};

export default PartnerList;
