import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import DashboardHeader from "../../../Hoc/DashboardHeader";
import SidePannel from "../../../Hoc/SidePannel";
import { UserMenueItems } from "../../../helper/MenuItems";

const UserDashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  return (
    <div>
      <DashboardHeader
        sidebarOpen={sidebarOpen}
        toggleSidebar={toggleSidebar}
      />
      <div className="flex min-h-screen">
        <SidePannel sidebarOpen={sidebarOpen} item={UserMenueItems} />
        <div className="sm:ml-64 mt-7  w-full  bg-slate-100 flex  justify-center border-2  ">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
