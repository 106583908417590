import React, { useEffect, useState } from "react";
import { TextInput } from "../../Common/FormComponents";

import properties from "../../../Properties/Properties";
import { useNavigate } from "react-router-dom";
import GenerateURL from "../../../utils/ApiUrlProvider";
import { PersistLoginCreds } from "../../../utils/AccessTokenStore";
import { PostData } from "../../../utils/ApiInvoker";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LoginModal = ({ isloginModalOpen, closeLoginModel }) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [mobileNo, setMobileNo] = useState("");
  const [otp, setOtp] = useState("");
  const [mobileNoError, setMobileNoError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [count, setCount] = useState(25);

  const startTimer = () => {
    let timer;
    setCount(25);
    if (step === 2) {
      timer = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount > 0) {
            return prevCount - 1;
          } else {
            clearInterval(timer);
            return 0;
          }
        });
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  };

  useEffect(() => {
    startTimer();
  }, [step]);

  const validateMobileNumber = (mobileNumber) => {
    if (
      !mobileNumber ||
      mobileNumber.length !== 10 ||
      !/^\d+$/.test(mobileNumber)
    ) {
      return {
        valid: false,
        message: mobileNumber
          ? "Mobile number must be 10 digits"
          : "Mobile number can't be blank",
      };
    }

    return {
      valid: true,
      message: "Valid Mobile Number",
    };
  };

  const ValidateOTP = (otp) => {
    if (!otp || otp.length !== 6) {
      return {
        valid: false,
        message: otp ? "OTP must be 6 digits" : "OTP can't be blank",
      };
    }
    return {
      valid: true,
      message: "Valid OTP",
    };
  };

  const handleGetOTPSucess = (responseData) => {
    if (responseData) {
      setStep(2);
    }
  };
  const handleGetOTPFailure = (response) => {
    console.log(response);
  };

  const SendOTP = (e) => {
    e.preventDefault();
    const url = GenerateURL({}, properties.api.getOTPURL);
    const data = {
      mobile_number: mobileNo,
    };
    const validation = validateMobileNumber(data.mobile_number);
    if (validation.valid) {
      PostData(url, data, handleGetOTPSucess, handleGetOTPFailure, true);
    } else {
      setMobileNoError(validation.message);
    }
  };
  const ResendOTP = (e) => {
    startTimer();
    SendOTP(e);
  };

  const handleLoginResponse = (responseData) => {
    if (responseData) {
      PersistLoginCreds(responseData);
      navigate("/user/dashboard");
      toast.success("Logged In!", {
        autoClose: 1000,
      });
    }
  };
  const handleLoginFailure = (response) => {
    setOtpError("Invalid OTP");
  };

  const HandleLogin = (e) => {
    e.preventDefault();
    const validation = ValidateOTP(otp);
    if (validation.valid) {
      const url = GenerateURL({}, properties.api.accessTokenURL);
      const data = {
        mobile_number: mobileNo,
        otp: otp,
      };
      PostData(url, data, handleLoginResponse, handleLoginFailure, true);
    } else {
      setOtpError(validation.message);
    }
  };

  return (
    <>
      {isloginModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 ">
          <div className="bg-white rounded-lg p-8 shadow-lg w-11/12  md:w-8/12 flex flex-row">
            <div className="hidden sm:block w-full sm:w-1/2">
              <img
                src="https://img.freepik.com/free-vector/access-control-system-abstract-concept_335657-3180.jpg?size=626&ext=jpg&ga=GA1.1.583267316.1695583278&semt=sph"
                alt="login_img"
              />
            </div>
            <div className=" bg-slate-50 w-full sm:w-1/2 rounded-xl p-4">
              <button
                className="m-2 float-right text-gray-600 hover:text-gray-800 focus:outline-none"
                onClick={closeLoginModel}
              >
                <img
                  className="h-4"
                  src="https://cdn-icons-png.flaticon.com/128/1828/1828774.png"
                  alt="close"
                />
              </button>
              <div>
                <h2 className="text-2xl text-center  font-bold text-gray-800 mb-4">
                  Login to your account
                </h2>
                <form>
                  <div className="p-4 space-y-4 ">
                    {step === 1 && (
                      <div className="pt-16">
                        <TextInput
                          label="Mobile Number"
                          type="text"
                          name="mobileNo"
                          value={mobileNo}
                          onChange={(e) => {
                            const inputNumeric = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              inputNumeric.test(e.target.value)
                            ) {
                              setMobileNoError("");
                              setMobileNo(e.target.value);
                            } else {
                              setMobileNoError(
                                "Please enter only numeric values."
                              );
                            }
                          }}
                          error={mobileNoError}
                        />
                        {step === 1 && (
                          <button
                            className=" bg-logo-blue bg-opacity-90 hover:bg-opacity-100 text-white px-1 py-1 md:px-4 md:py-2  rounded-md  focus:outline-none  focus:ring-0"
                            onClick={SendOTP}
                          >
                            Next
                          </button>
                        )}
                      </div>
                    )}
                    {step === 2 && (
                      <>
                        <p className=" w-full text-sm text-gray-600 p-2  cursor-pointer text-center">
                          We have sent the OTP to +91{mobileNo}
                          <span
                            onClick={() => {
                              setOtpError("");
                              setOtp("");
                              setStep(1);
                            }}
                            className=" text-logo-orange px-2 "
                          >
                            Edit
                          </span>
                        </p>
                        <div>
                          <TextInput
                            label="OTP"
                            type="text"
                            name="otp"
                            value={otp}
                            onChange={(e) => {
                              setOtp(e.target.value);
                              setOtpError("");
                            }}
                            error={otpError}
                          />
                          <div className="flex flex-col items-start">
                            {count === 0 ? (
                              <button
                                onClick={ResendOTP}
                                className="mb-4 text-sm text-logo-blue opacity-80"
                              >
                                Resend OTP
                              </button>
                            ) : (
                              <p className="mb-4 text-sm text-gray-600">
                                Resend OTP in <span>{count}</span> seconds
                              </p>
                            )}
                            {step === 2 && (
                              <button
                                className="bg-green-500 text-white px-1 py-1 md:px-4 md:py-2 rounded-md hover:bg-green-600 focus:outline-none focus:ring focus:ring-green-300"
                                onClick={HandleLogin}
                              >
                                Submit
                              </button>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <p className="text-center md:text-center">
                    Associated with us as a Partner?{" "}
                    <a href="/partner/login" className="text-blue-500">
                      Login
                    </a>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginModal;
