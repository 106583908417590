import React, { useState } from "react";
import Navbar from "../Views/Components/Home/Navbar";
import { Outlet } from "react-router-dom";
import Footer from "../Views/Components/Home/Footer";

const Rootlayout = () => {
  const [isloginModalOpen, setIsloginModalOpen] = useState(false);
  const openLoginModel = () => {
    setIsloginModalOpen(true);
  };
  const closeLoginModel = () => {
    setIsloginModalOpen(false);
  };
  return (
    <div>
      <div className="fixed top-0 left-0 w-full z-40 ">
        <Navbar
          isloginModalOpen={isloginModalOpen}
          openLoginModel={openLoginModel}
          closeLoginModel={closeLoginModel}
        />
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default Rootlayout;
