import React from "react";
import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";

const TermsAndConditionPage = () => {
  return (
    <div>
      <Navbar />
      <h1 className="text-4xl text-logo-blue text-center font-bold capitalize sm:text-4xl border-blue-900 mt-4">
        Terms And Condition Of UdhariBazaar
      </h1>
      <div className="flex justify-center mt-10">
        <div className=" w-3/4 flex flex-col bg-gray-100 p-10 mb-10">
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">Definitions:</p>
            <p>
              "Borrower" refers to the individual or entities receiving the
              loan, including co-borrowers, heirs, executors, administrators,
              and permitted assigns.
            </p>
            <p>
              "Business Day" denotes a day (excluding Sundays) when banks in
              Delhi transact normal banking business.
            </p>
            <p>
              "Penal Charges" represent charges payable by the Borrower to the
              Lender as specified in the Sanction Terms.
            </p>
            <p>
              "Debt" encompasses all amounts owed to the Lender under the Loan
              Documents, including principal, interest, fees, costs, penalties,
              and legal expenses incurred by the Lender.
            </p>
            <p>
              "Disbursement Request Form" refers to the form submitted by the
              Borrower to the Lender for loan disbursement.
            </p>
            <p>
              "Due Date(s)" are the dates specified for repayment of the Debt as
              per the Repayment Schedule.
            </p>
            <p>
              "Event of Default" outlines the specific defaults per Clause 7 of
              this GTC.
            </p>
            <p>
              "Fees and Charges" include various charges, costs, and expenses to
              be reimbursed by the Borrower, as detailed in the Loan Documents.
            </p>
            <p>
              "GTC" or "General Terms and Conditions" are the terms governing
              the Loan, Guarantor's guarantee, and other addressed matters.
            </p>
            <p>
              "Guarantor" refers to individuals/entities providing guarantee,
              including heirs, executors, administrators, partners, successors,
              and permitted assigns
            </p>
            <p>
              "Insolvency Code" refers to the Insolvency and Bankruptcy Code of
              India, 2016.
            </p>
            <p>
              "Interest" is the rate payable by the Borrower on the Loan as
              specified in the Sanction Terms.
            </p>
            <p>
              "Lender" refers to Udharibazaar.com, a company incorporated under
              applicable laws.
            </p>
            <p>
              "Loan Documents" encompass all documents pertaining to the Loan,
              including applications, promissory notes, cheques, mandates, and
              other correspondence related to the Loan.
            </p>
            <p>
              "RBI" or "Reserve Bank of India" denotes the regulatory authority
              as per the Reserve Bank of India Act, 1934.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">
              1.2 Interpretation
            </p>
            <p>
              Loan Document headings are for reference only.
              <br />
              All approvals from Udharibazaar.com require prior written consent.
              <br />
              References to "include" or "including" are without limitation.
              <br />
              Technical or commercial terms hold their known meanings.
              <br />
              Obligations due on non-Business Days are performed on the
              preceding Business Day.
              <br />
              Sanction Terms supersede the GTC in case of conflict, with the
              English version prevailing.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">
              Loan Amount and Disbursal
            </p>
            <p>
              2.1 Udharibazaar.com has sanctioned the Loan to the Borrower based
              on representations, warranties, and guarantees as per the Loan
              Documents.
              <br />
              2.2 Disbursement of the Loan by Udharibazaar.com is subject to the
              Disbursement Request Form and Loan Document terms. Withdrawal of
              the form requires prior written consent.
              <br />
              2.3 The Loan is disbursed to an account specified in the Sanction
              Terms upon the Borrower's compliance with Loan Document
              obligations.
              <br />
              2.4 The Borrower agrees to use the Loan only for the specified
              Purpose and not for unauthorized uses without Udharibazaar.com's
              prior written consent.
              <br />
              2.5 Udharibazaar.com may cancel the Loan, triggering immediate
              repayment within 15 days upon notice. The Borrower may prepay
              subject to terms in the Loan Documents.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">
              Interest and Penal Charges
            </p>
            <p>
              3.1 The Borrower pays Interest as per Sanction Terms/Repayment
              Schedule. Udharibazaar.com may change Interest rates to comply
              with laws or RBI directives.
              <br />
              3.2 In case of defaults or breaches, Penal Charges are payable by
              the Borrower until resolution. These charges represent
              Udharibazaar.com's estimated losses due to delays/default.
              <br />
              3.3 Interest and Penal Charges are calculated based on 365/366
              days and the actual elapsed days.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">Repayment</p>
            <p>
              4.1 The Borrower repays the Loan and Interest as per the Repayment
              Schedule unless early repayment is demanded by Udharibazaar.com.
              <br />
              4.2 Repayments are made as per Sanction Terms into the specified
              account. Credit is given on realization or the relevant Due Dates,
              whichever is later.
              <br />
              4.3 Any change in the Repayment Schedule or Event of Default
              triggers rescheduling charges payable by the Borrower as per the
              Sanction Terms.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">Session Data</p>
            <p>
              We automatically log generic session data about visitors' Internet
              connections, including IP addresses, operating systems, browsers
              used, and activities conducted on our Website. Although this
              information doesn't personally identify visitors, it assists in
              analyzing visitor preferences and diagnosing server issues.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">
              Sharing of Information
            </p>
            <p>
              UdhariBazaar may share your Information with its service
              providers, affiliates, agents, partners, and other third parties
              in accordance with applicable laws, for various purposes such as
              providing services, marketing new products, or intimating you
              about offers or promotions. It is explicitly declared that third
              parties/service partners shall be responsible for the use of your
              Information by them.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">
              Third Party Products and Services
            </p>
            <p>
              Third-party products and/or services offered on the Website are
              governed by their respective privacy policies. UdhariBazaar shall
              not be responsible for their use of your Information or their
              privacy policies.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">Other</p>
            <p>
              We may periodically add or enhance products/services on our
              Website and use the information provided by you to facilitate
              these products/services. For instance, if you contact us with a
              query, we may use your information to respond to your query and
              improve our Website.
              <br />
              UdhariBazaar's commitment to data received from Google APIs will
              adhere to Google API Services User Data Policy, including Limited
              Use requirements.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">
              Promotional Initiatives
            </p>
            <p>
              UdhariBazaar may have a presence on social networking websites and
              blogs as promotional initiatives to connect with a larger
              audience. UdhariBazaar disclaims any liabilities arising from the
              use/misuse of feedback, suggestions, views, etc., shared on any of
              these networking websites or blogs by any third party.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">
              Links to Other Sites
            </p>
            <p>
              Our Website may contain links to other websites. We recommend
              reviewing the privacy policies of these external sites as we have
              no control over their content, privacy policies, or practices.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">
              Protection of Personal Information
            </p>
            <p>
              UdhariBazaar may have a presence on social networking UdhariBazaar
              is committed to protecting the privacy and confidentiality of your
              Personal Information. We implement industry-standard measures to
              protect against unauthorized access or disclosure.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">
              Usage of Information
            </p>
            <p>
              Information shared by you is used by us and/or authorized third
              parties/ service partners/ affiliates to support your interaction
              with us, offer you services, and contact you regarding other
              services and products. By submitting your Information to
              UdhariBazaar, you expressly acknowledge and consent to
              UdhariBazaar's use of such Information as specified in this
              Privacy Policy, in compliance with applicable laws.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">
              Retention and Destruction of Information
            </p>
            <p>
              UdhariBazaar retains your Information for a period of five (05)
              years from the date of the last processing of your Information.
              Upon expiration of this retention period, all deletion of
              Information will be performed within a reasonable period (not
              exceeding ninety (90) days). Physical records will be disposed of
              in accordance with applicable laws, while electronic data will be
              responsibly disposed of with prior approval from UdhariBazaar's
              CTO/CISO.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">
              Changes to Privacy Policy
            </p>
            <p>
              UdhariBazaar reserves the right to update this Privacy Policy in
              compliance with applicable laws. The revised Privacy Policy shall
              be effective immediately upon posting on our Website unless
              otherwise stated.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">
              Grievance Officer Contact
            </p>
            <p>
              For any concerns or complaints related to this Privacy Policy,
              please contact our Grievance Redressal Officer:
            </p>
            <div className="flex ">
              <p>Name: </p>
              <p>Ms Priya </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditionPage;
