import React, { useState } from "react";
import CalculatorEnquiry from "../Home/CalculatorEnquiry";
import Footer from "../Home/Footer";
import AmortizationTable from "../../Common/AmortizationTable";

const AutoLoan = () => {
  const [currentNav, setCurrentNav] = useState(0);
  const [amortizationTableData, setAmortizationTableData] = useState(null);

  const pageData = {
    titles: [
      "Amortization Schedule",
      "Check Your Eligibility",
      "Benefits For You",
      "Documents Required",
    ],
    1: {
      title: "Check Your Eligibility",
      points: [
        "Indian citizenship is a must.",
        "Age between 21 to 60 years.",
        "Steady source of income.",
        "Good credit history.",
        "Check your eligibility and take the first step towards financial freedom.",
      ],
    },
    2: {
      title: "Benefits For You",
      points: [
        "Loan can be availed by individuals for personal use.",
        "Easy and Paperless documentation.",
        "No need to provide collaterals against the loan.",
        "They Can Help Build Your Credit Score.",
        "Quick disbursal process (Same Day).",
      ],
    },
    3: {
      title: "Documents Required",
      points: [
        "Proof of Identity (PAN Card & Passport Size Photo).",
        "Proof of Address (Aadhaar card, Voter ID, Passport or Driving License, E-Bill).",
        "For Salaried Person - 3 Months Salary Slip, 2 years ITR.",
        "Business Profile - (G.S.T, Udhayam Certificate) Last 3 Years ITR With Financial And Computation.",
        "Rent Agreement (If Rented).",
        "12 months bank statements in PDF format or Net Banking.",
      ],
    },
  };

  const updateAmortizationTableData = (data) => {
    setAmortizationTableData(data);
  };

  return (
    <div className="bg-gray-100 min-h-screen mt-24">
      <div className=" container mx-auto mb-10">
        <div className="flex">
          <a
            href="/"
            className="text-sm text-logo-blue font-normal  hover:text-logo-orange border-blue-900 mt-5 ml-3"
          >
            Home{" "}
            <span className="text-sm text-logo-blue font-medium  hover:text-logo-blue ">
              /{" "}
            </span>
          </a>
          <a
            href=""
            className="text-sm text-logo-blue font-normal hover:text-logo-orange  mt-5"
          >
            Auto Loan
          </a>
        </div>

        <h1 className="text-4xl text-logo-blue text-center font-bold capitalize sm:text-4xl border-blue-900 md:mt-4">
          Auto Loan
        </h1>

        <p className="text-xs text-logo-blue text-center w-11/12 m-auto font-normal sm:text-base md:mt-4">
          Rev up your aspirations with our speedy auto Loans, turning your
          automotive dreams into reality. Cruise down the road to ownership
          faster and smoother with UdhariBazaar's swift financing solutions.
        </p>

        <div className="flex justify-center items-center mt-10">
          <div className="w-full">
            <CalculatorEnquiry
              updateAmortizationTableData={updateAmortizationTableData}
            />
          </div>
        </div>
        <div className="bg-gray-200 rounded-lg w-full mx-auto mt-8 text-center">
          <ul className="flex items-center justify-start flex-wrap">
            {pageData.titles.map((menue, key) => {
              return (
                <li
                  key={key}
                  onClick={() => {
                    setCurrentNav(key);
                  }}
                  className={`cursor-pointer p-4 ${
                    key === currentNav
                      ? "text-orange-400 border-b-2 border-orange-400"
                      : ""
                  }`}
                >
                  {menue}
                </li>
              );
            })}
          </ul>
          <div className="w-full  py-2 bg-white rounded-b-lg">
            {currentNav === 0 && amortizationTableData && (
              <AmortizationTable data={amortizationTableData} />
            )}
            {currentNav !== 0 && currentNav !== 3 && (
              <div className="section-content mx-auto bg-white px-4 flex flex-col items-start">
                <h3 className="text-2xl font-bold text-logo-blue">
                  {pageData.titles[currentNav]}
                </h3>
                <ul className="list-disc list-inside mt-4 text-left text-logo-blue">
                  {pageData[currentNav].points.map((point, index) => (
                    <li key={index} className="mb-2">
                      {point}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {currentNav === 3 && (
              <div className="section-content mx-auto bg-white px-4 flex flex-col items-start">
                <h3 className="text-2xl font-bold text-logo-blue">
                  {pageData.titles[currentNav]}
                </h3>
                <ul className="list-disc list-inside mt-4 text-left text-logo-blue">
                  {pageData[currentNav].points.map((point, index) => (
                    <li key={index} className="mb-2">
                      {point}
                    </li>
                  ))}
                </ul>
                <h3 className="text-xl font-bold pt-2 text-logo-blue">
                  Used car loan (Private Vehicle)
                </h3>
                <ul className="list-disc list-inside mt-2 text-left text-logo-blue">
                  <li>Registration Certificate (RC).</li>
                  <li>Insurance.</li>
                  <li>Loan track ( Optional).</li>
                </ul>
                <h3 className="text-xl font-bold pt-2 text-logo-blue">
                  New Car Loan (Private Vehicle)
                </h3>
                <ul className="list-disc list-inside mt-2 text-left text-logo-blue">
                  <li>Quotation for New Car.</li>
                  <li>Loan track ( Optional).</li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AutoLoan;
