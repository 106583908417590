import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

export const DropdownItem = ({
  name,
  icon,
  children,
  link,
  isMenuOpen,
  isSubMenuOpen,
  onDropdownClick,
  onSubMenuClick,
}) => {
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const isActive = pathname === link;
  const [isOpen, setIsOpen] = useState(isMenuOpen);
  const [subMenuOpen, setSubMenuOpen] = useState(isSubMenuOpen);

  useEffect(() => {
    setIsOpen(isMenuOpen);
    setSubMenuOpen(isSubMenuOpen);
  }, [isMenuOpen, isSubMenuOpen]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      {children ? (
        <div
          className={` ${
            isOpen ? "bg-indigo-100 border-b border-b-indigo-300" : ""
          } flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-md group shadow-sm shadow-indigo-100 hover:bg-indigo-100 mb-1 group ${
            isActive ? "bg-indigo-100" : ""
          }`}
          onClick={() => {
            onDropdownClick();
            toggleDropdown();
          }}
        >
          {icon && <img className="h-5" src={icon} alt="icon" />}
          <span className="flex-1 ml-3 text-left whitespace-nowrap group-hover:text-indigo-600">
            {name}
          </span>
          {children && (
            <img
              className={`h-3 transition-transform duration-700 transform ${
                isOpen ? "rotate-180" : ""
              }`}
              src="https://cdn.staropstech.com/clients/udharibazaar/assets/extralogo/down.webp"
              alt="arrow-icon"
            />
          )}
        </div>
      ) : (
        <Link
          to={link}
          className={`flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-md group shadow-sm shadow-indigo-100 hover:bg-indigo-100 active:bg-indigo-100 mb-1 group overflow-hidden  ${
            isActive ? "bg-indigo-100" : ""
          }`}
          onClick={() => navigate(link, { replace: true })}
        >
          {icon && <img className="h-5" src={icon} alt="icon" />}
          <span className="flex-1 ml-3 text-left whitespace-nowrap group-hover:text-indigo-600 active:text-indigo-600">
            {name}
          </span>
        </Link>
      )}

      {children && isOpen && (
        <ul className="py-2 space-y-2">
          {children.map((child, index) => (
            <li key={index}>
              <DropdownItem {...child} onDropdownClick={onSubMenuClick} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
