import React, { useEffect, useState } from "react";
import properties from "../../../../Properties/Properties";
import { GetData } from "../../../../utils/ApiInvoker";
import GenerateURL from "../../../../utils/ApiUrlProvider";
import {
  GlobalFileDownlad,
  PartnerOrAssociate,
  StatusTag,
  containerStyle,
} from "../../../../utils/Healpers";
import { useNavigate, useParams } from "react-router-dom";
import { useLoadingContext } from "../../../../Context/LoadingContext";
import FileUploadModel from "../../../Common/Modal/FileUploadModel";
import DocumentDisplay from "../../../Common/DocumentDisplay";
import { PencilIcon } from "../../../../utils/SVGicons";
import { GetAuth } from "../../../../utils/AccessTokenStore";
import MissingDataImage from "../../../Common/MissingDataImage";
import ListTable from "../../../Common/ListTable";
import LoanBankOfferModal from "../../../Common/Modal/LoanBankOfferModal";

const AutoLoanDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const UserData = GetAuth();
  const PutUrls = properties.api.AutoLoanServices.EditUrl;
  const { showLoader, hideLoader } = useLoadingContext();
  const [loanDetail, setLoanDetail] = useState(null);
  const [bankOfferTableData, setbankOfferTableData] = useState(null);
  const [fileMoadlvisible, setFileMoadlvisible] = useState(false);
  const [imgEditDetail, setImgEditDetail] = useState({
    filedata: { label: "", name: "" },
    api: {
      url: "",
      id: "",
      secid: "",
    },
  });

  const [navtype, setNavType] = useState(0);
  const HandleSucess = (res) => {
    setLoanDetail(res);
    hideLoader();
  };
  const HandleError = (res) => {
    console.log(res);
    hideLoader();
  };
  const LoadAutoLoanData = () => {
    const url = GenerateURL(
      { id: params.id },
      properties.api.AutoLoanServices.GetAllDetailsAutoLoanById
    );

    showLoader();
    GetData(url, HandleSucess, HandleError, false, false);
  };

  const ReturnNavigateUrl = (url) => {
    if (PartnerOrAssociate()) {
      const navigateurl = `/partner${url}`;
      return navigateurl;
    } else {
      const navigateurl = `/user${url}`;
      return navigateurl;
    }
  };

  const convertDataToTableFormat = (data) => {
    const tableHeaders = [
      "Bank Name",
      "Rate Of Intrest",
      "Loan Tenure",
      "offer Amount",
      "Is Accepted",
      "Response Description",
    ];
    const tableContent = data.map((item) => {
      return {
        "Bank Name": item.bank_name.toUpperCase(),
        "Rate Of Intrest": item.rate_of_interest,
        "Loan Tenure": item.loan_tenure,
        "offer Amount": item.offer_amount,
        "Is Accepted": item.is_accepted
          ? StatusTag("TRUE")
          : StatusTag("FALSE"),
        "Response Description": item.response_description,
        bankoffer: item,
      };
    });
    return {
      tableHeaders,
      tableContent,
    };
  };
  const HandleBankSucess = (res) => {
    const data = convertDataToTableFormat(res);
    setbankOfferTableData(data);
  };
  const HandleBankError = () => {};
  const LoadBankOfferData = () => {
    const url = GenerateURL(
      { id: params.id },
      properties.api.Common.Loan.GetBankOffer
    );
    showLoader();
    GetData(url, HandleBankSucess, HandleBankError, false, false);
  };

  const [bankOfferModal, setBankOfferModal] = useState(false);
  const [bankOfferData, setBankOfferData] = useState(null);
  const ActionBtn = {
    name: ["Accept or Reject"],
    disabled: false,
    onClick: (e) => {
      setBankOfferData(e.bankoffer);
      setBankOfferModal(true);
    },
  };

  useEffect(() => {
    if (!fileMoadlvisible) {
      LoadAutoLoanData();
      // LoadBankOfferData();
    }
  }, [fileMoadlvisible, bankOfferModal]);
  return (
    <>
      {loanDetail && (
        <div className="w-full flex flex-col ml-5 mt-9 p-4 ">
          <div
            style={containerStyle}
            className="w-full border rounded-t-md p-4 py-10 text-3xl text-left capitalize text-white  "
          >
            <p className="shadow-sm">Auto Loan</p>
          </div>
          {bankOfferModal && (
            <LoanBankOfferModal
              onClose={() => setBankOfferModal(false)}
              initialValue={bankOfferData}
              putUrl={properties.api.Common.Loan.GetBankOfferEdit}
            />
          )}
          <div className="flex flex-col sm:flex-row items-center w-full gap-2 px-2 pt-2 border bg-logo-blue bg-opacity-10  rounded-b-md">
            <button
              onClick={() => setNavType(0)}
              className={`px-2 py-1 transition-all ease-in-out duration-200 ${
                navtype === 0
                  ? "text-logo-orange underline underline-offset-8"
                  : ""
              }`}
            >
              Personal Info
            </button>
            <button
              onClick={() => setNavType(1)}
              className={`px-2 py-1 transition-all ease-in-out duration-200 ${
                navtype === 1
                  ? "text-logo-orange underline underline-offset-8"
                  : ""
              }`}
            >
              {loanDetail?.is_salaried
                ? "Salaried Info"
                : "Self Employment Info"}
            </button>
            <button
              onClick={() => setNavType(2)}
              className={`px-2 py-1 transition-all ease-in-out duration-200 ${
                navtype === 2
                  ? "text-logo-orange underline underline-offset-8"
                  : ""
              }`}
            >
              Reference
            </button>
            <button
              onClick={() => setNavType(3)}
              className={`px-2 py-1 transition-all ease-in-out duration-200 ${
                navtype === 3
                  ? "text-logo-orange underline underline-offset-8"
                  : ""
              }`}
            >
              Documents
            </button>
            {/* <button
              onClick={() => setNavType(4)}
              className={`px-2 py-1 transition-all ease-in-out duration-200 ${
                navtype === 4
                  ? "text-logo-orange underline underline-offset-8"
                  : ""
              }`}
            >
              Bank Offer
            </button> */}
          </div>
          {navtype === 0 && (
            <div className="w-full flex flex-col shadow-lg rounded-2xl bg-white mt-5">
              <div className="p-4 flex justify-between items-center">
                <h1 className="text-2xl text-logo-orange">Personal Info</h1>
                {UserData.id === loanDetail.applied_by.id && (
                  <button
                    onClick={() =>
                      navigate(
                        ReturnNavigateUrl(
                          `/loan/autoloan/${loanDetail.id}/edit`
                        )
                      )
                    }
                  >
                    <PencilIcon styles={"h-5"} />
                  </button>
                )}
              </div>
              <div className="w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 p-4 capitalize">
                <div className="flex flex-col">
                  <p className="font-medium">Application Number</p>
                  <p className="text-gray-500 text-sm">
                    {loanDetail.application_number}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium">Status</p>
                  <p className="text-gray-500 text-sm">
                    {StatusTag(loanDetail.status)}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium">Description</p>
                  <p className="text-gray-500 text-sm">
                    {loanDetail.description || "-"}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium ">Disbursement Amount</p>
                  <p className="text-gray-500 text-sm">
                    {loanDetail.disbursement_amount || "--"}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium">Name</p>
                  <p className="text-gray-500 text-sm">
                    {loanDetail.first_name}
                    <span> </span>
                    {loanDetail.last_name}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium">Father's Name</p>
                  <p className="text-gray-500 text-sm">
                    {loanDetail.father_name}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium">Mother's Name</p>
                  <p className="text-gray-500 text-sm">
                    {loanDetail.mother_name}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium">Spouse/Husband's Name</p>
                  <p className="text-gray-500 text-sm">
                    {loanDetail.spouse_name || "--"}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium">Date Of Birth</p>
                  <p className="text-gray-500 text-sm">{loanDetail.dob}</p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium ">Pan Number</p>
                  <p className="text-gray-500 text-sm uppercase">
                    {loanDetail.pan_number}
                  </p>
                </div>
                <div className=" w-full flex flex-row justify-between">
                  <div className="flex flex-col">
                    <p className="font-medium">Permanent Address</p>
                    <p className="text-gray-500 text-sm">
                      {loanDetail.personal_address.house_address},<span> </span>
                      {loanDetail.personal_address.landmark || " "}
                      {loanDetail.personal_address.city}
                      <br />
                      {loanDetail.personal_address.state},<span> </span>
                      {loanDetail.personal_address.pin_code}
                    </p>
                  </div>
                </div>
                {loanDetail.current_address && (
                  <div className=" w-full flex flex-row justify-between">
                    <div className="flex flex-col">
                      <p className="font-medium">Current Address</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.current_address.house_address},
                        <span> </span>
                        {loanDetail.current_address.landmark || " "}
                        {loanDetail.current_address.city}
                        <br />
                        {loanDetail.current_address.state},<span> </span>
                        {loanDetail.current_address.pin_code}
                      </p>
                    </div>
                  </div>
                )}
                <div className="flex flex-col">
                  <p className="font-medium ">Gender</p>
                  <p className="text-gray-500 text-sm">{loanDetail.gender}</p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium ">Mobile No</p>
                  <p className="text-gray-500 text-sm overflow-clip">
                    {loanDetail.mobile_number}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium ">Email</p>
                  <p className="text-gray-500 text-sm overflow-clip lowercase">
                    {loanDetail.email}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium ">Vehicle Brand</p>
                  <p className="text-gray-500 text-sm overflow-clip lowercase">
                    {loanDetail.vehicle_brand}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium ">Vehicle Model</p>
                  <p className="text-gray-500 text-sm overflow-clip lowercase">
                    {loanDetail.vehicle_model}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium ">Vehicle Price</p>
                  <p className="text-gray-500 text-sm overflow-clip lowercase">
                    {loanDetail.vehicle_price}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium ">Email</p>
                  <p className="text-gray-500 text-sm overflow-clip lowercase">
                    {loanDetail.email}
                  </p>
                </div>
              </div>
            </div>
          )}
          {navtype === 1 && (
            <div className="w-full flex flex-col shadow-lg rounded-2xl bg-white mt-5">
              {loanDetail.is_salaried === true ? (
                <>
                  {loanDetail.salaried_information === null ? (
                    <div className="p-4 flex flex-row justify-between items-center">
                      <MissingDataImage
                        message={"No slaried Info added"}
                        btnurl={ReturnNavigateUrl(
                          `/loan/autoloan/${loanDetail.id}/salaried`
                        )}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="p-4  flex justify-between items-center">
                        <h1 className="text-2xl text-logo-orange">
                          Salaried Info
                        </h1>
                        {UserData.id === loanDetail.applied_by.id && (
                          <button
                            onClick={() =>
                              navigate(
                                ReturnNavigateUrl(
                                  `/loan/autoloan/${loanDetail.id}/salaried/${loanDetail.salaried_information.id}/edit`
                                )
                              )
                            }
                          >
                            <PencilIcon styles={"h-5"} />
                          </button>
                        )}
                      </div>

                      <div className="w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 p-4 capitalize">
                        <div className="flex flex-col">
                          <p className="font-medium">Company Name</p>
                          <p className="text-gray-500 text-sm">
                            {loanDetail.salaried_information.company_name}
                          </p>
                        </div>
                        <div className="flex flex-col">
                          <p className="font-medium">Salary</p>
                          <p className="text-gray-500 text-sm">
                            {loanDetail.salaried_information.salary}
                          </p>
                        </div>
                        <div className="flex flex-col">
                          <p className="font-medium">Working Since</p>
                          <p className="text-gray-500 text-sm">
                            {loanDetail.salaried_information.working_duration}
                          </p>
                        </div>
                        <div className=" w-full flex flex-row justify-between">
                          <div className="flex flex-col">
                            <p className="font-medium">Address</p>
                            <p className="text-gray-500 text-sm">
                              {
                                loanDetail.salaried_information.office_address
                                  .house_address
                              }
                              ,<span> </span>
                              {loanDetail.salaried_information.office_address
                                .landmark || " "}
                              {
                                loanDetail.salaried_information.office_address
                                  .city
                              }
                              <br />
                              {
                                loanDetail.salaried_information.office_address
                                  .state
                              }
                              ,<span> </span>
                              {
                                loanDetail.salaried_information.office_address
                                  .pin_code
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  {loanDetail.self_employeed_information === null ? (
                    <div className="p-4 flex flex-row justify-between items-center">
                      <MissingDataImage
                        message={"No Self Employment Info added"}
                        btnurl={ReturnNavigateUrl(
                          `/loan/autoloan/${loanDetail.id}/selfEmployed`
                        )}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="p-4  flex justify-between items-center">
                        <h1 className="text-2xl text-logo-orange">
                          Self Employment Info
                        </h1>
                        {UserData.id === loanDetail.applied_by.id && (
                          <button
                            onClick={() =>
                              navigate(
                                ReturnNavigateUrl(
                                  `/loan/autoloan/${loanDetail.id}/selfEmployed/${loanDetail.self_employeed_information.id}/edit`
                                )
                              )
                            }
                          >
                            <PencilIcon styles={"h-5"} />
                          </button>
                        )}
                      </div>
                      <div className="w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 p-4 capitalize">
                        <div className="flex flex-col">
                          <p className="font-medium">Firm Name</p>
                          <p className="text-gray-500 text-sm">
                            {loanDetail.self_employeed_information.firm_name}
                          </p>
                        </div>
                        <div className="flex flex-col">
                          <p className="font-medium">Email</p>
                          <p className="text-gray-500 text-sm">
                            {
                              loanDetail.self_employeed_information
                                .official_email
                            }
                          </p>
                        </div>
                        <div className="flex flex-col">
                          <p className="font-medium">Product Category</p>
                          <p className="text-gray-500 text-sm">
                            {
                              loanDetail.self_employeed_information
                                .product_category
                            }
                          </p>
                        </div>
                        <div className="flex flex-col">
                          <p className="font-medium">Business Run By</p>
                          <p className="text-gray-500 text-sm">
                            {
                              loanDetail.self_employeed_information
                                .business_run_by
                            }
                          </p>
                        </div>
                        <div className="flex flex-col">
                          <p className="font-medium">Business Nature</p>
                          <p className="text-gray-500 text-sm">
                            {
                              loanDetail.self_employeed_information
                                .nature_of_business
                            }
                          </p>
                        </div>
                        <div className="flex flex-col">
                          <p className="font-medium">Description</p>
                          <p className="text-gray-500 text-sm">
                            {loanDetail.self_employeed_information.description}
                          </p>
                        </div>
                        <div className="flex flex-col">
                          <p className="font-medium">Gst Number</p>
                          <p className="text-gray-500 text-sm">
                            {loanDetail.self_employeed_information.gst_no}
                          </p>
                        </div>
                        <div className=" w-full flex flex-row justify-between">
                          <div className="flex flex-col">
                            <p className="font-medium">Address</p>
                            <p className="text-gray-500 text-sm">
                              {
                                loanDetail.self_employeed_information
                                  .business_address.house_address
                              }
                              ,<span> </span>
                              {
                                loanDetail.self_employeed_information
                                  .business_address.city
                              }
                              <br />
                              {
                                loanDetail.self_employeed_information
                                  .business_address.state
                              }
                              ,<span> </span>
                              {
                                loanDetail.self_employeed_information
                                  .business_address.pin_code
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          )}

          {navtype === 2 && (
            <div className="w-full flex flex-col shadow-lg rounded-2xl bg-white mt-5">
              {loanDetail.first_reference === null ? (
                <div className="p-4 flex flex-row justify-between items-center">
                  <MissingDataImage
                    message={"No Refernce Info added"}
                    btnurl={ReturnNavigateUrl(
                      `/loan/autoloan/${loanDetail.id}/reference`
                    )}
                  />
                </div>
              ) : (
                <>
                  <div className="p-4  flex justify-between items-center">
                    <h1 className="text-2xl text-logo-orange">
                      First Reference Info
                    </h1>
                    {UserData.id === loanDetail.applied_by.id && (
                      <button
                        onClick={() =>
                          navigate(
                            ReturnNavigateUrl(
                              `/loan/autoloan/${loanDetail.id}/reference/${loanDetail.first_reference.id}/edit`
                            )
                          )
                        }
                      >
                        <PencilIcon styles={"h-5"} />
                      </button>
                    )}
                  </div>
                  <div className="w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 p-4 capitalize">
                    <div className="flex flex-col">
                      <p className="font-medium">Name</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.first_reference.name}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-medium">Email</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.first_reference.email}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-medium">Mobile No</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.first_reference.mobile_no}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-medium">Relation</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.first_reference.relationship}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-medium">Reference Address</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.first_reference.reffery_address
                          .house_address || ""}
                        ,<span> </span>
                        {loanDetail.first_reference.reffery_address.city || ""}
                        <br />
                        {loanDetail.first_reference.reffery_address.state || ""}
                        ,<span> </span>
                        {loanDetail.first_reference.reffery_address.pin_code ||
                          ""}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {navtype === 2 && loanDetail.first_reference != null && (
            <>
              <div className="w-full flex flex-col shadow-lg rounded-2xl bg-white mt-5">
                {loanDetail.second_reference === null ? (
                  <div className="p-4 flex flex-row justify-between items-center">
                    <MissingDataImage
                      message={"No Second Reference Info"}
                      btnurl={ReturnNavigateUrl(
                        `/loan/autoloan/${loanDetail.id}/reference`
                      )}
                    />
                  </div>
                ) : (
                  <>
                    <div className="p-4  flex justify-between items-center ">
                      <h1 className="text-2xl text-logo-orange">
                        Second Reference
                      </h1>
                      {UserData.id === loanDetail.applied_by.id && (
                        <button
                          onClick={() =>
                            navigate(
                              ReturnNavigateUrl(
                                `/loan/autoloan/${loanDetail.id}/reference/${loanDetail.second_reference.id}/edit`
                              )
                            )
                          }
                        >
                          <PencilIcon styles={"h-5"} />
                        </button>
                      )}
                    </div>
                    <div className="w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 p-4 capitalize">
                      <div className="flex flex-col">
                        <p className="font-medium">Name</p>
                        <p className="text-gray-500 text-sm">
                          {loanDetail.second_reference.name}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className="font-medium">Email</p>
                        <p className="text-gray-500 text-sm">
                          {loanDetail.second_reference.email || "--"}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className="font-medium">Mobile No</p>
                        <p className="text-gray-500 text-sm">
                          {loanDetail.second_reference.mobile_no}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className="font-medium">Relation</p>
                        <p className="text-gray-500 text-sm">
                          {loanDetail.second_reference.relationship}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className="font-medium">Reference Address</p>
                        <p className="text-gray-500 text-sm">
                          {loanDetail.second_reference.reffery_address
                            .house_address || ""}
                          ,<span> </span>
                          {loanDetail.second_reference.reffery_address.city ||
                            ""}
                          <br />
                          {loanDetail.second_reference.reffery_address.state ||
                            ""}
                          ,<span> </span>
                          {loanDetail.second_reference.reffery_address
                            .pin_code || ""}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}

          {navtype === 3 && (
            <div className="w-full flex flex-col shadow-lg rounded-2xl bg-white mt-5">
              {fileMoadlvisible && (
                <FileUploadModel
                  filedata={imgEditDetail.filedata}
                  api={imgEditDetail.api}
                  onClose={() => setFileMoadlvisible((prev) => !prev)}
                />
              )}
              <h1 className="p-4 text-2xl text-logo-orange">
                Personal Detail Files
              </h1>
              <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 p-4 capitalize">
                <DocumentDisplay
                  label={"Proof Of Identity"}
                  name="poi"
                  putapi={{
                    url: PutUrls.UserAutoLoanEdit,
                    id: loanDetail.id,
                    secid: "",
                  }}
                  ShowChange={Boolean(UserData.id === loanDetail.applied_by.id)}
                  Detail={loanDetail}
                  GlobalFileDownlad={GlobalFileDownlad}
                  setImgEditDetail={setImgEditDetail}
                  setFileMoadlvisible={setFileMoadlvisible}
                />
                <DocumentDisplay
                  label={"Proof Of Address"}
                  name="poa"
                  putapi={{
                    url: PutUrls.UserAutoLoanEdit,
                    id: loanDetail.id,
                    secid: "",
                  }}
                  Detail={loanDetail}
                  ShowChange={Boolean(UserData.id === loanDetail.applied_by.id)}
                  GlobalFileDownlad={GlobalFileDownlad}
                  setImgEditDetail={setImgEditDetail}
                  setFileMoadlvisible={setFileMoadlvisible}
                />
                <DocumentDisplay
                  label={"Rent Agreement"}
                  name="rent_aggrement"
                  putapi={{
                    url: PutUrls.UserAutoLoanEdit,
                    id: loanDetail.id,
                    secid: "",
                  }}
                  Detail={loanDetail}
                  ShowChange={Boolean(UserData.id === loanDetail.applied_by.id)}
                  GlobalFileDownlad={GlobalFileDownlad}
                  setImgEditDetail={setImgEditDetail}
                  setFileMoadlvisible={setFileMoadlvisible}
                />
                <DocumentDisplay
                  label={"Pan Card"}
                  name="pan_card"
                  putapi={{
                    url: PutUrls.UserAutoLoanEdit,
                    id: loanDetail.id,
                    secid: "",
                  }}
                  Detail={loanDetail}
                  ShowChange={Boolean(UserData.id === loanDetail.applied_by.id)}
                  GlobalFileDownlad={GlobalFileDownlad}
                  setImgEditDetail={setImgEditDetail}
                  setFileMoadlvisible={setFileMoadlvisible}
                />
                <DocumentDisplay
                  label={"Photo"}
                  name="photo"
                  putapi={{
                    url: PutUrls.UserAutoLoanEdit,
                    id: loanDetail.id,
                    secid: "",
                  }}
                  Detail={loanDetail}
                  ShowChange={Boolean(UserData.id === loanDetail.applied_by.id)}
                  GlobalFileDownlad={GlobalFileDownlad}
                  setImgEditDetail={setImgEditDetail}
                  setFileMoadlvisible={setFileMoadlvisible}
                />
                <DocumentDisplay
                  label={"Aadhaar card"}
                  name="aadhar_card"
                  putapi={{
                    url: PutUrls.UserAutoLoanEdit,
                    id: loanDetail.id,
                    secid: "",
                  }}
                  Detail={loanDetail}
                  ShowChange={Boolean(UserData.id === loanDetail.applied_by.id)}
                  GlobalFileDownlad={GlobalFileDownlad}
                  setImgEditDetail={setImgEditDetail}
                  setFileMoadlvisible={setFileMoadlvisible}
                />
              </div>
              {loanDetail.salaried_information && (
                <>
                  <h1 className="p-4 text-2xl text-logo-orange">
                    SalariedInfo Files
                  </h1>
                  <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 p-4 capitalize">
                    <DocumentDisplay
                      label={"Form Sixteen"}
                      name="form_sixteen_document"
                      putapi={{
                        url: PutUrls.SalaryDetailAutoLoanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.salaried_information.id,
                      }}
                      Detail={loanDetail.salaried_information}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"Bank Statement"}
                      name="bank_statement"
                      putapi={{
                        url: PutUrls.SalaryDetailAutoLoanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.salaried_information.id,
                      }}
                      Detail={loanDetail.salaried_information}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"Salary Slip (last 3 months)"}
                      name="last_three_month_salary_slip"
                      putapi={{
                        url: PutUrls.SalaryDetailAutoLoanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.salaried_information.id,
                      }}
                      Detail={loanDetail.salaried_information}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                  </div>
                </>
              )}
              {loanDetail.self_employeed_information && (
                <>
                  <h1 className="p-4 text-2xl text-logo-orange">
                    Self Employment Info Files
                  </h1>
                  <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 p-4 capitalize">
                    <DocumentDisplay
                      label={"Company GST"}
                      name="company_gst_certificate"
                      putapi={{
                        url: PutUrls.SelfEmploymentAutoLoanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.self_employeed_information.id,
                      }}
                      Detail={loanDetail.self_employeed_information}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"Company Udhyam"}
                      name="company_udayam_certificate"
                      putapi={{
                        url: PutUrls.SelfEmploymentAutoLoanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.self_employeed_information.id,
                      }}
                      Detail={loanDetail.self_employeed_information}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"Bank Statement"}
                      name="bank_statement"
                      putapi={{
                        url: PutUrls.SelfEmploymentAutoLoanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.self_employeed_information.id,
                      }}
                      Detail={loanDetail.self_employeed_information}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"Ownweship Proof"}
                      name="ownership_proof"
                      putapi={{
                        url: PutUrls.SelfEmploymentAutoLoanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.self_employeed_information.id,
                      }}
                      Detail={loanDetail.self_employeed_information}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"ITR With Finincial Computations (last 2 yr)"}
                      name="last_2_year_itr_with_financial_computation_gst_returns"
                      putapi={{
                        url: PutUrls.SelfEmploymentAutoLoanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.self_employeed_information.id,
                      }}
                      Detail={loanDetail.self_employeed_information}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />

                    <DocumentDisplay
                      label={"Twenty Six AS form"}
                      name="twenty_six_A_S_form"
                      putapi={{
                        url: PutUrls.SelfEmploymentAutoLoanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.self_employeed_information.id,
                      }}
                      Detail={loanDetail.self_employeed_information}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"RC Insurance Quotation"}
                      name="rc_insurance_quotation"
                      putapi={{
                        url: PutUrls.SelfEmploymentAutoLoanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.self_employeed_information.id,
                      }}
                      Detail={loanDetail.self_employeed_information}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                  </div>
                </>
              )}
              {loanDetail.co_applicant_details && (
                <>
                  <h1 className="p-4 text-2xl text-logo-orange">
                    CoApplicant Files
                  </h1>
                  <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 p-4 capitalize">
                    <DocumentDisplay
                      label={"Proof Of Identity"}
                      name="poi"
                      putapi={{
                        url: PutUrls.CoapplicantAutoLoanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.co_applicant_details.id,
                      }}
                      Detail={loanDetail.co_applicant_details}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"Proof Of Address"}
                      name="poa"
                      putapi={{
                        url: PutUrls.CoapplicantAutoLoanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.co_applicant_details.id,
                      }}
                      Detail={loanDetail.co_applicant_details}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"Rent Agreement"}
                      name="rent_aggrement"
                      putapi={{
                        url: PutUrls.CoapplicantAutoLoanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.co_applicant_details.id,
                      }}
                      Detail={loanDetail.co_applicant_details}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"Pan Card"}
                      name="pan_card"
                      putapi={{
                        url: PutUrls.CoapplicantAutoLoanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.co_applicant_details.id,
                      }}
                      Detail={loanDetail.co_applicant_details}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"Photo"}
                      name="photo"
                      putapi={{
                        url: PutUrls.CoapplicantAutoLoanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.co_applicant_details.id,
                      }}
                      Detail={loanDetail.co_applicant_details}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"Aadhaar card"}
                      name="aadhar_card"
                      putapi={{
                        url: PutUrls.CoapplicantAutoLoanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.co_applicant_details.id,
                      }}
                      Detail={loanDetail.co_applicant_details}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                  </div>
                </>
              )}
            </div>
          )}
          {navtype === 4 && (
            <>
              {bankOfferTableData ? (
                <div className="w-full py-4">
                  <ListTable
                    heading="Total Users"
                    tableData={bankOfferTableData}
                    pagination={false}
                    actions={{
                      view: false,
                      edit: false,
                      delete: false,
                      other: ActionBtn,
                    }}
                  />
                </div>
              ) : (
                <div className="w-full flex flex-col shadow-lg rounded-2xl bg-white mt-5">
                  <MissingDataImage message={"No bannk offer added "} />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default AutoLoanDetail;
