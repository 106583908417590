import properties from "../Properties/Properties";
var api = properties.api;

const base_url = api.baseURL;

export const GET_Request_Info = (url) => {
  return {
    endPoint: url,
    httpMethod: "GET",
    httpHeaders: { "Content-Type": "application/json" },
  };
};

const GenerateURL = (palceholders, url, only_append) => {
  const keys = palceholders ? Object.keys(palceholders) : [];
  var generated_url = url;
  // console.log("keys", palceholders, keys, generated_url);
  keys.forEach((key) => {
    generated_url = generated_url.replace("${" + key + "}", palceholders[key]);
  });
  return only_append ? "/" + generated_url : base_url + generated_url;
};

export function GenerateSearchURL(data, url) {
  url += "?";
  const keys = Object.keys(data);
  keys.forEach((key, index) => {
    if (index !== 0) {
      url += "&" + key + "=" + data[key];
    } else {
      url += key + "=" + data[key];
    }
  });
  return base_url + url;
}
export function GenerateSearchURLWithparms(palceholders, params, url) {
  const keys = palceholders ? Object.keys(palceholders) : [];
  var generated_url = url;
  keys.forEach((key) => {
    generated_url = generated_url.replace("${" + key + "}", palceholders[key]);
  });
  generated_url += "?";
  const Parmskeys = Object.keys(params);
  Parmskeys.forEach((key, index) => {
    if (index !== 0) {
      generated_url += "&" + key + "=" + params[key];
    } else {
      generated_url += key + "=" + params[key];
    }
  });

  return base_url + generated_url;
}

export default GenerateURL;
