import React, { useEffect, useState } from "react";
import { renderInput } from "../../Common/FormComponents";
import GenerateURL from "../../../utils/ApiUrlProvider";
import properties from "../../../Properties/Properties";
import {
  GetAuth,
  IsAssociatePartner,
  IsPartnerUser,
} from "../../../utils/AccessTokenStore";
import { GetData, PutData } from "../../../utils/ApiInvoker";
import { useNavigate } from "react-router-dom";
import PreLoader from "../../Common/Preloader";
import { useLoadingContext } from "../../../Context/LoadingContext";

const PartnerDetailsEdit = () => {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useLoadingContext();
  const user = GetAuth();
  const fileValidation = {
    file_size: "10MB",
    file_type: ["jpg", "png", "pdf", "heic", "jpeg"],
    is_required: false,
  };

  const [formData, setFormData] = useState(null);
  const [formError, setFormError] = useState({
    pan_number: "",
    aadhar_number: "",
    gst_number: "",
    gst_certificate_id: "",
    pan_card_id: "",
    aadhar_card_id: "",
    agreement_document_id: "",
  });

  const handleAdditionalDetailSuccess = (res) => {
    const data = {
      gst: Boolean(res.gst_number || res.gst_certificate),
      pan_number: res.pan_number,
      aadhar_number: res.aadhar_number,
      gst_number: res.gst_number,
      gst_certificate_id: res.gst_certificate ? res.gst_certificate.id : null,
      pan_card_id: res.pan_card ? res.pan_card.id : null,
      aadhar_card_id: res.aadhar_card ? res.aadhar_card.id : null,
      agreement_document_id: 1,
    };
    setFormData(data);
  };
  const handleAdditionalDetailError = (err) => {
    console.log(err);
  };
  const GetPartnerAdditionalDetail = () => {
    const user = GetAuth();
    if (IsPartnerUser() || IsAssociatePartner()) {
      const url = GenerateURL(
        { user_id: user.id },
        properties.api.partnerUpdateProfile
      );
      GetData(
        url,
        handleAdditionalDetailSuccess,
        handleAdditionalDetailError,
        false
      );
    }
  };

  useEffect(() => {
    GetPartnerAdditionalDetail();
  }, []);

  const ChangeHandle = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "aadhar_number") {
      const numericValue = /^[0-9]+$/.test(value) ? value : "";
      setFormData({ ...formData, [name]: numericValue });
      setFormError({ ...formError, [name]: "" });
    } else {
      setFormData({ ...formData, [name]: value });
      setFormError({ ...formError, [name]: "" });
    }
  };

  const Validation = (data) => {
    const errors = {};
    let valid = true;
    if (data.aadhar_number.trim() === "") {
      errors.aadhar_number = "Aadhaar number is required";
      valid = false;
    } else if (!/^\d{12}$/.test(data.aadhar_number)) {
      errors.aadhar_number = "Aadhaar number must be a 12-digit number";
      valid = false;
    }

    if (data.pan_number.trim() === "") {
      errors.pan_number = "PAN number is required";
      valid = false;
    } else if (
      !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(data.pan_number.toUpperCase())
    ) {
      errors.pan_number = "Invalid PAN number format";
      valid = false;
    }

    if (!data.aadhar_card_id) {
      errors.aadhar_card_id = "Upload Aadhaar card";
      valid = false;
    }

    if (!data.pan_card_id) {
      errors.pan_card_id = "Upload PAN card";
      valid = false;
    }

    if (data.gst) {
      if (data.gst_number.trim() === "") {
        errors.gst_number = "GST number is required";
        valid = false;
      } else if (data.gst_number.length !== 15) {
        errors.gst_number = "GST number is not Valid";
        valid = false;
      }
      if (!data.gst_certificate_id) {
        valid = false;
        errors.gst_certificate_id = "Upload  GST certificate";
      }
    }
    return { valid, errors };
  };

  const HandleSucess = (res) => {
    hideLoader();
    navigate("/partner/dashboard");
    console.log(res);
  };
  const HandleError = (err) => {
    hideLoader();
    console.log(err);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validated = Validation(formData);
    if (validated.valid) {
      const user = GetAuth();
      const url = GenerateURL(
        { user_id: user.id },
        properties.api.partnerUpdateProfile
      );
      showLoader();
      PutData(url, formData, HandleSucess, HandleError, false);
    } else {
      console.log("error", validated);
      setFormError({ ...validated.errors });
    }
  };
  return (
    <>
      {formData ? (
        <div className=" flex flex-row w-full">
          <div className="hidden sm:block sm:w-1/3  bg-logo-orange bg-opacity-50  text-logo-blue">
            <svg
              className="h-full"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 900 600"
            >
              <path
                fill="#fa7268"
                d="M0 235h82V97h82v6h81v90h82v48h82V115h82v210h82V181h82v42h81V91h82v24h82v78V0H0z"
              ></path>
              <path
                fill="#ea5e66"
                d="M0 313h82v-96h82v-6h81v72h82v12h82V169h82v222h82V241h82v144h81V253h82v-78h82v72-134h-82V89h-82v132h-81v-42h-82v144h-82V113h-82v126h-82v-48h-82v-90h-81v-6H82v138H0z"
              ></path>
              <path
                fill="#d84a64"
                d="M0 499h82v-96h82v-18h81v120h82V403h82v30h82v60h82v-48h82v36h81v-24h82v12h82V173h-82v78h-82v132h-81V239h-82v150h-82V167h-82v126h-82v-12h-82v-72h-81v6H82v96H0z"
              ></path>
              <path
                fill="#c53762"
                d="M0 541h82v-18h82v-24h81v60h82v-42h82v-12h82v42h82v18h82v-30h81v-6h82v-12h82v24-92 18h-82v-12h-82v24h-81v-36h-82v48h-82v-60h-82v-30h-82v102h-82V383h-81v18H82v96H0z"
              ></path>
              <path
                fill="#b0235f"
                d="M0 601h900v-86h-82v12h-82v6h-81v30h-82v-18h-82v-42h-82v12h-82v42h-82v-60h-81v24H82v18H0z"
              ></path>
            </svg>
          </div>
          <div className="w-full sm:w-2/3 flex items-center  flex-col bg-white ">
            <div className="w-10/12 mt-24">
              <h1 className="text-2xl md:text-3xl pl-2 my-2 border-l-4  font-sans font-bold  border-logo-blue mb-10  rounded-sm text-logo-blue ">
                Welcome {user.name},
                <span className="block ">
                  Update your <span className=" text-logo-orange">Partner</span>{" "}
                  profile.
                </span>
              </h1>
              {renderInput("STRING", {
                label: "Pan Number",
                name: "pan_number",
                value: formData.pan_number,
                onChange: ChangeHandle,
                error: formError.pan_number,
              })}
              {renderInput("FILE_UPLOAD", {
                label: "Pan Card",
                name: "pan_card_id",
                code: "pancard",
                value: formData.pan_card_id,
                validations: fileValidation,
                onChange: ChangeHandle,
                onlyId: true,
                error: formError.pan_card_id,
              })}
              {renderInput("STRING", {
                label: "Aadhaar Number",
                name: "aadhar_number",
                value: formData.aadhar_number,
                onChange: ChangeHandle,
                error: formError.aadhar_number,
              })}
              {renderInput("FILE_UPLOAD", {
                label: "Aadhaar Card",
                name: "aadhar_card_id",
                code: "aadharcard",
                value: formData.aadhar_card_id,
                validations: fileValidation,
                onChange: ChangeHandle,
                onlyId: true,
                error: formError.aadhar_card_id,
              })}
              {/* {renderInput("BOOLEAN", {
                name: "gst",
                label: "Do you have GST",
                value: formData.gst,
                onChange: ChangeHandle,
              })} */}
              <div className="mb-4 flex gap-1 sm:gap-2 md:gap-3  items-centre">
                <p className="flex items-center justify-end text-black w-1/2 md:w-1/3  text-right text-lg">
                  "Do you have GST"
                </p>
                <div className="w-3/4 md:w-2/3 ">
                  <div className=" w-full grid grid-cols-1 md:grid-cols-2 gap-1 items-center">
                    <div
                      className={`border flex items-center rounded-md h-10 px-1 text-sm sm:text-base  ${
                        formData.gst
                          ? "bg-blue-100 border-blue-200 font-semibold  text-gray-700 "
                          : "font-medium  text-gray-500 "
                      }`}
                      onClick={() =>
                        setFormData((prev) => ({ ...prev, gst: true }))
                      }
                    >
                      <div className="w-full h-full flex flex-row  items-center gap-2">
                        <div
                          className={`rounded-full h-3 w-3 uppercase border ${
                            formData.gst
                              ? " bg-slate-400 border-blue-200 outline outline-slate-400 "
                              : " "
                          }`}
                        ></div>
                        Yes
                      </div>
                    </div>
                    <div
                      className={`border flex items-center rounded-md h-10 px-1 text-sm sm:text-base  ${
                        !formData.gst
                          ? "bg-blue-100 border-blue-200 font-semibold  text-gray-700 "
                          : "font-medium  text-gray-500 "
                      }`}
                      onClick={() =>
                        setFormData((prev) => ({ ...prev, gst: false }))
                      }
                    >
                      <div className="w-full h-full flex flex-row  items-center gap-2">
                        <div
                          className={`rounded-full h-3 w-3 uppercase border ${
                            !formData.gst
                              ? " bg-slate-400 border-blue-200 outline outline-slate-400 "
                              : " "
                          }`}
                        ></div>
                        No
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {formData.gst && (
                <>
                  {renderInput("STRING", {
                    label: "GST Number",
                    name: "gst_number",
                    onChange: ChangeHandle,
                    value: formData.gst_number,
                    error: formError.gst_number,
                  })}
                  {renderInput("FILE_UPLOAD", {
                    label: "GST Certificate",
                    name: "gst_certificate_id",
                    code: "GST",
                    onChange: ChangeHandle,
                    onlyId: true,
                    value: formData.gst_certificate_id,
                    validations: fileValidation,
                    error: formError.gst_certificate_id,
                  })}
                </>
              )}
            </div>
            <button
              className="bg-blue-600 text-white px-4 py-2 rounded w-max"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      ) : (
        <PreLoader />
      )}
    </>
  );
};

export default PartnerDetailsEdit;
