import React, { useState, useEffect } from "react";
import data from "../../../data/homepagedata.json";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Counter = ({ iconSrc, label, initialValue, targetValue, unit }) => {
  const [count, setCount] = useState(initialValue);
  const animationDuration = 850;

  useEffect(() => {
    let startTime;
    let animationFrameId;
    const startAnimation = () => {
      const updateCount = () => {
        const currentTime = Date.now();
        const elapsed = currentTime - startTime;
        if (elapsed < animationDuration) {
          const progress = elapsed / animationDuration;
          const newValue = Math.floor(targetValue * progress);
          setCount(newValue);
          animationFrameId = requestAnimationFrame(updateCount);
        } else {
          setCount(targetValue);
          cancelAnimationFrame(animationFrameId);
        }
      };
      startTime = Date.now();
      animationFrameId = requestAnimationFrame(updateCount);
    };

    startAnimation();
    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <div className="card m-5 mx-auto w-[200px] h-[230px] border shadow-lg rounded-lg bg-white p-2  flex flex-col items-center justify-center border-logo-blue border-opacity-30 hover:scale-105 hover:shadow-xl gap-3">
      <div className="flex items-center flex-col justify-center">
        <img className="h-12" src={iconSrc} alt="" />
        <p className="text-sm text-logo-orange font-semibold text-opacity-80">
          {label}
        </p>
      </div>
      <p className="text-3xl font-extrabold">
        {count}
        {unit}
      </p>
    </div>
  );
};

const Count = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const counterdata = data.coundata;
  return (
    <div className="flex items-center flex-col text-logo-blue w-10/12 m-auto shadow-sm rounded">
      <h1 className="text-4xl  font-bold capitalize text-center py-2">
        The Story of UdhariBazaar In Numbers
      </h1>
      <p className="w-32 border-b-2 border-logo-orange"></p>
      <p className="text-sm text-logo-blue text-opacity-90 p-2 font-semibold text-center">
        Over Rs. 20 Cr Loan Distributed Annually
      </p>

      <div className="w-full  my-5 min-h-[290px]  ">
        <Carousel
          Carousel
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          responsive={responsive}
          swipeable={false}
          draggable={false}
          arrows={false}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          containerClass="carousel-container"
        >
          {counterdata.map((data, index) => {
            return <Counter key={index} {...data} />;
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default Count;
