import React, { useState } from "react";
import DashboardHeader from "../../../Hoc/DashboardHeader";
import SidePannel from "../../../Hoc/SidePannel";
import { AdminMenuItems } from "../../../helper/MenuItems";

const AdminLayout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div>
      <DashboardHeader
        sidebarOpen={sidebarOpen}
        toggleSidebar={toggleSidebar}
      />
      <div className="flex min-h-screen">
        <SidePannel sidebarOpen={sidebarOpen} item={AdminMenuItems} />
        <div className="sm:ml-64 mt-7  w-full  bg-slate-100 flex  justify-center border-2  ">
          {children}
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
