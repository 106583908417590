import React, { useEffect, useState } from "react";
import { InputTypeRendering, renderInput } from "../../Common/FormComponents";
import properties from "../../../Properties/Properties";
import { useNavigate } from "react-router-dom";
import GenerateURL from "../../../utils/ApiUrlProvider";
import { PersistLoginCreds } from "../../../utils/AccessTokenStore";
import { PostData } from "../../../utils/ApiInvoker";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminLogin = () => {
  const navigate = useNavigate();
  const [mobileNo, setMobileNo] = useState("");
  const [step, setStep] = useState(1);
  const [otp, setOtp] = useState("");
  const [mobileNoError, setMobileNoError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [count, setCount] = useState(25);

  const startTimer = () => {
    let timer;
    setCount(25);

    if (step === 2) {
      timer = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount > 0) {
            return prevCount - 1;
          } else {
            clearInterval(timer);
            return 0;
          }
        });
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  };

  useEffect(() => {
    startTimer();
  }, [step]);

  const validateMobileNumber = (mobileNumber) => {
    if (
      !mobileNumber ||
      mobileNumber.length !== 10 ||
      !/^\d+$/.test(mobileNumber)
    ) {
      return {
        valid: false,
        message: mobileNumber
          ? "Mobile number must be 10 digits"
          : "Mobile number can't be blank",
      };
    }

    return {
      valid: true,
      message: "Valid Mobile Number",
    };
  };

  const ValidateOTP = (otp) => {
    if (!otp || otp.length !== 6) {
      return {
        valid: false,
        message: otp ? "OTP must be 6 digits" : "OTP can't be blank",
      };
    }
    return {
      valid: true,
      message: "Valid OTP",
    };
  };

  function handleGetOTPResponse(responseData) {
    if (responseData) {
      setStep(2);
    }
  }
  const handleGetOTPFailure = (response) => {
    console.log(response);
  };

  const SendOTP = (e) => {
    e.preventDefault();
    const url = GenerateURL({}, properties.api.getadminOTP);
    const data = {
      mobile_number: mobileNo,
    };
    const validation = validateMobileNumber(data.mobile_number);
    if (validation.valid) {
      PostData(url, data, handleGetOTPResponse, handleGetOTPFailure, true);
    } else {
      setMobileNoError(validation.message);
    }
  };
  const ResendOTP = (e) => {
    startTimer();
    SendOTP(e);
  };

  const handleLoginResponse = (responseData) => {
    if (responseData) {
      PersistLoginCreds(responseData);
      navigate("/admin/dashboard");
      toast.success("Logged In!", {
        autoClose: 1000,
      });
    }
  };
  const handleLoginFailure = (response) => {
    setOtpError("Invalid OTP");
  };

  const HandleLogin = (e) => {
    e.preventDefault();
    const validation = ValidateOTP(otp);
    if (validation.valid) {
      const url = GenerateURL({}, properties.api.adminLogin);
      const data = {
        mobile_number: mobileNo,
        otp: otp,
      };
      PostData(url, data, handleLoginResponse, handleLoginFailure, true);
    } else {
      setOtpError(validation.message);
    }
  };
  return (
    <>
      <div
        className="flex justify-center items-center min-h-screen bg-gray-100 z-10"
        style={{
          backgroundImage: `url('https://img.freepik.com/free-photo/artistic-blurry-colorful-wallpaper-background_58702-8217.jpg?size=626&ext=jpg&uid=R128585067&ga=GA1.1.923863604.1702044673&semt=ais')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="bg-white p-4 md:p-8 sm:p-16 shadow-lg rounded-md lg:w-3/4 md:w-3/4 2xl:w-[45%] xl:w-1/2 sm:w-[85%] ">
          <h2 className="text-2xl md:text-3xl text-center font-bold text-gray-800 mb-4 md:mb-6">
            Login To Your Admin Dashboard
          </h2>
          {step === 2 && (
            <div className="flex flex-col  items-center ">
              <p className=" text-sm text-gray-600 pt-2  cursor-pointer">
                We have sent the OTP to +91 XXXXXX{mobileNo.slice(-4)}
                <span
                  onClick={() => {
                    setOtpError("");
                    setOtp("");
                    setStep(1);
                  }}
                  className=" text-logo-orange px-2 "
                >
                  Edit
                </span>
              </p>
            </div>
          )}

          <form className="pt-6 md:pt-14 flex flex-col items-center pr-8 sm:pr-8 ">
            <div className="w-full sm:w-10/12 md:w-full lg:w-8/12 xl:w-9/12 2xl:w-8/12 mb-4">
              {step === 1 && (
                <div className="mb-4">
                  {renderInput("STRING", {
                    label: "Mobile Number",
                    name: "mobileNo",
                    value: mobileNo,
                    onChange: (e) => {
                      const inputNumeric = /^[0-9\b]+$/;
                      if (
                        e.target.value === "" ||
                        inputNumeric.test(e.target.value)
                      ) {
                        setMobileNoError("");
                        setMobileNo(e.target.value);
                      } else {
                        setMobileNoError("Please enter only numeric values.");
                      }
                    },
                    error: mobileNoError,
                  })}
                </div>
              )}
              {step === 2 && (
                <>
                  <div className="mb-4">
                    {renderInput("STRING", {
                      label: "Enter Your OTP ",
                      name: "otp",
                      value: otp,
                      onChange: (e) => {
                        setOtpError("");
                        setOtp(e.target.value);
                      },
                      error: otpError,
                    })}
                  </div>
                  <InputTypeRendering>
                    {count === 0 ? (
                      <button
                        onClick={ResendOTP}
                        className="mb-4 text-sm  text-logo-blue opacity-80"
                      >
                        Resend OTP
                      </button>
                    ) : (
                      <p className="mb-4 text-sm text-gray-600">
                        Resend OTP in <span>{count}</span> seconds
                      </p>
                    )}
                  </InputTypeRendering>
                </>
              )}
              <div className="w-full  flex flex-row items-center justify-center gap-1 sm:gap-2 md:gap-3">
                <div className="w-1/2 md:w-1/3 "></div>
                <div className="w-1/2 md:w-2/3 flex justify-start items-center gap-4">
                  {step === 1 && (
                    <button
                      className="bg-blue-500 text-white px-1 py-1 md:px-4 md:py-2   rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
                      onClick={SendOTP}
                    >
                      Send OTP
                    </button>
                  )}
                  {step === 2 && (
                    <button
                      className="bg-green-500 text-white px-1 py-1 md:px-4 md:py-2 rounded-md hover:bg-green-600 focus:outline-none focus:ring focus:ring-green-300"
                      onClick={HandleLogin}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AdminLogin;
