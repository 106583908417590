import React, { useEffect, useState } from "react";
import ModalWindow from "../../Common/Modal/ModalWindow";
import { renderInput } from "../../Common/FormComponents";
import { GetData, PostData, PutData } from "../../../utils/ApiInvoker";
import GenerateURL from "../../../utils/ApiUrlProvider";
import { ValidateAssociatePartnerForm } from "../../Common/FormValidation/UserEditCardval";
import properties from "../../../Properties/Properties";
import { toast } from "react-toastify";
import {
  GetAuth,
  IsAssociatePartner,
  IsPartnerUser,
} from "../../../utils/AccessTokenStore";
import ListTable from "../../Common/ListTable";

import { containerStyle } from "../../../utils/Healpers";

const AssociatePartners = () => {
  const userData = GetAuth();
  const [associatePartnerDetail, setAssociatepartnerDetaiil] = useState(null);
  const [associateModel, setAssociateModel] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [pagination, setPagination] = useState(null);
  const associateInitialValues = {
    id: null,
    first_name: "",
    mobile_number: "",
    last_name: "",
    email: "",
  };
  const [associateFormData, setAssociateFormData] = useState(
    associateInitialValues
  );
  const [associateFormError, setAssociateFormErr] = useState(
    associateInitialValues
  );

  const HandleAssociateFormChange = (e) => {
    const { name, value } = e.target;
    setAssociateFormErr({ ...associateFormError, [name]: "" });
    setAssociateFormData({ ...associateFormData, [name]: value });
  };
  const AssociateGetSucess = (response) => {
    setPagination(response);
    const res = response.results;
    const tableData = {
      tableHeaders: ["Associate Partner Id", "Name", "Mobile No", "Email"],
      tableContent: res.map((data) => {
        return {
          "Associate Partner Id": data?.user?.user_profile.username,
          id: data?.user.id,
          Name: data?.user.first_name + " " + data?.user.last_name,
          "Mobile No": data?.user.mobile_number,
          mobile_number: data?.user.mobile_number,
          Email: data?.user.email,
          first_name: data?.user.first_name,
          last_name: data?.user.last_name,
        };
      }),
    };
    setAssociatepartnerDetaiil(tableData);
  };
  const AssociateGetErr = (res) => {
    console.log(res);
  };

  const GetNextAssocciatePartner = (nexturl) => {
    const url = GenerateURL({}, nexturl);
    GetData(url, AssociateGetSucess, AssociateGetErr, false, false);
  };
  const GetPrevAssocciatePartner = (prevurl) => {
    const url = GenerateURL({}, prevurl);
    GetData(url, AssociateGetSucess, AssociateGetErr, false, false);
  };
  const GetAssocciatePartner = () => {
    const url = GenerateURL(
      { id: userData.id },
      properties.api.getAsociatePartner
    );
    GetData(url, AssociateGetSucess, AssociateGetErr, false);
  };

  const AssociatePostSucess = (res) => {
    console.log(res);
    setAssociateModel(!associateModel);
  };
  const AssociatePostErr = (res) => {
    console.log(res);
    setAssociateModel(!associateModel);
    if (res.email && res.email[0]) {
      toast.error(res.email[0], {
        autoClose: 3000,
      });
    } else if (res.mobile_number && res.mobile_number[0]) {
      toast.error(res.mobile_number[0], {
        autoClose: 3000,
      });
    }
  };

  const AddAssocciatePartner = (e) => {
    e.preventDefault();
    const valid = ValidateAssociatePartnerForm(associateFormData);
    if (valid.is_valid) {
      if (isEditing) {
        const url = GenerateURL(
          { id: associateFormData.id },
          properties.api.partnerInfoUpdate
        );
        PutData(
          url,
          associateFormData,
          AssociatePostSucess,
          AssociatePostErr,
          false
        );
      } else {
        const url = GenerateURL(
          { id: userData.id },
          properties.api.addAsociatePartner
        );
        PostData(
          url,
          associateFormData,
          AssociatePostSucess,
          AssociatePostErr,
          false
        );
      }
    } else {
      setAssociateFormErr(valid.error);
    }
  };

  // const searchParameters = [
  //   { name: "Name", parm: "name" },
  //   // { name: "ASSOCIATE PARTNER ID", parm: "user_code" },
  //   { name: "Mobile No", parm: "Mobile_number" },
  //   { name: "Email", parm: "email" },
  // ];
  // const HandleSearchSucess = (res) => {
  //   if (res.results.length === 0) {
  //     toast.error("No Data found");
  //     GetAssocciatePartner();
  //   } else {
  //     AssociateGetSucess(res);
  //   }
  // };
  // const SearchClick = (e) => {
  //   const url = GenerateSearchURLWithparms(
  //     { id: userData.id },
  //     e,
  //     properties.api.getAsociatePartner
  //   );
  //   GetData(url, HandleSearchSucess, AssociateGetErr, false, false);
  // };
  // const EmptyCall = () => {
  //   GetAssocciatePartner();
  // };

  useEffect(() => {
    setAssociateFormErr(associateInitialValues);
    GetAssocciatePartner();
  }, [associateModel]);

  return (
    <div className="w-full flex flex-col  ml-5 mt-9 p-4">
      <div
        style={containerStyle}
        className="w-full border rounded-t-md p-4 py-10 text-3xl text-left capitalize text-white  "
      >
        <p className="shadow-sm">Associate Partners</p>
      </div>
      <div className="flex flex-row items-center w-full gap-2 p-2  border bg-logo-blue bg-opacity-10  rounded-b-md">
        <button className=" text-logo-orange underline underline-offset-[12px]">
          Associate Partners Info
        </button>

        {IsPartnerUser() && !IsAssociatePartner() && (
          <button
            onClick={() => {
              setIsEditing(false);
              setAssociateModel(!associateModel);
              setAssociateFormData(associateInitialValues);
            }}
            className="ml-auto border px-2 rounded-xl bg-logo-blue text-white"
          >
            Add associate partners
          </button>
        )}
      </div>
      {associateModel && (
        <>
          <ModalWindow
            onClose={() => setAssociateModel(!associateModel)}
            title={`${
              isEditing ? "Edit Partner Detail" : "Add Associates Partners"
            }`}
          >
            <div className="mb-4 flex gap-1 sm:gap-2 md:gap-3 w-full">
              <div className="w-full flex flex-col">
                {renderInput("STRING", {
                  label: "First Name",
                  name: "first_name",
                  value: associateFormData.first_name,
                  onChange: HandleAssociateFormChange,
                  isrequired: true,
                  error: associateFormError.first_name,
                  tooltip: "Use letters only for the name",
                })}
                {renderInput("STRING", {
                  label: "Last Name",
                  name: "last_name",
                  value: associateFormData.last_name,
                  error: associateFormError.last_name,
                  isrequired: true,
                  onChange: HandleAssociateFormChange,
                  tooltip: "Use letters only for the name",
                })}
                {renderInput("STRING", {
                  label: "Email",
                  name: "email",
                  value: associateFormData.email,
                  error: associateFormError.email,
                  isrequired: true,
                  onChange: HandleAssociateFormChange,
                  tooltip: `Use "@" , ".com"`,
                })}
                {renderInput("INTEGER", {
                  label: "Mobile Number",
                  name: "mobile_number",
                  isrequired: true,
                  value: associateFormData.mobile_number,
                  error: associateFormError.mobile_number,
                  onChange: HandleAssociateFormChange,
                  tooltip: `10 digit mobile number`,
                })}
                <div className="flex justify-center items-center">
                  <button
                    className="bg-blue-500 text-white px-1 py-1 md:px-4 md:py-2   rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300  "
                    onClick={AddAssocciatePartner}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </ModalWindow>
        </>
      )}
      <div className="w-full mt-5 gap-5 flex flex-col shadow-lg rounded-2xl">
        {associatePartnerDetail && (
          <>
            {associatePartnerDetail.tableContent.length === 0 ? (
              <h1 className="p-4 text-logo-orange">
                No associate partner details found
              </h1>
            ) : (
              <ListTable
                tableData={associatePartnerDetail}
                // actions={{ edit: false, delete: false, view: false }}
                // onEdit={onEditClick}
                pagination={Boolean(pagination.next || pagination.previous)}
                next={pagination.next}
                prev={pagination.previous}
                onNextClick={() => GetNextAssocciatePartner(pagination.next)}
                onPrevClick={() =>
                  GetPrevAssocciatePartner(pagination.previous)
                }
              />
            )}
            {/* <SearchBar
              searchParameters={searchParameters}
              SearchClick={SearchClick}
              EmptyCall={EmptyCall}
            /> */}
          </>
        )}
      </div>
    </div>
  );
};

export default AssociatePartners;
