import React, { useEffect, useState } from "react";
import { renderInput } from "../../../../Common/FormComponents";
import { GetData, PostData, PutData } from "../../../../../utils/ApiInvoker";
import GenerateURL from "../../../../../utils/ApiUrlProvider";
import { useLoadingContext } from "../../../../../Context/LoadingContext";
import properties from "../../../../../Properties/Properties";
import { useNavigate, useParams } from "react-router-dom";
import { validateReferralData } from "../../../../Common/FormValidation/PersonalLoanServVal";
import { toast } from "react-toastify";
import { PartnerOrAssociate } from "../../../../../utils/Healpers";

const ReferenceInfoAutoLoan = () => {
  const { id, secid, type } = useParams();
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useLoadingContext();
  const initialreferenceInfoData = {
    name: "",
    email: "",
    mobile_no: "",
    relationship: "",
    reffery_address: {
      house_address: "",
      city: "",
      state: "",
      pin_code: "",
      year_at_present_address: "",
      landmark: "",
    },
  };

  const [refInfoData, setRefInfoData] = useState(null);
  const [refInfoErr, setRefInfoErr] = useState(initialreferenceInfoData);
  const HandlerefereInfoChange = (e) => {
    const { name, value } = e.target;
    setRefInfoErr({ ...refInfoErr, [name]: "" });
    setRefInfoData({ ...refInfoData, [name]: value });
  };

  const HandleSubmitSucess = (e) => {
    hideLoader();
    if (PartnerOrAssociate()) {
      navigate(`/partner/loan/autoloan/${id}/detail`);
    } else {
      navigate(`/user/loan/autoloan/${id}/detail`);
    }

    toast.success("reference added sucessfully", {
      autoClose: 1000,
    });
  };
  const HandleSubmitError = (e) => {
    hideLoader();
    toast.error("Something went wrong", {
      autoClose: 1000,
    });
  };
  const HandleSubmitResponse = (e) => {
    e.preventDefault();
    showLoader();
    if (type === "edit") {
      const url = GenerateURL(
        { id: id, secid: secid },
        properties.api.AutoLoanServices.EditUrl.referenceAutoLoanEdit
      );
      PutData(url, refInfoData, HandleSubmitSucess, HandleSubmitError, false);
    } else {
      const url = GenerateURL(
        { id: id },
        properties.api.AutoLoanServices.RefreeAutoLoan
      );
      PostData(url, refInfoData, HandleSubmitSucess, HandleSubmitError, false);
    }
  };
  const handleNextClick = (e) => {
    e.preventDefault();
    const valid = validateReferralData(refInfoData);
    if (valid.valid) {
      HandleSubmitResponse(e);
    } else {
      setRefInfoErr((prev) => ({ ...prev, ...valid.errors }));
    }
  };

  const HandleGetSucess = (res) => {
    setRefInfoData(res);
  };
  const HandleGetError = (res) => {
    toast.error("Something went wrong", {
      autoClose: 1000,
    });
    navigate("/user/dashboard");
  };

  const GetInitialData = () => {
    const url = GenerateURL(
      { id: id, secid: secid },
      properties.api.AutoLoanServices.EditUrl.referenceAutoLoanEdit
      //   properties.api.BusinessLoanServices.EditUrl.referenceBusinessLoanEdit
    );
    GetData(url, HandleGetSucess, HandleGetError, false, false);
  };

  useEffect(() => {
    if (type === "edit") {
      GetInitialData();
    } else {
      setRefInfoData(initialreferenceInfoData);
    }
  }, []);
  return (
    <div className="p-5 w-full md:w-8/12 h-full pt-20  ">
      <h1 className="text-2xl md:text-3xl pl-2 my-2 border-l-4  text-slate-800 font-sans font-bold  border-logo-blue mb-5  rounded-sm  ">
        Auto Loan
      </h1>
      <div className="flex items-center w-full border shadow-md flex-col justify-center rounded-lg  bg-white  ">
        <div className="flex items-start w-full flex-col  px-6 py-3  bg-logo-blue bg-opacity-90 rounded-t-lg ">
          <p className="w-11/12 py-3 text-xl text-left capitalize text-white ">
            Reference Details
          </p>
        </div>
        <div className="flex items-center w-full flex-row justify-center rounded-lg  bg-white">
          {refInfoData && (
            <form className="w-full h-full  p-4 ">
              <p className="text-xl rounded-md px-10 py-1 bg-logo-blue bg-opacity-10 my-2  font-medium text-logo-orange">
                Personal Info
              </p>
              {renderInput("STRING", {
                label: "Name",
                name: "name",
                onChange: HandlerefereInfoChange,
                value: refInfoData.name,
                error: refInfoErr.name,
                isrequired: true,
              })}
              {renderInput("STRING", {
                label: "Email",
                name: "email",
                onChange: HandlerefereInfoChange,
                value: refInfoData.email,
                error: refInfoErr.email,
                isrequired: false,
              })}
              {renderInput("INTEGER", {
                label: "Mobile Number",
                name: "mobile_no",
                onChange: HandlerefereInfoChange,
                value: refInfoData.mobile_no,
                error: refInfoErr.mobile_no,
                isrequired: true,
              })}
              {renderInput("STRING", {
                label: "Relation",
                name: "relationship",
                onChange: HandlerefereInfoChange,
                value: refInfoData.relationship,
                error: refInfoErr.relationship,
                isrequired: true,
              })}
              <p className="text-xl rounded-md px-10 py-1 bg-logo-blue bg-opacity-10 my-2  font-medium text-logo-orange">
                Address
              </p>
              {renderInput("ADDRESS", {
                label: "Address",
                name: "reffery_address",
                onChange: HandlerefereInfoChange,
                value: refInfoData.reffery_address,
                error: refInfoErr.reffery_address,
                isrequired: false,
              })}
              <div className="mt-10 flex  flex-row-reverse gap-3 w-full items-center justify-between">
                <button
                  onClick={handleNextClick}
                  className="bg-green-500
                text-white px-4 py-2 rounded-md"
                >
                  Submit
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReferenceInfoAutoLoan;
