import React, { useEffect, useState } from "react";
import { renderInput } from "../../../../Common/FormComponents";
import { GetData, PostData, PutData } from "../../../../../utils/ApiInvoker";
import GenerateURL from "../../../../../utils/ApiUrlProvider";
import { useLoadingContext } from "../../../../../Context/LoadingContext";
import properties from "../../../../../Properties/Properties";
import {
  businessRunBy,
  natureOfBusiness,
  productCategory,
} from "../../../../../utils/LoanApplicationDropdownData";
import { useNavigate, useParams } from "react-router-dom";
import {
  validateBusiess1Info,
  validateBusinessAddress,
} from "../../../../Common/FormValidation/PersonalLoanServVal";
import { toast } from "react-toastify";
import { PartnerOrAssociate } from "../../../../../utils/Healpers";

const SelfEmploymentInfoPersonalLoan = () => {
  const { id, secid, type } = useParams();
  const navigate = useNavigate();
  const totalStage = 2;
  const [stage, setStage] = useState(1);
  const { showLoader, hideLoader } = useLoadingContext();

  const initialSelfEmpInfoData = {
    firm_name: "",
    trade_name: "",
    gst_no: "",
    udyam_no: "",
    official_email: "",
    business_run_by: "",
    nature_of_business: "",
    product_category: "",
    business_vintage: "",
    description: "",
    business_address: {
      house_address: "",
      city: "",
      state: "",
      pin_code: "",
      year_at_present_address: "",
      landmark: "",
    },
    company_gst_certificate: "",
    company_udayam_certificate: "",
    bank_statement: "",
    ownership_proof: "",
    last_2_year_itr_with_financial_computation_gst_returns: "",
  };
  const [selfEmpInfoData, setSelfEmpInfoData] = useState(null);
  const [selfEmpInfoError, setSelfEmpInfoError] = useState(
    initialSelfEmpInfoData
  );

  const HandleSelfEmpInfoChange = (e) => {
    const { name, value } = e.target;
    setSelfEmpInfoError({ ...selfEmpInfoError, [name]: "" });
    setSelfEmpInfoData({ ...selfEmpInfoData, [name]: value });
  };

  const HandleSubmitSucess = (e) => {
    hideLoader();
    if (PartnerOrAssociate()) {
      navigate(`/partner/loan/personalloan/${id}/detail`);
    } else {
      navigate(`/user/loan/personalloan/${id}/detail`);
    }
    toast.success("File Uploaded Sucessfully", {
      autoClose: 1000,
    });
  };
  const HandleSubmitError = (e) => {
    hideLoader();
    toast.error("Something went wrong", {
      autoClose: 1000,
    });
  };
  const HandleSubmitResponse = (e) => {
    e.preventDefault();
    showLoader();

    if (type === "edit") {
      const url = GenerateURL(
        { id: id, secid: secid },
        properties.api.PersonalLoanServices.EditUrl
          .SelfEmploymentPersonalLoanEdit
      );
      PutData(
        url,
        selfEmpInfoData,
        HandleSubmitSucess,
        HandleSubmitError,
        false
      );
    } else {
      const url = GenerateURL(
        { id: id },
        properties.api.PersonalLoanServices.SelfEmployedPersonalLoan
      );
      showLoader();
      PostData(
        url,
        selfEmpInfoData,
        HandleSubmitSucess,
        HandleSubmitError,
        false
      );
    }
  };

  const HandleNextClick = (e) => {
    e.preventDefault();
    if (stage === 1) {
      const valid = validateBusiess1Info(selfEmpInfoData);
      if (valid.valid) {
        setStage(stage + 1);
      } else {
        setSelfEmpInfoError((prev) => ({ ...prev, ...valid.errors }));
      }
    } else {
      const valid = validateBusinessAddress(selfEmpInfoData);
      console.log(valid);
      if (valid.valid) {
        HandleSubmitResponse(e);
      } else {
        setSelfEmpInfoError((prev) => ({ ...prev, ...valid.errors }));
      }
    }
  };

  const HandlePrevClick = (e) => {
    e.preventDefault();
    setStage((prev) => prev - 1);
  };
  const filevalidtion = {
    file_size: "10MB",
    file_type: ["jpg", "png", "pdf", "heic", "jpeg"],
    is_required: false,
  };

  const HandleGetSucess = (res) => {
    setSelfEmpInfoData(res);
  };
  const HandleGetError = (res) => {
    toast.error("Something went wrong", {
      autoClose: 1000,
    });
    navigate("/user/dashboard");
  };

  const GetInitialData = () => {
    const url = GenerateURL(
      { id: id, secid: secid },
      properties.api.PersonalLoanServices.EditUrl.SelfEmploymentPersonalLoanEdit
    );
    GetData(url, HandleGetSucess, HandleGetError, false, false);
  };

  useEffect(() => {
    if (type === undefined) {
      setSelfEmpInfoData(initialSelfEmpInfoData);
    } else {
      GetInitialData();
    }
  }, []);
  return (
    <div className="p-5 w-full md:w-8/12 h-full pt-20  ">
      <h1 className="text-2xl md:text-3xl pl-2 my-2 border-l-4  text-slate-800 font-sans font-bold  border-logo-blue mb-5  rounded-sm  ">
        Personal Loan
      </h1>
      <div className="flex items-center w-full border shadow-md flex-col justify-center rounded-lg  bg-white  ">
        <div className="flex items-start w-full flex-col  px-6 py-3  bg-logo-blue bg-opacity-90 rounded-t-lg ">
          <p className="w-11/12 py-3 text-xl text-left capitalize text-white ">
            Basic Details
          </p>
        </div>
        <div className="flex items-center w-full flex-row justify-center rounded-lg  bg-white">
          {selfEmpInfoData && (
            <form className="w-full h-full  p-4 ">
              {stage === 1 && (
                <>
                  <p className="text-xl rounded-md px-10 py-1 bg-logo-blue bg-opacity-10 my-2  font-medium text-logo-orange">
                    Company Info
                  </p>
                  {renderInput("STRING", {
                    label: "Firm Name",
                    name: "firm_name",
                    onChange: HandleSelfEmpInfoChange,
                    value: selfEmpInfoData.firm_name,
                    error: selfEmpInfoError.firm_name,
                    isrequired: false,
                  })}
                  {renderInput("STRING", {
                    label: "Official Email",
                    name: "official_email",
                    onChange: HandleSelfEmpInfoChange,
                    value: selfEmpInfoData.official_email,
                    error: selfEmpInfoError.official_email,
                    isrequired: true,
                  })}
                  {renderInput("STRING", {
                    label: "Trade Name",
                    name: "trade_name",
                    onChange: HandleSelfEmpInfoChange,
                    value: selfEmpInfoData.trade_name,
                    error: selfEmpInfoError.trade_name,
                    isrequired: false,
                  })}
                  {renderInput("STRING", {
                    label: "GST Number",
                    name: "gst_no",
                    onChange: HandleSelfEmpInfoChange,
                    value: selfEmpInfoData.gst_no,
                    error: selfEmpInfoError.gst_no,
                    isrequired: false,
                  })}
                  {renderInput("STRING", {
                    label: "Udyam Number",
                    name: "udyam_no",
                    onChange: HandleSelfEmpInfoChange,
                    value: selfEmpInfoData.udyam_no,
                    error: selfEmpInfoError.udyam_no,
                    isrequired: false,
                  })}
                  <p className="text-xl rounded-md px-10 py-1 bg-logo-blue bg-opacity-10 my-2  font-medium text-logo-orange">
                    Business Info
                  </p>
                  {renderInput("DROP_DOWN", {
                    label: "Business Run By",
                    name: "business_run_by",
                    value: selfEmpInfoData.business_run_by,
                    onChange: HandleSelfEmpInfoChange,
                    options: businessRunBy,
                    error: selfEmpInfoError.business_run_by,
                    isrequired: true,
                  })}
                  {renderInput("INTEGER", {
                    label: "Business Age (months)",
                    name: "business_vintage",
                    value: selfEmpInfoData.business_vintage,
                    onChange: HandleSelfEmpInfoChange,
                    error: selfEmpInfoError.business_vintage,
                    isrequired: true,
                  })}
                  {renderInput("DROP_DOWN", {
                    label: "Nature of Business",
                    name: "nature_of_business",
                    options: natureOfBusiness,
                    value: selfEmpInfoData.nature_of_business,
                    onChange: HandleSelfEmpInfoChange,
                    error: selfEmpInfoError.nature_of_business,
                    isrequired: true,
                  })}
                  {renderInput("DROP_DOWN", {
                    label: "Product Category",
                    name: "product_category",
                    options: productCategory,
                    value: selfEmpInfoData.product_category,
                    onChange: HandleSelfEmpInfoChange,
                    error: selfEmpInfoError.product_category,
                    isrequired: true,
                  })}
                  {renderInput("STRING", {
                    label: "Description",
                    name: "description",
                    value: selfEmpInfoData.description,
                    onChange: HandleSelfEmpInfoChange,
                    error: selfEmpInfoError.description,
                    isrequired: false,
                  })}
                </>
              )}
              {stage === 2 && (
                <>
                  <p className="text-xl rounded-md px-10 py-1 bg-logo-blue bg-opacity-10 my-2  font-medium text-logo-orange">
                    Company Address
                  </p>
                  {renderInput("ADDRESS", {
                    label: "Address",
                    name: "business_address",
                    onChange: HandleSelfEmpInfoChange,
                    value: selfEmpInfoData.business_address,
                    error: selfEmpInfoError.business_address,
                    isrequired: true,
                  })}
                  <p className="text-xl rounded-md px-10 py-1 bg-logo-blue bg-opacity-10 my-2  font-medium text-logo-orange">
                    Basic Documents
                  </p>
                  {renderInput("FILE_UPLOAD", {
                    label: "Company GST",
                    name: "company_gst_certificate",
                    code: "company_gst_certificate",
                    value: selfEmpInfoData.company_gst_certificate,
                    onChange: HandleSelfEmpInfoChange,
                    error: selfEmpInfoError.company_gst_certificate,
                    onlyId: true,
                    isrequired: false,
                    validations: filevalidtion,
                  })}
                  {renderInput("FILE_UPLOAD", {
                    label: "Company Udhyam",
                    name: "company_udayam_certificate",
                    code: "company_udayam_certificate",
                    value: selfEmpInfoData.company_udayam_certificate,
                    onChange: HandleSelfEmpInfoChange,
                    error: selfEmpInfoError.company_udayam_certificate,
                    onlyId: true,
                    isrequired: false,
                    validations: filevalidtion,
                  })}
                  {renderInput("FILE_UPLOAD", {
                    label: "Bank Statement",
                    name: "bank_statement",
                    code: "bank_statement",
                    value: selfEmpInfoData.bank_statement,
                    onChange: HandleSelfEmpInfoChange,
                    error: selfEmpInfoError.bank_statement,
                    onlyId: true,
                    isrequired: false,
                    validations: filevalidtion,
                  })}
                  {renderInput("FILE_UPLOAD", {
                    label: "Ownweship Proof",
                    name: "ownership_proof",
                    code: "ownership_proof",
                    value: selfEmpInfoData.ownership_proof,
                    onChange: HandleSelfEmpInfoChange,
                    error: selfEmpInfoError.ownership_proof,
                    onlyId: true,
                    isrequired: false,
                    validations: filevalidtion,
                  })}
                  {renderInput("FILE_UPLOAD", {
                    label: "ITR With Finincial Computations (last 2 yr)",
                    name: "last_2_year_itr_with_financial_computation_gst_returns",
                    code: "last_2_year_itr_with_financial_computation_gst_returns",
                    value:
                      selfEmpInfoData.last_2_year_itr_with_financial_computation_gst_returns,
                    onChange: HandleSelfEmpInfoChange,
                    error:
                      selfEmpInfoError.last_2_year_itr_with_financial_computation_gst_returns,
                    onlyId: true,
                    isrequired: false,
                    validations: filevalidtion,
                  })}
                </>
              )}
              <div className="mt-10 flex  flex-row-reverse gap-3 w-full items-center justify-between">
                <button
                  onClick={HandleNextClick}
                  className={`${
                    stage === totalStage ? "bg-green-500" : "bg-blue-600"
                  } text-white px-4 py-2 rounded-md`}
                >
                  {stage === totalStage ? "Submit" : "Next"}
                </button>
                {stage !== 1 && (
                  <button
                    className="bg-blue-600 text-white px-4 py-2 rounded"
                    onClick={HandlePrevClick}
                  >
                    Prev
                  </button>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelfEmploymentInfoPersonalLoan;
