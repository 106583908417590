import React, { useEffect, useState } from "react";
import { renderInput } from "../../../../Common/FormComponents";
import { GetData, PostData, PutData } from "../../../../../utils/ApiInvoker";
import GenerateURL from "../../../../../utils/ApiUrlProvider";
import { useLoadingContext } from "../../../../../Context/LoadingContext";
import properties from "../../../../../Properties/Properties";
import { useNavigate, useParams } from "react-router-dom";
import {
  FirmValBLstage1,
  FirmValBLstage2,
} from "../../../../Common/FormValidation/BusinessLoanServVal";
import { toast } from "react-toastify";
import { PartnerOrAssociate } from "../../../../../utils/Healpers";

const FirmDetailBusinessLoan = () => {
  const navigate = useNavigate();
  const { id, secid, type } = useParams();
  const totalStage = 4;
  const [stage, setStage] = useState(1);
  const { showLoader, hideLoader } = useLoadingContext();

  const InitialFirmDetails = {
    firm_name: "",
    firm_pan_no: "", //
    gst_no: "", //
    udyam_no: "", //
    official_email: "", //
    business_turnover: "", //
    business_vintage: "", //
    nature_of_business: "", //
    product_category: "", //
    description: "",
    firm_address: {
      house_address: "",
      city: "",
      state: "",
      pin_code: "",
    }, //
    firm_gst_certificate: "",
    firm_pan_card: "",
    firm_udyam_certificate: "",
    moa: "",
    aoa: "",
    coi: "",
    rent_aggremnet: "",
    industry_license: "",
    one_year_bank_statement: "",
    ownership_proof: "",
    last_2_year_itr_with_financial_computation_gst_returns: "",
    patnership_deed: "",
  };

  const [firmData, setFirmData] = useState(null);
  const [firmErr, setFirmErr] = useState(InitialFirmDetails);

  const HandleFirmDetail = (e) => {
    const { name, value } = e.target;
    setFirmErr({ ...firmErr, [name]: "" });
    setFirmData({ ...firmData, [name]: value });
  };

  const HandleSubmitSucess = (e) => {
    hideLoader();
    if (PartnerOrAssociate()) {
      navigate(`/partner/loan/businessloan/${id}/detail`);
    } else {
      navigate(`/user/loan/businessloan/${id}/detail`);
    }
    toast.success("firm detail added sucessfully", {
      autoClose: 1000,
    });
  };
  const HandleSubmitError = (e) => {
    hideLoader();
    toast.error("Something went wrong", {
      autoClose: 1000,
    });
  };
  const HandleSubmitResponse = (e) => {
    e.preventDefault();
    showLoader();
    if (type === "edit") {
      const url = GenerateURL(
        { id: id, secid: secid },
        properties.api.BusinessLoanServices.EditUrl.FirmdetailBusinessloanEdit
      );
      PutData(url, firmData, HandleSubmitSucess, HandleSubmitError, false);
    } else {
      const url = GenerateURL(
        { id: id },
        properties.api.BusinessLoanServices.FirmdetailBusinessloan
      );
      PostData(url, firmData, HandleSubmitSucess, HandleSubmitError, false);
    }
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    if (stage <= 3) {
      if (stage === 1) {
        const valid = FirmValBLstage1(firmData);
        if (valid.valid) {
          setStage(2);
        } else {
          setFirmErr((prev) => ({ ...prev, ...valid.errors }));
        }
      } else if (stage === 2) {
        const valid = FirmValBLstage2(firmData);
        if (valid.valid) {
          setStage(3);
        } else {
          setFirmErr((prev) => ({ ...prev, ...valid.errors }));
        }
      } else {
        setStage((stage) => stage + 1);
      }
    } else {
      HandleSubmitResponse(e);
    }
  };

  const HandlePrevClick = (e) => {
    e.preventDefault();
    setStage((prev) => prev - 1);
  };
  const HandleGetSucess = (res) => {
    setFirmData({
      ...res,
      firm_address: res.firm_address
        ? res.firm_address
        : {
            house_address: "",
            city: "",
            state: "",
            pin_code: "",
            year_at_present_address: "",
            landmark: "",
          },
    });
  };

  const HandleGetError = (res) => {
    toast.error("Something went wrong", {
      autoClose: 1000,
    });
    navigate("/user/dashboard");
  };
  const GetInitialData = () => {
    const url = GenerateURL(
      { id: id, secid: secid },
      properties.api.BusinessLoanServices.EditUrl.FirmdetailBusinessloanEdit
    );
    GetData(url, HandleGetSucess, HandleGetError, false, false);
  };

  useEffect(() => {
    if (type === undefined) {
      setFirmData(InitialFirmDetails);
    } else {
      GetInitialData();
    }
  }, []);
  const productCategory = [
    {
      value: "",
      label: "Select One",
    },
    {
      value:
        "Advisory services (legal, business, educational, psychological etc.)",
      label:
        "Advisory services (legal, business, educational, psychological etc.)",
    },
    {
      value: "Aircel distributor / R-com distributor",
      label: "Aircel distributor / R-com distributor",
    },
    {
      value: "Apparel, Readymade garments",
      label: "Apparel, Readymade garments",
    },
    {
      value: "Arms and ammunition dealers",
      label: "Arms and ammunition dealers",
    },
    {
      value: "Arts, design and other creative services",
      label: "Arts, design and other creative services",
    },
    {
      value: "Automobile accessories / service centre",
      label: "Automobile accessories / service centre",
    },
    { value: "Automotive parts", label: "Automotive parts" },
    {
      value: "BPO/KPO & facility management",
      label: "BPO/KPO & facility management",
    },
    { value: "Bar / Liquor", label: "Bar / Liquor" },
    {
      value:
        "CSC, photocopying and other documents and ID (Aadhaar, PAN) related services, Kisan seva kendra",
      label:
        "CSC, photocopying and other documents and ID (Aadhaar, PAN) related services, Kisan seva kendra",
    },
    { value: "Cab Services", label: "Cab Services" },
    {
      value: "Camera, CCTV and related accessories",
      label: "Camera, CCTV and related accessories",
    },
    {
      value: "Cargo and retail transport service",
      label: "Cargo and retail transport service",
    },
    {
      value: "Chain restaurant / verified income",
      label: "Chain restaurant / verified income",
    },
    {
      value: "Chemist, Retail health accessories, medicines and supplements",
      label: "Chemist, Retail health accessories, medicines and supplements",
    },
    {
      value: "Clinics, hospital, nursing home, lab, gym, pet clinic etc.",
      label: "Clinics, hospital, nursing home, lab, gym, pet clinic etc.",
    },
    {
      value: "Computer, Mobile and related accessories",
      label: "Computer, Mobile and related accessories",
    },
    {
      value: "Computer, mobile and camera repairing service",
      label: "Computer, mobile and camera repairing service",
    },
    {
      value: "Construction equipment including building, road, sewage etc.",
      label: "Construction equipment including building, road, sewage etc.",
    },
    {
      value: "Construction material like cement, bricks, sand etc",
      label: "Construction material like cement, bricks, sand etc",
    },
    {
      value: "Corporate services / Corporate bookings",
      label: "Corporate services / Corporate bookings",
    },
    {
      value: "Cotton bales, Textile manufacturing",
      label: "Cotton bales, Textile manufacturing",
    },
    {
      value: "Countdown Timers, Electronic Scoreboards and Digital Clocks",
      label: "Countdown Timers, Electronic Scoreboards and Digital Clocks",
    },
    { value: "Courier", label: "Courier" },
    {
      value: "Design, Fabrication & Painting Services",
      label: "Design, Fabrication & Painting Services",
    },
    {
      value: "Distributor of cooking/commercial Gas",
      label: "Distributor of cooking/commercial Gas",
    },
    {
      value:
        "Eco-friendly solutions for industry (solar, greenhouse, biomass, recycling, etc.)",
      label:
        "Eco-friendly solutions for industry (solar, greenhouse, biomass, recycling, etc.)",
    },
    { value: "Edible Oil business", label: "Edible Oil business" },
    {
      value:
        "Educational Institute (Pre-school, school, college, coaching center, library etc.)",
      label:
        "Educational Institute (Pre-school, school, college, coaching center, library etc.)",
    },
    {
      value:
        "Educational products (books, educational toys, stationeries, e-learning etc.)",
      label:
        "Educational products (books, educational toys, stationeries, e-learning etc.)",
    },
    {
      value: "Electronic sensors, devices",
      label: "Electronic sensors, devices",
    },
    { value: "Entertainment Event", label: "Entertainment Event" },
    {
      value: "Equipment used in agriculture",
      label: "Equipment used in agriculture",
    },
    { value: "Event Management", label: "Event Management" },
    {
      value: "Fabric (woven and non-woven) & textiles",
      label: "Fabric (woven and non-woven) & textiles",
    },
    {
      value: "Facility Management Services",
      label: "Facility Management Services",
    },
    {
      value:
        "Fibres, threads, buttons and other raw materials supplier to textile industry",
      label:
        "Fibres, threads, buttons and other raw materials supplier to textile industry",
    },
    { value: "Film Producer", label: "Film Producer" },
    {
      value:
        "Financial service providers (Chit funds/ small finance companies/Stock broking Companies)",
      label:
        "Financial service providers (Chit funds/ small finance companies/Stock broking Companies)",
    },
    {
      value:
        "Financial service providers (DSA,agents and other intermediators)",
      label:
        "Financial service providers (DSA,agents and other intermediators)",
    },
    {
      value:
        "Financial service providers (NBFCs/ Other money lending companies)",
      label:
        "Financial service providers (NBFCs/ Other money lending companies)",
    },
    {
      value: "Fire fighting equipment, safety materials",
      label: "Fire fighting equipment, safety materials",
    },
    {
      value: "Footwear, bags, cosmetics and other fashion accessories",
      label: "Footwear, bags, cosmetics and other fashion accessories",
    },
    {
      value: "Freight forwarding services",
      label: "Freight forwarding services",
    },
    {
      value: "Furniture & Fixtures (including on rent)",
      label: "Furniture & Fixtures (including on rent)",
    },
    {
      value: "Grocery/ Kirana store / Departmental Store",
      label: "Grocery/ Kirana store / Departmental Store",
    },
    {
      value: "Hardware & Fittings (ceramics, tiles, cables, lights etc.)",
      label: "Hardware & Fittings (ceramics, tiles, cables, lights etc.)",
    },
    {
      value: "Hardware provider to telecom industry",
      label: "Hardware provider to telecom industry",
    },
    {
      value: "Heavy machineries & Robotics",
      label: "Heavy machineries & Robotics",
    },
    { value: "Home & Kitchen Appliances", label: "Home & Kitchen Appliances" },
    {
      value:
        "Home Decor, interior items, kitchen accessories, toys, gift articles",
      label:
        "Home Decor, interior items, kitchen accessories, toys, gift articles",
    },
    { value: "Hotels, resorts and club", label: "Hotels, resorts and club" },
    { value: "Housekeeping products", label: "Housekeeping products" },
    {
      value: "Housekeeping, security & industrial labour supply",
      label: "Housekeeping, security & industrial labour supply",
    },
    { value: "Imitation jewellery", label: "Imitation jewellery" },
    {
      value: "Industrial and customized software, applications and ITES",
      label: "Industrial and customized software, applications and ITES",
    },
    {
      value: "Installation and repair services for telecom products",
      label: "Installation and repair services for telecom products",
    },
    {
      value: "Intelligence Agencies/ Private Security Firms",
      label: "Intelligence Agencies/ Private Security Firms",
    },
    { value: "Interior Designers", label: "Interior Designers" },
    {
      value: "Jewellery, precious metals and stones",
      label: "Jewellery, precious metals and stones",
    },
    { value: "Live Stock Trading", label: "Live Stock Trading" },
    {
      value: "Law services, astrological services, etc.",
      label: "Law services, astrological services, etc.",
    },
    { value: "Leather Trading", label: "Leather Trading" },
    {
      value: "Material handling service provider",
      label: "Material handling service provider",
    },
    {
      value: "Medical, non-medical & wellness equipments",
      label: "Medical, non-medical & wellness equipments",
    },
    { value: "Mining & Mining Products", label: "Mining & Mining Products" },
    {
      value: "Mobile and related accessories",
      label: "Mobile and related accessories",
    },
    {
      value:
        "Mobile recharge, Telecom, DTH, data service provider (telephone, internet, broadband, ISP, leased line etc.)",
      label:
        "Mobile recharge, Telecom, DTH, data service provider (telephone, internet, broadband, ISP, leased line etc.)",
    },
    {
      value: "Musical Instruments, bulbs, tubelights",
      label: "Musical Instruments, bulbs, tubelights",
    },
    {
      value: "Networking, storage, data management and other support services",
      label: "Networking, storage, data management and other support services",
    },
    {
      value: "Non-metal products like wood, paper, plastic, glass, etc",
      label: "Non-metal products like wood, paper, plastic, glass, etc",
    },
    {
      value: "Oils, Paint, Chemicals and petroleum products",
      label: "Oils, Paint, Chemicals and petroleum products",
    },
    {
      value: "Other metals, alloys, minerals and their scraps",
      label: "Other metals, alloys, minerals and their scraps",
    },
    { value: "Others", label: "Others" },
    {
      value: "Outdoor furniture (swings etc.)",
      label: "Outdoor furniture (swings etc.)",
    },
    {
      value:
        "Outsourced consultancy (recruitment, research, placement, transcription, visa etc.)",
      label:
        "Outsourced consultancy (recruitment, research, placement, transcription, visa etc.)",
    },
    {
      value: "Pet Shop, Pet Clinic & Pet Grooming Parlour",
      label: "Pet Shop, Pet Clinic & Pet Grooming Parlour",
    },
    { value: "Petrol pump & Gas station", label: "Petrol pump & Gas station" },
    { value: "Power", label: "Power" },
    {
      value:
        "Professional services (branding, media, architecture, saloon, beauty parlour etc.)",
      label:
        "Professional services (branding, media, architecture, saloon, beauty parlour etc.)",
    },
    {
      value: "Property dealing & management (Rent, lease or Sell)",
      label: "Property dealing & management (Rent, lease or Sell)",
    },
    {
      value: "Raw materials and parts used for manufacturing",
      label: "Raw materials and parts used for manufacturing",
    },
    {
      value: "Real estate developer, Civil contractor",
      label: "Real estate developer, Civil contractor",
    },
    { value: "Renewal (other than Via)", label: "Renewal (other than Via)" },
    {
      value: "Repair and maintenance services",
      label: "Repair and maintenance services",
    },
    {
      value: "Repair and servicing of household appliances",
      label: "Repair and servicing of household appliances",
    },
    {
      value: "Restaurants, Cafes, food outlets & catering",
      label: "Restaurants, Cafes, food outlets & catering",
    },
    {
      value: "Retail and standard softwares, applications and ITES",
      label: "Retail and standard softwares, applications and ITES",
    },
    {
      value:
        "Seeds, Fertilizer, Pesticides, Cattle feeds and agricultural products",
      label:
        "Seeds, Fertilizer, Pesticides, Cattle feeds and agricultural products",
    },
    {
      value:
        "Shares/Bitcoins/Old coins trading or Other speculative activities",
      label:
        "Shares/Bitcoins/Old coins trading or Other speculative activities",
    },
    { value: "Shipping Services", label: "Shipping Services" },
    {
      value: "Supplier for switches, cables etc",
      label: "Supplier for switches, cables etc",
    },
    {
      value: "Support services (facilitators, intermediators, agents, etc.)",
      label: "Support services (facilitators, intermediators, agents, etc.)",
    },
    {
      value:
        "Support services to industries like industrial design, warehousing, testing, repairing etc.",
      label:
        "Support services to industries like industrial design, warehousing, testing, repairing etc.",
    },
    { value: "Tax planners / Auditors", label: "Tax planners / Auditors" },
    {
      value:
        "Tea / coffee vending machine & Tea / Coffee powder to Government / corporates",
      label:
        "Tea / coffee vending machine & Tea / Coffee powder to Government / corporates",
    },
    {
      value: "Ticket, Travel & Tour Packages",
      label: "Ticket, Travel & Tour Packages",
    },
    { value: "Tobacco Products", label: "Tobacco Products" },
    {
      value:
        "Tools and equipments including electricals, transformers, inverters and batteries",
      label:
        "Tools and equipments including electricals, transformers, inverters and batteries",
    },
    {
      value:
        "Vegetables, Fruits, Milk and other dairy products, Spices, Sea-food and food-processing",
      label:
        "Vegetables, Fruits, Milk and other dairy products, Spices, Sea-food and food-processing",
    },
    {
      value: "Vehicles (New/second hand)",
      label: "Vehicles (New/second hand)",
    },
    { value: "Via cases", label: "Via cases" },
  ];
  const validations = {
    file_size: "10MB",
    file_type: ["jpg", "png", "pdf", "heic", "jpeg"],
    is_required: false,
  };

  return (
    <div className="p-5 w-full md:w-8/12 h-full pt-20  ">
      <h1 className="text-2xl md:text-3xl pl-2 my-2 border-l-4  text-slate-800 font-sans font-bold  border-logo-blue mb-5  rounded-sm  ">
        Business Loan
      </h1>
      <div className="flex items-center w-full border shadow-md flex-col justify-center rounded-lg  bg-white  ">
        <div className="flex items-start w-full flex-col  px-6 py-3  bg-logo-blue bg-opacity-90 rounded-t-lg ">
          <p className="w-11/12 py-3 text-xl text-left capitalize text-white ">
            Firm Details
          </p>
          <div className="flex items-center justify-between w-full flex-row ">
            <div className="w-full bg-white rounded-full h-1.5 overflow-hidden">
              <div
                className="bg-logo-orange h-1.5 rounded-full transition-width duration-800"
                style={{
                  width: "0%",
                  transition: "width 1s ease-in-out",
                  width: `${(stage / totalStage) * 100}%`,
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="flex items-center w-full flex-row justify-center rounded-lg  bg-white">
          {firmData && (
            <form className="w-full h-full  p-4 ">
              {stage === 1 && (
                <>
                  <p className="text-xl rounded-md px-10 py-1 bg-logo-blue bg-opacity-10 my-2  font-medium text-logo-orange">
                    Firm Info
                  </p>
                  {renderInput("STRING", {
                    label: "Firm Name",
                    name: "firm_name",
                    onChange: HandleFirmDetail,
                    value: firmData.firm_name,
                    error: firmErr.firm_name,
                    isrequired: false,
                  })}
                  {renderInput("STRING", {
                    label: "Firm PAN No",
                    name: "firm_pan_no",
                    onChange: HandleFirmDetail,
                    value: firmData.firm_pan_no,
                    error: firmErr.firm_pan_no,
                    isrequired: false,
                  })}
                  {renderInput("STRING", {
                    label: "Firm GST No",
                    name: "gst_no",
                    onChange: HandleFirmDetail,
                    value: firmData.gst_no,
                    error: firmErr.gst_no,
                    isrequired: false,
                  })}
                  {renderInput("STRING", {
                    label: "Firm Udyam No",
                    name: "udyam_no",
                    onChange: HandleFirmDetail,
                    value: firmData.udyam_no,
                    error: firmErr.udyam_no,
                    isrequired: false,
                  })}
                  {renderInput("STRING", {
                    label: "Official Email",
                    name: "official_email",
                    onChange: HandleFirmDetail,
                    value: firmData.official_email,
                    error: firmErr.official_email,
                    isrequired: true,
                  })}
                  {renderInput("INTEGER", {
                    label: "Turnover",
                    name: "business_turnover",
                    onChange: HandleFirmDetail,
                    value: firmData.business_turnover,
                    error: firmErr.business_turnover,
                    isrequired: true,
                  })}
                  {renderInput("INTEGER", {
                    label: "Business age (months)",
                    name: "business_vintage",
                    onChange: HandleFirmDetail,
                    value: firmData.business_vintage,
                    error: firmErr.business_vintage,
                    isrequired: true,
                  })}
                  {renderInput("DROP_DOWN", {
                    label: "Nature of Business",
                    name: "nature_of_business",
                    options: [
                      { value: "", label: "Select One" },
                      { value: "Importer", label: "Importer" },
                      { value: "Trader", label: "Trader" },
                      { value: "Exporter", label: "Exporter" },
                      { value: "Service", label: "Service" },
                      { value: "Others", label: "Others" },
                      { value: "Manufacturer", label: "Manufacturer" },
                      { value: "Retailer", label: "Retailer" },
                      { value: "Distributor", label: "Distributor" },
                      { value: "Online Seller", label: "Online Seller" },
                      { value: "Offline Seller", label: "Offline Seller" },
                      { value: "CSC/VLE", label: "CSC/VLE" },
                    ],
                    onChange: HandleFirmDetail,
                    value: firmData.nature_of_business,
                    error: firmErr.nature_of_business,
                    isrequired: true,
                  })}
                  {renderInput("DROP_DOWN", {
                    label: "Product Category",
                    name: "product_category",
                    options: productCategory,
                    onChange: HandleFirmDetail,
                    value: firmData.product_category,
                    error: firmErr.product_category,
                    isrequired: true,
                  })}
                  {renderInput("STRING", {
                    label: "Description",
                    name: "description",
                    onChange: HandleFirmDetail,
                    value: firmData.description,
                    error: firmErr.description,
                    isrequired: false,
                  })}
                </>
              )}
              {stage === 2 && (
                <>
                  <p className="text-xl rounded-md px-10 py-1 bg-logo-blue bg-opacity-10 my-2  font-medium text-logo-orange">
                    Firm Address
                  </p>
                  {renderInput("ADDRESS", {
                    label: "Address",
                    name: "firm_address",
                    onChange: HandleFirmDetail,
                    value: firmData.firm_address,
                    error: firmErr.firm_address,
                    isrequired: true,
                  })}
                </>
              )}
              {stage === 3 && (
                <>
                  <p className="text-xl rounded-md px-10 py-1 bg-logo-blue bg-opacity-10 my-2  font-medium text-logo-orange">
                    Basic Documents
                  </p>
                  {renderInput("FILE_UPLOAD", {
                    label: "Pan Card",
                    name: "firm_pan_card",
                    code: "firm_pan_card",
                    onChange: HandleFirmDetail,
                    value: firmData.firm_pan_card,
                    error: firmErr.firm_pan_card,
                    onlyId: true,
                    isrequired: false,
                    validations: validations,
                  })}
                  {renderInput("FILE_UPLOAD", {
                    label: "GST Certificate",
                    name: "firm_gst_certificate",
                    code: "firm_gst_certificate",
                    onChange: HandleFirmDetail,
                    value: firmData.firm_gst_certificate,
                    error: firmErr.firm_gst_certificate,
                    onlyId: true,
                    isrequired: false,
                    validations: validations,
                  })}
                  {renderInput("FILE_UPLOAD", {
                    label: "Udyam Certificate",
                    name: "firm_udyam_certificate",
                    code: "firm_udyam_certificate",
                    onChange: HandleFirmDetail,
                    value: firmData.firm_udyam_certificate,
                    error: firmErr.firm_udyam_certificate,
                    onlyId: true,
                    isrequired: false,
                    validations: validations,
                  })}
                  {renderInput("FILE_UPLOAD", {
                    label: "Memorandum of Association (MOA)",
                    name: "moa",
                    code: "moa",
                    onChange: HandleFirmDetail,
                    value: firmData.moa,
                    error: firmErr.moa,
                    onlyId: true,
                    isrequired: false,
                    validations: validations,
                  })}
                  {renderInput("FILE_UPLOAD", {
                    label: "Articles of Association (AOA)",
                    name: "aoa",
                    code: "aoa",
                    onChange: HandleFirmDetail,
                    value: firmData.aoa,
                    error: firmErr.aoa,
                    onlyId: true,
                    isrequired: false,
                    validations: validations,
                  })}
                  {renderInput("FILE_UPLOAD", {
                    label: "Certificate of Insurance (COI)",
                    name: "coi",
                    code: "coi",
                    onChange: HandleFirmDetail,
                    value: firmData.coi,
                    error: firmErr.coi,
                    onlyId: true,
                    isrequired: false,
                    validations: validations,
                  })}
                </>
              )}
              {stage === 4 && (
                <>
                  <p className="text-xl rounded-md px-10 py-1 bg-logo-blue bg-opacity-10 my-2  font-medium text-logo-orange">
                    More Documents
                  </p>
                  {renderInput("FILE_UPLOAD", {
                    label: "Rent Aggrement (if rented)",
                    name: "rent_aggremnet",
                    code: "rent_aggremnet",
                    onChange: HandleFirmDetail,
                    value: firmData.rent_aggremnet,
                    error: firmErr.rent_aggremnet,
                    onlyId: true,
                    isrequired: false,
                    validations: validations,
                  })}
                  {renderInput("FILE_UPLOAD", {
                    label: "Industry License",
                    name: "industry_license",
                    code: "industry_license",
                    onChange: HandleFirmDetail,
                    value: firmData.industry_license,
                    error: firmErr.industry_license,
                    onlyId: true,
                    isrequired: false,
                    validations: validations,
                  })}
                  {renderInput("FILE_UPLOAD", {
                    label: "Bank Statement (12 months)",
                    name: "one_year_bank_statement",
                    code: "one_year_bank_statement",
                    onChange: HandleFirmDetail,
                    value: firmData.one_year_bank_statement,
                    error: firmErr.one_year_bank_statement,
                    onlyId: true,
                    isrequired: false,
                    validations: validations,
                  })}
                  {renderInput("FILE_UPLOAD", {
                    label: "Ownership Proof",
                    name: "ownership_proof",
                    code: "ownership_proof",
                    onChange: HandleFirmDetail,
                    value: firmData.ownership_proof,
                    error: firmErr.ownership_proof,
                    onlyId: true,
                    isrequired: false,
                    validations: validations,
                  })}
                  {renderInput("FILE_UPLOAD", {
                    label: "ITR (last 2 years)",
                    name: "last_2_year_itr_with_financial_computation_gst_returns",
                    code: "last_2_year_itr_with_financial_computation_gst_returns",
                    onChange: HandleFirmDetail,
                    value:
                      firmData.last_2_year_itr_with_financial_computation_gst_returns,
                    error:
                      firmErr.last_2_year_itr_with_financial_computation_gst_returns,
                    onlyId: true,
                    isrequired: false,
                    validations: validations,
                  })}
                  {renderInput("FILE_UPLOAD", {
                    label: "Partnership Deed",
                    name: "patnership_deed",
                    code: "patnership_deed",
                    onChange: HandleFirmDetail,
                    value: firmData.patnership_deed,
                    error: firmErr.patnership_deed,
                    onlyId: true,
                    isrequired: false,
                    validations: validations,
                  })}
                </>
              )}
              <div className="mt-10 flex  flex-row-reverse gap-3 w-full items-center justify-between">
                <button
                  onClick={handleNextClick}
                  className={`${
                    stage === totalStage ? "bg-green-500" : "bg-blue-600"
                  } text-white px-4 py-2 rounded-md`}
                >
                  {stage === totalStage ? "Submit" : "Next"}
                </button>
                {stage !== 1 && (
                  <button
                    className="bg-blue-600 text-white px-4 py-2 rounded"
                    onClick={HandlePrevClick}
                  >
                    Prev
                  </button>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default FirmDetailBusinessLoan;
