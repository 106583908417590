import React from "react";
import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";

const FeedBackPage = () => {
  return (
    <div>
      <Navbar />
      <h1 className="text-4xl text-logo-blue text-center font-bold capitalize sm:text-4xl border-blue-900 mt-80">
        Hold tight! while we are Building this page
      </h1>
      <p className="text-xl text-logo-blue text-center font-medium   border-blue-900 mt-4">
        FeedBackPage Page Coming Soon...
      </p>
    </div>
  );
};

export default FeedBackPage;
