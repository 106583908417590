import React, { useEffect, useState } from "react";
import GenerateURL, { GenerateSearchURL } from "../../../utils/ApiUrlProvider";
import properties from "../../../Properties/Properties";
import { GetData } from "../../../utils/ApiInvoker";
import ListTable from "../../Common/ListTable";
import { useNavigate } from "react-router-dom";
import { GetTimeAgo } from "../../../utils/Healpers";
import DashboardBanner from "../../Common/DashboardBanner";
import MissingDataImage from "../../Common/MissingDataImage";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchBar from "../../Common/SearchBar";

const CreditCardList = () => {
  const [tableData, setTableData] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [rawdata, setRawdata] = useState(null);

  const navigate = useNavigate();

  const getIdFromApplicationNumber = (applicationNumber) => {
    console.log(rawdata);
    for (let i = 0; i < rawdata.length; i++) {
      if (rawdata[i].application_number === applicationNumber) {
        const { id } = rawdata[i];
        return {
          id,
        };
      }
    }
    return null;
  };

  const clickOn = (e) => {
    const loaninfo = getIdFromApplicationNumber(e["LeadNumber"]);
    navigate(`/admin/credit/${loaninfo.id}`);
  };

  const clickableOn = {
    on: ["LeadNumber"],
    funct: { LeadNumber: clickOn },
  };

  const convertDataToTableFormat = (data) => {
    const tableHeaders = [
      "LeadNumber",
      "Disbursement Amount",
      "Applied By",
      "Status",
      "Time",
    ];

    const tableContent = data.map((user) => {
      return {
        LeadNumber: user.application_number || "--",
        "Disbursement Amount": user.disbursement_amount || "--",
        "Applied By": user.user.mobile_number,
        Status: user.status || "N/A",
        Time: GetTimeAgo(user.created_at),
        id: user.id,
      };
    });
    return {
      tableHeaders,
      tableContent,
    };
  };

  const HandleSearchSucess = (res) => {
    console.log(res.results);
    if (res.results.length === 0) {
      toast.error("No Data found");
      GetCreditList();
    } else {
      HandleSucess(res);
    }
  };
  const searchParameters = [
    { name: "Application No", parm: "application_number" },
    { name: "Applied By", parm: "user_code" },
    { name: "Status", parm: "status" },
  ];
  const SearchClick = (e) => {
    const url = GenerateSearchURL(e, properties.api.adminCredit);
    GetData(url, HandleSearchSucess, HandleError, false, false);
  };
  const EmptyCall = () => {
    GetCreditList();
  };

  const HandleSucess = (response) => {
    const data = convertDataToTableFormat(response.results);
    setTableData(data);
    setPagination(response);
    setRawdata(response.results);
  };

  const HandleError = (response) => {
    console.log(response);
  };

  const GetNextLoanList = (nexturl) => {
    const url = GenerateURL({}, nexturl);
    GetData(url, HandleSucess, HandleError, false, false);
  };

  const GetPrevLoanList = (prevurl) => {
    const url = GenerateURL({}, prevurl);
    GetData(url, HandleSucess, HandleError, false, false);
  };

  const GetCreditList = () => {
    const url = GenerateURL({}, properties.api.adminCredit);
    GetData(url, HandleSucess, HandleError, false, false);
  };

  useEffect(() => {
    GetCreditList();
  }, []);

  const view = (e) => {
    navigate(`/admin/credit/${e.id}`);
  };

  return (
    <div className="w-11/12">
      <DashboardBanner />
      {tableData && tableData.tableContent.length === 0 ? (
        <div className="flex flex-col gap-2">
          <MissingDataImage message={"No Credit Card Applications Found"} />
        </div>
      ) : (
        tableData && (
          <div className="w-full flex flex-col  gap-2 ">
            <SearchBar
              searchParameters={searchParameters}
              SearchClick={SearchClick}
              EmptyCall={EmptyCall}
            />
            <ListTable
              heading="Loan Applications"
              tableData={tableData}
              pagination={Boolean(pagination.next || pagination.previous)}
              next={pagination.next}
              prev={pagination.previous}
              onNextClick={() => GetNextLoanList(pagination.next)}
              onPrevClick={() => GetPrevLoanList(pagination.previous)}
              actions={{ view: true, edit: false, delete: false }}
              onView={view}
              clickabaleOn={clickableOn}
            />
          </div>
        )
      )}
    </div>
  );
};

export default CreditCardList;
