import React, { useState } from "react";
import CalculatorEnquiry from "../Home/CalculatorEnquiry";
import Footer from "../Home/Footer";
import AmortizationTable from "../../Common/AmortizationTable";

const BusinessLoan = () => {
  const [currentNav, setCurrentNav] = useState(0);
  const [amortizationTableData, setAmortizationTableData] = useState(null);

  const pageData = {
    titles: [
      "Amortization Schedule",
      "Check Your Eligibility",
      "Benefits For You",
      "Documents Required",
    ],
    1: {
      title: "Check Your Eligibility",
      points: [
        "Revenue Criteria: Minimum turnover of ₹10,00,000 in the last 12 months.",
        "A clear tax record; having a good tax filing history will ensure that you get a high loan amount.",
        "Business Operation: Must have been operational for over 6 months.",
        "Age Between 18 to 60 years.",
      ],
    },
    2: {
      title: "Benefits For You",
      points: [
        "Flexible Financing: Offers loans from ₹1 LAKH to ₹5 Crore to increase SME approval rates.",
        "Quick disbursal process (Same Day).",
        "Competitive Rates: Attractive interest rates based on thorough financial analysis.",
        "Flexible tenure from 12 months to 48 months.",
      ],
    },
    3: {
      title: "Documents Required",
      points: [
        "KYC of Applicant and Co-Applicant: Pan Card, Aadhaar card, passport size photo.",
        "1 Year Bank Statement.",
        "Business Proof (G.S.T. Certificate & MSME certificate or Trade License).",
        "Ownership Proof (E-Bill).",
        "Last 2 Year ITR with Financial & GST Returns.",
      ],
    },
  };

  const updateAmortizationTableData = (data) => {
    setAmortizationTableData(data);
  };

  return (
    <div className="bg-gray-100 min-h-screen mt-24">
      <div className=" container mx-auto mb-10">
        <div className="flex">
          <a
            href="/"
            className="text-sm text-logo-blue font-normal  hover:text-logo-orange border-blue-900 mt-5 ml-3"
          >
            Home{" "}
            <span className="text-sm text-logo-blue font-medium  hover:text-logo-blue ">
              /{" "}
            </span>
          </a>
          <a
            href=""
            className="text-sm text-logo-blue font-normal hover:text-logo-orange  mt-5"
          >
            Business Loan
          </a>
        </div>
        <h1 className="text-4xl text-logo-blue text-center font-bold capitalize sm:text-4xl border-blue-900 md:mt-4">
          Business Loan
        </h1>
        <p className="text-xs text-logo-blue text-center w-11/12 m-auto font-normal sm:text-base md:mt-4">
          Fuel your entrepreneurial dreams with Udharibazaar's Business Loans.
          Turn your business vision into reality with our tailored financing
          solutions.
        </p>
        <div className="flex justify-center items-center mt-10">
          <div className="w-full">
            <CalculatorEnquiry
              updateAmortizationTableData={updateAmortizationTableData}
            />
          </div>
        </div>
        <div className="bg-gray-200 rounded-lg w-full mx-auto mt-8 text-center">
          <ul className="flex items-center justify-start flex-wrap">
            {pageData.titles.map((menue, key) => {
              return (
                <li
                  key={key}
                  onClick={() => {
                    setCurrentNav(key);
                  }}
                  className={`cursor-pointer p-4 ${
                    key === currentNav
                      ? "text-orange-400 border-b-2 border-orange-400"
                      : ""
                  }`}
                >
                  {menue}
                </li>
              );
            })}
          </ul>
          <div className="w-full  py-2 bg-white rounded-b-lg">
            {currentNav === 0 && amortizationTableData && (
              <AmortizationTable data={amortizationTableData} />
            )}
            {currentNav !== 0 && (
              <div className="section-content mx-auto bg-white px-4  flex flex-col items-start">
                <h3 className="text-2xl font-bold text-logo-blue">
                  {pageData.titles[currentNav]}
                </h3>

                <ul className="list-disc list-inside mt-4 text-left text-logo-blue">
                  {pageData[currentNav].points.map((point, index) => (
                    <li key={index} className="mb-2">
                      {point}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BusinessLoan;
