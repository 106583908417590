import React, { useState } from "react";
import { renderInput } from "../../Common/FormComponents";
import properties from "../../../Properties/Properties";
import GenerateURL from "../../../utils/ApiUrlProvider";
import { PostData } from "../../../utils/ApiInvoker";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLoadingContext } from "../../../Context/LoadingContext";

const PartnerRegister = () => {
  const { showLoader, hideLoader } = useLoadingContext();

  const [formData, setFormData] = useState({
    mobile_number: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    terms: false,
  });
  const [formErrors, setFormErrors] = useState({
    mobile_number: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    terms: "",
  });
  const navigate = useNavigate();

  function registrationSuccess(response) {
    hideLoader();
    navigate("/partner/login");
    toast.success(
      "Registered successfully, Please use login portal to login!",
      {
        autoClose: 2000,
      }
    );
  }
  const registrationFail = (response) => {
    hideLoader();
    const error = {
      email: response.email ? response.email[0] : "",
      mobile_number: response.mobile_number ? response.mobile_number[0] : "",
    };
    setFormErrors(error);
  };

  function invokeRegister(e) {
    e.preventDefault();
    if (validateForm()) {
      var url = GenerateURL({}, properties.api.partnerRegister);
      showLoader();
      PostData(url, formData, registrationSuccess, registrationFail, true);
    }
  }

  const validateForm = () => {
    let valid = true;
    const errors = {};
    if (!formData.first_name.trim()) {
      errors.first_name = "First name is required";
      valid = false;
    }
    if (!formData.last_name.trim()) {
      errors.last_name = "Last name is required";
      valid = false;
    }

    if (!formData.terms) {
      errors.terms = "Agree to Terms & Condition";
      valid = false;
    }
    const mobileRegex = /^[0-9]{10}$/;
    if (
      !formData.mobile_number.trim() ||
      !mobileRegex.test(formData.mobile_number.trim())
    ) {
      errors.mobile_number = "Invalid mobile number (should be 10 digits)";
      valid = false;
    }

    const emailRegex = /\S+@\S+\.\S+/;
    if (!formData.email.trim() || !emailRegex.test(formData.email.trim())) {
      errors.email = "Invalid email address";
      valid = false;
    }
    setFormErrors(errors);
    return valid;
  };

  const handlechange = (e) => {
    const { name, value } = e.target;

    let inputNumeric;

    if (name === "mobile_number") {
      inputNumeric = /^[0-9\b]+$/;
      if (value === "" || inputNumeric.test(value)) {
        setFormErrors({ ...formErrors, [name]: "" });
        setFormData({ ...formData, [name]: value });
      } else {
        setFormErrors({
          ...formErrors,
          [name]: "Please enter only numeric values.",
        });
      }
    } else if (name === "first_name" || name === "last_name") {
      let inputAlphabets = /^[A-Za-z]+$/;
      if (value === "" || inputAlphabets.test(value)) {
        setFormErrors({ ...formErrors, [name]: "" });
        setFormData({ ...formData, [name]: value });
      } else {
        setFormErrors({
          ...formErrors,
          [name]: "Please enter only alphabetic values.",
        });
      }
    } else {
      setFormData({ ...formData, [name]: value });
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };
  const handleCheckboxchange = (event) => {
    const isChecked = event.target.checked;
    setFormErrors({ ...formErrors, terms: "" });
    setFormData((prevFormData) => ({
      ...prevFormData,
      terms: isChecked,
    }));
  };

  return (
    <div
      className="flex flex-col justify-center items-center min-h-screen bg-gray-100"
      style={{
        backgroundImage: `url('https://img.freepik.com/free-photo/artistic-blurry-colorful-wallpaper-background_58702-8169.jpg?size=626&ext=jpg&uid=R128585067&ga=GA1.1.923863604.1702044673&semt=ais')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="bg-white p-4 md:p-8 shadow-md rounded-md lg:w-1/2 md:w-3/4 sm:w-3/4">
        <h1 className="text-2xl md:text-3xl text-center font-bold text-gray-800 mb-4 md:mb-6">
          Register for a new account
        </h1>
        <div className="mb-4 md:mb-6">
          {renderInput("STRING", {
            label: "First Name",
            name: "first_name",
            value: formData.first_name,
            onChange: handlechange,
            error: formErrors.first_name,
          })}
          {renderInput("STRING", {
            label: "Middle Name",
            name: "middle_name",
            value: formData.middle_name,
            onChange: handlechange,
            error: formErrors.middle_name,
          })}
          {renderInput("STRING", {
            label: "Last Name",
            name: "last_name",
            value: formData.last_name,
            onChange: handlechange,
            error: formErrors.last_name,
          })}
          {renderInput("STRING", {
            label: "Mobile Number",
            name: "mobile_number",
            value: formData.mobile_number,
            onChange: handlechange,
            error: formErrors.mobile_number,
          })}
          {renderInput("STRING", {
            label: "Email",
            name: "email",
            value: formData.email,
            onChange: handlechange,
            error: formErrors.email,
          })}
          <div className="text-center">
            <input
              name="terms"
              type="checkbox"
              onClick={handleCheckboxchange}
              value={formData.terms}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label className="ms-2 text-sm font-medium text-black dark:text-black">
              I agree with the{" "}
              <a
                href="#"
                className="text-blue-600 dark:text-blue-500 hover:underline"
              >
                terms and conditions
              </a>
              .
            </label>
            <div className="w-full">
              {formErrors.terms && (
                <p className="text-red-500 text-sm mt-1">{formErrors.terms}</p>
              )}
            </div>
          </div>
        </div>

        <div className="mt-4 md:mt-6 text-center mb-4 md:mb-6 ">
          <button
            onClick={invokeRegister}
            className="bg-[#0065FF] text-white py-2 px-4 rounded "
          >
            Register
          </button>
        </div>
        <p className="mt-4 md:mt-6 text-center md:text-center">
          Already have an account?{" "}
          <a href="/partner/login" className="text-blue-500">
            Login
          </a>
        </p>
      </div>
    </div>
  );
};

export default PartnerRegister;
