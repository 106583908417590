import React, { useEffect, useRef, useState } from "react";
import GenerateURL from "../../../utils/ApiUrlProvider";
import properties from "../../../Properties/Properties";
import { renderInput } from "../../Common/FormComponents";
import { PutData } from "../../../utils/ApiInvoker";
import { IsNormalUser } from "../../../utils/AccessTokenStore";
import { useLoadingContext } from "../../../Context/LoadingContext";
import ModalWindow from "./ModalWindow";
import { ProfileEditVal } from "../FormValidation/ProfileEditval";

const UpdateProfile = ({
  isModalOpen,
  setProfileEditModal,
  initialUserData,
}) => {
  const { showLoader, hideLoader } = useLoadingContext();
  const [formData, setFormData] = useState({
    first_name: initialUserData.first_name || "",
    middle_name: initialUserData.middle_name || "",
    last_name: initialUserData.last_name || "",
    email: initialUserData.email || "",
  });
  const [formErrors, setFormErrors] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
  });

  const saveResposneSucess = (response) => {
    setProfileEditModal(false);
    hideLoader();
  };
  const saveResposneFailure = (response) => {
    hideLoader();
  };

  const updateUserInfo = () => {
    const authData = JSON.parse(localStorage.getItem("authData"));
    const validation = ProfileEditVal(formData);
    console.log(validation);
    if (validation.is_valid) {
      if (IsNormalUser()) {
        var url = GenerateURL(
          { id: authData.userDetails.id },
          properties.api.userinfoupdate
        );
      } else {
        var url = GenerateURL(
          { id: authData.userDetails.id },
          properties.api.partnerInfoUpdate
        );
      }
      showLoader();
      PutData(url, formData, saveResposneSucess, saveResposneFailure, false);
    } else {
      console.log(validation.error);
      setFormErrors(validation.error);
    }
  };
  const handlechange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  return (
    <>
      {isModalOpen && (
        <ModalWindow
          title={"Edit Details"}
          onClose={() => setProfileEditModal(false)}
        >
          <div className="w-full flex flex-col">
            {renderInput("STRING", {
              label: "First Name",
              name: "first_name",
              value: formData.first_name,
              onChange: handlechange,
              error: formErrors.first_name,
              tooltip: "Use letters only for the name",
            })}
            {renderInput("STRING", {
              label: "Middle Name",
              name: "middle_name",
              value: formData.middle_name,
              error: formErrors.middle_name,
              onChange: handlechange,
              tooltip: "Use letters only for the name",
            })}
            {renderInput("STRING", {
              label: "Last Name",
              name: "last_name",
              value: formData.last_name,
              error: formErrors.last_name,
              onChange: handlechange,
              tooltip: "Use letters only for the name",
            })}
            {renderInput("STRING", {
              label: "Email",
              name: "email",
              value: formData.email,
              error: formErrors.email,
              onChange: handlechange,
              tooltip: `Use "@" , ".com"`,
            })}
            <div className="flex justify-center items-center">
              <button
                className="bg-blue-500 text-white px-1 py-1 md:px-4 md:py-2   rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300  "
                onClick={updateUserInfo}
              >
                Save
              </button>
            </div>
          </div>
        </ModalWindow>
      )}
    </>
  );
};

export default UpdateProfile;
