import React, { useEffect, useRef, useState } from "react";
import GenerateURL from "../../../utils/ApiUrlProvider";
import properties from "../../../Properties/Properties";
import { renderInput } from "../../Common/FormComponents";
import { PostData } from "../../../utils/ApiInvoker";
import { useLoadingContext } from "../../../Context/LoadingContext";

const AddRMModal = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    mobile_number: "",
    email: "",
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    mobile_number: "",
    email: "",
  });
  const modalRef = useRef();
  const { showLoader, hideLoader } = useLoadingContext();
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };
  const handleInteractionInsideModal = (event) => {
    event.stopPropagation();
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);
  const handleSaveClick = () => {
    updateRm();
  };
  const validateForm = () => {
    let valid = true;
    const errors = {};
    if (!formData.name.trim()) {
      errors.name = "Name is required";
      valid = false;
    }
    const mobileRegex = /^[1-9][0-9]{9}$/;
    if (
      !formData.mobile_number.trim() ||
      !mobileRegex.test(formData.mobile_number.trim())
    ) {
      errors.mobile_number = "Invalid mobile number";
      valid = false;
    }
    if (!formData.email.trim()) {
      errors.email = "Email is Required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email.trim())) {
      errors.email = "Invalid Email Adress type";
      valid = false;
    }
    setFormErrors(errors);
    return { valid, errors };
  };
  function saveResposneSucess(response) {
    window.location.reload();
    hideLoader();
  }
  function saveResposneFailure(response) {
    hideLoader();
    const error = {
      mobile_number: response.mobile_number ? response.mobile_number[0] : "",
      email: response.email ? response.email[0] : "",
    };
    setFormErrors(error);
  }
  function updateRm(e) {
    const validation = validateForm();
    if (validation.valid) {
      var url = GenerateURL({}, properties.api.adminRelationManager);
    }
    PostData(url, formData, saveResposneSucess, saveResposneFailure, false);
  }
  const handlechange = (e) => {
    const { name, value } = e.target;
    let inputNumeric;
    if (name === "mobile_number") {
      inputNumeric = /^[0-9\b]+$/;
      if (value === "" || inputNumeric.test(value)) {
        setFormErrors({ ...formErrors, [name]: "" });
        setFormData({ ...formData, [name]: value });
      } else {
        setFormErrors({
          ...formErrors,
          [name]: "Please enter only numeric values.",
        });
      }
    } else {
      setFormData({ ...formData, [name]: value });
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };
  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60"
      onClick={onClose}
      style={{ zIndex: 1000 }}
    >
      <div
        className="w-[80%] sm:w-[55%] md:1/2  sm:ml-60 p-6 bg-white rounded-lg shadow-lg mt-48 mb-80 lg:w-1/2  "
        onClick={handleInteractionInsideModal}
        style={{ zIndex: 1001 }}
        ref={modalRef}
      >
        <div className="flex justify-between border-b-2 pb-4 text-2xl md:text-3xl  font-bold text-gray-800 mb-4 md:mb-6">
          <h1 className="font-medium text-xl ">Add Relation Manager</h1>
          <div className="flex flex-row relative">
            <button className="rounded-lg px-1  hover:scale-110">
              <img
                src="https://cdn-icons-png.flaticon.com/128/2997/2997911.png"
                alt=""
                className="h-5"
                onClick={onClose}
              />
            </button>
          </div>
        </div>
        <div className="p-8 gap-5 flex flex-col items-center justify-center">
          <>
            <div className="w-full flex flex-col">
              {renderInput("STRING", {
                label: "Name",
                name: "name",
                value: formData.name,
                onChange: handlechange,
                error: formErrors.name,
              })}
              {renderInput("STRING", {
                label: "Mobile Number",
                name: "mobile_number",
                value: formData.mobile_number,
                onChange: handlechange,
                error: formErrors.mobile_number,
              })}
              {renderInput("STRING", {
                label: "Email",
                name: "email",
                value: formData.email,
                onChange: handlechange,
                error: formErrors.email,
              })}
              <div className="flex justify-center items-center">
                <button
                  className="bg-blue-500 text-white px-1 py-1 md:px-4 md:py-2   rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300  "
                  onClick={handleSaveClick}
                >
                  Save
                </button>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};
export default AddRMModal;
