import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Reviews = () => {
  const reviewsData = [
    {
      id: 1,
      reviewText:
        "UdhariBazaar made getting a loan incredibly easy and fast. The online application was simple, and I got my funds the next day. Competitive rates and flexible repayment options made the process stress-free. Highly recommended!",
      name: "Sanjay",
      PhotoUrl: "https://cdn.staropstech.com/clients/ReviewImages/client2.webp",
    },
    {
      id: 2,
      reviewText:
        "UdhariBazaar was a lifesaver for my emergency expenses. The process was quick and transparent, with no hidden fees. Overall, a reliable and efficient loan service.",
      name: "Anil Patel",
      PhotoUrl: "https://cdn.staropstech.com/clients/ReviewImages/client1.webp",
    },
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className=" flex items-center flex-col text-logo-blue w-10/12 m-auto  ">
      <h1 className="text-4xl font-bold capitalize text-center py-2">
        Highest Standards. <span className="text-logo-orange"> Happiest</span>{" "}
        Partners
      </h1>
      <p className="w-32 border-b-2 border-logo-orange"></p>
      <p className="text-sm text-logo-blue text-opacity-90 p-2 font-semibold">
        Our partners are our strength
      </p>
      <div className="w-full  my-5">
        <Carousel
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={6000}
          responsive={responsive}
          swipeable={false}
          draggable={false}
          arrows={false}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          containerClass="carousel-container"
          showDots
          // renderDotsOutside={true}
        >
          {reviewsData.map((review, index) => (
            <div
              className="flex flex-row w-full shadow-lg rounded-md"
              key={index}
            >
              <img
                className="hidden sm:block w-1/2 h-[250px] aspect-video rounded-l-md "
                src={review.PhotoUrl}
                alt="udharibazaar clients review"
              />
              <div>
                <div className="p-4 w-full flex flex-col gap-5">
                  <p className="w-full italic text-base text-logo-blue text-opacity-80 ">
                    {review.reviewText}
                  </p>
                  <div className="flex items-center gap-4">
                    <div className="font-medium">
                      <div>{review.name}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Reviews;
