import React, { useState } from "react";

const ConnectWithUs = () => {
  const [showOptions, setShowOptions] = useState(false);
  const [showMenu, setShowMenu] = useState(true);

  const openWhatsApp = () => {
    window.open(`https://wa.me/6393803009`, "_blank");
  };

  const makePhoneCall = () => {
    window.open("tel:+91 6393803009");
  };

  const toggleOptions = (value) => {
    setShowOptions(value);
  };

  const closeCircle = () => {
    setShowMenu(!showMenu);
  };

  return (
    <>
      {showMenu ? (
        <button
          className=" fixed bottom-9 right-7 z-50 bg-red-500 rounded-full "
          onClick={closeCircle}
        >
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      ) : (
        <button
          className=" fixed bottom-9 right-7 z-50 bg-green-600 rounded-full   "
          onClick={closeCircle}
        >
          <img
            className="h-6"
            src="https://cdn.staropstech.com/clients/udharibazaar/assets/extralogo/up.webp"
            alt=""
          />
        </button>
      )}
      {showMenu && (
        <div className="fixed bottom-10 right-10 flex flex-col items-end z-40 group">
          <div
            className="bg-blue-100 text-white p-2 rounded-full cursor-pointer relative shadow-md "
            onMouseEnter={() => toggleOptions(true)}
            onMouseLeave={() => toggleOptions(false)}
          >
            <img
              src="https://cdn.staropstech.com/clients/udharibazaar/assets/extra-images/chat.webp"
              alt="chat"
              className="w-14 h-14 "
            />
            {showOptions && (
              <div className="absolute -top-20 right-5 bg-blue-100 text-black p-4 rounded-md w-52 h-90 flex flex-col justify-around shadow-lg -z-50">
                <h3 className="text-lg font-semibold">Chat with us:</h3>
                <div className="flex gap-3  mt-4">
                  <a href="" className="text-sm">
                    <img
                      src="https://cdn.staropstech.com/clients/udharibazaar/assets/extra-images/whatsapp.webp"
                      alt=""
                      className="w-10 cursor-pointer"
                      onClick={openWhatsApp}
                    />
                  </a>

                  <a
                    href="mailto:info@udharibazaar.com"
                    title="send email"
                    target="_blank"
                    className="text-sm"
                  >
                    <img
                      src="https://cdn.staropstech.com/clients/udharibazaar/assets/extra-images/mail.webp"
                      alt=""
                      className="w-10 cursor-pointer"
                    />
                  </a>
                  <a href="#" className="text-sm">
                    <img
                      src="https://cdn.staropstech.com/clients/udharibazaar/assets/extra-images/phone-call.webp"
                      alt="call"
                      className="w-10 cursor-pointer"
                      onClick={makePhoneCall}
                    />
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ConnectWithUs;
