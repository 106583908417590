import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HealthCheck from "./Views/HealthCheck";
import CalculatorApp from "./Views/Calculator/Calculator";
import Test from "./Views/Components/Test/Test";
import AuthenticatedRoutes from "./utils/AuthenticatedRoutes";
import UserDashboard from "./Views/Components/Dashboard/UserDashboard";
import PartnerRegister from "./Views/Partner/ResigntrationForms/Register";
import PartnerLogin from "./Views/Partner/ResigntrationForms/Login";
import AdminLogin from "./Views/Admin/RegistrationForms/AdminLogin";
import { ToastContainer } from "react-toastify";
import { LoadingProvider } from "./Context/LoadingContext";
import LeadEdit from "./Views/Components/Pages/Services/LeadEdit";
import Homepage from "./Views/Components/Home/Homepage";
import PartnerDashboard from "./Views/Partner/Dashboard/PartnerDashboard";

import PartnerData from "./Views/Partner/Pages/PartnerData";
import ConnectWithUs from "./Views/Components/Home/ConnectWithUs";
import PersonalLoan from "./Views/Components/loanComponents/PersonalLoan";
import BusinessLoan from "./Views/Components/loanComponents/BusinessLoan";
import HomeLoan from "./Views/Components/loanComponents/HomeLoan";
import CarLoan from "./Views/Components/loanComponents/CarLoan";
import CreditLoan from "./Views/Components/loanComponents/CreditLoan";
import HealthInsurance from "./Views/Components/loanComponents/HealthInsurance";
import TermInsurance from "./Views/Components/loanComponents/TermInsurance";
import Support from "./Views/Components/Pages/Support";
import Rootlayout from "./Layout/Rootlayout";
import Commisions from "./Views/Partner/Pages/Commisions";
import PrivacyPage from "./Views/Components/DetailsPages/PrivacyPage";
import TermsAndConditionPage from "./Views/Components/DetailsPages/TermsAndConditionPage";
import FAQPage from "./Views/Components/DetailsPages/FAQPage";
import Blogs from "./Views/Components/DetailsPages/Blogs";
import FeedBackPage from "./Views/Components/DetailsPages/FeedBackPage";
import CareerPage from "./Views/Components/DetailsPages/Career";
import AboutUsPage from "./Views/Components/DetailsPages/AboutUsPage";
import PartnerDetailsInfo from "./Views/Partner/Pages/PartnerDetailsInfo";
import DashboardWithoutSidebar from "./Views/Partner/Dashboard/DashboardWithoutSidebar";
import NotFound from "./Views/NotFound";
import InsurancePage from "./Views/Components/loanComponents/Insurance";
import NewsPage from "./Views/Components/DetailsPages/NewsPage";
import PartnerDetailsEdit from "./Views/Partner/Pages/PartnerDetailEdit";

import BusinessLoanDetail from "./Views/Components/Pages/Servicesdetail/BusinessLoanDetail";
import AssociatePartners from "./Views/Partner/Pages/AssociatePartners";
import AssociatePartnerList from "./Views/Admin/ListTable/AssociatePartnerList";
import UserDetailsAdmin from "./Views/Admin/DetailsCard/UserDetailsAdmin";
import AdminProfile from "./Views/Admin/Pages/AdminProfile";
import UserProfile from "./Views/Common/UserProfile";
import RecentLoggedUserList from "./Views/Admin/ListTable/RecentLoggedUserList";
import AdminLayout from "./Views/Admin/Dashboard/AdminLayout";
import CardsData from "./Views/Admin/Dashboard/CardsData";
import UserList from "./Views/Admin/ListTable/UserList";
import PartnerList from "./Views/Admin/ListTable/PartnerList";
import CreditCardList from "./Views/Admin/ListTable/CreditCardList";
import CreditCardInfo from "./Views/Admin/DetailsCard/CreditCard";
import InsuranceList from "./Views/Admin/ListTable/InsuranceList";
import InsuranceCard from "./Views/Admin/DetailsCard/InsuranceCard";
import RelationManagerList from "./Views/Admin/ListTable/RelationManagerList";
import RMCard from "./Views/Admin/DetailsCard/RMCard";
import SupportList from "./Views/Admin/ListTable/SupportList";
import EnquieryList from "./Views/Admin/ListTable/EnquieryList";
import PayoutList from "./Views/Admin/ListTable/PayoutList";
import LoanAmountList from "./Views/Admin/ListTable/LoanAmountList";
import InsurnaceAmountList from "./Views/Admin/ListTable/InsurnaceAmountList";
import BasicInfoPersonalLoan from "./Views/Components/Pages/Services/Personal Loan/BasicInfoPersonalLoan";
import PersonalLoanDetail from "./Views/Components/Pages/Servicesdetail/PersonalLoanDetail";
import SalaryInfoPersonalLoan from "./Views/Components/Pages/Services/Personal Loan/SalaryInfoPersonalLoan";
import CoApplicantPersonalLoan from "./Views/Components/Pages/Services/Personal Loan/CoApplicantPersonalLoan";
import SelfEmploymentInfoPersonalLoan from "./Views/Components/Pages/Services/Personal Loan/SelfEmploymentInfoPersonalLoan";
import ReferenceInfoPersonalLoan from "./Views/Components/Pages/Services/Personal Loan/ReferenceInfoPersonalLoan";
import PersonalLoanListAdmin from "./Views/Admin/ListTable/PersonalLoanListAdmin";
import PersonalLoanDetailAdmin from "./Views/Admin/DetailsCard/PersonalLoanDetailAdmin";
import BasicInfoBusinessLoan from "./Views/Components/Pages/Services/Business Loan/BasicInfoBusinessLoan";
import CoApplicantBusinessLoan from "./Views/Components/Pages/Services/Business Loan/CoApplicantBusinessLoan";
import ReferenceBusinessLoan from "./Views/Components/Pages/Services/Business Loan/ReferenceBusinessLoan";
import FirmDetailBusinessLoan from "./Views/Components/Pages/Services/Business Loan/FirmDetailBusinessLoan";
import BusinessLoanListAdmin from "./Views/Admin/ListTable/BusinessLoanListAdmin";
import BusinessLoanDetailAdmin from "./Views/Admin/DetailsCard/BusinessLoanDetailAdmin";
import BasicInfoAutoLoan from "./Views/Components/Pages/Services/Auto Loan/BasicInfoAutoLoan";
import LeadList from "./Views/Components/Pages/Services/Service Table/LeadList";
import AutoLoanDetail from "./Views/Components/Pages/Servicesdetail/AutoLoanDetail";
import SelfEmployedInfoAutoLoan from "./Views/Components/Pages/Services/Auto Loan/SelfEmployedInfoAutoLoan";
import SalaryInfoAutoLoan from "./Views/Components/Pages/Services/Auto Loan/SalaryInfoAutoLoan";
import ReferenceInfoAutoLoan from "./Views/Components/Pages/Services/Auto Loan/ReferenceInfoAutoLoan";
import AutoLoanListAdmin from "./Views/Admin/ListTable/AutoLoanListAdmin";
import AutoLoanDetailAdmin from "./Views/Admin/DetailsCard/AutoLoanDetailAdmin";

const App = () => {
  return (
    <Router>
      <LoadingProvider>
        <ToastContainer />
        <AuthenticatedRoutes>
          <Routes>
            <Route path="/" element={<Rootlayout />}>
              <Route path="" element={<Homepage />} />
              <Route path="health" element={<HealthCheck />} />
              <Route path="calculator" element={<CalculatorApp />} />
              <Route path="personal-loan" element={<PersonalLoan />} />
              <Route path="business-loan" element={<BusinessLoan />} />
              <Route path="home-loan" element={<HomeLoan />} />
              <Route path="car-loan" element={<CarLoan />} />
              <Route path="credit-loan" element={<CreditLoan />} />
              <Route path="health-insurance" element={<HealthInsurance />} />
              <Route path="term-insurance" element={<TermInsurance />} />
              <Route path="auto-insurance" element={<InsurancePage />} />
              <Route path="privacy-policy" element={<PrivacyPage />} />
              <Route path="blogs" element={<Blogs />} />
              <Route path="feedback" element={<FeedBackPage />} />
              <Route path="career" element={<CareerPage />} />
              <Route path="about-us" element={<AboutUsPage />} />
              <Route path="news" element={<NewsPage />} />
              <Route
                path="term-and-conditions"
                element={<TermsAndConditionPage />}
              />
              <Route path="faq" element={<FAQPage />} />
              <Route path="test" element={<Test />} />
            </Route>
            <Route path="/partner/login" element={<PartnerLogin />} />
            <Route path="/partner/register" element={<PartnerRegister />} />
            <Route path="/admin/login" element={<AdminLogin />} />

            {/* ================
                  USER
            =============== */}
            <Route path="/user/dashboard" element={<UserDashboard />}>
              <Route path="" element={<LeadList />} />
            </Route>
            <Route path="/user" element={<UserDashboard />}>
              <Route path="profile/" element={<UserProfile />} />
              {/*Personal Loan */}
              <Route
                path="loan/personalloan/:id?/:type?"
                element={<BasicInfoPersonalLoan />}
              />
              <Route
                path="loan/personalloan/:id/salaried/:secid?/:type?"
                element={<SalaryInfoPersonalLoan />}
              />
              <Route
                path="loan/personalloan/:id/selfEmployed/:secid?/:type?"
                element={<SelfEmploymentInfoPersonalLoan />}
              />
              <Route
                path="loan/personalloan/:id/coapplicant/:secid?/:type?"
                element={<CoApplicantPersonalLoan />}
              />

              <Route
                path="loan/personalloan/:id/reference/:secid?/:type?"
                element={<ReferenceInfoPersonalLoan />}
              />
              <Route
                path="loan/personalloan/:id/detail"
                element={<PersonalLoanDetail />}
              />
              {/*Business  Loan */}
              <Route
                path="loan/businessloan/:id?/:type?"
                element={<BasicInfoBusinessLoan />}
              />
              <Route
                path="loan/businessloan/:id/coapplicant/:secid?/:type?"
                element={<CoApplicantBusinessLoan />}
              />
              <Route
                path="loan/businessloan/:id/reference/:secid?/:type?"
                element={<ReferenceBusinessLoan />}
              />
              <Route
                path="loan/businessloan/:id/firmdetail/:secid?/:type?"
                element={<FirmDetailBusinessLoan />}
              />
              <Route
                path="loan/businessLoan/:id/detail"
                element={<BusinessLoanDetail />}
              />

              {/*Auto Loan */}
              <Route
                path="loan/autoloan/:id?/:type?"
                element={<BasicInfoAutoLoan />}
              />
              <Route
                path="loan/autoloan/:id/selfEmployed/:secid?/:type?"
                element={<SelfEmployedInfoAutoLoan />}
              />
              <Route
                path="loan/autoloan/:id/salaried/:secid?/:type?"
                element={<SalaryInfoAutoLoan />}
              />

              <Route
                path="loan/autoloan/:id/detail"
                element={<AutoLoanDetail />}
              />
              <Route
                path="loan/autoloan/:id/reference/:secid?/:type?"
                element={<ReferenceInfoAutoLoan />}
              />

              <Route path="support" element={<Support />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            {/* ================ADMIN=============== */}
            <Route
              path="/admin/*"
              element={
                <AdminLayout>
                  <Routes>
                    <Route path="dashboard" element={<CardsData />} />
                    <Route path="profile" element={<AdminProfile />} />
                    <Route path="users" element={<UserList />} />
                    <Route path="user/:id" element={<UserDetailsAdmin />} />
                    <Route path="user-partner" element={<PartnerList />} />
                    <Route
                      path="associated_partner"
                      element={<AssociatePartnerList />}
                    />
                    <Route path="partner/:id" element={<UserDetailsAdmin />} />
                    <Route
                      path="user/recents"
                      element={<RecentLoggedUserList />}
                    />
                    {/* Personal Loan */}
                    <Route
                      path="personalloan"
                      element={<PersonalLoanListAdmin />}
                    />
                    <Route
                      path="personalloan/:id"
                      element={<PersonalLoanDetailAdmin />}
                    />
                    {/* Business Loan */}
                    <Route
                      path="businessloan"
                      element={<BusinessLoanListAdmin />}
                    />
                    <Route
                      path="businessloan/:id"
                      element={<BusinessLoanDetailAdmin />}
                    />
                    {/* Auto Loan */}
                    <Route path="autoloan" element={<AutoLoanListAdmin />} />
                    <Route
                      path="autoloan/:id"
                      element={<AutoLoanDetailAdmin />}
                    />

                    <Route path="credit" element={<CreditCardList />} />
                    <Route path="credit/:id" element={<CreditCardInfo />} />
                    <Route path="insurance" element={<InsuranceList />} />
                    <Route path="insurance/:id" element={<InsuranceCard />} />
                    <Route
                      path="relation-manager"
                      element={<RelationManagerList />}
                    />
                    <Route path="relation-manager/:id" element={<RMCard />} />
                    <Route path="support" element={<SupportList />} />
                    <Route path="user-enquiery" element={<EnquieryList />} />
                    <Route path="payouts" element={<PayoutList />} />
                    <Route path="loan-amount" element={<LoanAmountList />} />
                    <Route
                      path="insurance-amount"
                      element={<InsurnaceAmountList />}
                    />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </AdminLayout>
              }
            />
            {/*================
                 PARTNER
            =============== */}

            <Route
              path="/partner/userdetail"
              element={<DashboardWithoutSidebar />}
            >
              <Route path="" element={<PartnerDetailsInfo />} />
              <Route path="edit" element={<PartnerDetailsEdit />} />
            </Route>
            <Route path="/partner/dashboard" element={<PartnerDashboard />}>
              <Route path="" element={<LeadList />} />
            </Route>

            <Route path="/partner" element={<PartnerDashboard />}>
              <Route path="profile" element={<UserProfile />} />
              {/* =========PERSONAL LOAN =========== */}
              {/*Personal Loan */}
              <Route
                path="loan/personalloan/:id?/:type?"
                element={<BasicInfoPersonalLoan />}
              />
              <Route
                path="loan/personalloan/:id/salaried/:secid?/:type?"
                element={<SalaryInfoPersonalLoan />}
              />
              <Route
                path="loan/personalloan/:id/selfEmployed/:secid?/:type?"
                element={<SelfEmploymentInfoPersonalLoan />}
              />
              <Route
                path="loan/personalloan/:id/coapplicant/:secid?/:type?"
                element={<CoApplicantPersonalLoan />}
              />

              <Route
                path="loan/personalloan/:id/reference/:secid?/:type?"
                element={<ReferenceInfoPersonalLoan />}
              />
              <Route
                path="loan/personalloan/:id/detail"
                element={<PersonalLoanDetail />}
              />

              {/*Business  Loan */}
              <Route
                path="loan/businessloan/:id?/:type?"
                element={<BasicInfoBusinessLoan />}
              />
              <Route
                path="loan/businessloan/:id/coapplicant/:secid?/:type?"
                element={<CoApplicantBusinessLoan />}
              />
              <Route
                path="loan/businessloan/:id/reference/:secid?/:type?"
                element={<ReferenceBusinessLoan />}
              />
              <Route
                path="loan/businessloan/:id/firmdetail/:secid?/:type?"
                element={<FirmDetailBusinessLoan />}
              />
              <Route
                path="loan/businessLoan/:id/detail"
                element={<BusinessLoanDetail />}
              />
              {/*Auto Loan */}
              <Route
                path="loan/autoloan/:id?/:type?"
                element={<BasicInfoAutoLoan />}
              />
              <Route
                path="loan/autoloan/:id/selfEmployed/:secid?/:type?"
                element={<SelfEmployedInfoAutoLoan />}
              />
              <Route
                path="loan/autoloan/:id/salaried/:secid?/:type?"
                element={<SalaryInfoAutoLoan />}
              />

              <Route
                path="loan/autoloan/:id/detail"
                element={<AutoLoanDetail />}
              />
              <Route
                path="loan/autoloan/:id/reference/:secid?/:type?"
                element={<ReferenceInfoAutoLoan />}
              />
              <Route path="account" element={<PartnerData />} />
              <Route path="commisions" element={<Commisions />} />
              <Route path="associate-partner" element={<AssociatePartners />} />

              <Route
                path="lead/:param1/service/:param2/edit/:id"
                element={<LeadEdit />}
              />
              <Route path="support" element={<Support />} />
            </Route>
          </Routes>
          <ConnectWithUs />
        </AuthenticatedRoutes>
      </LoadingProvider>
    </Router>
  );
};

export default App;
