import React, { useState } from "react";
import { PostData } from "../../../utils/ApiInvoker";
import GenerateURL from "../../../utils/ApiUrlProvider";
import properties from "../../../Properties/Properties";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLoadingContext } from "../../../Context/LoadingContext";

const EnquiryForm = () => {
  const { showLoader, hideLoader } = useLoadingContext();
  const [formData, setFormData] = useState({
    name: "",
    mobile_no: "",
    email: "",
    description: "",
    category: "---Select the service---",
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    mobile_no: "",
    email: "",
    category: "",
  });

  const validateForm = () => {
    const newErrors = {};

    // Validation for name
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    } else if (!/^[a-zA-Z ]+$/.test(formData.name.trim())) {
      newErrors.name = "Enter a valid Name";
    }

    // Validation for mobile number
    const mobileRegex = /^[0-9]{10}$/;
    if (!mobileRegex.test(formData.mobile_no.trim())) {
      newErrors.mobile_no = "Enter a valid 10-digit mobile number";
    }

    // Validation for email
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(formData.email.trim())) {
      newErrors.email = "Enter a valid email";
    }

    // Validation for category
    if (formData.category === "---Select the service---") {
      newErrors.category = "Please select a service";
    }

    setFormErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleServiceChange = (e) => {
    setFormData({
      ...formData,
      category: e.target.value,
    });
  };

  const handleSuccess = (response) => {
    toast.success("We'll Contact you soon");
    hideLoader();
    setFormData({
      name: "",
      mobile_no: "",
      email: "",
      description: "",
      category: "---Select the service---",
    });
  };

  const handleFailure = (error) => {
    hideLoader();
    toast.error("OOPS! Try again Submitting the form");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      const url = GenerateURL({}, properties.api.commonEnquiery);
      showLoader();
      PostData(url, formData, handleSuccess, handleFailure, true);
    } else {
      hideLoader();
      console.log("Form has validation errors");
    }
  };

  return (
    <div className="w-full p-2 flex flex-col gap-5 bg-white shadow-md rounded-lg sm:mt-0 ml-7 md:h-[430px]  ">
      <ul className="flex flex-row gap-6 w-full  border-b-2 ">
        <li className="p-2 cursor-pointer border-b-2 border-logo-orange ">
          <div className="flex flex-row w-160 gap-2 items-center justify-center ">
            <img
              className="h-6 "
              src="https://cdn-icons-png.flaticon.com/128/5254/5254175.png"
              alt="loancalv"
            />
            <p className="text-sm text-logo-blue leading-3 text-center ">
              Enquiry Now
            </p>
          </div>
        </li>
      </ul>

      <form onSubmit={handleSubmit}>
        <div className="mb-4 flex flex-row items-center">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-900 w-1/4"
          >
            Name:
          </label>
          <div className="flex flex-col w-full">
            <input
              type="text"
              id="name"
              name="name"
              className={`border-logo-orange border-opacity-50 border w-full px-3 py-2 rounded-md text-base focus:outline-none focus:ring-2 focus:ring-logo-orange ${
                formErrors.name ? "border-red-500" : ""
              }`}
              placeholder="Enter your name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
            {formErrors.name && (
              <p className="text-red-500 mt-1 w-full">{formErrors.name}</p>
            )}
          </div>
        </div>

        <div className="mb-4 flex  flex-row items-center">
          <label
            htmlFor="mobile_no"
            className="block text-sm font-medium text-gray-900 w-1/4"
          >
            Mobile Number:
          </label>
          <div className="flex flex-col w-full">
            <input
              type="tel"
              id="mobile_no"
              name="mobile_no"
              className={`border-logo-orange border-opacity-50 border w-full px-3 py-2 rounded-md text-base focus:outline-none focus:ring-2 focus:ring-logo-orange ${
                formErrors.mobile_no ? "border-red-500" : ""
              }`}
              placeholder="Enter your mobile number"
              value={formData.mobile_no}
              onChange={handleInputChange}
              required
            />
            {formErrors.mobile_no && (
              <p className="text-red-500 mt-1 w-full">{formErrors.mobile_no}</p>
            )}
          </div>
        </div>

        <div className="mb-4 flex flex-row items-center">
          <label className="block text-sm font-medium text-gray-900 w-1/4">
            Email:
          </label>
          <div className="flex flex-col w-full">
            <input
              type="email"
              id="email"
              name="email"
              className={`border-logo-orange border-opacity-50 border w-full px-3 py-2 rounded-md text-base focus:outline-none focus:ring-2 focus:ring-logo-orange ${
                formErrors.email ? "border-red-500" : ""
              }`}
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            {formErrors.email && (
              <p className="text-red-500 mt-1 w-full">{formErrors.email}</p>
            )}
          </div>
        </div>

        <div className="mb-4 flex flex-row items-center">
          <label className="block text-sm font-medium text-gray-900 w-1/4">
            Service:
          </label>
          <div className="flex flex-col w-full">
            <select
              id="service"
              name="service"
              value={formData.category}
              onChange={handleServiceChange}
              className={`border-logo-orange border-opacity-50 border w-full px-3 py-2 rounded-md text-base focus:outline-none focus:ring-2 focus:ring-logo-orange ${
                formErrors.category ? "border-red-500" : ""
              }`}
              required
            >
              <option disabled value="---Select the service---">
                ---Select the service---
              </option>
              <option value="PERSONAL_LOAN">Personal Loan</option>
              <option value="AUTO_LOAN">Auto Loan</option>
              <option value="HOME_LOAN">Home Loan</option>
              <option value="BUSINESS_LOAN">Business Loan</option>
              <option value="OTHER">Others</option>
            </select>
            {formErrors.category && (
              <p className="text-red-500 mt-1 w-full">{formErrors.category}</p>
            )}
          </div>
        </div>

        {formData.category === "OTHER" && (
          <div className="mb-4 flex items-center">
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-900 w-1/4"
            >
              Description:
            </label>
            <textarea
              id="description"
              name="description"
              className="border-logo-orange border-opacity-50 border w-full px-3 py-2 rounded-md text-base focus:outline-none focus:ring-2 focus:ring-logo-orange"
              placeholder="Enter your description"
              value={formData.description}
              onChange={handleInputChange}
              rows="2"
            />
          </div>
        )}

        <div className="flex items-center justify-center">
          <button
            type="submit"
            className="bg-logo-orange text-white rounded p-2"
          >
            Get a Callback
          </button>
        </div>
      </form>
    </div>
  );
};

export default EnquiryForm;
