import React, { useEffect, useState } from "react";
import ModalWindow from "../../Common/Modal/ModalWindow";
import GenerateURL, { GenerateSearchURL } from "../../../utils/ApiUrlProvider";
import properties from "../../../Properties/Properties";
import { GetData, PostData } from "../../../utils/ApiInvoker";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ListTable from "../../Common/ListTable";
import { useLoadingContext } from "../../../Context/LoadingContext";
import { SupportFormValidation } from "../../Common/FormValidation/SupportValidation";
import {
  GenerateChatElements,
  StatusTag,
  containerStyle,
} from "../../../utils/Healpers";
import { GetAuth } from "../../../utils/AccessTokenStore";
import SupportChatModal from "../../Common/Modal/SupportChatModal";

const Support = () => {
  const { showLoader, hideLoader } = useLoadingContext();
  const [tableData, setTableData] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [supportModalOpen, setSupportmodalOpen] = useState(false);
  const [allapplicationNo, setAllApplicationNo] = useState(null);
  const [personalLoanData, setPersonalLoanData] = useState(null);
  const [businessLoanData, setBusinessLoanData] = useState(null);
  const [autoLoanData, setAutoLoanData] = useState(null);
  const [selectedLoanType, setSelectedLoanType] = useState(0);
  const [isSupportChatMoadal, setIsSupportChatModal] = useState(false);
  const [supportData, setSupportData] = useState(null);
  const [currentId, setCurrentId] = useState(null);
  const initialValues = {
    subject: "",
    emails: "",
    description: "",
    personal_loan_application_id: "",
    business_loan_application_id: "",
    auto_loan_application_id: "",
  };
  const [formData, setFormData] = useState(initialValues);
  const [formError, setFormError] = useState(initialValues);

  const convertDataIntoApplicationNo = (
    personalServ,
    businessServ,
    autoServ
  ) => {
    const result = {
      personalLoan: [],
      businessLoan: [],
      autoLoan: [],
    };
    if (personalServ !== null) {
      personalServ.forEach((element) => {
        result.personalLoan.push({
          label: element?.application_number,
          value: element?.id,
        });
      });
    }
    if (businessServ !== null) {
      businessServ.forEach((element) => {
        result.businessLoan.push({
          label: element?.application_number,
          value: element?.id,
        });
      });
    }
    if (autoServ !== null) {
      autoServ.forEach((element) => {
        result.autoLoan.push({
          label: element?.application_number,
          value: element?.id,
        });
      });
    }

    return result;
  };

  const convertDataToTableFormat = (data) => {
    const tableHeaders = [
      "Refernce No",
      "Applied By",
      "Application No",
      "Subject",
      "Status",
    ];
    const tableContent = data.map((item) => {
      return {
        id: item.id,
        "Refernce No": item.reference_number || "--",
        Email: item.emails || " --",
        Subject:
          item.subject.length > 30
            ? item.subject.slice(0, 30) + "..."
            : item.subject,
        Status: item.status,
        "Applied By": (
          <span className="relative group">
            <span className="group-hover:underline cursor-pointer text-blue-500 -z-50">
              {item.user.user_profile.username}
            </span>
            <div className="absolute hidden left-5 bottom-3 bg-blue-100 border border-blue-300 p-2 rounded shadow-md group-hover:block w-52 z-50">
              <div className="text-black font-bold text-sm">
                Name:{item.user.first_name || ""}
              </div>
              <div className="text-black text-sm">
                Mobile:{item.user.mobile_number || ""}
              </div>
            </div>
          </span>
        ),
        "Application No":
          item.business_loan_application?.application_number ||
          item.personal_loan_application?.application_number ||
          item.auto_loan_application?.application_number ||
          "--",
      };
    });
    return {
      tableHeaders,
      tableContent,
    };
  };
  const HandleGetSucess = (res) => {
    setPagination(res);
    console.log(res);
    const data = convertDataToTableFormat(res.results);
    setTableData(data);
  };
  const HandleGetError = (err) => {
    hideLoader();
    console.log(err);
  };
  const GetNextSupportData = (nexturl) => {
    const url = GenerateURL({}, nexturl);
    GetData(url, HandleGetSucess, HandleGetError, false, false);
  };

  const GetPrevSupportData = (prevurl) => {
    const url = GenerateURL({}, prevurl);
    GetData(url, HandleGetSucess, HandleGetError, false, false);
  };
  const GetSupportData = () => {
    const url = GenerateURL({}, properties.api.supportapi);
    GetData(url, HandleGetSucess, HandleGetError, false, false);
  };

  /* 
  ==============================
   ALL LOANS ARE FEACHED
  ============================== 
  */

  const HandleGetPersonalLoan = (res) => {
    setPersonalLoanData(res.results);
    hideLoader();
  };
  const HandleSucessGetAllBusinessloan = (res) => {
    setBusinessLoanData(res.results);
    hideLoader();
  };
  const HandleSucessGetAllAutoloan = (res) => {
    setAutoLoanData(res.results);
    hideLoader();
  };

  const GetPersonalLoan = () => {
    const url = GenerateSearchURL(
      {
        all: true,
      },
      properties.api.PersonalLoanServices.UserPersonalLoan
    );
    GetData(url, HandleGetPersonalLoan, HandleGetError, false, false);
  };
  const GetAllBusinessLoan = () => {
    const url = GenerateSearchURL(
      {
        all: true,
      },
      properties.api.BusinessLoanServices.UserBusinessLoan
    );
    GetData(url, HandleSucessGetAllBusinessloan, HandleGetError, false, false);
  };
  const GetAllAutoLoan = () => {
    const url = GenerateSearchURL(
      {
        all: true,
      },
      properties.api.AutoLoanServices.UserAutoLoan
    );
    GetData(url, HandleSucessGetAllAutoloan, HandleGetError, false, false);
  };

  const GetAllLoansDetails = () => {
    showLoader();
    GetPersonalLoan();
    GetAllBusinessLoan();
    GetAllAutoLoan();
  };

  useEffect(() => {
    GetAllLoansDetails();
    GetSupportData();
    setFormData(initialValues);
    setFormError(initialValues);
  }, [supportModalOpen]);

  /* 
  ==============================
   POST REQUEST
  ============================== 
  */

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  const HandlepostSucess = (res) => {
    if (res) {
      toast.success("Response Submitted ", {
        autoClose: 3000,
      });
      setFormData(initialValues);
      setSupportmodalOpen(false);
    }
  };
  const HandlePostError = (err) => {
    toast.error("Error Happend ", {
      autoClose: 3000,
    });
    setFormData(initialValues);
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    const { is_valid, error } = SupportFormValidation(formData);
    if (is_valid) {
      const url = GenerateURL({}, properties.api.supportapi);
      // const data = {
      //   subject: formData.subject,
      //   emails: formData.emails,
      //   description: formData.description,
      //   business_loan_application_id: formData.business_loan_application_id,
      // };
      PostData(url, formData, HandlepostSucess, HandlePostError, false);
    } else {
      setFormError(error);
    }
  };

  const SupportClicked = () => {
    const data = convertDataIntoApplicationNo(
      personalLoanData,
      businessLoanData,
      autoLoanData
    );
    setAllApplicationNo(data);
    setSupportmodalOpen(true);
  };
  // ==================
  // chat Modal
  // ==================
  const user = GetAuth();
  const GetSupportSucess = (res) => {
    setSupportData(res);
  };
  const GetSupportFail = (res) => {
    console.log(res);
  };
  const GetSupportDetail = (id) => {
    const url = GenerateURL({ id: id }, properties.api.supportdetail);
    GetData(url, GetSupportSucess, GetSupportFail, false, false);
  };

  const onView = (e) => {
    setCurrentId(e.id);
    setIsSupportChatModal(true);
    GetSupportDetail(e.id);
  };

  // ==================
  // Chat By User
  // ==================
  useEffect(() => {
    setChatResp({
      msg: "",
      err: "",
    });
  }, [isSupportChatMoadal]);
  const [chatResp, setChatResp] = useState({
    msg: "",
    err: "",
  });
  const HandlePostSucess = (res) => {
    setChatResp({
      msg: "",
      err: "",
    });
    GetSupportDetail(currentId);
  };
  const HandlePostErr = (res) => {
    console.log(res);
  };
  const HandleSendChat = (e) => {
    e.preventDefault();
    const data = {
      response: chatResp.msg.trim(),
    };
    if (data.response === "") {
      setChatResp((prev) => ({ ...prev, err: "Please enter a message " }));
    } else {
      const url = GenerateURL({ id: currentId }, properties.api.supportdetail);
      PostData(url, data, HandlePostSucess, HandlePostErr, false);
      console.log(url);
    }
  };

  const searchParameters = [
    { name: "Application Number", parm: "filled_service_by_user" },
    { name: "Refernce No", parm: "reference_no" },
    { name: "Business Loan App No", parm: "business_loan_application" },
    { name: "Status", parm: "status" },
    { name: "Applied By", parm: "user_code" },
  ];
  const HandleSearchSucess = (res) => {
    if (res.results.length === 0) {
      toast.error("No Data found");
      GetSupportData();
    } else {
      HandleGetSucess(res);
    }
  };
  const SearchClick = (e) => {
    const url = GenerateSearchURL(e, properties.api.supportapi);
    GetData(url, HandleSearchSucess, HandleGetError, false, false);
  };
  const EmptyCall = () => {
    GetSupportData();
  };

  return (
    <div className="w-full flex flex-col  ml-5 mt-9 p-4">
      <div
        style={containerStyle}
        className="w-full border rounded-t-md p-4 py-10 text-3xl text-left capitalize text-white  "
      >
        <p className="shadow-sm">Support</p>
      </div>
      <div className="flex flex-row items-center w-full gap-2 p-2  border bg-logo-blue bg-opacity-10  rounded-b-md">
        <button className=" text-logo-orange underline underline-offset-[12px]">
          Support Info
        </button>
        <button
          onClick={SupportClicked}
          className="ml-auto border px-2 rounded-xl bg-logo-blue text-white"
        >
          Raise Support
        </button>
      </div>
      {/* <div className="w-full mt-5">
        <SearchBar
          searchParameters={searchParameters}
          SearchClick={SearchClick}
          EmptyCall={EmptyCall}
        />
      </div> */}
      <div className="w-full mt-3 gap-5 flex flex-col shadow-lg rounded-2xl">
        {tableData && tableData.tableContent.length !== 0 ? (
          <ListTable
            tableData={tableData}
            pagination={Boolean(pagination.next || pagination.previous)}
            next={pagination.next}
            prev={pagination.previous}
            onNextClick={() => GetNextSupportData(pagination.next)}
            onPrevClick={() => GetPrevSupportData(pagination.previous)}
            actions={{ view: true }}
            onView={onView}
          />
        ) : (
          <h1 className="p-4 text-logo-orange">No support raised</h1>
        )}
      </div>
      {isSupportChatMoadal && (
        <SupportChatModal
          title="Support Chat"
          onClose={() => setIsSupportChatModal(false)}
        >
          {supportData && (
            <>
              {console.log(supportData)}
              <div className="bg-blue-50 rounded-lg w-full mt-2 grid grid-cols-2 p-2 gap-1">
                <h1 className="font-medium text-xs sm:text-base">
                  Ref No:&nbsp;&nbsp;
                  <span className="text-gray-500">
                    {supportData.reference_number || ""}
                  </span>
                </h1>
                <h1 className="font-medium text-xs sm:text-base">
                  Status:&nbsp;&nbsp;
                  <span className="text-gray-500">
                    {StatusTag(supportData.status)}
                  </span>
                </h1>

                <h1 className="font-medium text-xs sm:text-base">
                  Raised By:&nbsp;&nbsp;
                  <span className="relative group">
                    <span className="group-hover:underline cursor-pointer text-blue-500 -z-50">
                      {supportData.user.user_profile.username}
                    </span>
                    <div className="absolute hidden left-5 bottom-3 bg-pink-100 border border-pink-300 p-2 rounded shadow-md group-hover:block w-52 z-50">
                      <div className="text-black font-bold text-sm">
                        Name:{supportData.user.first_name || ""}
                      </div>
                      <div className="text-black text-sm">
                        Mobile:{supportData.user.mobile_number || ""}
                      </div>
                    </div>
                  </span>
                </h1>
                <h1 className="font-medium text-xs sm:text-base">
                  Application No:&nbsp;&nbsp;
                  <span className="text-gray-500">
                    {supportData.business_loan_application
                      ?.application_number ||
                      supportData.personal_loan_application
                        ?.application_number ||
                      supportData.auto_loan_application?.application_number ||
                      "--"}
                  </span>
                </h1>
                <h1 className="font-medium text-xs sm:text-base col-span-2">
                  Email:&nbsp;&nbsp;
                  <span className="text-gray-500">
                    {supportData.emails || ""}
                  </span>
                </h1>
                <h1 className="font-medium text-xs sm:text-base col-span-2">
                  Subject:&nbsp;&nbsp;
                  <span className="text-gray-500">
                    {supportData.subject || ""}
                  </span>
                </h1>
                <h1 className="font-medium text-xs sm:text-base col-span-2">
                  Description:&nbsp;&nbsp;
                  <span className="text-gray-500">
                    {supportData.description || ""}
                  </span>
                </h1>
              </div>
              <div className="bg-blue-50 rounded-lg w-full mt-2 flex flex-col p-2 gap-2 min-h-[20vh] max-h-[40vh] overflow-y-scroll scrollbar-none mb-0">
                {GenerateChatElements(supportData.support_response, user.id)}
              </div>
            </>
          )}
          {/* ++++CHAT INTERFACE */}
          {supportData && supportData.status === "RESOLVED" ? (
            <p className="w-full text-center text-red-400">
              This chat is closed
            </p>
          ) : (
            <>
              <div className="mt-2">
                <div className="flex justify-between gap-1">
                  <input
                    type="text"
                    value={chatResp.msg}
                    className="w-10/12 border rounded-md p-2"
                    placeholder="Enter your querry here ...."
                    onChange={(e) => {
                      setChatResp((prev) => ({ ...prev, msg: e.target.value }));
                    }}
                  />
                  <button
                    onClick={HandleSendChat}
                    className="w-2/12  bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none "
                  >
                    send
                  </button>
                </div>
                {chatResp.err && (
                  <p className="w-full text-sm text-red-500 ">{chatResp.err}</p>
                )}
              </div>
            </>
          )}
        </SupportChatModal>
      )}

      {supportModalOpen && (
        <ModalWindow
          title="Raise a Query"
          onClose={() => setSupportmodalOpen(false)}
        >
          <div className="gap-2 flex flex-row w-full sm:w-10/12">
            <button
              className={` border-logo-blue p-2  rounded-md ${
                selectedLoanType === 0
                  ? "bg-logo-blue text-white "
                  : "bg-logo-blue bg-opacity-10"
              }`}
              onClick={() => setSelectedLoanType(0)}
            >
              Personal Loan
            </button>

            <button
              className={` border-logo-blue p-2 rounded-md ${
                selectedLoanType === 1
                  ? "bg-logo-blue text-white  "
                  : "bg-logo-blue bg-opacity-10"
              }`}
              onClick={() => setSelectedLoanType(1)}
            >
              Business Loan
            </button>
            <button
              className={` border-logo-blue p-2 rounded-md ${
                selectedLoanType === 2
                  ? "bg-logo-blue text-white  "
                  : "bg-logo-blue bg-opacity-10"
              }`}
              onClick={() => setSelectedLoanType(2)}
            >
              Auto Loan
            </button>
          </div>

          <form className="flex flex-col  w-full  gap-2 items-center">
            {selectedLoanType === 0 && (
              <div className="flex flex-col gap-2 sm:flex-row sm:gap-2 justify-center items-center w-full sm:w-10/12">
                <label className="text-base sm:text-lg font-medium text-left w-full text-gray-900 sm:w-1/3 sm:text-right sm:py-3">
                  Service:
                </label>
                <div className="w-full sm:w-2/3">
                  <select
                    name="personal_loan_application_id"
                    value={formData.personal_loan_application_id}
                    onChange={handleInputChange}
                    className="border-logo-orange border-opacity-50 border w-full px-3 py-2 rounded-md text-base focus:outline-none focus:ring-2 focus:ring-logo-orange"
                    required
                  >
                    <option value="">Please select one value</option>
                    {allapplicationNo.personalLoan &&
                      allapplicationNo.personalLoan.map((item, key) => {
                        return (
                          <option key={key} value={item.value}>
                            {item.label}
                          </option>
                        );
                      })}
                  </select>
                  {formError.personal_loan_application_id && (
                    <p className="text-red-500">
                      {formError.personal_loan_application_id}
                    </p>
                  )}
                </div>
              </div>
            )}
            {selectedLoanType === 1 && (
              <div className="flex flex-col gap-2 sm:flex-row sm:gap-2 justify-center items-center w-full sm:w-10/12">
                <label className="text-base sm:text-lg font-medium text-left w-full text-gray-900 sm:w-1/3 sm:text-right sm:py-3">
                  Service:
                </label>
                <div className="w-full sm:w-2/3">
                  <select
                    name="business_loan_application_id"
                    value={formData.business_loan_application_id}
                    onChange={handleInputChange}
                    className="border-logo-orange border-opacity-50 border w-full px-3 py-2 rounded-md text-base focus:outline-none focus:ring-2 focus:ring-logo-orange"
                    required
                  >
                    <option value="">Please select one value</option>
                    {allapplicationNo.businessLoan &&
                      allapplicationNo.businessLoan.map((item, key) => {
                        return (
                          <option key={key} value={item.value}>
                            {item.label}
                          </option>
                        );
                      })}
                  </select>
                  {formError.business_loan_application_id && (
                    <p className="text-red-500">
                      {formError.business_loan_application_id}
                    </p>
                  )}
                </div>
              </div>
            )}
            {selectedLoanType === 2 && (
              <div className="flex flex-col gap-2 sm:flex-row sm:gap-2 justify-center items-center w-full sm:w-10/12">
                <label className="text-base sm:text-lg font-medium text-left w-full text-gray-900 sm:w-1/3 sm:text-right sm:py-3">
                  Service:
                </label>
                <div className="w-full sm:w-2/3">
                  <select
                    name="auto_loan_application_id"
                    value={formData.auto_loan_application_id}
                    onChange={handleInputChange}
                    className="border-logo-orange border-opacity-50 border w-full px-3 py-2 rounded-md text-base focus:outline-none focus:ring-2 focus:ring-logo-orange"
                    required
                  >
                    <option value="">Please select one value</option>
                    {allapplicationNo.autoLoan &&
                      allapplicationNo.autoLoan.map((item, key) => {
                        return (
                          <option key={key} value={item.value}>
                            {item.label}
                          </option>
                        );
                      })}
                  </select>
                  {formError.auto_loan_application_id && (
                    <p className="text-red-500">
                      {formError.auto_loan_application_id}
                    </p>
                  )}
                </div>
              </div>
            )}

            <div className=" flex flex-col  gap-2 sm:flex-row sm:gap-2 justify-center  items-center w-full sm:w-10/12">
              <label className="text-base sm:text-lgfont-medium text-left w-full text-gray-900  sm:w-1/3  sm:text-right sm:py-3">
                Subject:
              </label>
              <div className="w-full sm:w-2/3">
                <input
                  name="subject"
                  className="border-logo-orange border-opacity-50 border w-full px-3 py-2 rounded-md text-base focus:outline-none focus:ring-1 focus:ring-logo-orange"
                  placeholder="Enter your subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                  required
                />
                {formError.subject && (
                  <p className="text-red-500">{formError.subject}</p>
                )}
              </div>
            </div>
            <div className=" flex flex-col  gap-2 sm:flex-row sm:gap-2 justify-center  items-center w-full sm:w-10/12">
              <label className="text-base sm:text-lg font-medium text-left w-full text-gray-900  sm:w-1/3  sm:text-right sm:py-3">
                Email:
              </label>
              <div className="w-full sm:w-2/3">
                <input
                  type="text"
                  name="emails"
                  className="border-logo-orange border-opacity-50 border w-full px-3 py-2 rounded-md text-base focus:outline-none focus:ring-1 focus:ring-logo-orange"
                  placeholder="Enter your emails"
                  value={formData.emails}
                  onChange={handleInputChange}
                />
                {formError.emails && (
                  <p className="text-red-500">{formError.emails}</p>
                )}
              </div>
            </div>
            <div className="flex flex-col  gap-2 sm:flex-row sm:gap-2 justify-center  items-center w-full sm:w-10/12">
              <label className="text-base sm:text-lg font-medium text-left w-full text-gray-900  sm:w-1/3  sm:text-right sm:py-3">
                Description:
              </label>
              <div className="w-full  sm:w-2/3">
                <textarea
                  name="description"
                  className="border-logo-orange border-opacity-50 border w-full px-3 py-2 rounded-md text-base focus:outline-none focus:ring-1 focus:ring-logo-orange"
                  placeholder="Enter your description"
                  value={formData.description}
                  onChange={handleInputChange}
                  required
                />
                {formError.description && (
                  <p className="text-red-500">{formError.description}</p>
                )}
              </div>
            </div>
            <button
              onClick={HandleSubmit}
              className=" bg-logo-blue h-10  w-max text-white px-4 rounded  focus:outline-none focus:ring "
            >
              Submit
            </button>
          </form>
        </ModalWindow>
      )}
    </div>
  );
};

export default Support;
