export const BankOfferAdminVal = (formData) => {
  const errors = {};

  if (!formData.bank_name.trim()) {
    errors.bank_name = "Bank name is required";
  }

  if (!formData.offer_amount.trim()) {
    errors.offer_amount = "Offer amount is required";
  } else if (
    isNaN(formData.offer_amount) ||
    Number(formData.offer_amount) <= 0
  ) {
    errors.offer_amount = "Offer amount must be a positive number";
  }

  if (!formData.rate_of_interest.trim()) {
    errors.rate_of_interest = "Rate of interest is required";
  } else if (
    isNaN(formData.rate_of_interest) ||
    Number(formData.rate_of_interest) < 0
  ) {
    errors.rate_of_interest = "Rate of interest must be a non-negative number";
  }

  if (!formData.loan_tenure.trim()) {
    errors.loan_tenure = "Loan tenure is required";
  } else if (isNaN(formData.loan_tenure) || Number(formData.loan_tenure) <= 0) {
    errors.loan_tenure = "Loan tenure must be a positive number";
  }

  let isValid = Object.keys(errors).length === 0;
  return { is_valid: isValid, error: errors };
};
