import React from "react";
import { useNavigate } from "react-router-dom";

const Card = ({ title, value, viewLink, imageLink }) => {
  const navigate = useNavigate();

  const handleViewLinks = () => {
    if (viewLink) {
      navigate(viewLink);
    }
  };

  return (
    <div
      onClick={handleViewLinks}
      className="border p-2 rounded-xl bg-white shadow-md min-w-[260px] flex-shrink w-full sm:w-[280px] cursor-pointer"
    >
      <div className="flex flex-row items-center justify-start gap-3 ">
        <div className="p-2 w-max rounded-md bg-blue-100">
          <img className="h-6" src={imageLink} alt="title" />
        </div>
        <div className="text-xl font-medium text-logo-blue">{title}</div>
      </div>
      <div className="my-2 flex flex-row items-center justify-start gap-3">
        <p className=" text-3xl font-semibold text-logo-orange">{value} </p>
        {/* <p className="px-3 rounded-full text-sm bg-green-100 text-green-400 ">
          &uarr; 20%
        </p> */}
      </div>
      <p className="text-sm text-gray-400">Recently Updated</p>
    </div>
  );
};

export default Card;
