import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GetAuth,
  IsAssociatePartner,
  IsPartnerUser,
} from "../../../../../utils/AccessTokenStore";
import { GetTimeAgo, ReturnLoanType } from "../../../../../utils/Healpers";
import GenerateURL from "../../../../../utils/ApiUrlProvider";
import { GetData } from "../../../../../utils/ApiInvoker";
import properties from "../../../../../Properties/Properties";
import MissingDataImage from "../../../../Common/MissingDataImage";
import ListTable from "../../../../Common/ListTable";

const CommonLoanList = ({ loanType }) => {
  const navigate = useNavigate();
  const Ispartner = IsPartnerUser();
  const IsAssociate = IsAssociatePartner();
  const [tableData, setTableData] = useState(null);
  const [pagination, setPagination] = useState(null);
  const convertDataToTableFormat = (data) => {
    const tableHeaders = [
      "Application No",
      "Applicant Name",
      "Applied By",
      "Loan Amount",
      "Disbursement Amount",
      "Status",
      "Time",
    ];
    const tableContent = data.map((item) => {
      return {
        "Application No": item.application_number || "N/A",
        "Applicant Name": item.first_name || "N/A",
        "Loan Amount": item.loan_amount,
        "Disbursement Amount": item.disbursement_amount || "N/A",
        "Applied By": (
          <span className="relative group">
            <span className="group-hover:underline cursor-pointer text-blue-500 -z-50">
              {item.applied_by.user_profile.username || "--"}
            </span>
            <div className="absolute hidden left-5 bottom-3 bg-blue-100 border border-blue-300 p-2 rounded shadow-md group-hover:block w-52 z-50">
              <div className="text-black font-bold text-sm">
                Name: {item.applied_by.first_name || "--"}
              </div>
              <div className="text-black text-sm">
                Mobile: {item.applied_by.mobile_number || "--"}
              </div>
            </div>
          </span>
        ),
        applied_by_user: item.applied_by,
        Status: item.status || "N/A",
        Time: GetTimeAgo(item.created_at) || "N/A",
        id: item.id,
      };
    });
    return {
      tableHeaders,
      tableContent,
    };
  };

  const HandleSucess = (response) => {
    const data = convertDataToTableFormat(response.results);
    setTableData(data);
    setPagination(response);
  };
  const HandleError = (response) => {
    console.log(response);
  };
  const GetAllNextvLoanData = (nexturl) => {
    const url = GenerateURL({}, nexturl);
    GetData(url, HandleSucess, HandleError, false, false);
  };
  const GetAllPrevLoanData = (prevurl) => {
    const url = GenerateURL({}, prevurl);
    GetData(url, HandleSucess, HandleError, false, false);
  };
  const ReturnUrl = (loanType) => {
    if (loanType === "PL") {
      return properties.api.PersonalLoanServices.UserPersonalLoan;
    } else if (loanType === "BL") {
      return properties.api.BusinessLoanServices.UserBusinessLoan;
    } else if (loanType === "AL") {
      return properties.api.AutoLoanServices.UserAutoLoan;
    }
  };
  const GetAllLoanData = () => {
    const url = GenerateURL({}, ReturnUrl(loanType));
    GetData(url, HandleSucess, HandleError, false, false);
  };

  useEffect(() => {
    GetAllLoanData();
  }, []);

  const view = (e) => {
    if (Ispartner || IsAssociate) {
      // console.log(ReturnLoanType(loanType));
      navigate(`/partner/loan/${ReturnLoanType(loanType)}/${e.id}/detail`);
    } else {
      navigate(`/user/loan/${ReturnLoanType(loanType)}/${e.id}/detail`);
    }
  };

  return (
    <>
      {tableData && (
        <>
          {tableData.tableContent.length === 0 ? (
            <>
              <MissingDataImage
                message={"currently no Loan applied"}
                btnurl={
                  Ispartner || IsAssociate
                    ? `/partner/loan/${ReturnLoanType(loanType)}/`
                    : `/user/loan/${ReturnLoanType(loanType)}/`
                }
              />
            </>
          ) : (
            <>
              <ListTable
                tableData={tableData}
                pagination={Boolean(pagination.next || pagination.previous)}
                next={pagination.next}
                prev={pagination.previous}
                onNextClick={() => GetAllNextvLoanData(pagination.next)}
                onPrevClick={() => GetAllPrevLoanData(pagination.previous)}
                actions={{ view: true, edit: false, delete: false }}
                onView={view}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default CommonLoanList;
