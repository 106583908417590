import React, { useEffect, useState } from "react";
import GenerateURL, { GenerateSearchURL } from "../../../utils/ApiUrlProvider";
import properties from "../../../Properties/Properties";
import { GetData } from "../../../utils/ApiInvoker";
import ListTable from "../../Common/ListTable";
import { GetTimeAgo } from "../../../utils/Healpers";
import DashboardBanner from "../../Common/DashboardBanner";
import MissingDataImage from "../../Common/MissingDataImage";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchBar from "../../Common/SearchBar";
import { useNavigate } from "react-router-dom";
import AddRMModal from "../Modals/AddRMModal";

const RelationManagerList = () => {
  const [tableData, setTableData] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [isAddRMModalVisible, setIsAddRMModalVisible] = useState(false);
  const navigate = useNavigate();

  const clickOn = (e) => {
    navigate(`/admin/relation-manager/${e.id}`);
  };

  const clickableOn = {
    on: ["RM Code"],
    funct: { "RM Code": clickOn },
  };

  const convertDataToTableFormat = (data) => {
    const tableHeaders = ["Name", "Mobile Number", "Email", "RM Code", "Time"];

    const tableContent = data.map((results) => {
      return {
        Name: results.name || "--",
        "Mobile Number": results.mobile_number || "--",
        Email: results.email,
        "RM Code": results.relational_manager_profile.rm_code || "N/A",
        Time: GetTimeAgo(results.created_at),
        id: results.id,
      };
    });
    return {
      tableHeaders,
      tableContent,
    };
  };

  const HandleSearchSucess = (res) => {
    console.log(res.results);
    if (res.results.length === 0) {
      toast.error("No Data found");
      GetRMList();
    } else {
      HandleSucess(res);
    }
  };
  const searchParameters = [
    { name: "Name", parm: "name" },
    { name: "Email", parm: "email" },
    { name: "Phone Number", parm: "mobile_number" },
    { name: "RM Code", parm: "user_code" },
  ];
  const SearchClick = (e) => {
    const url = GenerateSearchURL(e, properties.api.adminRelationManager);
    GetData(url, HandleSearchSucess, HandleError, false, false);
  };
  const EmptyCall = () => {
    GetRMList();
  };

  const HandleSucess = (response) => {
    const data = convertDataToTableFormat(response.results);
    setTableData(data);
    setPagination(response);
  };

  const HandleError = (response) => {
    console.log(response);
  };

  const GetNextEnquiryList = (nexturl) => {
    const url = GenerateURL({}, nexturl);
    GetData(url, HandleSucess, HandleError, false, false);
  };

  const GetPrevEnquiryList = (prevurl) => {
    const url = GenerateURL({}, prevurl);
    GetData(url, HandleSucess, HandleError, false, false);
  };

  const GetRMList = () => {
    const url = GenerateURL({}, properties.api.adminRelationManager);
    GetData(url, HandleSucess, HandleError, false, false);
  };

  useEffect(() => {
    GetRMList();
  }, []);

  const handleAddRMClick = () => {
    setIsAddRMModalVisible(true);
  };

  const view = (e) => {
    navigate(`/admin/relation-manager/${e.id}`);
  };

  return (
    <div className="w-11/12">
      <DashboardBanner />
      <div className="flex justify-end mb-4">
        <button
          className="bg-blue-500 text-white px-1 py-1 md:px-4 md:py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
          onClick={handleAddRMClick}
        >
          Add RM
        </button>
      </div>
      {isAddRMModalVisible && (
        <AddRMModal onClose={() => setIsAddRMModalVisible(false)} />
      )}
      {tableData && tableData.tableContent.length === 0 ? (
        <div className="flex flex-col gap-2">
          <MissingDataImage message={"No Relation Manager Found"} />
        </div>
      ) : (
        tableData && (
          <div className="w-full flex flex-col  gap-2 ">
            <SearchBar
              searchParameters={searchParameters}
              SearchClick={SearchClick}
              EmptyCall={EmptyCall}
            />
            <ListTable
              heading="Loan Applications"
              tableData={tableData}
              pagination={pagination.next || pagination.prev}
              next={pagination.next}
              prev={pagination.previous}
              onNextClick={() => GetNextEnquiryList(pagination.next)}
              onPrevClick={() => GetPrevEnquiryList(pagination.previous)}
              actions={{ view: true, edit: false, delete: false }}
              onView={view}
              clickabaleOn={clickableOn}
            />
          </div>
        )
      )}
    </div>
  );
};

export default RelationManagerList;
