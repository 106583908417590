import React from "react";
import logo from "../../../assets/logo2.png";

const About = () => {
  return (
    <div className="m-10 " id="about">
      <div className="text-4xl text-logo-blue  font-bold capitalize text-center flex flex-col items-center ">
        How UdhariBazaar became
        <img className="h-8 w-56 m-2" src={logo} alt="" />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 px-5  sm:px-20  mt-7  justify-items-center md:w-[80%]  md:ml-52 ">
        <div className=" flex flex-col justify-left lg:mr-10 xl:mr-10 ">
          <h1 className=" text-3xl font-bold text-logo-blue border-b-2 border-logo-orange max-w-max py-2 ">
            Background
          </h1>
          <p className="mt-5 w-full sm:w-10/12 text-justify  ">
            Welcome to UdhariBazaar, where trust meets finance. Our company is
            built on a foundation of integrity, reliability, and
            customer-centric values. With a focus on transparency, we offer the
            best interest rates in the market for&nbsp;
            <strong>
              Personal loans, Business loans, Auto loans, and Home loans.
            </strong>
            &nbsp;At UdhariBazaar, we prioritize your financial well-being,
            forging lasting relationships based on trust. Join us in achieving
            your financial goals with confidence and assurance.
          </p>
        </div>
        <img
          className="hidden sm:block h-[260px]"
          src="https://cdn.staropstech.com/clients/udharibazaar/assets/extra-images/growth.webp"
          alt=""
        />
        <img
          className="hidden sm:block  h-[260px]"
          src="https://cdn.staropstech.com/clients/udharibazaar/assets/extra-images/business.webp"
          alt=""
        />
        <div className=" flex flex-col justify-center  items-end">
          <h1 className=" text-3xl font-bold text-logo-blue border-b-2 border-logo-orange max-w-max py-2  ">
            Our Vision
          </h1>
          <p className="mt-5 w-full sm:w-10/12   text-justify ">
            At UdhariBazaar, our vision is to redefine financial accessibility
            by providing a diverse array of loans, including
            <strong>
              &nbsp;Personal loans, Business loans, Auto loans, and Home
              loans,&nbsp;
            </strong>
            all at the most competitive interest rates in the market. We
            envision a future where individuals can effortlessly access the
            funds they need to achieve their goals. Committed to transparency
            and customer satisfaction, UdhariBazaar is dedicated to making
            financial journeys smooth and hassle-free. Our vision is to
            prioritize your financial well-being, ensuring that obtaining loans
            is a seamless experience with the best interest rates available
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
