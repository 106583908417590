import React, { useEffect, useState } from "react";
import GenerateURL from "../../../utils/ApiUrlProvider";
import { GetData, PutData } from "../../../utils/ApiInvoker";
import properties from "../../../Properties/Properties";
import {
  GetTimeAgo,
  GetUserTpeByCode,
  containerStyle,
} from "../../../utils/Healpers";
import ModalWindow from "../../Common/Modal/ModalWindow";
import { renderInput } from "../../Common/FormComponents";
import { AdminProfileEditVal } from "../../Common/FormValidation/AdminProfileEditVal";
import { useLoadingContext } from "../../../Context/LoadingContext";

const AdminProfile = () => {
  const { showLoader, hideLoader } = useLoadingContext();
  const [adminData, setadminData] = useState();
  const [editModalopen, setEditModalOpen] = useState(false);
  const [navtype, setNavType] = useState(0);
  const initialFormData = {
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState(initialFormData);

  const saveResposneSucess = (response) => {
    hideLoader();
    setEditModalOpen(false);
    console.log(response);
  };
  const saveResposneFailure = (response) => {
    hideLoader();
    console.log(response);
  };

  const EditAdminInfo = () => {
    const authData = JSON.parse(localStorage.getItem("authData"));
    const validation = AdminProfileEditVal(formData);
    if (validation.is_valid) {
      var url = GenerateURL(
        { id: authData.userDetails.id },
        properties.api.adminInfoUpdate
      );
      showLoader();
      PutData(url, formData, saveResposneSucess, saveResposneFailure, false);
    } else {
      setFormErrors(validation.error);
    }
  };

  const handlechange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const HandleSuccess = (response) => {
    hideLoader();
    setadminData(response);
  };
  const HandleError = (response) => {
    hideLoader();
    console.log(response);
  };
  const UserInfo = () => {
    const authData = JSON.parse(localStorage.getItem("authData"));
    const url = GenerateURL(
      { id: authData.userDetails.id },
      properties.api.adminInfoUpdate
    );
    showLoader();
    GetData(url, HandleSuccess, HandleError, false, false);
  };

  useEffect(() => {
    UserInfo();
  }, [editModalopen]);

  return (
    <>
      {adminData && (
        <div className="w-full flex flex-col ml-5 mt-9 p-4 ">
          <div
            style={containerStyle}
            className="w-full border rounded-t-md p-4 py-10 text-3xl text-left capitalize text-white  "
          >
            <p className="shadow-sm">Profile Information</p>
          </div>
          <div className="flex flex-row flex-wrap items-center w-full gap-2 p-2 border bg-logo-blue bg-opacity-10  rounded-b-md">
            <button
              onClick={() => setNavType(0)}
              className={`px-2 py-1 transition-all ease-in-out duration-200  ${
                navtype === 0
                  ? "text-logo-orange border-b-2 border-logo-orange "
                  : ""
              }`}
            >
              Basic Info
            </button>
            <div className="md:ml-auto">
              <button
                onClick={() => {
                  setFormData({
                    first_name: adminData.first_name || "",
                    middle_name: adminData.middle_name || "",
                    last_name: adminData.last_name || "",
                    email: adminData.email || "",
                  });
                  setEditModalOpen(true);
                }}
                className="ml-auto border px-2 rounded-xl bg-logo-blue text-white"
              >
                Edit Profile
              </button>
            </div>
          </div>
          {navtype === 0 && (
            <div className="w-full flex flex-col shadow-lg rounded-2xl bg-white my-4 pb-4">
              <h1 className="p-4 text-2xl text-logo-orange">Personal Info</h1>
              <div className="w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 px-4 capitalize">
                <div className="flex flex-col">
                  <p className="font-medium">Name</p>
                  {console.log(adminData)}
                  <p className="text-gray-500 text-sm">
                    {adminData.first_name +
                      " " +
                      adminData.middle_name +
                      " " +
                      adminData.last_name}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium">Email</p>
                  <p className="text-gray-500 text-sm">{adminData.email}</p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium">Mobile Number</p>
                  <p className="text-gray-500 text-sm">
                    {adminData.mobile_number}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium ">User Type</p>
                  <p className="text-gray-500 text-sm uppercase">
                    {GetUserTpeByCode(adminData.user_profile.username)}
                  </p>
                </div>
                <div className="flex flex-col">
                  {GetUserTpeByCode(adminData.user_profile.username)}
                  Code
                  <p className="font-medium"></p>
                  <p className="text-gray-500 text-sm">
                    {adminData.user_profile.username}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium ">Created</p>
                  <p className="text-gray-500 text-sm">
                    {GetTimeAgo(adminData.user_profile.created_at)}
                  </p>
                </div>
              </div>
            </div>
          )}
          {editModalopen && (
            <ModalWindow
              onClose={() => setEditModalOpen(false)}
              title={"Edit Profile"}
            >
              <>
                <div className="w-full flex flex-col">
                  {renderInput("STRING", {
                    label: "First Name",
                    name: "first_name",
                    value: formData.first_name,
                    onChange: handlechange,
                    error: formErrors.first_name,
                    tooltip: "Use letters only for the name",
                  })}
                  {renderInput("STRING", {
                    label: "Middle Name",
                    name: "middle_name",
                    value: formData.middle_name,
                    onChange: handlechange,
                    error: formErrors.middle_name,
                    tooltip: "Use letters only for the name",
                  })}
                  {renderInput("STRING", {
                    label: "Last Name",
                    name: "last_name",
                    value: formData.last_name,
                    onChange: handlechange,
                    error: formErrors.last_name,
                    tooltip: "Use letters only for the name",
                  })}
                  {renderInput("STRING", {
                    label: "Email",
                    name: "email",
                    value: formData.email,
                    onChange: handlechange,
                    error: formErrors.email,
                    tooltip: `Use "@" , ".com"`,
                  })}
                  <div className="flex justify-center items-center">
                    <button
                      className="bg-blue-500 text-white px-1 py-1 md:px-4 md:py-2   rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300  "
                      onClick={EditAdminInfo}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </>
            </ModalWindow>
          )}
        </div>
      )}
    </>
  );
};

export default AdminProfile;
