import React, { useEffect, useState } from "react";
import GenerateURL from "../../../../utils/ApiUrlProvider";
import properties from "../../../../Properties/Properties";
import { GetData, PutData } from "../../../../utils/ApiInvoker";
import { renderInput } from "../../../Common/FormComponents";
import { ValidateInput } from "../../../../utils/FormValidation";
import { useNavigate, useParams } from "react-router-dom";
import { GetHeadingByid } from "../../../../utils/Healpers";
import { useLoadingContext } from "../../../../Context/LoadingContext";

const LeadEdit = () => {
  const navigate = useNavigate();
  const param = useParams();
  const { showLoader, hideLoader } = useLoadingContext();

  const [formSchema, setFormSchema] = useState([]);
  const [formdata, setFormdata] = useState({});
  const [serviceInfo, setServiceInfo] = useState(null);
  const [stepErrors, setStepErrors] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [formInputId, setFormInputId] = useState(null);

  const HandleSubmitResponse = (e) => {
    e.preventDefault();
    showLoader();
    const servinfo = { ...serviceInfo, is_completed: true };
    const data = ConvertToSubmit(formdata, servinfo);
    console.log(data);
    const url = GenerateURL(
      { id: param.id },
      properties.api.filled_services_byid
    );
    // console.log(data);
    PutData(url, data, HandleSubmitSucess, HandleSubmitFaild, false);
  };

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNextClick = (e) => {
    // Check if currentIndex is within the bounds of the formSchema
    if (currentIndex <= (formSchema?.length || 0) - 1) {
      // Fetch data for the current step based on the currentIndex
      const currentStepData = formSchema[currentIndex];
      let isStepValid = true;
      // Validate each field in the current step data
      currentStepData.some((data) => {
        const fieldValidation = ValidateInput(
          data.validations,
          formdata[data.id]
        );
        console.log(fieldValidation);

        // If validation fails for a field
        if (!fieldValidation.isValid) {
          isStepValid = false;
          setStepErrors({ [data.id]: fieldValidation.message });
          return true; // Exit the loop when an error is encountered
        }
        return false; // Continue to the next iteration if no error
      });

      // Proceed to the next step if current step is valid and not the last step
      if (isStepValid && currentIndex < (formSchema?.length || 0) - 1) {
        setCurrentIndex(currentIndex + 1);
      }
      // If current step is valid and it's the last step, handle form submission
      else if (isStepValid && currentIndex === (formSchema?.length || 0) - 1) {
        HandleSubmitResponse(e);
      }
    }
  };

  const calculateCompletionPercentage = (currentIndex) => {
    const totalSteps = formSchema?.length || 1; // Set a default of 1 step if no schema
    const percentage = (currentIndex / (totalSteps - 1)) * 100; // Subtract 1 for accurate percentage
    return percentage;
  };

  const InputInitialValue = (inputData) => {
    const result = {};
    inputData.forEach((item) => {
      if (item.input_type !== "MULTI_SELECT") {
        result[item.id] = "";
      } else {
        result[item.id] = [];
      }
    });
    return result;
  };

  const ConvertToSubmit = (inputData, servicedata) => {
    const output = {
      ...servicedata,
      service_input_field_values: Object.keys(inputData).map((key) => {
        const currentValue = inputData[key];
        if (typeof currentValue === "object") {
          return {
            id: formInputId[key],
            supported_services_input_field_id: parseInt(key),
            supported_services_input_value: "file_path",
            supported_services_input_encrypted_value: null,
            upload_files_id: currentValue.id,
          };
        } else {
          return {
            id: formInputId[key],
            supported_services_input_field_id: parseInt(key),
            supported_services_input_value: currentValue,
            supported_services_input_encrypted_value: null,
            upload_files_id: null,
          };
        }
      }),
    };
    return output;
  };

  const HandleSubmitSucess = (response) => {
    hideLoader();
    if (response) {
      navigate("/user/dashboard");
    }
  };
  const HandleSubmitFaild = (response) => {
    console.log(response);
  };

  const HandleSucess = (response) => {
    const stages = [];
    response.forEach((item) => {
      if (!stages[item.stage]) {
        stages[item.stage] = [];
      }
      stages[item.stage].push(item);
    });
    stages.shift();
    console.log(stages);
    const initialValues = InputInitialValue(response);
    // for sending response we need service type
    const servInfo = response[0]?.supported_service_id;
    setServiceInfo({ supported_service_id: servInfo, is_completed: false });
    setFormdata({ ...initialValues });
    setFormSchema(stages);
    GetPrevSubmmitedData();
  };
  const HandleError = (response, exception) => {
    console.log(response);
  };

  const HandlePrevdataSucess = (res) => {
    const convertDataToIdValueObject = (data) => {
      const result = {};
      const idstore = {};
      data.forEach((item) => {
        const {
          id,
          supported_services_input_value,
          supported_services_input_field,
          upload_files,
        } = item;
        if (supported_services_input_value === "file_path") {
          const inputFieldId = item.supported_services_input_field.id;
          result[inputFieldId] = upload_files;
          idstore[supported_services_input_field.id] = id;
        } else {
          const inputFieldId = item.supported_services_input_field.id;
          result[inputFieldId] = supported_services_input_value;
          idstore[supported_services_input_field.id] = id;
        }
      });
      setFormInputId(idstore);
      return result;
    };
    const initialValues = convertDataToIdValueObject(
      res.service_input_field_values
    );
    console.log(initialValues);
    setFormdata({ ...initialValues });
    hideLoader();
  };
  const HandlePrevdataErrror = (res) => {
    console.log(res);
  };

  const GetPrevSubmmitedData = () => {
    const url = GenerateURL(
      {
        id: param.id,
      },
      properties.api.filled_services_byid
    );
    GetData(url, HandlePrevdataSucess, HandlePrevdataErrror, false, false);
  };

  const GetFormSchema = async (e) => {
    showLoader();
    var url = GenerateURL(
      {
        supported_category_id: param.param1,
        supported_service_id: param.param2,
      },
      properties.api.supported_category
    );
    console.log(url);
    GetData(url, HandleSucess, HandleError, false, false);
  };

  useEffect(() => {
    GetFormSchema();
  }, [param]);

  const ChangeHandle = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setStepErrors({ [name]: null });
    setFormdata({ ...formdata, [name]: value });
  };
  return (
    <div className="p-5 w-full md:w-8/12 h-full pt-20  ">
      <h1 className="text-2xl md:text-3xl pl-2 my-2 border-l-4  font-sans font-bold  border-logo-blue mb-5  rounded-sm  ">
        {GetHeadingByid(param.param2)}
      </h1>
      <div className="flex items-center w-full border shadow-md flex-col justify-center rounded-lg  bg-white  ">
        <div className="flex items-start w-full flex-col  px-6 py-3  bg-indigo-600 rounded-t-lg ">
          <p className="w-11/12 text-xl text-left capitalize text-white ">
            {formSchema.length !== 0 && formSchema[currentIndex][0].stage_name}
          </p>
          <div className="flex items-center justify-between w-full flex-row ">
            <div className="w-full bg-white rounded-full h-1.5 overflow-hidden">
              <div
                className="  bg-indigo-950 h-1.5 rounded-full transition-width duration-800"
                style={{
                  width: "0%",
                  transition: "width 1s ease-in-out",
                  width: `${calculateCompletionPercentage(currentIndex)}%`,
                }}
              ></div>
            </div>
            <p className="px-1 text-white">
              {currentIndex + 1}/{formSchema?.length}
            </p>
          </div>
        </div>

        <div className="flex items-center w-full flex-row justify-center rounded-lg mt-5 bg-white">
          <div className="w-full h-full  p-4 ">
            {formSchema.length !== 0 &&
              formSchema[currentIndex].map((data, key) => {
                return (
                  <div key={key}>
                    {renderInput(data.input_type, {
                      label: data.label,
                      name: data.id,
                      value: formdata[data.id],
                      onChange: ChangeHandle,
                      options: data.choices,
                      error: stepErrors[data.id],
                      validations: data.validations,
                      code: data.code,
                      tooltip: data.input_description,
                    })}
                  </div>
                );
              })}
            <div className="mt-10 flex flex-row gap-3 w-full items-center justify-between">
              {currentIndex === 0 ? (
                <div></div>
              ) : (
                <button
                  className="bg-blue-600 text-white px-4 py-2 rounded"
                  onClick={handlePrevClick}
                  disabled={currentIndex === 0}
                >
                  Prev
                </button>
              )}

              <button
                onClick={handleNextClick}
                disabled={
                  currentIndex === formSchema.length - 1 && !serviceInfo
                }
                className={`${
                  currentIndex === formSchema.length - 1
                    ? "bg-green-500"
                    : "bg-blue-600"
                } text-white px-4 py-2 rounded-md`}
              >
                {currentIndex === formSchema.length - 1 ? "Submit" : "Next"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadEdit;
