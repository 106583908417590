import React from "react";

const NotFound = () => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-white bg-opacity-70 backdrop-filter backdrop-blur-lg z-50 flex justify-center items-center">
      <div className="container mx-auto">
        <div className="flex justify-center">
          <div className="w-full sm:w-10/12 text-center">
            <div
              className="four_zero_four_bg h-96 bg-cover bg-center"
              style={{
                backgroundImage:
                  "url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif)",
              }}
            >
              <h1 className="text-6xl">404</h1>
            </div>
            <div className="contant_box_404">
              <h3 className="text-6xl">Look like you're lost</h3>
              <p className="text-lg">
                The page you are looking for is not available!
              </p>
              <a
                href="/"
                className="link_404 inline-block py-2 px-4 bg-green-600 text-white mt-8"
              >
                Go to Home
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
