import React, { useState, useEffect } from "react";
import Chart from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import { IsAuthenticated, IsNormalUser } from "../../../utils/AccessTokenStore";
import { useNavigate } from "react-router-dom";
import LoginModal from "../Login/LoginModal";

const InputSlider = ({
  label,
  name,
  min,
  max,
  step,
  value,
  onChange,
  minval,
  maxval,
}) => {
  const handleBlur = () => {
    // Ensure the value is within the specified range
    const newValue = Math.min(Math.max(parseFloat(value), min), max);
    onChange({ target: { name, value: newValue } });
  };
  return (
    <div>
      <div className="w-full flex flex-row  justify-between">
        <label
          htmlFor="default-range"
          className="block mb-1 text-sm font-medium text-gray-900  "
        >
          {label}
        </label>

        <input
          type="number"
          name={name}
          className="border-logo-orange border-opacity-50 border w-36 text-right px-1 rounded-md text-base focus:outline-none focus:ring-2 focus:ring-logo-orange appearance-none"
          value={value}
          min={min}
          max={max}
          onChange={onChange}
          onBlur={handleBlur}
        />
      </div>
      <input
        id="default-range"
        name={name}
        type="range"
        min={min}
        step={step}
        max={max}
        value={value}
        onChange={onChange}
        className="w-full h-2 bg-gray-200 rounded-lg  cursor-pointer appearance-none "
      />
      <div className="w-full flex flex-row justify-between">
        <p className="text-xs text-slate-700 font-semibold">{minval}</p>
        <p className="text-xs text-slate-700  font-semibold">{maxval}</p>
      </div>
    </div>
  );
};

const Calculator = () => {
  const [isloginModalOpen, setIsloginModalOpen] = useState(false);
  const navigate = useNavigate();
  const NavigateToLink = (data) => {
    const route = data.route;
    if (route) {
      navigate(route);
    } else {
      if (IsAuthenticated() && IsNormalUser()) {
        navigate(`/user/dashboard`);
      } else {
        setIsloginModalOpen(true);
      }
    }
  };
  const [formInput, setFormInput] = useState({
    principal: 0,
    rate: 1,
    time: 1,
  });
  const [emiData, setEmidata] = useState({
    totalAmount: 0,
    principleAmount: 0,
    emi: 0,
  });
  const [pieData, setPieData] = useState({
    datasets: [
      {
        data: [300, 50],
        backgroundColor: ["#143C74", "#F47C34"],
        hoverOffset: 4,
      },
    ],
  });

  const calculateEMI = (principal, rate, tenureYears) => {
    const monthlyRate = rate / 12 / 100;
    const tenureMonths = tenureYears * 12;
    const emi =
      (principal * monthlyRate * Math.pow(1 + monthlyRate, tenureMonths)) /
      (Math.pow(1 + monthlyRate, tenureMonths) - 1);
    const totalAmount = emi * tenureMonths;

    return {
      totalAmount: totalAmount.toFixed(2),
      principleAmount: principal,
      emi: emi.toFixed(2),
    };
  };
  useEffect(() => {
    const { totalAmount, principleAmount, emi } = calculateEMI(
      formInput.principal,
      formInput.rate,
      formInput.time
    );
    setEmidata({ ...emiData, totalAmount, principleAmount, emi });
    setPieData({
      datasets: [
        {
          data: [totalAmount - principleAmount, principleAmount],
          backgroundColor: ["#F47C34", "#143C74"],
          hoverOffset: 4,
        },
      ],
    });
  }, [formInput]);

  const HandleOnchange = (e) => {
    const { name, value } = e.target;

    let minVal, maxVal;

    // Define validation rules based on the input name
    switch (name) {
      case "principal":
        minVal = 0;
        maxVal = 50000000;
        break;
      case "rate":
        minVal = 0;
        maxVal = 35;
        break;
      case "time":
        minVal = 0;
        maxVal = 35;
        break;
      default:
        minVal = 0;
        maxVal = 0;
    }

    const sanitizedValue = isNaN(parseFloat(value))
      ? formInput[name]
      : parseFloat(value);
    const clampedValue = Math.min(Math.max(sanitizedValue, minVal), maxVal);

    setFormInput((prevFormInput) => ({
      ...prevFormInput,
      [name]: clampedValue,
    }));
  };

  return (
    <div className="flex flex-col items-center" id="calculator">
      {isloginModalOpen && (
        <LoginModal
          isloginModalOpen={isloginModalOpen}
          closeLoginModel={() => setIsloginModalOpen(false)}
        />
      )}
      <h1 className="text-4xl text-logo-blue text-center  font-bold capitalize sm:text-4xl   border-blue-900 mb-4 mt-5 md:mt-0 ">
        Calculate Your EMI
      </h1>
      <div className="flex flex-row  w-full border relative h-[740px] sm:h-[60vh]">
        <div className="absolute w-full h-full">
          <img
            className="w-full h-full "
            src="https://img.freepik.com/free-psd/modern-farmhouse-meadow-hill-generative-ai_587448-2232.jpg?size=626&ext=jpg&uid=R128585067&ga=GA1.1.923863604.1702044673&semt=ais"
            alt="calculator"
          />
        </div>
        <div className="flex w-full mr-0 items-center  flex-col z-10 h-full md:w-[50%] md:mr-10 bg-white bg-opacity-90 p-2 absolute right-0 ">
          <ul className="flex flex-row gap-6  w-full mx-10 border-b-2 ">
            <li className=" p-2 cursor-pointer border-b-2  border-logo-orange  ">
              <div className="flex flex-row w-20 gap-2 items-center justify-center ">
                <img
                  className="h-6"
                  src="https://cdn-icons-png.flaticon.com/128/5254/5254175.png"
                  alt="loancalv"
                />
                <p className="text-sm text-logo-blue leading-3 text-center">
                  Calculate your EMI
                </p>
              </div>
            </li>
          </ul>
          <div className="mt-2 flex flex-col sm:flex-row  w-full md:p-3  gap-2">
            <div className="h-[280cpx] sm:h-[360px] w-full sm:w-1/2  p-2  py-6  flex flex-col gap-5 bg-white shadow-md rounded-lg ">
              <InputSlider
                name="principal"
                label="Loan Amount"
                onChange={HandleOnchange}
                value={formInput.principal}
                step={1000}
                min={0}
                max={50000000}
                minval="0"
                maxval="5 Cr."
              />

              <InputSlider
                label="Interest Rate(% P.A.)"
                name="rate"
                onChange={HandleOnchange}
                value={formInput.rate}
                step={0.1}
                min={1}
                max={35}
                minval="1 %"
                maxval="35 %"
              />
              <InputSlider
                name="time"
                label="Tenure (year)"
                onChange={HandleOnchange}
                value={formInput.time}
                step={0.5}
                min={1}
                max={35}
                minval="1 year"
                maxval="35 year"
              />
            </div>
            <div className="h-[400px] sm:h-[360px] w-full sm:w-1/2 p-2  py-6  flex flex-col items-center  justify-between bg-white shadow-md rounded-lg ">
              <div className="h-2/3 bg-logo-orange bg-opacity-10 w-full flex items-center justify-center gap-2 px-3 py-6 rounded-sm shadow-sm">
                <div className="w-1/2">
                  <Doughnut data={pieData} />
                </div>
                <div className="w-1/2 flex flex-col gap-2">
                  <div className="w-full ">
                    <p className="text-xs leading-5  text-slate-600  ">
                      Principal Amount
                      <span className="block text-xl leading-4 ">
                        {emiData.principleAmount}
                      </span>
                    </p>
                  </div>
                  <div className="w-full">
                    <p className="text-xs leading-5 text-logo-orange ">
                      EMI Per Month
                      <span className="block text-xl leading-4">
                        {emiData.emi}
                      </span>
                    </p>
                  </div>
                  <div className="w-full">
                    <p className="text-xs leading-5   text-logo-blue">
                      Total Amount
                      <span className="block text-xl leading-4">
                        {emiData.totalAmount}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col p-1 gap-2">
                <button
                  onClick={NavigateToLink}
                  className=" w-full bg-logo-orange   text-white rounded p-1"
                >
                  Apply Now
                </button>
                <p className=" text-xs">
                  <span className="font-extrabold ">Note: </span>
                  Please be advised that the calculator provided in this app is
                  intended for informational purposes only.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
