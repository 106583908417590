import React, { useState } from "react";
import { GetTimeAgo, StatusTag } from "../../utils/Healpers";
import { IsPartnerUser, IsSuperUser } from "../../utils/AccessTokenStore";

import EditApplicationStatus from "../Admin/Dashboard/EditApplicationStatus";
import { useNavigate } from "react-router-dom";

const InsuranceDetailCard = ({ userData }) => {
  const isSuperUser = IsSuperUser();
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const shouldShowAdminButtons = isSuperUser;

  const toggleStatusModal = () => {
    setIsStatusModalOpen(!isStatusModalOpen);
  };
  return (
    <div className="w-full flex flex-col ml-5 mt-9 p-4 ">
      <div className=" mt-3 rounded-2xl ">
        <div className=" w-full p-5 rounded-t-2xl bg-white flex flex-row justify-between border-b-2">
          <h1 className="font-medium text-xl ">
            Basic Application Information
          </h1>
          {shouldShowAdminButtons && (
            <button
              className="bg-blue-200 rounded-lg px-1 shadow-lg hover:scale-110"
              onClick={toggleStatusModal}
            >
              Update Status
            </button>
          )}
          {isStatusModalOpen && (
            <EditApplicationStatus
              onClose={toggleStatusModal}
              initialUserData={userData}
            />
          )}
        </div>
        <div className="w-full p-8 gap-5 flex flex-col shadow-lg rounded-b-2xl bg-white ">
          {userData && (
            <>
              <div className="w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 ">
                <div className="flex flex-col">
                  <h3 className="font-medium  ">Applied Service</h3>
                  <h3 className=" text-gray-500 font-semibold">{"N/A"}</h3>
                </div>
                <div className="flex flex-col">
                  <h3 className="font-medium">Application Number</h3>
                  <h3 className="text-gray-500 font-semibold">
                    {userData.application_number || "N/A"}
                  </h3>
                </div>
                <div className="flex flex-col">
                  <h3 className="font-medium ">Applied Loan Amount</h3>
                  <h3 className="text-gray-500 font-semibold">
                    {userData.disbursement_amount || "N/A"}
                  </h3>
                </div>

                <div className="flex flex-col">
                  <h3 className="font-medium ">Applied By</h3>
                  <h3 className="font-semibold text-gray-500">
                    {userData.user.user_profile.username || "N/A"}
                  </h3>
                </div>
                <div className="flex flex-col">
                  <h3 className="font-medium">Status</h3>
                  <h3 className="text-gray-500 font-semibold">
                    {StatusTag(userData.status)}
                  </h3>
                </div>
                <div className="flex flex-col">
                  <h3 className="font-medium ">Disbursement Amount</h3>
                  <h3 className="text-gray-500 font-semibold">
                    {userData.disbursement_amount || "N/A"}
                  </h3>
                </div>
                <div className=" w-full flex flex-row justify-between">
                  <div className="flex flex-col">
                    <h3 className="font-medium">Created</h3>
                    <h3 className="text-gray-500 font-semibold">
                      {GetTimeAgo(userData.created_at) || "N/A"}
                    </h3>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {/* <div className="rounded-2xl   bg-white mt-10">
          <div className=" w-full px-5  pt-3 rounded-t-2xl border-b-2 flex flex-row  items-center ">
            <div className="w-full  flex flex-row  items-center gap-4">
              <h1
                className={`${
                  navtype === 1
                    ? "text-logo-blue border-b-2  border-logo-orange"
                    : ""
                }   font-medium  cursor-pointer py-3 `}
                onClick={() => setNavtype(1)}
              >
                Lead's Information
              </h1>
            </div>
            <button onClick={() => handleEditClick(userData)}>
              {!isSuperUser && (
                <img
                  className="w-5"
                  src="https://cdn.staropstech.com/clients/udharibazaar/assets/extralogo/editPencil.webp"
                  alt=""
                />
              )}
            </button>
          </div>
          <div className="w-full p-8 gap-5 flex flex-col justify-between sm:flex-row shadow-lg rounded-2xl ">
            {userData && (
              <>
                {navtype === 1 && (
                  <>
                    <div className="w-full sm:w-[45%] flex flex-col gap-5 ">
                      <h1 className="font-medium  text-xl border-b-2">
                        Provided Value
                      </h1>
                      {userData.service_input_field_values.map((value, key) => {
                        if (
                          value.supported_services_input_field.input_type !==
                          "FILE_UPLOAD"
                        ) {
                          return (
                            <div
                              className="flex flex-row justify-between"
                              key={key}
                            >
                              <h3 className="font-medium  ">
                                {value.supported_services_input_field.label}
                              </h3>
                              <h3 className="text-gray-500 font-medium">
                                {value.supported_services_input_value || "N/A"}
                              </h3>
                            </div>
                          );
                        }
                      })}
                    </div>
                    <div className="w-full sm:w-[45%] flex flex-col gap-5 ">
                      <h1 className="font-medium text-xl border-b-2">
                        Documents Uploded
                      </h1>

                      {userData.service_input_field_values.map((value, key) => {
                        if (
                          value.supported_services_input_field.input_type ===
                          "FILE_UPLOAD"
                        ) {
                          return (
                            <div
                              className="flex flex-row justify-between"
                              key={key}
                            >
                              <h3 className="font-medium ">
                                {value.supported_services_input_field.label}
                              </h3>
                              <h3 className=" text-gray-500 font-semibold">
                                {value.upload_files == null ? (
                                  <button
                                    disabled
                                    className="bg-gray-200 rounded-md shadow-md text-sm p-1 px-2  "
                                  >
                                    Download
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => {
                                      DownloadFile(value.upload_files);
                                    }}
                                    className="bg-green-200 rounded-md shadow-md hover:scale-110 text-sm  p-1 px-2"
                                  >
                                    Download
                                  </button>
                                )}
                              </h3>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default InsuranceDetailCard;
