import React, { useRef } from "react";
import ModalWindow from "../../Common/Modal/ModalWindow";
import GenerateURL from "../../../utils/ApiUrlProvider";
import { useParams } from "react-router-dom";
import { PutData } from "../../../utils/ApiInvoker";

const EditApplicationStatusModal = ({ onClose, putUrl }) => {
  const params = useParams();
  const ref = useRef();
  const desref = useRef();
  const HandleChangeStatusSucess = () => {
    onClose();
  };
  const HandleChangeStatusError = () => {};

  const updateStatus = (e) => {
    e.preventDefault();

    const url = GenerateURL({ id: params.id }, putUrl);

    PutData(
      url,
      {
        status: ref.current.value,
        description: desref.current.value,
      },
      HandleChangeStatusSucess,
      HandleChangeStatusError
    );
  };
  return (
    <ModalWindow title="Update Status" onClose={onClose}>
      <form className="flex flex-col w-full gap-1 items-center">
        <div className="mb-2 flex w-full gap-1 sm:gap-2 md:gap-3">
          <p className="flex items-center justify-end text-black w-1/2 md:w-1/3 text-right text-sm md:text-lg">
            Status:
          </p>
          <div className="w-3/4 md:w-2/3">
            <select
              ref={ref}
              name="status"
              className={`p-2 border rounded-lg w-full items-start text-xs md:text-sm focus:outline-none h-10 focus:border-blue-600 ${
                false ? "border-red-500" : "border-gray-300"
              }`}
            >
              <option value="INITIATED">INITIATED</option>
              <option value="PROCESSING">PROCESSING</option>
              <option value="APPROVED">APPROVED</option>
              <option value="SANCTIONED">SANCTIONED</option>
              <option value="DISBURSED">DISBURSED</option>
              <option value="REJECTED">REJECTED</option>
            </select>
          </div>
        </div>

        <div className="mb-2 flex w-full gap-1 sm:gap-2 md:gap-3">
          <p className="flex items-center justify-end text-black w-1/2 md:w-1/3 text-right text-sm md:text-lg">
            Description
          </p>
          <div className="w-3/4 md:w-2/3">
            <input
              type="text"
              ref={desref}
              name="description"
              className={`p-2 border rounded-lg w-full items-start text-xs md:text-sm focus:outline-none h-10 focus:border-blue-600 ${
                false ? "border-red-500" : "border-gray-300"
              }`}
            />
          </div>
        </div>

        <button
          onClick={updateStatus}
          className=" w-max bg-blue-500 text-white px-1 py-1 md:px-4 md:py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300  "
        >
          save
        </button>
      </form>
    </ModalWindow>
  );
};

export default EditApplicationStatusModal;
