import React, { useState } from "react";

const SearchBar = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedParameter, setSelectedParameter] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const paramater = ["para1", "paera2", "para3", "para4"];

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const handleParameterSelect = (parameter) => {
    setSelectedParameter(parameter);
    setSearchInput(parameter);
    setDropdownOpen(false);
  };

  const handleInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  return (
    <div className="relative inline-block text-left">
      <input
        type="text"
        value={searchInput}
        onChange={handleInputChange}
        onFocus={toggleDropdown}
        onBlur={() => setTimeout(() => setDropdownOpen(false), 200)}
        className="border-2 w-full  border-gray-300 bg-white h-10 px-5 pr-10 rounded-full text-sm focus:outline-none"
        placeholder="Search..."
      />
      {isDropdownOpen && (
        <div className="absolute mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {paramater.map((value, key) => {
              return (
                <div
                  key={key}
                  onClick={() => handleParameterSelect(value)}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                >
                  {value}
                </div>
              );
            })}
          </div>
        </div>
      )}
      <p className="mt-2 text-sm text-gray-500">
        Selected Parameter: {selectedParameter}
      </p>
    </div>
  );
};

const Test = () => {
  return (
    <div className="mt-[100px]">
      <SearchBar></SearchBar>
    </div>
  );
};

export default Test;
