import React from "react";

const Footer = () => {
  const makePhoneCall = () => {
    window.open("tel:+91 6393803009");
  };
  const makePhoneCall2 = () => {
    window.open("tel:+91 9650599471");
  };
  return (
    <footer
      className="bg-cover bg-center w-full"
      style={{
        backgroundImage: "url('https://wallpapercave.com/dwp1x/wp3185342.jpg')",
      }}
    >
      <div className=" w-full grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4 text-white p-12 ">
        <div className="sm:col-span-2">
          <a href="#" className="inline-flex items-center">
            <img
              src="https://cdn.staropstech.com/clients/udharibazaar/assets/extra-images/logo.webp"
              alt="logo"
              className="h-8"
            />
          </a>
          <div className="mt-6 lg:max-w-xl">
            <p className="text-sm">
              UdhariBazaar is your trusted online hub for tailored financial
              solutions, offering competitive interest rates on personal loans,
              salaried loans, bike loans, car loans and much more. Our
              commitment to transparency and customer satisfaction ensures a
              smooth and empowering financial journey. Join us at UdhariBazaar
              for convenience and confidence in achieving your financial goals.
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-2 text-sm">
          <p className="text-base font-bold tracking-wide  border-b-2 border-logo-orange max-w-max  pb-1 mb-2">
            Products
          </p>
          <a href="/personal-loan">Personal Loan</a>
          <a href="/car-loan">Auto Loan</a>
          <a href="/home-loan">Home Loan</a>
          <a href="/business-loan">Business Loan</a>
          <a href="/health-insurance">Health Insurance</a>
          <a href="/term-insurance">Term Insurance</a>
          <a href="/auto-insurance">Auto Insurance</a>
          <a href="/credit-loan">Credit Card</a>
        </div>
        <div className="flex flex-col gap-2 text-sm">
          <p className="text-base font-bold tracking-wide border-b-2 border-logo-orange max-w-max  pb-1 mb-2 ">
            General & Policy
          </p>
          <a href="/about-us">About us</a>
          <a href="/faq">FAQ</a>
          <a href="/blogs">Blogs</a>
          <a href="/career">Career</a>
          <a href="/privacy-policy">Privacy Policy</a>
          <a href="/feedback">Feedback</a>
          <a href="/term-and-conditions">Terms of Use</a>
        </div>

        <div className="w-full sm:col-span-2">
          <p className="text-base font-bold tracking-wide border-b-2 border-logo-orange max-w-max pb-1 mb-2 w-full ">
            Contact Us
          </p>
          <div className="flex">
            <p className="mr-1 text-sm">Email:</p>
            <a
              href="mailto:info@udharibazaar.com"
              title="send email"
              target="_blank"
              className="text-sm"
            >
              info@udharibazaar.com
            </a>
          </div>
          <div className="flex w-full ">
            <p className="mr-1 text-sm">Contact:</p>
            <div className="flex w-full flex-row gap-2">
              <p
                className="cursor-pointer text-xs sm:text-sm"
                onClick={makePhoneCall2}
              >
                +91 9650599471,
              </p>
              <p
                className="cursor-pointer text-xs sm:text-sm"
                onClick={makePhoneCall}
              >
                +91 6393803009
              </p>
            </div>
          </div>
          <div className=" flex gap-2 mt-2">
            <a
              href="https://www.linkedin.com/company/udharibazaar"
              target="_blank"
            >
              <img
                className="w-6"
                src="https://cdn.staropstech.com/clients/udharibazaar/assets/social-icons/linkedin.webp"
                alt="icons"
              />
            </a>
            <a href="https://www.instagram.com/udharibazaar" target="_blank">
              <img
                className="w-6"
                src="https://cdn.staropstech.com/clients/udharibazaar/assets/social-icons/instagram.webp"
                alt=""
              />
            </a>
            <a href="https://www.facebook.com/UdhariBazaar" target="_blank">
              <img
                className="w-6"
                src="https://cdn.staropstech.com/clients/udharibazaar/assets/social-icons/facebook.webp"
                alt=""
              />
            </a>
            <a href="https://twitter.com/udharibazaar" target="_blank">
              <img
                className="w-6"
                src="https://cdn.staropstech.com/clients/udharibazaar/assets/social-icons/twitter.webp"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>

      <div className="flex flex-col-reverse justify-between pt-5 pb-4 border-t lg:flex-row text-white ">
        <div className="text-sm w-full text-center ">
          Copyright © 2023 Udharibazaar. All rights reserved.
          <p>
            Managed by{" "}
            <a href="https://staropstech.com/" target="_blank">
              <span>StarOps Technologies Pvt Ltd</span>
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
