if (process.env.REACT_APP_ENV === "PROD") {
  var baseURL = "https://public-api.udharibazaar.com/";
} else if (process.env.REACT_APP_ENV === "UAT") {
  var baseURL = "https://public-api-uat.udharibazaar.in/";
} else {
  var baseURL = "https://a898-217-45-195-33.ngrok-free.app/";
}

const properties = {
  api: {
    baseURL: baseURL,
    // Users urls
    accessTokenURL: "api/v1/user/login/",
    refreshTokenURL: "api/v1/user/login/refresh/",
    logoutURL: "api/v1/user/logout/",
    getOTPURL: "api/v1/user/get-otp/",
    resendOTPURL: "api/v1/user/resend-otp/",
    supportedCategoryURL: "api/v1/common/supported_category/",
    supportedServicesURL:
      "api/v1/common/supported_category/${supported_category_id}/services/",
    allServicesURL: "api/v1/common/supported/services/",
    supported_category:
      "api/v1/common/supported_category/${supported_category_id}/services/${supported_service_id}/input_fields/",
    filled_services: "api/v1/user/filled/services/",
    filled_services_byid: "api/v1/user/filled/services/${id}/",
    userinfoupdate: "api/v1/user/${id}/",
    userBankOffer:
      "api/v1/user/filled/services/application/${filled_service_id}/offer/",
    userBankOfferAdd:
      "api/v1/user/filled/services/application/${filled_service_id}/offer/${offer_id}/",
    getBankDetails: "api/v1/user/bank/",
    updateBankDetails: "api/v1/user/bank/${id}/",
    businessLoan: "api/v1/user/business_loan/",
    getbusinessLoan: "api/v1/user/business_loan/${id}/",

    // Partner urls
    partnerRegister: "api/v1/partner/register/",
    partnerUpdateProfile: "api/v1/partner/${user_id}/profile/",
    getPartnerOTP: "api/v1/partner/get-otp/",
    partnerLogin: "api/v1/partner/login/",
    partnerDashboard: "api/v1/partner/dashboard/",
    partnerInfoUpdate: "api/v1/partner/${id}/",
    partenerCommision: "api/v1/user/${user_id}/bank/transaction/",
    // associate partner
    addAsociatePartner: "api/v1/partner/${id}/register_associated_partner/",
    getAsociatePartner: "api/v1/partner/${id}/associated_partner/",

    // Admin urls
    getadminOTP: "api/v1/admin/get-otp/",
    adminLogin: "api/v1/admin/login/",
    adminUsers: "api/v1/admin/user/",
    adminSingleUsers: "api/v1/admin/user/${id}/",
    adminLoans: "api/v1/admin/loan/",
    userLoanData: "api/v1/admin/loan/${id}/",
    adminInsurance: "api/v1/admin/insurance/",
    adminInsuranceData: "api/v1/admin/insurance/${id}/",
    adminCredit: "api/v1/admin/credit/",
    adminMutualList: "api/v1/admin/lic/",
    adminLicList: "api/v1/admin/mutualFunds/",
    adminCreditData: "api/v1/admin/credit/${id}/",
    adminBankOffer: "api/v1/admin/application/${filled_service_id}/bank_offer/",
    adminGetBankDeatils: "api/v1/admin/user/${id}/bank/",
    adminBankOfferAdd:
      "api/v1/admin/application/${filled_service_id}/bank_offer/",
    adminBankOfferEdit:
      "api/v1/admin/application/${filled_service_id}/bank_offer/${bank_offer_id}/",
    adminEnquiry: "api/v1/admin/enquiry/",
    adminSupport: "api/v1/admin/support/",
    adminRelationManager: "api/v1/admin/relational_manager/",
    adminSingleRM: "api/v1/admin/relational_manager/${id}/",
    adminbusinessLoan: "api/v1/admin/business_loan/",
    adminbusinessLoanbyid: "api/v1/admin/business_loan/${id}/",
    //statics
    adminRecentUser: "api/v1/admin/login_user/",
    adminDashboard: "api/v1/admin/dashboard/",

    // Updating Information
    adminUserUpdate: "api/v1/admin/user/${id}/",
    adminInfoUpdate: "api/v1/admin/${id}/",
    adminUpdateUserStatus: "api/v1/admin/user_status/${id}/",
    getallAssociatedPartner: "api/v1/admin/associated_partner/",
    getallAssociatepartnerofpartner:
      "api/v1/admin/partner/${id}/associated_partner/",

    applicationStatus:
      "api/v1/common/application_status/${application_number}/",

    adminaddrm: "api/v1/admin/partner/profile/${id}/add_rm/",

    // UpdatingStatus
    adminApplicationStatus: "api/v1/admin/application_status/${id}/",

    //common enquiry form
    commonEnquiery: "api/v1/common/enquiry/",
    //
    supportapi: "api/v1/support/customer/",
    supportdetail: "api/v1/support/customer/${id}/",
    updateSupportstatus: "api/v1/admin/support_status/${id}/",
    supportDetailadmin: "api/v1/admin/support/${id}/",

    //
    fileUpload: "api/v1/file/upload/",
    fileDownload: "api/v1/file/upload/${file_unique_id}/",
    Admin: {
      BankOfferAdd: "api/v1/admin/application/${id}/bank_offer/",
      BankOfferEdit: "api/v1/admin/application/${id}/bank_offer/${secid}/",
    },
    Common: {
      Loan: {
        GetBankOffer: "api/v1/user/application/${id}/bank_offer/",
        GetBankOfferEdit: "api/v1/user/application/${id}/bank_offer/${secid}/",
      },
    },

    PersonalLoanServices: {
      UserPersonalLoan: "api/v1/user/personal_loan/",
      GetAllDetailsPersonalLoanById: "api/v1/user/personal_loan/detail/${id}/",
      SelfEmployedPersonalLoan:
        "api/v1/user/personal_loan/${id}/self_employeed/",
      SalaryDetailPersonalLoan: "api/v1/user/personal_loan/${id}/salaried/",
      CoApplicantPersonalLoan:
        "api/v1/user/personal_loan/${id}/co_applicant_details/",
      RefreePersonalLoan: "api/v1/user/personal_loan/${id}/reference/",
      EditUrl: {
        UserPersonalLoanEdit: "api/v1/user/personal_loan/${id}/",
        SalaryDetailPersonalLoanEdit:
          "api/v1/user/personal_loan/${id}/salaried/${secid}/",
        SelfEmploymentPersonalLoanEdit:
          "api/v1/user/personal_loan/${id}/self_employeed/${secid}/",
        CoapplicantPersonalLoanEdit:
          "api/v1/user/personal_loan/${id}/co_applicant_details/${secid}/",
        referencePersonalLoanEdit:
          "api/v1/user/personal_loan/${id}/reference/${secid}/",
      },
      adminUrls: {
        AdminPersonalLoan: "api/v1/admin/personal_loan/",
        GetAllDetailsPersonalLoanById: "api/v1/admin/personal_loan/${id}/",
      },
    },
    BusinessLoanServices: {
      UserBusinessLoan: "api/v1/user/business_loan/",
      GetAllDetailsBusinessLoanById: "api/v1/user/business_loan/detail/${id}/",
      FirmdetailBusinessloan: "api/v1/user/business_loan/${id}/firm_details/",
      CoApplicantBusinessLoan:
        "api/v1/user/business_loan/${id}/co_applicant_details/",
      RefreeBusinessLoan: "api/v1/user/business_loan/${id}/reference/",
      EditUrl: {
        UserBusinessLoanEdit: "api/v1/user/business_loan/${id}/",
        FirmdetailBusinessloanEdit:
          "api/v1/user/business_loan/${id}/firm_details/${secid}/",
        CoapplicantBusinessLoanEdit:
          "api/v1/user/business_loan/${id}/co_applicant_details/${secid}/",
        referenceBusinessLoanEdit:
          "api/v1/user/business_loan/${id}/reference/${secid}/",
      },
      adminUrls: {
        AdminBusinessLoan: "api/v1/admin/business_loan/",
        GetAllDetailsBusinessLoanById: "api/v1/admin/business_loan/${id}/",
      },
    },
    AutoLoanServices: {
      UserAutoLoan: "api/v1/user/auto_loan/",
      GetAllDetailsAutoLoanById: "api/v1/user/auto_loan/detail/${id}/",
      SelfEmployedAutoLoan: "api/v1/user/auto_loan/${id}/self_employeed/",
      SalaryDetailAutoLoan: "api/v1/user/auto_loan/${id}/salaried/",

      FirmdetailAutoloan: "api/v1/user/auto_loan/${id}/firm_details/",
      CoApplicantAutoLoan: "api/v1/user/auto_loan/${id}/co_applicant_details/",
      RefreeAutoLoan: "api/v1/user/auto_loan/${id}/reference/",
      EditUrl: {
        UserAutoLoanEdit: "api/v1/user/auto_loan/${id}/",
        SalaryDetailAutoLoanEdit:
          "api/v1/user/auto_loan/${id}/salaried/${secid}/",
        SelfEmploymentAutoLoanEdit:
          "api/v1/user/auto_loan/${id}/self_employeed/${secid}/",
        FirmdetailAutoloanEdit:
          "api/v1/user/auto_loan/${id}/firm_details/${secid}/",
        CoapplicantAutoLoanEdit:
          "api/v1/user/auto_loan/${id}/co_applicant_details/${secid}/",
        referenceAutoLoanEdit:
          "api/v1/user/auto_loan/${id}/reference/${secid}/",
      },
      adminUrls: {
        AdminAutoLoan: "api/v1/admin/auto_loan/",
        GetAllDetailsAutoLoanById: "api/v1/admin/auto_loan/${id}/",
      },
    },
  },
};

export default properties;
