import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import homeicondata from "../../../data/homepagedata.json";

import banner1 from "../../../assets/sliderimage/banner1.webp";
import banner2 from "../../../assets/sliderimage/banner2.webp";
import banner3 from "../../../assets/sliderimage/banner3.webp";
import banner4 from "../../../assets/sliderimage/banner4.webp";

import Calculator from "./Calculator";
import About from "./About";
import Count from "./Count";
import Reviews from "./Reviews";
import Partners from "./Partners";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { IsAuthenticated, IsNormalUser } from "../../../utils/AccessTokenStore";
import LoginModal from "../Login/LoginModal";

const Banner = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const image = [banner1, banner2];
  const imagesmall = [banner3, banner4];
  return (
    <>
      <div className="hidden sm:block">
        <Carousel
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          responsive={responsive}
          swipeable={false}
          draggable={false}
          arrows={false}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          containerClass="carousel-container"
        >
          {image.map((data, key) => {
            return (
              <img
                className="h-[85vh] w-full object-cover sm:object-fill "
                src={data}
                alt={`banner ${key}`}
                key={key}
              />
            );
          })}
        </Carousel>
      </div>
      <div className="sm:hidden">
        <Carousel
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          responsive={responsive}
          swipeable={false}
          draggable={false}
          arrows={false}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          containerClass="carousel-container"
        >
          {imagesmall.map((data, key) => {
            return (
              <img
                className="h-[85vh] w-full object-cover sm:object-fill "
                src={data}
                alt={`banner ${key}`}
                key={key}
              />
            );
          })}
        </Carousel>
      </div>
    </>
  );
};

const Homepage = () => {
  const [isloginModalOpen, setIsloginModalOpen] = useState(false);
  const navigate = useNavigate();

  const NavigateToLink = (data) => {
    const route = data.route;
    if (route) {
      navigate(route);
    } else {
      if (IsAuthenticated() && IsNormalUser()) {
        navigate(`/user/dashboard`);
      } else {
        setIsloginModalOpen(true);
      }
    }
  };

  return (
    <div>
      {isloginModalOpen && (
        <LoginModal
          isloginModalOpen={isloginModalOpen}
          closeLoginModel={() => setIsloginModalOpen(false)}
        />
      )}
      <div className="h-[85vh] relative">
        <Banner />
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2   w-10/12 sm:w-8/12 p-5 rounded-3xl bg-white  bg-opacity-60 backdrop-blur  shadow-lg ">
          <div className="w-full h-full grid grid-cols-3  sm:grid-cols-4 justify-center gap-x-1 gap-y-2">
            {homeicondata.Icondata.map((value, key) => {
              return (
                <div
                  className="flex flex-col items-center justify-center gap-1  w-full  rounded-lg  m-auto p-3 hover:bg-logo-orange hover:bg-opacity-10"
                  key={key}
                  onClick={() => {
                    NavigateToLink(value);
                  }}
                >
                  <img className="h-14 w-14" src={value.iconlink} alt="" />
                  <h1 className=" text-md font-bold text-center leading-4 text-orange-400 ">
                    {value.name}
                    <span className=" block text-xs font-semibold leading-4 text-logo-blue text-opacity-80">
                      {value.category}
                    </span>
                  </h1>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mt-[230px] sm:mt-[200px] text-logo-blue">
          <Calculator />
        </div>

        <div className="text-4xl text-logo-blue  font-bold capitalize text-center flex flex-col items-center mt-10 md:mt-20 ">
          How UdhariBazaar Works
        </div>
        <img
          src="https://cdn.staropstech.com/clients/udharibazaar/assets/extra-images/process.webp"
          alt="Banner"
          className="w-full h-auto max-w-full mx-auto object-cover object-center rounded-2xl mt-4  mb-10"
        />
        <About />
        <Count />
        <Reviews />
        <Partners />
        <Footer />
      </div>
    </div>
  );
};

export default Homepage;
