import React, { useEffect, useState } from "react";
import GenerateURL, { GenerateSearchURL } from "../../../utils/ApiUrlProvider";
import properties from "../../../Properties/Properties";
import { GetData } from "../../../utils/ApiInvoker";
import { GetTimeAgo, GetUserTpeByCode } from "../../../utils/Healpers";
import ListTable from "../../Common/ListTable";
import { useNavigate } from "react-router-dom";
import DashboardBanner from "../../Common/DashboardBanner";
import MissingDataImage from "../../Common/MissingDataImage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchBar from "../../Common/SearchBar";

const UserList = () => {
  const [tableData, setTableData] = useState(null);
  const [pagination, setPagination] = useState(null);
  const navigate = useNavigate();
  const clickOn = (e) => {
    navigate(`/admin/user/${e.id}`);
  };
  const clickableOn = {
    on: ["User Code"],
    funct: { "User Code": clickOn },
  };

  const convertDataToTableFormat = (data) => {
    const tableHeaders = [
      "User Code",
      "Name",
      "Phone Number",
      "E-mail",
      "User-Type",
      "Time",
    ];
    const tableContent = data.map((user) => {
      return {
        "User Code": user.user_profile.username || "N/A",
        Name: user.first_name + " " + user.last_name || "--",
        "Phone Number": user.mobile_number || "--",
        "E-mail": user.email || "--",
        "User-Type": GetUserTpeByCode(user.user_profile.username),
        Time: GetTimeAgo(user.user_profile.created_at),
        id: user.id,
      };
    });
    return {
      tableHeaders,
      tableContent,
    };
  };

  const HandleSearchSucess = (res) => {
    console.log(res.results);
    if (res.results.length === 0) {
      toast.error("No Data found");
      GetUserList();
    } else {
      HandleSuccess(res);
    }
  };

  const searchParameters = [
    { name: "Name", parm: "name" },
    { name: "Email", parm: "email" },
    { name: "Phone Number", parm: "mobile_number" },
    { name: "User Code", parm: "username" },
  ];

  const SearchClick = (e) => {
    const url = GenerateSearchURL(e, properties.api.adminUsers);
    GetData(url, HandleSearchSucess, HandleError, false, false);
  };
  const EmptyCall = () => {
    GetUserList();
  };

  const HandleSuccess = (response) => {
    const data = convertDataToTableFormat(response.results);
    setTableData(data);
    setPagination(response);
  };

  const HandleError = (response) => {
    console.log(response);
  };

  const GetNextFilledServices = (nexturl) => {
    const url = GenerateURL({}, nexturl);
    GetData(url, HandleSuccess, HandleError, false, false);
  };

  const GetPrevFilledServices = (prevurl) => {
    const url = GenerateURL({}, prevurl);
    GetData(url, HandleSuccess, HandleError, false, false);
  };

  const GetFilledServices = () => {
    const url = GenerateURL({}, properties.api.adminUsers);
    GetData(url, HandleSuccess, HandleError, false, false);
  };

  const GetUserList = () => {
    const url = GenerateURL({}, properties.api.adminUsers);
    GetData(url, HandleSuccess, HandleError, false, false);
  };

  useEffect(() => {
    GetFilledServices();
  }, []);

  const view = (e) => {
    navigate(`/admin/user/${e.id}`);
  };

  return (
    <div className="w-11/12">
      <DashboardBanner />
      {tableData && tableData.tableContent.length === 0 ? (
        <div className="flex flex-col gap-2">
          <MissingDataImage message={"No Users Found"} />
        </div>
      ) : (
        tableData && (
          <div className="w-full flex flex-col  gap-2 ">
            <SearchBar
              searchParameters={searchParameters}
              SearchClick={SearchClick}
              EmptyCall={EmptyCall}
            />
            <ListTable
              heading="Total Users"
              tableData={tableData}
              pagination={Boolean(pagination.next || pagination.previous)}
              next={pagination.next}
              prev={pagination.previous}
              onNextClick={() => GetNextFilledServices(pagination.next)}
              onPrevClick={() => GetPrevFilledServices(pagination.previous)}
              actions={{ view: true, edit: false, delete: false }}
              onView={view}
              clickabaleOn={clickableOn}
            />
          </div>
        )
      )}
    </div>
  );
};

export default UserList;
