import React, { useState, useEffect } from "react";
import Card from "./Card";
import properties from "../../../Properties/Properties";
import GenerateURL from "../../../utils/ApiUrlProvider";
import { GetData } from "../../../utils/ApiInvoker";

const CardSection = ({ title, cards }) => (
  <>
    <h1 className="w-full text-2xl font-bold my-2 text-logo-blue">{title}</h1>
    <div className="flex flex-row gap-3 flex-wrap ">
      {cards.map((card, index) => (
        <Card
          key={index}
          title={card.title}
          value={card.value}
          viewLink={card.viewLink}
          imageLink={card.imageLink}
        />
      ))}
    </div>
  </>
);

const CardsData = () => {
  const [apiData, setApiData] = useState({
    total_associated_partner: "Loading...",
    total_loan_amount: "Loading...",
    total_insurance_amount: "Loading...",
    total_credit_card_applied: "Loading...",
    total_personal_loan: "Loading...",
    total_insurance_taken: "Loading...",
    totalCreditCardApplied: "Loading...",
    total_users: "Loading...",
    total_premium_partner: "Loading...",
    total_enquiry: "Loading...",
    total_support: "Loading...",
    total_relational_manager: "Loading...",
    total_logged_in_users_last_24_hours: "Loading...",
  });

  const HandleData = (res) => {
    setApiData(res);
  };

  const HandleError = (err) => {
    console.error("Failed to fetch data:", err);
  };

  useEffect(() => {
    const url = GenerateURL({}, properties.api.adminDashboard);
    GetData(url, HandleData, HandleError);
  }, []);

  const userStatsCards = [
    {
      title: "Recent Logged Users",
      value: apiData.total_logged_in_users_last_24_hours,
      viewLink: "/admin/user/recents",
      imageLink:
        "https://cdn.staropstech.com/clients/udharibazaar/assets/admin-sidebar/users.webp",
    },
  ];

  const loanAmountCards = [
    {
      title: "Loan Amount",
      value: apiData.total_loan_amount,
      viewLink: "/admin/loan-amount",
      imageLink:
        "https://cdn.staropstech.com/clients/udharibazaar/assets/sidebar-icons/autoloan.webp",
    },
    {
      title: "Insurance Amount",
      value: apiData.total_insurance_amount,
      viewLink: "/admin/insurance-amount",
      imageLink:
        "https://cdn.staropstech.com/clients/udharibazaar/assets/sidebar-icons/insurance.webp",
    },
  ];

  const servicesCards = [
    {
      title: "Personal Loan",
      value: apiData.total_personal_loan,
      viewLink: "/admin/personalloan",
      imageLink:
        "https://cdn.staropstech.com/clients/udharibazaar/assets/sidebar-icons/autoloan.webp",
    },
    {
      title: "Business Loan",
      value: apiData.total_business_loan,
      viewLink: "/admin/businessloan",
      imageLink:
        "https://cdn.staropstech.com/clients/udharibazaar/assets/sidebar-icons/autoloan.webp",
    },
    {
      title: "Auto Loan",
      value: apiData.total_auto_loan,
      viewLink: "/admin/autoloan",
      imageLink:
        "https://cdn.staropstech.com/clients/udharibazaar/assets/sidebar-icons/carloan.webp",
    },
    {
      title: "Insurance Taken",
      value: apiData.total_insurance_taken,
      viewLink: "/admin/insurance",
      imageLink:
        "https://cdn.staropstech.com/clients/udharibazaar/assets/sidebar-icons/insurance.webp",
    },
    {
      title: "Credit Cards",
      value: apiData.total_credit_card_applied,
      viewLink: "/admin/credit",
      imageLink:
        "https://cdn.staropstech.com/clients/udharibazaar/assets/sidebar-icons/creditcard.webp",
    },
    {
      title: "Mutual Funds",
      value: apiData.total_mutual_funds,
      viewLink: "",
      imageLink:
        "https://cdn.staropstech.com/clients/udharibazaar/assets/sidebar-icons/mutualfunds.webp",
    },
    {
      title: "LIC Plans",
      value: apiData.total_lic_plan,
      viewLink: "",
      imageLink:
        "https://cdn.staropstech.com/clients/udharibazaar/assets/sidebar-icons/lic.webp",
    },
  ];

  const usersCards = [
    {
      title: "Total Users",
      value: apiData.total_users,
      viewLink: "/admin/users",
      imageLink:
        "https://cdn.staropstech.com/clients/udharibazaar/assets/admin-sidebar/users.webp",
    },
    {
      title: "Relation Manager",
      value: apiData.total_relational_manager,
      viewLink: "/admin/relation-manager",
      imageLink:
        "https://cdn.staropstech.com/clients/udharibazaar/assets/admin-sidebar/manager.webp",
    },
    {
      title: "Premium Partners",
      value: apiData.total_premium_partner,
      viewLink: "/admin/user-partner",
      imageLink:
        "https://cdn.staropstech.com/clients/udharibazaar/assets/admin-sidebar/partners.webp",
    },
    {
      title: "Associate Partners",
      value: apiData.total_associated_partner,
      viewLink: "/admin/associated_partner",
      imageLink:
        "https://cdn.staropstech.com/clients/udharibazaar/assets/admin-sidebar/users.webp",
    },
  ];

  const enquiryCards = [
    {
      title: "Total Enquiry",
      value: apiData.total_enquiry,
      viewLink: "/admin/user-enquiery",
      imageLink:
        "https://cdn.staropstech.com/clients/udharibazaar/assets/admin-sidebar/question.webp",
    },
    {
      title: "Support Request",
      value: apiData.total_support,
      viewLink: "/admin/support",
      imageLink:
        "https://cdn.staropstech.com/clients/udharibazaar/assets/sidebar-icons/salariedloan.webp",
    },
  ];

  return (
    <div className="p-3 sm:p-0 md:ml-3 mt-10 w-full">
      <CardSection title="User Stats" cards={userStatsCards} />
      <CardSection title="Loan Amount" cards={loanAmountCards} />
      <CardSection title="Services" cards={servicesCards} />
      <CardSection title="Users" cards={usersCards} />
      <CardSection title="Enquiry" cards={enquiryCards} />
      <div className="h-10"></div>
    </div>
  );
};

export default CardsData;
