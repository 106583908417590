import React, { useEffect, useRef, useState } from "react";

import { IsSuperUser } from "../../utils/AccessTokenStore";
import GenerateURL from "../../utils/ApiUrlProvider";
import properties from "../../Properties/Properties";
import { GetData, PostData, PutData } from "../../utils/ApiInvoker";
import { GetTimeAgo, StatusTag } from "../../utils/Healpers";
import ModalWindow from "./Modal/ModalWindow";
import { renderInput } from "./FormComponents";
import ListTable from "./ListTable";

const BankOffer = ({ shouldShowAdminButtons, userData }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const initialValues = {
    filled_service_id: userData.id,
    is_accepted: false,
    bank_name: "",
    offer_amount: "",
    rate_of_interest: "",
    response_description: " ",
    loan_tenure: "",
  };
  const [bankinfo, setBankInfo] = useState(null);
  const [bankformdata, setBankformdata] = useState({ ...initialValues });
  const HandleSucess = (res) => {
    const tableData = {
      tableHeaders: [
        "Bank Name",
        "Offer Amount",
        "Rate Of Interest",
        "Description",
        "Accepted",
        "Tennure",
      ],
      tableContent: res.map((data, key) => {
        return {
          ID: data.id,
          "Bank Name": data.bank_name,
          "Offer Amount": data.offer_amount,
          "Rate Of Interest": data.rate_of_interest,
          Description: data.response_description,
          Accepted: data.is_accepted ? "Yes" : "No",
          Tennure: data.loan_tenure,
        };
      }),
    };
    setBankInfo(tableData);
    console.log(bankinfo);
  };
  const HandleError = (err) => {
    console.log(err);
  };
  const GetAdminBankOffer = () => {
    const url = GenerateURL(
      { filled_service_id: userData.id },
      properties.api.adminBankOffer
    );
    GetData(url, HandleSucess, HandleError, false, false);
  };
  const GetUserBankOffer = () => {
    console.log(userData.id);
    const url = GenerateURL(
      { filled_service_id: userData.id },
      properties.api.userBankOffer
    );
    GetData(url, HandleSucess, HandleError, false, false);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (IsSuperUser()) {
      GetAdminBankOffer();
    } else {
      GetUserBankOffer();
    }
  }, [isModalOpen]);

  const editButtonRef = useRef(null);
  const toggleEditModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBankformdata({ ...bankformdata, [name]: value });
  };
  const HandlePostSucess = (res) => {
    setIsModalOpen(false);

    console.log(res);
  };
  const HandlePostError = (err) => {
    console.log(err);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const url = GenerateURL(
      { filled_service_id: userData.id },
      properties.api.adminBankOfferAdd
    );
    console.log(bankformdata);
    PostData(url, bankformdata, HandlePostSucess, HandlePostError, false);
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    const url = GenerateURL(
      { filled_service_id: userData.id, bank_offer_id: currentId },
      properties.api.adminBankOfferEdit
    );
    console.log(bankformdata);

    PutData(url, bankformdata, HandlePostSucess, HandlePostError, false);
  };
  const CustomClick = (e) => {
    console.log(e);
  };

  const custombtn = {
    show: true,
    name: "Accept",
    customClick: CustomClick,
  };

  const onEditClick = (e) => {
    setIsEditing(true);
    setCurrentId(e["ID"]);
    setBankformdata({
      filled_service_id: userData.id,
      id: e["ID"],
      is_accepted: false,
      bank_name: e["Bank Name"],
      offer_amount: e["Offer Amount"],
      rate_of_interest: e["Rate Of Interest"],
      response_description: e["Description"],
      loan_tenure: e["Tennure"],
    });
    setIsModalOpen(true);
  };

  return (
    <div className="min-h-screen  w-full">
      {
        <div className="w-full mt-5 flex justify-end items-center">
          {bankinfo && !IsSuperUser() && (
            <ListTable
              heading="apple"
              tableData={bankinfo}
              actions={{ edit: false, delete: false, view: false }}
              pagination={false}
              customButton={custombtn}
            />
          )}
        </div>
      }
      {shouldShowAdminButtons && (
        <>
          <div className="w-full flex justify-end items-center">
            <button
              className="bg-blue-200 rounded-lg px-2 shadow-lg hover:scale-110"
              ref={editButtonRef}
              onClick={() => {
                setIsEditing(false);
                setBankformdata(initialValues);
                setIsModalOpen(!isModalOpen);
              }}
            >
              Add Proposal
            </button>
          </div>

          <div className="w-full mt-5 flex justify-end items-center">
            {bankinfo && (
              <ListTable
                heading="apple"
                tableData={bankinfo}
                actions={{ edit: true, delete: false, view: false }}
                pagination={false}
                onEdit={onEditClick}
              />
            )}
          </div>

          {isModalOpen && (
            <ModalWindow onClose={toggleEditModal} title="Add Bank Offer">
              <form className="w-full flex  flex-col items-center  justify-between ">
                <div className="w-full">
                  {renderInput("STRING", {
                    label: "Bank Name",
                    name: "bank_name",
                    value: bankformdata.bank_name,
                    onChange: handleChange,
                    error: "",
                  })}
                  {renderInput("STRING", {
                    label: "Offer Amount",
                    name: "offer_amount",
                    value: bankformdata.offer_amount,
                    onChange: handleChange,
                    error: "",
                  })}
                  {renderInput("STRING", {
                    label: "Rate Of Interest",
                    name: "rate_of_interest",
                    value: bankformdata.rate_of_interest,
                    onChange: handleChange,
                    error: "",
                  })}
                  {renderInput("STRING", {
                    label: "Description",
                    name: "response_description",
                    value: bankformdata.response_description,
                    onChange: handleChange,
                    error: "",
                  })}
                  {renderInput("STRING", {
                    label: "Loan Tenure",
                    name: "loan_tenure",
                    value: bankformdata.loan_tenure,
                    onChange: handleChange,
                    error: "",
                  })}
                </div>
                {!isEditing ? (
                  <button
                    className="bg-blue-500 h-10  text-white px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
                    onClick={handleSubmit}
                  >
                    submit
                  </button>
                ) : (
                  <button
                    className="bg-blue-500 h-10  text-white px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
                    onClick={handleUpdate}
                  >
                    Update
                  </button>
                )}
              </form>
            </ModalWindow>
          )}
        </>
      )}
    </div>
  );
};

const CreditCardDetail = ({ userData }) => {
  const isSuperUser = IsSuperUser();
  const [navtype, setNavtype] = useState(1);
  const shouldShowAdminButtons = isSuperUser;

  return (
    <div className="w-full flex flex-col ml-5 mt-9 p-4 ">
      <div className=" mt-3 rounded-2xl ">
        <div className=" w-full p-5 rounded-t-2xl bg-white flex flex-row justify-between border-b-2">
          <h1 className="font-medium text-xl ">
            Basic Application Information
          </h1>
          {shouldShowAdminButtons && (
            <button className="bg-blue-200 rounded-lg px-1 shadow-lg hover:scale-110">
              Update Status
            </button>
          )}
        </div>
        <div className="w-full p-8 gap-5 flex flex-col shadow-lg rounded-b-2xl bg-white ">
          {userData && (
            <>
              <div className="w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 ">
                <div className="flex flex-col">
                  <h3 className="font-medium  ">Applied Service</h3>
                  <h3 className=" text-gray-500 font-semibold">Credit Card</h3>
                </div>
                <div className="flex flex-col">
                  <h3 className="font-medium">Application Number</h3>
                  <h3 className="text-gray-500 font-semibold">
                    {userData.application_number || "N/A"}
                    Hello
                  </h3>
                </div>
                <div className="flex flex-col">
                  <h3 className="font-medium ">Applied Loan Amount</h3>
                  <h3 className="text-gray-500 font-semibold">
                    {userData.disbursement_amount || "N/A"}
                  </h3>
                </div>

                <div className="flex flex-col">
                  <h3 className="font-medium ">Applied By</h3>
                  <h3 className="font-semibold text-gray-500">
                    {userData.user.user_profile.username || "N/A"}
                  </h3>
                </div>
                <div className="flex flex-col">
                  <h3 className="font-medium">Status</h3>
                  <h3 className="text-gray-500 font-semibold">
                    {StatusTag(userData.status)}
                  </h3>
                </div>
                <div className="flex flex-col">
                  <h3 className="font-medium ">Disbursement Amount</h3>
                  <h3 className="text-gray-500 font-semibold">
                    {userData.disbursement_amount || "N/A"}
                  </h3>
                </div>
                <div className=" w-full flex flex-row justify-between">
                  <div className="flex flex-col">
                    <h3 className="font-medium">Created</h3>
                    <h3 className="text-gray-500 font-semibold">
                      {GetTimeAgo(userData.created_at) || "N/A"}
                    </h3>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="rounded-2xl   bg-white mt-10">
          <div className=" w-full p-5 rounded-t-2xl border-b-2 flex flex-row items-center gap-4">
            <h1
              className=" w-32 font-medium  cursor-pointer "
              onClick={() => setNavtype(1)}
            >
              Lead's Information
            </h1>
            <h1
              className="w-32  font-medium  cursor-pointer"
              onClick={() => setNavtype(2)}
            >
              Lending Offers{" "}
            </h1>
          </div>
          <div className="w-full p-8 gap-5 flex flex-col justify-between sm:flex-row shadow-lg rounded-2xl ">
            {/* {userData && (
              <>
                {navtype === 1 && (
                  <>
                    <div className="w-full sm:w-[45%] flex flex-col gap-5 ">
                      <h1 className="font-medium  text-xl border-b-2">
                        Provided Value
                      </h1>
                      {userData.service_input_field_values.map((value, key) => {
                        if (
                          value.supported_services_input_field.input_type !==
                          "FILE_UPLOAD"
                        ) {
                          return (
                            <div
                              className="flex flex-row justify-between"
                              key={key}
                            >
                              <h3 className="font-medium  ">
                                {value.supported_services_input_field.label}
                              </h3>
                              <h3 className="text-gray-500 font-medium">
                                {value.supported_services_input_value || "N/A"}
                              </h3>
                            </div>
                          );
                        }
                      })}
                    </div>
                    <div className="w-full sm:w-[45%] flex flex-col gap-5 ">
                      <h1 className="font-medium text-xl border-b-2">
                        Documents Uploded
                      </h1>
                      {userData.service_input_field_values.map((value, key) => {
                        if (
                          value.supported_services_input_field.input_type ===
                          "FILE_UPLOAD"
                        ) {
                          return (
                            <div
                              className="flex flex-row justify-between"
                              key={key}
                            >
                              <h3 className="font-medium ">
                                {value.supported_services_input_field.label}
                              </h3>
                              <h3 className=" text-gray-500 font-semibold">
                                {value.upload_files == null ? (
                                  <button className="bg-gray-200 rounded-md shadow-md text-sm p-1 px-2  ">
                                    Download
                                  </button>
                                ) : (
                                  <button className="bg-green-200 rounded-md shadow-md hover:scale-110 text-sm  p-1 px-2">
                                    Download
                                  </button>
                                )}
                              </h3>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </>
                )}
                {navtype === 2 && (
                  <BankOffer
                    shouldShowAdminButtons={shouldShowAdminButtons}
                    userData={userData}
                  />
                )}
              </>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditCardDetail;
