import React, { useState } from "react";
import CalculatorEnquiry from "../Home/CalculatorEnquiry";
import Footer from "../Home/Footer";
import AmortizationTable from "../../Common/AmortizationTable";

const HealthInsurance = () => {
  const [selectedMenu, setSelectedMenu] = useState("amortizationSchedule");
  const [amortizationTableData, setAmortizationTableData] = useState(null);

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
  };

  const updateAmortizationTableData = (data) => {
    setAmortizationTableData(data);
  };

  const menuTitles = {
    benefitsForYou: "Benefits For You",
    checkYourEligibility: "Check Your Eligibility",
    interestRates: "Interest Rates",
    feeAndCharges: "Fee and Charges",
    FAQ: "Got an Query",
    amortizationSchedule: "Amortization Schedule",
  };

  const menuData = {
    amortizationSchedule: {},
    benefitsForYou: {
      title: "Benefits For You",
      content:
        "Experience the incredible benefits of UdhariBazaar Personal Loan, tailored just for you. Our loan offering includes:",
      points: [
        "Loan amounts ranging up to Rs. 40 lakhs",
        "Competitive low-interest rates",
        "Flexible repayment options",
        "Quick disbursal process",
        "Easy and hassle-free application process",
      ],
    },
    checkYourEligibility: {
      title: "Check Your Eligibility",
      content:
        "Curious about your eligibility for UdhariBazaar Personal Loan? Here's what you need to know:",
      points: [
        "Indian citizenship is a must.",
        "Age between 21 to 60 years.",
        "Steady source of income.",
        "Good credit history.",
        "Check your eligibility and take the first step towards financial freedom.",
      ],
    },
    interestRates: {
      title: "Interest Rates",
      content:
        "Understanding the interest rates is crucial for informed financial decisions. With UdhariBazaar Personal Loan:",
      points: [
        "Enjoy competitive interest rates starting from 8.5% p.a.",
        "Rates vary based on credit score and loan amount.",
        "Transparent and fair interest rate policies.",
        "Explore our interest rates to find the best fit for your financial needs.",
      ],
    },
    feeAndCharges: {
      title: "Fee and Charges",
      content:
        "Transparency is at the core of our values. Here are the fees and charges associated with UdhariBazaar Personal Loan:",
      points: [
        "Processing Fee: 1.5% of the loan amount.",
        "Late Payment Charges: Rs. 500 per delay.",
        "Foreclosure Charges: 3% on the outstanding amount.",
        "Review the detailed fee structure for a clear understanding.",
      ],
    },
    FAQ: {
      title: "Got a Query",
      content:
        "Have questions? We've got answers. Check out our frequently asked questions to find solutions to common queries:",
      points: [
        "How to apply for a personal loan?",
        "What is the maximum loan amount offered?",
        "Are there any prepayment charges?",
        "How long does the approval process take?",
        "Get clarity on your queries and make informed decisions.",
      ],
    },
  };

  return (
    <>
      <div className="bg-gray-100 min-h-screen pt-10 mt-5">
        <div className="  md:ml-20 flex">
          <a
            href="/"
            className="text-sm text-logo-blue font-normal  hover:text-logo-orange border-blue-900 mt-5 ml-3"
          >
            Home{" "}
            <span className="text-sm text-logo-blue font-medium  hover:text-logo-blue ">
              /{" "}
            </span>
          </a>
          <a
            href=""
            className="text-sm text-logo-blue font-normal hover:text-logo-orange  mt-5"
          >
            Health Insurance
          </a>
        </div>
        <h1 className="text-4xl text-logo-blue text-center font-bold capitalize sm:text-4xl border-blue-900 md:mt-4">
          Health Insurance
        </h1>
        <p className="text-xs text-logo-blue text-center font-normal sm:text-base border-blue-900 md:mt-4">
          Safeguard your well-being with UdhariBazaar's reliable health
          insurance options. Prioritize your health and future security with
          comprehensive coverage.
        </p>
        <div className="flex justify-center items-center mt-10">
          <div className="w-full md:w-3/4 lg:w-11/12">
            <CalculatorEnquiry
              updateAmortizationTableData={updateAmortizationTableData}
            />
          </div>
        </div>
        <div className="bg-gray-200 rounded-lg w-full md:w-3/4 lg:w-11/12 mx-auto mt-8 text-center">
          <ul className="flex justify-center items-center flex-wrap">
            {Object.keys(menuData).map((menu) => (
              <li
                key={menu}
                onClick={() => handleMenuClick(menu)}
                className={`cursor-pointer p-4 ${
                  selectedMenu === menu
                    ? "text-orange-400 border-b-2 border-orange-400"
                    : ""
                }`}
              >
                {menuTitles[menu]}
              </li>
            ))}
          </ul>

          {selectedMenu === "amortizationSchedule" && amortizationTableData && (
            <AmortizationTable data={amortizationTableData} />
          )}

          {selectedMenu !== "amortizationSchedule" && (
            <div className="section-content mx-auto bg-white p-8 rounded-lg mb-16 flex flex-col items-start">
              <h3 className="text-3xl font-bold text-logo-blue mb-5">
                {menuData[selectedMenu].title}
              </h3>
              <p className="text-lg text-logo-blue mb-5 text-left">
                {menuData[selectedMenu].content}
              </p>
              <ul className="list-disc list-inside">
                {menuData[selectedMenu].points.map((point, index) => (
                  <li key={index} className="flex items-start mb-2">
                    <span className="mr-2">&#8226;</span>
                    <span className="text-logo-blue text-justify">{point}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default HealthInsurance;
