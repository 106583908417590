import React, { useEffect, useState } from "react";
import GenerateURL from "../../../utils/ApiUrlProvider";
import { GetData } from "../../../utils/ApiInvoker";
import properties from "../../../Properties/Properties";
import CreditCardDetail from "../../Common/CreditCardDetail";
import { useParams } from "react-router-dom";

const CreditCardInfo = () => {
  const [userData, setUserData] = useState();
  const params = useParams();
  const HandleSucess = (res) => {
    setUserData(res);
  };
  const HandleError = (res) => {
    console.log(res);
  };
  const LoadLoanData = () => {
    const url = GenerateURL({ id: params.id }, properties.api.adminCreditData);
    GetData(url, HandleSucess, HandleError, false, false);
  };
  useEffect(() => {
    LoadLoanData();
  }, []);

  return <>{userData ? <CreditCardDetail userData={userData} /> : <> </>}</>;
};

export default CreditCardInfo;
