import React, { useEffect, useState } from "react";
import GenerateURL, { GenerateSearchURL } from "../../../utils/ApiUrlProvider";
import properties from "../../../Properties/Properties";
import { GetData, PostData, PutData } from "../../../utils/ApiInvoker";
import {
  GenerateChatElements,
  GetTimeAgo,
  StatusTag,
} from "../../../utils/Healpers";
import ListTable from "../../Common/ListTable";
import MissingDataImage from "../../Common/MissingDataImage";
import DashboardBanner from "../../Common/DashboardBanner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchBar from "../../Common/SearchBar";
import ModalWindow from "../../Common/Modal/ModalWindow";
import { renderInput } from "../../Common/FormComponents";
import { useLoadingContext } from "../../../Context/LoadingContext";
import SupportChatWindow from "../../Common/Modal/SupportChatModal";
import { GetAuth } from "../../../utils/AccessTokenStore";

const SupportList = () => {
  const { showLoader, hideLoader } = useLoadingContext();
  const [tableData, setTableData] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [status, setStatus] = useState(null);
  const [statuserr, setStatuserr] = useState("");
  const [isSupportChatMoadal, setIsSupportChatModal] = useState(false);
  const [supportData, setSupportData] = useState(null);
  const user = GetAuth();

  const convertDataToTableFormat = (data) => {
    const tableHeaders = [
      "Referance Number",
      "Applied By",
      "Application No",
      "Subject",
      "Status",
      "Time",
    ];
    const tableContent = data.map((item) => {
      return {
        "Referance Number": item.reference_number || "--",
        "Applied By": (
          <span className="relative group">
            <span className="group-hover:underline cursor-pointer text-blue-500 -z-50">
              {item.user.user_profile.username}
            </span>
            <div className="absolute hidden left-5 bottom-3 bg-blue-100 border border-blue-300 p-2 rounded shadow-md group-hover:block w-52 z-50">
              <div className="text-black font-bold text-sm">
                Name:{item.user.first_name || ""}
              </div>
              <div className="text-black text-sm">
                Mobile:{item.user.mobile_number || ""}
              </div>
            </div>
          </span>
        ),
        "Application No":
          item.business_loan_application?.application_number ||
          item.personal_loan_application?.application_number ||
          item.auto_loan_application?.application_number ||
          "--",
        Subject:
          item.subject.length > 20
            ? item.subject.slice(0, 20) + "..."
            : item.subject,
        Status: item.status || "N/A",
        Time: GetTimeAgo(item.created_at),
        id: item.id,
      };
    });
    return {
      tableHeaders,
      tableContent,
    };
  };

  const HandleSearchSucess = (res) => {
    if (res.results.length === 0) {
      toast.error("No Data found");
      GetSupportList();
    } else {
      HandleSucess(res);
    }
  };
  const searchParameters = [
    {
      name: "Business Loan Application Number",
      parm: "business_loan_application",
    },

    { name: "Appplication Number", parm: "personal_loan_application" },
    { name: "Reference Number", parm: "reference_number" },
    { name: "Applied By", parm: "user_code" },
    { name: "Status", parm: "status" },
  ];
  const SearchClick = (e) => {
    const url = GenerateSearchURL(e, properties.api.adminSupport);
    GetData(url, HandleSearchSucess, HandleError, false, false);
  };
  const EmptyCall = () => {
    GetSupportList();
  };
  const HandleSucess = (response) => {
    hideLoader();
    const data = convertDataToTableFormat(response.results);
    setTableData(data);
    setPagination(response);
  };
  const HandleError = (response) => {
    hideLoader();
    console.log(response);
  };
  const GetNextLoanList = (nexturl) => {
    const url = GenerateURL({}, nexturl);
    GetData(url, HandleSucess, HandleError, false, false);
  };
  const GetPrevLoanList = (prevurl) => {
    const url = GenerateURL({}, prevurl);
    GetData(url, HandleSucess, HandleError, false, false);
  };

  const GetSupportList = () => {
    showLoader();
    const url = GenerateURL({}, properties.api.adminSupport);
    GetData(url, HandleSucess, HandleError, false, false);
  };

  useEffect(() => {
    setStatus("");
    setStatuserr("");
    setChatResp({
      msg: "",
      err: "",
    });
    GetSupportList();
  }, [isModalOpen, isSupportChatMoadal]);

  const onUpdateStatusClick = (e) => {
    setCurrentId(e.id);
    setIsModalOpen(true);
  };
  const HandleUpdateSucess = (res) => {
    setIsModalOpen(false);
  };
  const HandleUpdateError = (res) => {
    console.log(res);
  };
  const HandleSubmit = (e) => {
    e.preventDefault();
    const url = GenerateURL(
      { id: currentId },
      properties.api.updateSupportstatus
    );
    const data = {
      status: status,
    };
    if (data.status === null || data.status === "") {
      setStatuserr("Please Select one");
    } else {
      PutData(url, data, HandleUpdateSucess, HandleUpdateError, false);
    }
  };

  // +++++++++++++++++++++
  // SUPPORT CHAT
  // +++++++++++++++++++++
  const GetSupportSucess = (res) => {
    hideLoader();
    setSupportData(res);
  };
  const GetSupportFail = (res) => {
    hideLoader();
    console.log(res);
  };
  const GetSupportDetail = (id) => {
    const url = GenerateURL({ id: id }, properties.api.supportDetailadmin);
    GetData(url, GetSupportSucess, GetSupportFail, false, false);
  };

  const [chatResp, setChatResp] = useState({
    msg: "",
    err: "",
  });
  const HandlePostSucess = (res) => {
    setChatResp({
      msg: "",
      err: "",
    });
    GetSupportDetail(currentId);
  };

  const HandlePostErr = (res) => {
    console.log(res);
  };
  const HandleSendChat = (e) => {
    e.preventDefault();
    const data = {
      response: chatResp.msg.trim(),
    };
    if (data.response === "") {
      setChatResp((prev) => ({ ...prev, err: "Please enter a message " }));
    } else {
      const url = GenerateURL(
        { id: currentId },
        properties.api.supportDetailadmin
      );
      PostData(url, data, HandlePostSucess, HandlePostErr, false);
    }
  };

  const onView = (e) => {
    setCurrentId(e.id);
    setIsSupportChatModal(true);
    GetSupportDetail(e.id);
  };

  return (
    <>
      <div className="w-11/12">
        {isSupportChatMoadal && (
          <SupportChatWindow
            title="Support Chat"
            onClose={() => setIsSupportChatModal(false)}
          >
            {supportData && (
              <>
                <div className="bg-blue-50 rounded-lg w-full mt-2 grid grid-cols-2 p-2 gap-1">
                  <h1 className="font-medium text-xs sm:text-base">
                    Ref No:&nbsp;&nbsp;
                    <span className="text-gray-500">
                      {supportData.reference_number || ""}
                    </span>
                  </h1>
                  <h1 className="font-medium text-xs sm:text-base">
                    Status:&nbsp;&nbsp;
                    <span className="text-gray-500">
                      {StatusTag(supportData.status)}
                    </span>
                  </h1>

                  <h1 className="font-medium text-xs sm:text-base">
                    Raised By:&nbsp;&nbsp;
                    <span className="relative group">
                      <span className="group-hover:underline cursor-pointer text-blue-500 -z-50">
                        {supportData.user.user_profile.username}
                      </span>
                      <div className="absolute hidden left-5 bottom-3 bg-pink-100 border border-pink-300 p-2 rounded shadow-md group-hover:block w-52 z-50">
                        <div className="text-black font-bold text-sm">
                          Name:{supportData.user.first_name || ""}
                        </div>
                        <div className="text-black text-sm">
                          Mobile:{supportData.user.mobile_number || ""}
                        </div>
                      </div>
                    </span>
                  </h1>
                  <h1 className="font-medium text-xs sm:text-base">
                    Application No:&nbsp;&nbsp;
                    <span className="text-gray-500">
                      {supportData.business_loan_application
                        ?.application_number ||
                        supportData.personal_loan_application
                          ?.application_number ||
                        supportData.auto_loan_application?.application_number ||
                        "--"}
                    </span>
                  </h1>
                  <h1 className="font-medium text-xs sm:text-base w-full col-span-2">
                    Email:&nbsp;&nbsp;
                    <span className="text-gray-500">
                      {supportData.emails || ""}
                    </span>
                  </h1>

                  <h1 className="font-medium text-xs sm:text-base w-full col-span-2">
                    Subject:&nbsp;&nbsp;
                    <span className="text-gray-500">
                      {supportData.subject || ""}
                    </span>
                  </h1>
                  <h1 className="font-medium text-xs sm:text-base col-span-2">
                    Description:&nbsp;&nbsp;
                    <span className="text-gray-500">
                      {supportData.description || ""}
                    </span>
                  </h1>
                </div>

                <div className="bg-blue-50 rounded-lg w-full mt-2 flex flex-col p-2 gap-2 min-h-[20vh] max-h-[40vh] overflow-y-scroll scrollbar-none mb-0">
                  {GenerateChatElements(supportData.support_response, user.id)}
                </div>
              </>
            )}
            {/* ++++CHAT INTERFACE */}
            {supportData && supportData.status === "RESOLVED" ? (
              <p className="w-full text-center text-red-400">
                This chat is closed
              </p>
            ) : (
              <>
                <div className="mt-2">
                  <div className="flex justify-between gap-1">
                    <input
                      type="text"
                      value={chatResp.msg}
                      className="w-10/12 border rounded-md p-2"
                      placeholder="Enter your querry here ...."
                      onChange={(e) => {
                        setChatResp((prev) => ({
                          ...prev,
                          msg: e.target.value,
                        }));
                      }}
                    />
                    <button
                      onClick={HandleSendChat}
                      className="w-2/12  bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none "
                    >
                      send
                    </button>
                  </div>
                  {chatResp.err && (
                    <p className="w-full text-sm text-red-500 ">
                      {chatResp.err}
                    </p>
                  )}
                </div>
              </>
            )}
          </SupportChatWindow>
        )}
        {isModalOpen && (
          <ModalWindow
            onClose={() => setIsModalOpen(false)}
            title={"Update Status"}
          >
            <form className="w-full flex items-center justify-center flex-col">
              <div className="w-full">
                {renderInput("DROP_DOWN", {
                  label: "Status",
                  name: "status",
                  options: [
                    { label: "Select One", value: "" },
                    { label: "INQUIRY", value: "INQUIRY" },
                    { label: "PENDING", value: "PENDING" },
                    { label: "REPLIED", value: "REPLIED" },
                    { label: "RESOLVED", value: "RESOLVED" },
                  ],
                  onChange: (e) => {
                    setStatuserr("");
                    setStatus(e.target.value);
                  },
                  error: statuserr,
                })}
              </div>
              <button
                className=" bg-logo-blue h-10 mx-auto  w-max text-white px-4 rounded  focus:outline-none focus:ring "
                onClick={HandleSubmit}
              >
                Submit
              </button>
            </form>
          </ModalWindow>
        )}
        <DashboardBanner />
        {tableData && tableData.tableContent.length === 0 ? (
          <div className="flex flex-col gap-2">
            <MissingDataImage message={"No Support Application Found"} />
          </div>
        ) : (
          tableData && (
            <div className="w-full flex flex-col  gap-2 ">
              <SearchBar
                searchParameters={searchParameters}
                SearchClick={SearchClick}
                EmptyCall={EmptyCall}
              />
              <ListTable
                heading="Loan Lead"
                tableData={tableData}
                pagination={Boolean(pagination.next || pagination.previous)}
                next={pagination.next}
                prev={pagination.previous}
                onNextClick={() => GetNextLoanList(pagination.next)}
                onPrevClick={() => GetPrevLoanList(pagination.previous)}
                actions={{
                  view: true,
                  other: {
                    name: "Update Status",
                    onClick: onUpdateStatusClick,
                  },
                }}
                onView={onView}
              />
            </div>
          )
        )}
      </div>
    </>
  );
};

export default SupportList;
