import React, { createContext, useContext, useState } from "react";

const LoadingContext = createContext();

export const useLoadingContext = () => {
  return useContext(LoadingContext);
};
export const LoadingProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(false);
  const showLoader = () => {
    setLoading(true);
  };
  const hideLoader = () => {
    setLoading(false);
  };
  return (
    <LoadingContext.Provider value={{ showLoader, hideLoader }}>
      {isLoading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-white bg-opacity-70 backdrop-filter backdrop-blur-lg z-50 flex justify-center items-center">
          <div className="loader relative">
            <div className="w-12 h-12  bg-logo-orange rounded-full absolute top-0 left-0 animate-ping"></div>
            <div
              className="w-8 h-8  bg-logo-orange rounded-full absolute top-2 left-2 animate-pulse"
              style={{ animationDelay: "-1s" }}
            ></div>
          </div>
        </div>
      )}
      {children}
    </LoadingContext.Provider>
  );
};
