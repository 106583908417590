import React, { useEffect, useState } from "react";
import GenerateURL, { GenerateSearchURL } from "../../../utils/ApiUrlProvider";
import properties from "../../../Properties/Properties";
import { GetData } from "../../../utils/ApiInvoker";
import ListTable from "../../Common/ListTable";
import { GetTimeAgo } from "../../../utils/Healpers";
import DashboardBanner from "../../Common/DashboardBanner";
import MissingDataImage from "../../Common/MissingDataImage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchBar from "../../Common/SearchBar";

const EnquieryList = () => {
  const [tableData, setTableData] = useState(null);
  const [pagination, setPagination] = useState(null);

  const convertDataToTableFormat = (data) => {
    const tableHeaders = ["Name", "Mobile Number", "Email", "Category", "Time"];

    const tableContent = data.map((user) => {
      return {
        Name: user.name || "--",
        // "Process Status": user.is_completed || "--",
        "Mobile Number": user.mobile_no || "--",
        Email: user.email,
        Category: user.category,
        Time: GetTimeAgo(user.created_at),
      };
    });
    return {
      tableHeaders,
      tableContent,
    };
  };

  const HandleSearchSucess = (res) => {
    console.log(res.results);
    if (res.results.length === 0) {
      toast.error("No Data found");
      GetEnquiryList();
    } else {
      HandleSucess(res);
    }
  };
  const searchParameters = [
    { name: "Name", parm: "name" },
    { name: "Mobile Number", parm: "mobile_no" },
    { name: "Email", parm: "email" },
    { name: "Category", parm: "application_category" },
  ];

  const SearchClick = (e) => {
    const url = GenerateSearchURL(e, properties.api.adminEnquiry);
    GetData(url, HandleSearchSucess, HandleError, false, false);
  };
  const EmptyCall = () => {
    GetEnquiryList();
  };

  const HandleSucess = (response) => {
    const data = convertDataToTableFormat(response.results);
    setTableData(data);
    setPagination(response);
  };

  const HandleError = (response) => {
    console.log(response);
  };

  const GetNextEnquiryList = (nexturl) => {
    const url = GenerateURL({}, nexturl);
    GetData(url, HandleSucess, HandleError, false, false);
  };

  const GetPrevEnquiryList = (prevurl) => {
    const url = GenerateURL({}, prevurl);
    GetData(url, HandleSucess, HandleError, false, false);
  };

  const GetEnquiryList = () => {
    const url = GenerateURL({}, properties.api.adminEnquiry);
    GetData(url, HandleSucess, HandleError, false, false);
  };

  useEffect(() => {
    GetEnquiryList();
  }, []);

  return (
    <div className="w-11/12">
      <DashboardBanner />
      {tableData && tableData.tableContent.length === 0 ? (
        <div className="flex flex-col gap-2">
          <MissingDataImage message={" No Enquiry Found"} />
        </div>
      ) : (
        tableData && (
          <div className="w-full flex flex-col  gap-2 ">
            <SearchBar
              searchParameters={searchParameters}
              SearchClick={SearchClick}
              EmptyCall={EmptyCall}
            />
            <>
              <ListTable
                heading="Loan Applications"
                tableData={tableData}
                pagination={Boolean(pagination.next || pagination.previous)}
                next={pagination.next}
                prev={pagination.previous}
                onNextClick={() => GetNextEnquiryList(pagination.next)}
                onPrevClick={() => GetPrevEnquiryList(pagination.previous)}
              />
            </>
          </div>
        )
      )}
    </div>
  );
};

export default EnquieryList;
