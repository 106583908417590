import React, { useState } from "react";
import CalculatorEnquiry from "../Home/CalculatorEnquiry";
import Footer from "../Home/Footer";
import AmortizationTable from "../../Common/AmortizationTable";

const HomeLoan = () => {
  const [currentNav, setCurrentNav] = useState(0);
  const [amortizationTableData, setAmortizationTableData] = useState(null);

  const pageData = {
    titles: [
      "Amortization Schedule",
      "Check Your Eligibility",
      "Benefits For You",
      "Documents Required",
    ],
    1: {
      title: "Check Your Eligibility",
      points: [
        "Minimum age of applicant should be 21 years and Maximum age of applicant should be 65 years or the retirement age.",
        "Regular source of income, whether from employment or business, to ensure repayment capability.",
        "Good credit history and score, indicating responsible financial behavior.",
      ],
    },
    2: {
      title: "Benefits For You",
      points: [
        "Our versatile offerings cater to all your housing needs, ensuring your dream home becomes a tangible reality.",
        "Designed especially for lower-income segments, our ‘Affordable’ home loans aim to empower individuals to own their homes.",
        "We have simple eligibility criteria and minimum documentation to ensure that you get the best home loan without any hassles.",
        "Reducing the burden of high monthly payments and ensuring affordability.",
        "Our Home Loan interest rates are affordable and competitive and hence, they don’t put any extra burden on your finances.",
        "Loan amounts ranging up to 80% Of Property’s Value.",
      ],
    },
    3: {
      title: "Documents Required",
      points: [
        "Identity Proof: KYC of Applicant and Co-Applicant - PAN Card, Aadhaar card, passport size photo.",
        "Residence Proof: Utility bills, rent agreement, or property documents.",
        "Property Document: Property Paper With Complete Chain Map (If Available).",
        "Bank Statements: Statements from the past 12 months showcasing your financial transactions.",
        "3 Year ITR With Computation, With Financial.",
      ],
    },
  };

  const updateAmortizationTableData = (data) => {
    setAmortizationTableData(data);
  };
  return (
    <div className="bg-gray-100 min-h-screen mt-24">
      <div className="container mx-auto mb-10">
        <div>
          <a
            href="/"
            className="text-sm text-logo-blue font-normal  hover:text-logo-orange border-blue-900 mt-5 ml-3"
          >
            Home{" "}
            <span className="text-sm text-logo-blue font-medium  hover:text-logo-blue ">
              /{" "}
            </span>
          </a>
          <a
            href=""
            className="text-sm text-logo-blue font-normal hover:text-logo-orange  mt-5"
          >
            Home Loan
          </a>
        </div>
        <div className="text-logo-blue text-center p-4">
          <h1 className="text-4xl font-bold capitalize sm:text-4xl ">
            Home Loan
          </h1>
          <p className="text-xs text-logo-blue text-center font-normal sm:text-base border-blue-900 md:mt-4">
            Embark on your aspirations with ease – our personal loans at
            UdhariBazaar offer swift financial solutions tailored to your needs,
            ensuring you realize your dreams without compromise.
          </p>
        </div>
        <div className="flex justify-center items-center mt-10">
          <div className="w-full">
            <CalculatorEnquiry
              updateAmortizationTableData={updateAmortizationTableData}
            />
          </div>
        </div>
        <div className="bg-gray-200 rounded-lg w-full mx-auto mt-8 text-center">
          <ul className="flex items-center justify-start flex-wrap">
            {pageData.titles.map((menue, key) => {
              return (
                <li
                  key={key}
                  onClick={() => {
                    setCurrentNav(key);
                  }}
                  className={`cursor-pointer p-4 ${
                    key === currentNav
                      ? "text-orange-400 border-b-2 border-orange-400"
                      : ""
                  }`}
                >
                  {menue}
                </li>
              );
            })}
          </ul>
          <div className="w-full  py-2 bg-white rounded-b-lg">
            {currentNav === 0 && amortizationTableData && (
              <AmortizationTable data={amortizationTableData} />
            )}
            {currentNav !== 0 && (
              <div className="section-content mx-auto bg-white px-4  flex flex-col items-start">
                <h3 className="text-2xl font-bold text-logo-blue">
                  {pageData.titles[currentNav]}
                </h3>

                <ul className="list-disc list-inside mt-4 text-left text-logo-blue">
                  {pageData[currentNav].points.map((point, index) => (
                    <li key={index} className="mb-2">
                      {point}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomeLoan;
