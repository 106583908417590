import properties from "../Properties/Properties";
import { IsAssociatePartner, IsPartnerUser } from "./AccessTokenStore";
import { GetData } from "./ApiInvoker";
import GenerateURL from "./ApiUrlProvider";

export function ConvertBoolean(input) {
  if (typeof input === "boolean") {
    return input;
  } else if (typeof input === "string") {
    const lowerInput = input.toLowerCase();
    if (lowerInput === "yes") {
      return true;
    } else if (lowerInput === "no") {
      return false;
    } else if (lowerInput === "true") {
      return true;
    } else if (lowerInput === "false") {
      return false;
    }
  } else if (typeof input === "number") {
    return input > 0;
  }
  return false;
}

const HandleFileDownlaodSucess = (blob, fileName) => {
  const url = window.URL.createObjectURL(blob);
  // Create a link element
  const a = document.createElement("a");
  a.href = url;
  a.download = `${fileName}`; // Set the desired file name and extension
  document.body.appendChild(a);
  // Trigger the click event on the link to initiate download
  a.click();
  // Cleanup: remove the link and revoke the Blob URL after download
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};
const HandleFileDownlaodError = (err) => {
  console.log(err);
};

export const GlobalFileDownlad = (fileInfo) => {
  const url = GenerateURL(
    { file_unique_id: fileInfo.unique_id },
    properties.api.fileDownload
  );
  GetData(
    url,
    (blob) => HandleFileDownlaodSucess(blob, fileInfo.file_name),
    HandleFileDownlaodError,
    false,
    false
  );
};
export const GlobalFileDownladbyID = (id, name) => {
  const url = GenerateURL({ file_unique_id: id }, properties.api.fileDownload);
  GetData(
    url,
    (blob) => HandleFileDownlaodSucess(blob, name),
    HandleFileDownlaodError,
    false,
    false
  );
};
export const GetTimeAgo = (timestamp) => {
  const currentDate = new Date();
  const pastDate = new Date(timestamp);
  const timeDifference = currentDate - pastDate;

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (years > 0) {
    return `${years} year${years > 1 ? "s" : ""} ago`;
  } else if (months > 0) {
    return `${months} month${months > 1 ? "s" : ""} ago`;
  } else if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
  }
};
export const GetDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const StatusTag = (tag) => {
  switch (tag) {
    case "INITIATED":
      return (
        <span className="inline-flex items-center bg-indigo-100 text-indigo-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
          <span className="w-2 h-2 me-1 bg-indigo-500 rounded-full"></span>
          INITIATED
        </span>
      );

    case "PROCESSING":
      return (
        <span className="inline-flex items-center bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
          <span className="w-2 h-2 me-1 bg-yellow-500 rounded-full"></span>
          PROCESSING
        </span>
      );
    case "APPROVED":
      return (
        <span className="inline-flex items-center bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
          <span className="w-2 h-2 me-1 bg-blue-500 rounded-full"></span>
          APPROVED
        </span>
      );
    case "SANCTIONED":
      return (
        <span className="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
          <span className="w-2 h-2 me-1 bg-green-500 rounded-full"></span>
          SANCTIONED
        </span>
      );
    case "DISBURSED":
      return (
        <span className="inline-flex items-center bg-gray-100 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
          <span className="w-2 h-2 me-1 bg-gray-500 rounded-full"></span>
          DISBURSED
        </span>
      );
    case "REJECTED":
      return (
        <span className="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
          <span className="w-2 h-2 me-1 bg-red-500 rounded-full"></span>
          REJECTED
        </span>
      );
    case "FAILED":
      return (
        <span className="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
          <span className="w-2 h-2 me-1 bg-red-500 rounded-full"></span>
          FAILED
        </span>
      );
    case "SUCCESS":
      return (
        <span className="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
          <span className="w-2 h-2 me-1 bg-green-500 rounded-full"></span>
          SUCCESS
        </span>
      );
    case "RESOLVED":
      return (
        <span className="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
          <span className="w-2 h-2 me-1 bg-green-500 rounded-full"></span>
          RESOLVED
        </span>
      );
    case "REPLIED":
      return (
        <span className="inline-flex items-center bg-indigo-100 text-indigo-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
          <span className="w-2 h-2 me-1 bg-indigo-500 rounded-full"></span>
          REPLIED
        </span>
      );
    case "PENDING":
      return (
        <span className="inline-flex items-center bg-pink-100 text-pink-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
          <span className="w-2 h-2 me-1 bg-pink-500 rounded-full"></span>
          PENDING
        </span>
      );
    case "INQUIRY":
      return (
        <span className="inline-flex items-center bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
          <span className="w-2 h-2 me-1 bg-yellow-500 rounded-full"></span>
          INQUIRY
        </span>
      );
    case "TRUE":
      return (
        <span className="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
          <span className="w-2 h-2 me-1 bg-green-500 rounded-full"></span>
          Yes
        </span>
      );
    case "FALSE":
      return (
        <span className="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
          <span className="w-2 h-2 me-1 bg-red-500 rounded-full"></span>
          No
        </span>
      );
    default:
      return (
        <span className="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
          <span className="w-2 h-2 me-1 bg-red-500 rounded-full"></span>
          {tag}
        </span>
      );
  }
};

export function GetHeadingByid(id) {
  const loanOptions = [
    {
      id: 1000,
      name: "Personal Loan (Salaried)",
    },
    {
      id: 1100,
      name: "Personal Loan (Self Employed)",
    },
    {
      id: 1200,
      name: "Auto Loan (Salaried)",
    },
    {
      id: 1300,
      name: "Auto Loan (Self Employed)",
    },
    {
      id: 1400,
      name: "Home Loan (Salaried)",
    },
    {
      id: 1500,
      name: "Home Loan (Self Employed)",
    },
    {
      id: 1600,
      name: "Business Loan",
    },
    {
      id: 1700,
      name: "Loan Against Property",
    },
    {
      id: 1800,
      name: "Gold Loan",
    },
    {
      id: 1900,
      name: "Other Loan (Query)",
    },
    {
      id: 2000,
      name: "Health Insurance",
    },
    {
      id: 2100,
      name: "Car Insurance",
    },
    {
      id: 2200,
      name: "Bike Insurance",
    },
    {
      id: 2300,
      name: "Term Life Insurance",
    },
    {
      id: 3000,
      name: "Credit Card",
    },
    {
      id: 4000,
      name: "LIC Plans",
    },
    {
      id: 4100,
      name: "Mutual Funds",
    },
  ];

  const foundLoan = loanOptions.find((option) => option.id === parseInt(id));
  return foundLoan ? foundLoan.name : "Application form";
}
export const GetUserTpeByCode = (userCode) => {
  if (userCode.startsWith("UDB-AP")) {
    return "admin";
  } else if (userCode.startsWith("UDB-UP")) {
    return "partner";
  } else if (userCode.startsWith("UDB-UAP")) {
    return "associate partner";
  } else if (userCode.startsWith("UDB-U")) {
    return "user";
  }
};
export const GenerateChatElements = (support_chat, userid) => {
  if (support_chat.length !== 0) {
    return support_chat.map((chat) => {
      const { user_id, response } = chat;
      const isUser = user_id === userid;
      const className = !isUser ? "bg-logo-blue" : "bg-logo-orange";
      return (
        <div
          key={chat.id}
          className={`border relative ${className} bg-opacity-40 p-1 px-2 w-2/3 rounded-md ${
            isUser ? "ml-auto" : ""
          }`}
        >
          {response}
          <p
            className={`text-[10px] w-full text-logo-blue ${
              isUser ? "" : "text-right"
            }`}
          >
            {isUser ? "Me" : "other"}
          </p>
        </div>
      );
    });
  } else {
    return (
      <div
        className={`border bg-logo-blue font-bold bg-opacity-40 p-1 px-2 w-full rounded-md `}
      >
        No chat Happend
      </div>
    );
  }
};
export const checkUserType = (isSuperUser, isPartner) => {
  if (isSuperUser) {
    return "Admin";
  } else if (isPartner) {
    return "Partner";
  } else {
    return "User";
  }
};
export const containerStyle = {
  backgroundColor: "#143c74",
  backgroundImage:
    "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='152' height='152' viewBox='0 0 152 152'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='temple' fill='%23f47c34' fill-opacity='0.24'%3E%3Cpath d='M152 150v2H0v-2h28v-8H8v-20H0v-2h8V80h42v20h20v42H30v8h90v-8H80v-42h20V80h42v40h8V30h-8v40h-42V50H80V8h40V0h2v8h20v20h8V0h2v150zm-2 0v-28h-8v20h-20v8h28zM82 30v18h18V30H82zm20 18h20v20h18V30h-20V10H82v18h20v20zm0 2v18h18V50h-18zm20-22h18V10h-18v18zm-54 92v-18H50v18h18zm-20-18H28V82H10v38h20v20h38v-18H48v-20zm0-2V82H30v18h18zm-20 22H10v18h18v-18zm54 0v18h38v-20h20V82h-18v20h-20v20H82zm18-20H82v18h18v-18zm2-2h18V82h-18v18zm20 40v-18h18v18h-18zM30 0h-2v8H8v20H0v2h8v40h42V50h20V8H30V0zm20 48h18V30H50v18zm18-20H48v20H28v20H10V30h20V10h38v18zM30 50h18v18H30V50zm-2-40H10v18h18V10z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")",
};

export const PartnerOrAssociate = () => {
  const Ispartner = IsPartnerUser();
  const IsAssociate = IsAssociatePartner();
  return Boolean(Ispartner || IsAssociate);
};
export const ReturnLoanType = (lonetype) => {
  if (lonetype === "BL") {
    return "businessloan";
  }
  if (lonetype === "PL") {
    return "personalloan";
  }
  if (lonetype === "AL") {
    return "autoloan";
  }
};
