export const SupportFormValidation = (formData, type) => {
  let formIsValid = true;
  const errors = {};

  // Validate description
  if (formData.description.trim() === "") {
    errors.description = "Description cannot be empty";
    formIsValid = false;
  }

  // Validate subject
  const sub = formData.subject.trim();
  if (sub === "") {
    errors.subject = "Subject cannot be empty";
    formIsValid = false;
  } else if (sub.length > 50) {
    errors.subject = "Maximum 50 characters";
    formIsValid = false;
  }

  // Validate application_number
  if (type === 0) {
    if (
      !formData.personal_loan_application_id ||
      formData.personal_loan_application_id === ""
    ) {
      errors.personal_loan_application_id = "Please select one option";
      formIsValid = false;
    }
  } else if (type === 1) {
    if (
      !formData.business_loan_application_id ||
      formData.business_loan_application_id === ""
    ) {
      errors.business_loan_application_id = "Please select one option";
      formIsValid = false;
    }
  } else if (type === 2) {
    if (
      !formData.auto_loan_application_id ||
      formData.auto_loan_application_id === ""
    ) {
      errors.auto_loan_application_id = "Please select one option";
      formIsValid = false;
    }
  }

  // Validate email
  if (!formData.emails.trim()) {
    errors.emails = "Email is required";
    formIsValid = false;
  } else if (!/\S+@\S+\.\S+/.test(formData.emails.trim())) {
    errors.emails = "Invalid email address format";
    formIsValid = false;
  }

  let isValid = Object.keys(errors).length === 0;
  return { is_valid: isValid, error: errors };
};

// export const SupportFormValidationBusiness = (formData) => {
//   let formIsValid = true;
//   const errors = {};

//   // Validate description
//   if (formData.description.trim() === "") {
//     errors.description = "Description cannot be empty";
//     formIsValid = false;
//   }

//   // Validate subject
//   const sub = formData.subject.trim();
//   if (sub === "") {
//     errors.subject = "Subject cannot be empty";
//     formIsValid = false;
//   } else if (sub.length > 50) {
//     errors.subject = "Maximum 50 characters";
//     formIsValid = false;
//   }

//   // Validate application_number
//   if (
//     !formData.business_loan_application_id ||
//     formData.business_loan_application_id === ""
//   ) {
//     errors.business_loan_application_id = "Please select one option";
//     formIsValid = false;
//   }
//   // Validate email
//   if (!formData.email.trim()) {
//     errors.email = "Email is required";
//     formIsValid = false;
//   } else if (!/\S+@\S+\.\S+/.test(formData.email.trim())) {
//     errors.email = "Invalid email address format";
//     formIsValid = false;
//   }

//   let isValid = Object.keys(errors).length === 0;
//   return { is_valid: isValid, error: errors };
// };
