import React, { useEffect, useState } from "react";
import GenerateURL from "../../../utils/ApiUrlProvider";
import { GetData, PutData } from "../../../utils/ApiInvoker";
import properties from "../../../Properties/Properties";
import { useParams } from "react-router-dom";
import {
  GetTimeAgo,
  GetUserTpeByCode,
  GlobalFileDownlad,
  containerStyle,
} from "../../../utils/Healpers";
import AssignRMModal from "../Modals/AssignRMModal";
import PrintableDocument from "../../Common/PrintableIMetaInformation";
import ListTable from "../../Common/ListTable";

const UserDetailsAdmin = () => {
  const params = useParams();
  const [userData, setUserData] = useState();
  const [bankDetails, setBankDetails] = useState(null);
  const [navtype, setNavType] = useState(0);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isAssignRMModalVisible, setIsAssignRMModalVisible] = useState(false);
  const [associatePartnerDetail, setAssociatepartnerDetaiil] = useState(null);
  const [pagination, setPagination] = useState(null);

  //  change status
  const HandleActiveStatusUpdateSuce = (response) => {
    UserInfo();
    GetBankDetails();
  };
  const HandleActiveStatusUpdateErr = (response) => {
    console.log(response);
  };
  const handleStatusToggle = (isactive) => {
    const requestData = {
      id: params.id,
      is_active: isactive,
      user_id: params.id,
    };
    const statusUpdateUrl = GenerateURL(
      requestData,
      properties.api.adminUpdateUserStatus
    );
    PutData(
      statusUpdateUrl,
      requestData,
      HandleActiveStatusUpdateSuce,
      HandleActiveStatusUpdateErr
    );
  };

  const handleBankDetailsSuccess = (response) => {
    const tableData = {
      tableHeaders: [
        "Account Number",
        "Bank Name",
        "Branch Name",
        "IFSC Code",
        "Account Holder",
        "Account Type",
        "Default",
      ],
      tableContent: response.map((data, key) => {
        return {
          ID: data.id,
          "Account Number": data.account_number,
          "Bank Name": data.bank_name,
          "Branch Name": data.branch_name,
          "IFSC Code": data.ifsc_code,
          "Account Holder": data.account_holder_name,
          "Account Type": data.account_type,
          Default: data.is_default ? "Yes" : "No",
        };
      }),
    };
    setBankDetails(tableData);
  };
  const handleBankDetailsError = (error) => {
    console.log(error);
  };

  const GetBankDetails = () => {
    const url = GenerateURL(
      { id: params.id },
      properties.api.adminGetBankDeatils
    );
    GetData(url, handleBankDetailsSuccess, handleBankDetailsError, false);
  };

  const HandleSuccess = (response) => {
    setUserData(response);
  };
  const HandleError = (response) => {
    console.log(response);
  };
  const UserInfo = () => {
    const url = GenerateURL({ id: params.id }, properties.api.adminSingleUsers);
    GetData(url, HandleSuccess, HandleError, false, false);
  };
  // ASSOCIATE PARTNERS
  const GetNextAssocciatePartner = (nexturl) => {
    const url = GenerateURL({}, nexturl);
    GetData(url, AssociateGetSucess, AssociateGetErr, false, false);
  };
  const GetPrevAssocciatePartner = (prevurl) => {
    const url = GenerateURL({}, prevurl);
    GetData(url, AssociateGetSucess, AssociateGetErr, false, false);
  };
  const AssociateGetSucess = (response) => {
    setPagination(response);
    const res = response.results;
    const tableData = {
      tableHeaders: ["Associate Partner Id", "Name", "Mobile No", "Email"],
      tableContent: res.map((data) => {
        return {
          "Associate Partner Id": data?.user?.user_profile.username,
          id: data?.user.id,
          Name: data?.user.first_name + " " + data?.user.last_name,
          "Mobile No": data?.user.mobile_number,
          mobile_number: data?.user.mobile_number,
          Email: data?.user.email,
          first_name: data?.user.first_name,
          last_name: data?.user.last_name,
        };
      }),
    };
    setAssociatepartnerDetaiil(tableData);
  };
  const AssociateGetErr = (res) => {
    console.log(res);
  };
  const GetAssocciatePartner = () => {
    const url = GenerateURL(
      { id: userData.partner_profile.id },
      properties.api.getallAssociatepartnerofpartner
    );
    GetData(url, AssociateGetSucess, AssociateGetErr, false);
  };

  useEffect(() => {
    UserInfo();
  }, []);

  return (
    <>
      {userData && (
        <div className="w-full flex flex-col ml-5 mt-9 p-4 ">
          <div
            style={containerStyle}
            className="w-full border rounded-t-md p-4 py-10 text-3xl text-left capitalize text-white  "
          >
            <p className="shadow-sm">
              {GetUserTpeByCode(userData.user_profile.username)} Info
            </p>
          </div>
          <div className="flex flex-row flex-wrap items-center w-full gap-2 p-2 border bg-logo-blue bg-opacity-10  rounded-b-md">
            <button
              onClick={() => setNavType(0)}
              className={`px-2 py-1 transition-all ease-in-out duration-200  ${
                navtype === 0
                  ? "text-logo-orange border-b-2 border-logo-orange "
                  : ""
              }`}
            >
              Basic Info
            </button>
            <button
              onClick={() => setNavType(1)}
              className={`px-2 py-1 transition-all ease-in-out duration-200  ${
                navtype === 1
                  ? "text-logo-orange border-b-2 border-logo-orange "
                  : ""
              }`}
            >
              Document Info
            </button>
            <button
              onClick={() => {
                setNavType(2);
                GetBankDetails();
              }}
              className={`px-2 py-1 transition-all ease-in-out duration-200  ${
                navtype === 2
                  ? "text-logo-orange border-b-2 border-logo-orange "
                  : ""
              }`}
            >
              Bank Info
            </button>
            {GetUserTpeByCode(userData.user_profile.username) === "partner" && (
              <button
                onClick={() => {
                  setNavType(3);
                  GetAssocciatePartner();
                }}
                className={`px-2 py-1 transition-all ease-in-out duration-200  ${
                  navtype === 3
                    ? "text-logo-orange border-b-2 border-logo-orange "
                    : ""
                }`}
              >
                Associate Partners
              </button>
            )}

            <div className="md:ml-auto">
              <button
                onClick={() => {
                  // to add bank details in PDF
                  GetBankDetails();
                  setIsPreviewOpen(true);
                }}
                className="ml-auto border px-2 rounded-xl bg-logo-blue text-white"
              >
                Download PDF
              </button>
              {isPreviewOpen && (
                <div className="preview-modal">
                  <div className="preview-content">
                    <PrintableDocument
                      userData={userData}
                      bankDetails={bankDetails}
                      onClose={() => setIsPreviewOpen(false)}
                    />
                  </div>
                </div>
              )}
              {console.log(userData)}
              {(userData.is_partner || userData.is_associated_partner) && (
                <button
                  className="ml-auto border px-2 rounded-xl bg-logo-blue text-white"
                  onClick={() => setIsAssignRMModalVisible(true)}
                >
                  Add RM
                </button>
              )}
              <button
                className={`border px-2 rounded-xl  text-white ${
                  !userData.is_active ? "bg-green-600" : "bg-red-600"
                } `}
                onClick={() => handleStatusToggle(!userData.is_active)}
              >
                {userData.is_active ? "Deactivate" : "Activate"}
              </button>
              {isAssignRMModalVisible && (
                <AssignRMModal
                  onClose={() => setIsAssignRMModalVisible(false)}
                  userData={userData}
                />
              )}
            </div>
          </div>
          {navtype === 0 && (
            <div className="w-full flex flex-col shadow-lg rounded-2xl bg-white my-4 pb-4">
              <h1 className="p-4 text-2xl text-logo-orange">Personal Info</h1>
              <div className="w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 px-4 capitalize">
                <div className="flex flex-col">
                  <p className="font-medium">Name</p>
                  <p className="text-gray-500 text-sm">
                    {userData.first_name} <span>{userData.middle_name}</span>
                    <span>{userData.last_name}</span>
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium">Email</p>
                  <p className="text-gray-500 text-sm overflow-scroll scrollbar-none">
                    {userData.email}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium">Mobile_number</p>
                  <p className="text-gray-500 text-sm">
                    {userData.mobile_number}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium ">User Type</p>
                  <p className="text-gray-500 text-sm uppercase">
                    {GetUserTpeByCode(userData.user_profile.username)}
                  </p>
                </div>
                <div className="flex flex-col">
                  {GetUserTpeByCode(userData.user_profile.username)}
                  Code
                  <p className="font-medium"></p>
                  <p className="text-gray-500 text-sm">
                    {userData.user_profile.username}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium ">Created</p>
                  <p className="text-gray-500 text-sm">
                    {GetTimeAgo(userData.user_profile.created_at)}
                  </p>
                </div>
              </div>
              {(GetUserTpeByCode(userData.user_profile.username) ===
                "partner" ||
                GetUserTpeByCode(userData.user_profile.username) ===
                  "associate partner") &&
                userData.partner_profile && (
                  <>
                    <h1 className="p-4 text-2xl text-logo-orange">
                      Relation Manager Info
                    </h1>
                    <div className="w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 px-4  capitalize">
                      <div className="flex flex-col">
                        <p className="font-medium">Name</p>
                        <p className="text-gray-500 text-sm">
                          {userData.partner_profile.relational_manager?.name ||
                            "--"}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className="font-medium">Email</p>
                        <p className="text-gray-500 text-sm overflow-scroll scrollbar-none">
                          {userData.partner_profile.relational_manager?.email ||
                            "--"}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className="font-medium">Mobile No</p>
                        <p className="text-gray-500 text-sm">
                          {userData.partner_profile.relational_manager
                            ?.mobile_number || "--"}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              {GetUserTpeByCode(userData.user_profile.username) ===
                "associate partner" &&
                userData.partner_profile?.parent_partner?.user && (
                  <>
                    <h1 className="p-4 text-2xl text-logo-orange">
                      Parent Partner Info
                    </h1>
                    <div className="w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 px-4  capitalize">
                      <div className="flex flex-col">
                        <p className="font-medium">Name</p>
                        <p className="text-gray-500 text-sm">
                          {userData.partner_profile?.parent_partner?.user
                            ?.first_name || "--"}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className="font-medium">Email</p>
                        <p className="text-gray-500 text-sm overflow-scroll scrollbar-none">
                          {userData.partner_profile?.parent_partner?.user
                            ?.email || "--"}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className="font-medium">Mobile No</p>
                        <p className="text-gray-500 text-sm">
                          {userData.partner_profile?.parent_partner?.user
                            ?.mobile_number || "--"}
                        </p>
                      </div>
                    </div>
                  </>
                )}
            </div>
          )}
          {navtype == 1 && (
            <div className="w-full flex flex-col shadow-lg rounded-2xl bg-white mt-5">
              <h1 className="p-4 text-2xl text-logo-orange">Docoment Info</h1>
              {userData.partner_profile ? (
                <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-9 p-4 capitalize">
                  <div className="w-full flex flex-col gap-3">
                    <div className="flex flex-row justify-between">
                      <h3 className="font-medium  ">Aadhaar Number</h3>
                      <h3 className="text-gray-500 font-medium">
                        {userData.partner_profile.aadhar_number || "--"}
                      </h3>
                    </div>
                    <div className="flex flex-row justify-between">
                      <h3 className="font-medium  ">Pan Number</h3>
                      <h3 className="text-gray-500 font-medium">
                        {userData.partner_profile.pan_number || "--"}
                      </h3>
                    </div>
                    <div className="flex flex-row justify-between">
                      <h3 className="font-medium  ">GST Number</h3>
                      <h3 className="text-gray-500 font-medium">
                        {userData.partner_profile.gst_number || "--"}
                      </h3>
                    </div>
                  </div>
                  <div className="w-full flex flex-col gap-3">
                    <div className="flex flex-row justify-between">
                      <h3 className="font-medium  ">Aadhaar Card</h3>
                      <h3 className=" text-gray-500 font-semibold">
                        <button
                          disabled={!userData.partner_profile.aadhar_card}
                          onClick={() => {
                            GlobalFileDownlad(
                              userData.partner_profile.aadhar_card
                            );
                          }}
                          className={`${
                            userData.partner_profile.aadhar_card != null
                              ? "bg-green-200"
                              : " bg-slate-300 cursor-not-allowed"
                          } rounded-md shadow-md hover:scale-110 text-sm  p-1 px-2`}
                        >
                          Download
                        </button>
                      </h3>
                    </div>
                    <div className="flex flex-row justify-between">
                      <h3 className="font-medium  ">Pan Card</h3>
                      <h3 className=" text-gray-500 font-semibold">
                        <button
                          disabled={!userData.partner_profile.pan_card}
                          onClick={() => {
                            GlobalFileDownlad(
                              userData.partner_profile.pan_card
                            );
                          }}
                          className={`${
                            userData.partner_profile.pan_card != null
                              ? "bg-green-200"
                              : " bg-slate-300 cursor-not-allowed"
                          } rounded-md shadow-md hover:scale-110 text-sm  p-1 px-2`}
                        >
                          Download
                        </button>
                      </h3>
                    </div>
                    <div className="flex flex-row justify-between">
                      <h3 className="font-medium  ">GST Certificate</h3>
                      <h3 className=" text-gray-500 font-semibold">
                        <button
                          disabled={!userData.partner_profile.gst_certificate}
                          onClick={() => {
                            GlobalFileDownlad(
                              userData.partner_profile.gst_certificate
                            );
                          }}
                          className={`${
                            userData.partner_profile.gst_certificate != null
                              ? "bg-green-200"
                              : " bg-slate-300 cursor-not-allowed"
                          } rounded-md shadow-md hover:scale-110 text-sm  p-1 px-2`}
                        >
                          Download
                        </button>
                      </h3>
                    </div>
                  </div>
                </div>
              ) : (
                <p className="p-4 capitalize"> No document details found</p>
              )}
            </div>
          )}
          {navtype === 2 && (
            <div className="w-full mt-5 gap-5 flex flex-col shadow-lg rounded-2xl">
              {bankDetails && bankDetails.tableContent.length > 0 ? (
                <ListTable
                  tableData={bankDetails}
                  actions={{ edit: false, delete: false, view: false }}
                  pagination={false}
                />
              ) : (
                <h1 className="p-4 text-logo-orange">No Bank details found</h1>
              )}
            </div>
          )}

          {navtype === 3 && (
            <div className="w-full mt-3 flex flex-col  gap-2 ">
              {associatePartnerDetail !== null ? (
                <>
                  <ListTable
                    tableData={associatePartnerDetail}
                    // actions={{ edit: false, delete: false, view: false }}
                    // onEdit={onEditClick}
                    pagination={Boolean(pagination.next || pagination.previous)}
                    next={pagination.next}
                    prev={pagination.previous}
                    onNextClick={() =>
                      GetNextAssocciatePartner(pagination.next)
                    }
                    onPrevClick={() =>
                      GetPrevAssocciatePartner(pagination.previous)
                    }
                  />
                </>
              ) : (
                <h1 className="p-4 text-logo-orange">No Bank details found</h1>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default UserDetailsAdmin;
