import React, { useEffect, useState } from "react";
import {
  GetTimeAgo,
  GetUserTpeByCode,
  GlobalFileDownlad,
  containerStyle,
} from "../../utils/Healpers";
import { GetData, PostData, PutData } from "../../utils/ApiInvoker";
import GenerateURL from "../../utils/ApiUrlProvider";
import properties from "../../Properties/Properties";
import {
  IsAssociatePartner,
  IsPartnerUser,
} from "../../utils/AccessTokenStore";

import UpdateProfile from "./Modal/UpdateProfile";
import ListTable from "./ListTable";
import { renderInput } from "./FormComponents";
import ModalWindow from "./Modal/ModalWindow";
import { ValidateBankForm } from "./FormValidation/UserEditCardval";
import { useNavigate } from "react-router-dom";
import { useLoadingContext } from "../../Context/LoadingContext";

const UserProfile = () => {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useLoadingContext();
  const [userData, setUserData] = useState();
  const [navtype, setNavType] = useState(0);
  const [profileEditModal, setProfileEditModal] = useState(false);
  const [bankDetails, setBankDetails] = useState(null);

  const [bankModal, setBankModal] = useState(false);
  const [editingBankDetails, setEditingBankDetails] = useState(null);

  // add Bank details and edit
  const [formData, setFormData] = useState({
    bank_name: "",
    ifsc_code: "",
    account_number: "",
    branch_name: "",
    account_holder_name: "",
    is_default: true,
    account_type: "",
  });
  const [formErrors, setFormErrors] = useState({
    bank_name: "",
    ifsc_code: "",
    account_number: "",
    is_default: "",
    branch_name: "",
    account_holder_name: "",
    account_type: "",
  });

  const handlechange = (e) => {
    const { name, value } = e.target;
    if (name === "is_default") {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
      setFormErrors({ ...formErrors, [name]: "" });
    }
    if (name === "account_type") {
      setFormData({ ...formData, account_type: value });
    }
  };
  const saveResponseSuccess = (response) => {
    hideLoader();
    setFormData({
      bank_name: "",
      ifsc_code: "",
      account_number: "",
      branch_name: "",
      account_holder_name: "",
      is_default: true,
      account_type: "",
    });
    setBankModal(false);
  };

  const saveResponseFailure = (error) => {
    hideLoader();
    console.error(error);
  };

  const updateBankDetails = () => {
    const valid = ValidateBankForm(formData);
    if (valid.is_valid) {
      showLoader();
      if (editingBankDetails) {
        const updateUrl = GenerateURL(
          { id: editingBankDetails.ID },
          properties.api.updateBankDetails
        );
        PutData(
          updateUrl,
          formData,
          saveResponseSuccess,
          saveResponseFailure,
          false
        );
      } else {
        const addUrl = GenerateURL({}, properties.api.getBankDetails);
        PostData(
          addUrl,
          formData,
          saveResponseSuccess,
          saveResponseFailure,
          false
        );
      }
    } else {
      setFormErrors(valid.error);
    }
  };
  const onEditClick = (e) => {
    setBankModal(true);
    setEditingBankDetails(e);
    setFormData({
      bank_name: e["Bank Name"],
      ifsc_code: e["IFSC Code"],
      account_number: e["Account Number"],
      branch_name: e["Branch Name"],
      account_holder_name: e["Account Holder"],
      is_default: e["Default"],
      account_type: e["Account Type"],
    });
  };

  //bank details
  const handleBankDetailsSuccess = (response) => {
    const tableData = {
      tableHeaders: [
        "Account Number",
        "Bank Name",
        "Branch Name",
        "IFSC Code",
        "Account Holder",
        "Account Type",
        "Default",
      ],
      tableContent: response.map((data, key) => {
        return {
          ID: data.id,
          "Account Number": data.account_number,
          "Bank Name": data.bank_name,
          "Branch Name": data.branch_name,
          "IFSC Code": data.ifsc_code,
          "Account Holder": data.account_holder_name,
          "Account Type": data.account_type,
          Default: data.is_default ? "Yes" : "No",
        };
      }),
    };
    setBankDetails(tableData);
  };
  const handleBankDetailsError = (error) => {
    console.log(error);
  };
  const GetBankDetails = () => {
    const url = GenerateURL({}, properties.api.getBankDetails);
    GetData(url, handleBankDetailsSuccess, handleBankDetailsError, false);
  };

  // user profile
  const HandleSuccess = (response) => {
    setUserData(response);
  };
  const HandleError = (response) => {
    console.log(response);
  };
  const UserInfo = () => {
    const authData = JSON.parse(localStorage.getItem("authData"));
    const url = GenerateURL(
      { id: authData.userDetails.id },
      properties.api.userinfoupdate
    );
    GetData(url, HandleSuccess, HandleError, false, false);
  };
  useEffect(() => {
    UserInfo();
  }, [profileEditModal, bankModal]);
  useEffect(() => {
    GetBankDetails();
  }, [bankModal]);
  return (
    <>
      {userData && (
        <div className="w-full flex flex-col ml-5 mt-9 p-4 ">
          <div
            style={containerStyle}
            className="w-full border rounded-t-md p-4 py-10 text-3xl text-left capitalize text-white  "
          >
            <p className="shadow-sm">Profile Information</p>
          </div>
          <div className="flex flex-row flex-wrap items-center w-full gap-2 p-2 border bg-logo-blue bg-opacity-10  rounded-b-md">
            <button
              onClick={() => setNavType(0)}
              className={`px-2 py-1 transition-all ease-in-out duration-200  ${
                navtype === 0
                  ? "text-logo-orange border-b-2 border-logo-orange "
                  : ""
              }`}
            >
              Basic Info
            </button>
            {(IsPartnerUser() || IsAssociatePartner()) && (
              <button
                onClick={() => setNavType(1)}
                className={`px-2 py-1 transition-all ease-in-out duration-200  ${
                  navtype === 1
                    ? "text-logo-orange border-b-2 border-logo-orange "
                    : ""
                }`}
              >
                Document Info
              </button>
            )}
            <button
              onClick={() => {
                setNavType(2);
                GetBankDetails();
              }}
              className={`px-2 py-1 transition-all ease-in-out duration-200  ${
                navtype === 2
                  ? "text-logo-orange border-b-2 border-logo-orange "
                  : ""
              }`}
            >
              Bank Info
            </button>

            <div className="md:ml-auto">
              {navtype === 0 && (
                <button
                  onClick={() => {
                    setProfileEditModal(true);
                  }}
                  className="ml-auto border px-2 rounded-xl bg-logo-blue text-white"
                >
                  Edit Profile
                </button>
              )}
              {navtype === 1 && (
                <>
                  {(IsPartnerUser() || IsAssociatePartner()) && (
                    <button
                      className="ml-auto border px-2 rounded-xl bg-logo-blue text-white"
                      onClick={() => {
                        navigate("/partner/userdetail/edit");
                      }}
                    >
                      Edit Document
                    </button>
                  )}
                </>
              )}
              {navtype === 2 && (
                <button
                  className="ml-auto border px-2 rounded-xl bg-logo-blue text-white"
                  onClick={() => setBankModal(true)}
                >
                  Add Bank Detail
                </button>
              )}
            </div>
          </div>

          <UpdateProfile
            isModalOpen={profileEditModal}
            setProfileEditModal={setProfileEditModal}
            initialUserData={userData}
          />
          {navtype === 0 && (
            <div className="w-full flex flex-col shadow-lg rounded-2xl bg-white my-4 pb-4">
              <h1 className="px-4 mt-1 text-2xl text-logo-orange">
                Personal Info
              </h1>
              <div className="w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-2 px-4 capitalize">
                <div className="flex flex-col">
                  <p className="font-medium">Name</p>
                  <p className="text-gray-500 text-sm">
                    {userData.first_name} <span>{userData.middle_name}</span>
                    <span>{userData.last_name}</span>
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium">Email</p>
                  <p className="text-gray-500 text-sm overflow-scroll scrollbar-none">
                    {userData.email}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium">Mobile Number</p>
                  <p className="text-gray-500 text-sm">
                    {userData.mobile_number}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium ">User Type</p>
                  <p className="text-gray-500 text-sm uppercase">
                    {GetUserTpeByCode(userData.user_profile.username)}
                  </p>
                </div>
                <div className="flex flex-col">
                  {GetUserTpeByCode(userData.user_profile.username)}
                  Code
                  <p className="font-medium"></p>
                  <p className="text-gray-500 text-sm">
                    {userData.user_profile.username}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium ">Created</p>
                  <p className="text-gray-500 text-sm">
                    {GetTimeAgo(userData.user_profile.created_at)}
                  </p>
                </div>
              </div>
              {/* reletion manager */}
              {(GetUserTpeByCode(userData.user_profile.username) ===
                "partner" ||
                GetUserTpeByCode(userData.user_profile.username) ===
                  "associate partner") &&
                userData.partner_profile && (
                  <>
                    <h1 className="px-4 mt-1 text-2xl text-logo-orange">
                      Relation Manager Info
                    </h1>
                    <div className="w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 px-4  capitalize">
                      <div className="flex flex-col">
                        <p className="font-medium">Name</p>
                        <p className="text-gray-500 text-sm">
                          {userData.partner_profile.relational_manager?.name ||
                            "--"}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className="font-medium">Email</p>
                        <p className="text-gray-500 text-sm overflow-scroll scrollbar-none">
                          {userData.partner_profile.relational_manager?.email ||
                            "--"}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className="font-medium">Mobile No</p>
                        <p className="text-gray-500 text-sm">
                          {userData.partner_profile.relational_manager
                            ?.mobile_number || "--"}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              {/* Parnet partner */}
              {GetUserTpeByCode(userData.user_profile.username) ===
                "associate partner" &&
                userData.partner_profile?.parent_partner?.user && (
                  <>
                    <h1 className="px-4 mt-1 text-2xl text-logo-orange">
                      Parent Partner Info
                    </h1>
                    <div className="w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-2 px-4  capitalize">
                      <div className="flex flex-col">
                        <p className="font-medium">Name</p>
                        <p className="text-gray-500 text-sm">
                          {userData.partner_profile?.parent_partner?.user
                            ?.first_name || "--"}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className="font-medium">Email</p>
                        <p className="text-gray-500 text-sm overflow-scroll scrollbar-none">
                          {userData.partner_profile?.parent_partner?.user
                            ?.email || "--"}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className="font-medium">Mobile No</p>
                        <p className="text-gray-500 text-sm">
                          {userData.partner_profile?.parent_partner?.user
                            ?.mobile_number || "--"}
                        </p>
                      </div>

                      <div className="flex flex-col">
                        <p className="font-medium">Mobile No</p>
                        <p className="text-gray-500 text-sm">
                          {userData.partner_profile?.parent_partner?.user
                            ?.user_profile?.username || "--"}
                        </p>
                      </div>
                    </div>
                  </>
                )}
            </div>
          )}
          {navtype === 1 && (
            <div className="w-full flex flex-col shadow-lg rounded-2xl bg-white mt-5">
              <h1 className="p-4 text-2xl text-logo-orange">Docoment Info</h1>
              {userData.partner_profile ? (
                <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-9 p-4 capitalize">
                  <div className="w-full flex flex-col gap-3">
                    <div className="flex flex-row justify-between">
                      <h3 className="font-medium  ">Aadhaar Number</h3>
                      <h3 className="text-gray-500 font-medium">
                        {userData.partner_profile.aadhar_number || "--"}
                      </h3>
                    </div>
                    <div className="flex flex-row justify-between">
                      <h3 className="font-medium  ">Pan Number</h3>
                      <h3 className="text-gray-500 font-medium">
                        {userData.partner_profile.pan_number || "--"}
                      </h3>
                    </div>
                    <div className="flex flex-row justify-between">
                      <h3 className="font-medium  ">GST Number</h3>
                      <h3 className="text-gray-500 font-medium">
                        {userData.partner_profile.gst_number || "--"}
                      </h3>
                    </div>
                  </div>
                  <div className="w-full flex flex-col gap-3">
                    <div className="flex flex-row justify-between">
                      <h3 className="font-medium  ">Aadhaar Card</h3>
                      <h3 className=" text-gray-500 font-semibold">
                        <button
                          disabled={!userData.partner_profile.aadhar_card}
                          onClick={() => {
                            GlobalFileDownlad(
                              userData.partner_profile.aadhar_card
                            );
                          }}
                          className={`${
                            userData.partner_profile.aadhar_card != null
                              ? "bg-green-200"
                              : " bg-slate-300 cursor-not-allowed"
                          } rounded-md shadow-md hover:scale-110 text-sm  p-1 px-2`}
                        >
                          Download
                        </button>
                      </h3>
                    </div>
                    <div className="flex flex-row justify-between">
                      <h3 className="font-medium  ">Pan Card</h3>
                      <h3 className=" text-gray-500 font-semibold">
                        <button
                          disabled={!userData.partner_profile.pan_card}
                          onClick={() => {
                            GlobalFileDownlad(
                              userData.partner_profile.pan_card
                            );
                          }}
                          className={`${
                            userData.partner_profile.pan_card != null
                              ? "bg-green-200"
                              : " bg-slate-300 cursor-not-allowed"
                          } rounded-md shadow-md hover:scale-110 text-sm  p-1 px-2`}
                        >
                          Download
                        </button>
                      </h3>
                    </div>
                    <div className="flex flex-row justify-between">
                      <h3 className="font-medium  ">GST Certificate</h3>
                      <h3 className=" text-gray-500 font-semibold">
                        <button
                          disabled={!userData.partner_profile.gst_certificate}
                          onClick={() => {
                            GlobalFileDownlad(
                              userData.partner_profile.gst_certificate
                            );
                          }}
                          className={`${
                            userData.partner_profile.gst_certificate != null
                              ? "bg-green-200"
                              : " bg-slate-300 cursor-not-allowed"
                          } rounded-md shadow-md hover:scale-110 text-sm  p-1 px-2`}
                        >
                          Download
                        </button>
                      </h3>
                    </div>
                  </div>
                </div>
              ) : (
                <p className="p-4 capitalize"> No document details found</p>
              )}
            </div>
          )}
          {navtype === 2 && (
            <div className="w-full mt-5 gap-5 flex flex-col shadow-lg rounded-2xl">
              {bankDetails && bankDetails.tableContent.length > 0 ? (
                <ListTable
                  tableData={bankDetails}
                  actions={{ edit: true, delete: false, view: false }}
                  pagination={false}
                  onEdit={onEditClick}
                />
              ) : (
                <h1 className="p-4 text-logo-orange">No Bank details found</h1>
              )}
            </div>
          )}
          {bankModal && (
            <ModalWindow
              onClose={() => setBankModal(false)}
              title="Update Bank Details"
            >
              <div className="mb-4 flex gap-1 sm:gap-2 md:gap-3 w-full">
                <div className="w-full flex flex-col">
                  {renderInput("DROP_DOWN", {
                    label: "Account Type",
                    name: "account_type",
                    value: formData.account_type,
                    onChange: handlechange,
                    options: [
                      { label: "Select The Account Type", value: "" },
                      { label: "Savings", value: "SAVINGS" },
                      { label: "Current", value: "CURRENT" },
                    ],
                    error: formErrors.account_type,
                  })}
                  {renderInput("STRING", {
                    label: "Account Holder Name",
                    name: "account_holder_name",
                    value: formData.account_holder_name,
                    onChange: handlechange,
                    placeholder: "Enter IFSC code",
                    error: formErrors.account_holder_name,
                    tooltip: "Use letters only for the name",
                  })}
                  {renderInput("STRING", {
                    label: "Account Number",
                    name: "account_number",
                    value: formData.account_number,
                    onChange: handlechange,
                    placeholder: "Enter account number",
                    error: formErrors.account_number,
                    tooltip: "Only Numeric Value Only",
                  })}
                  {renderInput("STRING", {
                    label: "Bank Name",
                    name: "bank_name",
                    value: formData.bank_name,
                    onChange: handlechange,
                    placeholder: "Enter bank name",
                    error: formErrors.bank_name,
                    tooltip: "Please Enter Genuine Bank Name",
                  })}
                  {renderInput("STRING", {
                    label: "Branch Name",
                    name: "branch_name",
                    value: formData.branch_name,
                    onChange: handlechange,
                    placeholder: "Enter branch name",
                    error: formErrors.branch_name,
                  })}
                  {renderInput("STRING", {
                    label: "IFSC Code",
                    name: "ifsc_code",
                    value: formData.ifsc_code,
                    onChange: handlechange,
                    placeholder: "Enter IFSC code",
                    error: formErrors.ifsc_code,
                  })}

                  {renderInput("DROP_DOWN", {
                    label: "Primary Account",
                    name: "is_default",
                    value: formData.is_default,
                    onChange: handlechange,
                    options: [
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ],
                    error: formErrors.is_default,
                  })}

                  <div className="flex justify-center items-center">
                    <button
                      className="bg-blue-500 text-white px-1 py-1 md:px-4 md:py-2   rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300  "
                      onClick={updateBankDetails}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </ModalWindow>
          )}
        </div>
      )}
    </>
  );
};

export default UserProfile;
