import React, { useEffect, useState } from "react";
import GenerateURL from "../../utils/ApiUrlProvider";
import properties from "../../Properties/Properties";
import { PostFormData } from "../../utils/ApiInvoker";
import { validateImageInput } from "../../utils/FormValidation";
import lodingImage from "../../assets/loading.png";

export const renderInput = (inputType, props) => {
  switch (inputType) {
    case "STRING":
      return <FormTextInput {...props} />;
    case "INTEGER":
      return <FormIntegerInput {...props} />;
    case "FLOAT_VALUE":
      return <FormIntegerInput {...props} />;
    case "DOB":
      return <FormDateInput {...props} />;
    case "DROP_DOWN":
      return <Dropdown {...props} />;
    case "BOOLEAN":
      return <Boolean {...props} />;
    case "SELECT":
      return <SingleSelect {...props} />;
    case "MULTI_SELECT":
      return <MultipleSelect {...props} />;
    case "YESNO":
      return <YesOrNo {...props} />;
    case "checkbox":
      return <CheckboxInput {...props} />;
    case "FILE_UPLOAD":
      return <FileUploadInput {...props} />;
    case "ADDRESS":
      return <FormAddressInput {...props} />;
    default:
      return null;
  }
};
const FormTextInput = ({ label, name, value, onChange, error, isrequired }) => {
  return (
    <div className="mb-4 flex gap-1 sm:gap-2 md:gap-3">
      <p className="flex items-center justify-end text-black w-1/2 md:w-1/3 text-right text-sm md:text-lg">
        {label}
        {isrequired && <span className="text-base pl-1 text-red-600">*</span>}
      </p>
      <div className="w-1/2 md:w-2/3">
        <input
          type="text"
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          className={`p-2 border uppercase rounded-lg w-full   items-start text-xs  md:text-sm focus:outline-none h-10 focus:border-blue-600  ${
            error ? "border-red-500" : "border-gray-300 "
          }`}
          placeholder={`Enter your ${label}`}
        />
        <div className="w-full">
          {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
        </div>
      </div>
    </div>
  );
};

const FormIntegerInput = ({
  label,
  name,
  value,
  onChange,
  error,
  isrequired,
}) => {
  return (
    <div className="mb-4 flex gap-1 sm:gap-2 md:gap-3 ">
      <p className="flex items-center justify-end text-black w-1/2 md:w-1/3 text-right text-sm md:text-lg">
        {label}
        {isrequired && <span className="text-base pl-1 text-red-600">*</span>}
      </p>
      <div className="w-3/4 md:w-2/3">
        <input
          type="number"
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          className={`p-2 border uppercase rounded-lg w-full   items-start text-xs  md:text-sm focus:outline-none h-10 focus:border-blue-600  ${
            error ? "border-red-500" : "border-gray-300 "
          }`}
          placeholder={`ENTER YOUR ${label.toLowerCase()}`}
        />
        <div className="w-full">
          {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
        </div>
      </div>
    </div>
  );
};

const FormDateInput = ({ label, name, value, onChange, error, isrequired }) => {
  return (
    <div className="mb-4 flex gap-1 sm:gap-2 md:gap-3 ">
      <p className="flex items-center justify-end text-black w-1/2 md:w-1/3 text-right text-sm md:text-lg">
        {label}
        {isrequired && <span className="text-base pl-1 text-red-600">*</span>}
      </p>
      <div className="w-3/4 md:w-2/3">
        <input
          type="date"
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          className={`p-2 border uppercase rounded-lg w-full   items-start text-xs  md:text-sm focus:outline-none h-10 focus:border-blue-600  ${
            error ? "border-red-500" : "border-gray-300 "
          }`}
          placeholder={`Enter your ${label.toLowerCase()}`}
        />
        <div className="w-full">
          {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
        </div>
      </div>
    </div>
  );
};

const SingleSelect = ({
  label,
  name,
  value,
  onChange,
  options,
  error,
  isrequired,
}) => {
  return (
    <div>
      <select
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        className="hidden"
      >
        {options.map((option, index) => (
          <option value={option.value} key={index}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="mb-4 flex gap-1 sm:gap-2 md:gap-3  items-centre">
        <p className="flex items-center justify-end text-black w-1/2 md:w-1/3  text-right text-lg">
          {label}
          {isrequired && <span className="text-base pl-1 text-red-600">*</span>}
        </p>
        <div className="w-3/4 md:w-2/3 ">
          <div className=" w-full grid grid-cols-1 md:grid-cols-2 gap-1 items-center">
            {options.map((option, index) => (
              <div
                className={`border uppercase flex items-center rounded-md h-10 px-1 text-sm sm:text-base  ${
                  value === option.value
                    ? "bg-blue-100 border-blue-200 font-semibold  text-gray-700 "
                    : "font-medium  text-gray-500 "
                }`}
                key={index}
                onClick={() =>
                  onChange({ target: { name, value: option.value } })
                }
              >
                <div className="w-full h-full flex  flex-row  items-center gap-2">
                  <div
                    className={`rounded-full h-3 w-3 border  ${
                      value === option.value
                        ? " bg-slate-400 border-blue-200 outline outline-slate-400 "
                        : " "
                    }`}
                  ></div>
                  {option.label}
                </div>
              </div>
            ))}
          </div>
          <div className="w-full">
            {error && (
              <p className="text-red-500 text-sm mt-1">
                Please select an option
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const Boolean = ({ label, name, value, onChange, error, isrequired }) => {
  const options = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ];
  return (
    <div>
      <select
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        className="hidden"
      >
        {options.map((option, index) => (
          <option value={option.value} key={index}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="mb-4 flex gap-1 sm:gap-2 md:gap-3  items-centre">
        <p className="flex items-center justify-end text-black w-1/2 md:w-1/3  text-right text-lg">
          {label}
          {isrequired && <span className="text-base pl-1 text-red-600">*</span>}
        </p>
        <div className="w-3/4 md:w-2/3 ">
          <div className=" w-full grid grid-cols-1 md:grid-cols-2 gap-1 items-center">
            {options.map((option, index) => (
              <div
                className={`border flex items-center rounded-md h-10 px-1 text-sm sm:text-base  ${
                  value === option.value
                    ? "bg-blue-100 border-blue-200 font-semibold  text-gray-700 "
                    : "font-medium  text-gray-500 "
                }`}
                key={index}
                onClick={() =>
                  onChange({ target: { name, value: option.value } })
                }
              >
                <div className="w-full h-full flex flex-row  items-center gap-2">
                  <div
                    className={`rounded-full h-3 w-3 uppercase border ${
                      value === option.value
                        ? " bg-slate-400 border-blue-200 outline outline-slate-400 "
                        : " "
                    }`}
                  ></div>
                  {option.label}
                </div>
              </div>
            ))}
          </div>
          <div className="w-full">
            {error && (
              <p className="text-red-500 text-sm mt-1">
                Please select an option
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const MultipleSelect = ({
  label,
  name,
  value,
  onChange,
  options,
  isrequired,
}) => {
  const handleSelection = (selectedValues) => {
    onChange({ target: { name, value: selectedValues } });
  };
  return (
    <div className="px-2">
      <select
        name={name}
        id={name}
        value={value}
        onChange={(e) => {
          const selectedOptions = Array.from(
            e.target.selectedOptions,
            (option) => option.value
          );
          handleSelection(selectedOptions);
        }}
        className="hidden"
        multiple
      >
        {options.map((option, index) => (
          <option value={option.value} key={index}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="mb-4 flex gap-1 sm:gap-2 md:gap-3 border-red-600 ">
        <p className="flex items-center justify-end text-black w-1/2 md:w-1/3 text-right text-sm md:text-lg">
          {label}
          {isrequired && <span className="text-base pl-1 text-red-600">*</span>}
        </p>
        <div className="grid grid-cols-2 gap-4 w-96 ">
          {options.map((option, index) => (
            <div
              className={`border p-4  uppercase flex items-center rounded-md h-12 text-lg ${
                value.includes(option.value)
                  ? "bg-blue-100 border-blue-200 font-semibold"
                  : ""
              }`}
              key={index}
              onClick={() => {
                const selectedValues = value.includes(option.value)
                  ? value.filter((val) => val !== option.value)
                  : [...value, option.value];
                handleSelection(selectedValues);
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Dropdown = ({
  label,
  name,
  value,
  onChange,
  options,
  error,
  isrequired,
}) => {
  return (
    <div className="mb-4 flex gap-1 sm:gap-2 md:gap-3">
      <p className="flex items-center justify-end text-black w-1/2 md:w-1/3 text-right text-sm md:text-lg">
        {label}
        {isrequired && <span className="text-base pl-1 text-red-600">*</span>}
      </p>
      <div className="w-3/4 md:w-2/3">
        <select
          name={name}
          id={name}
          value={value}
          onChange={onChange}
          className={`p-2 border rounded-lg w-full items-start text-xs md:text-sm focus:outline-none h-10 focus:border-blue-600 ${
            error ? "border-red-500" : "border-gray-300"
          }`}
        >
          {options.map((option, index) => (
            <option value={option.value} key={index}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="w-full">
          {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
        </div>
      </div>
    </div>
  );
};

const FormAddressInput = ({
  label,
  name,
  value,
  onChange,
  error,
  isrequired,
  ischanged,
  isCurrent,
}) => {
  const [address, setAddress] = useState({
    house_address: value.house_address || "",
    city: value.city || "",
    state: value.state || "",
    pin_code: value.pin_code || "",
    year_at_present_address: value.year_at_present_address || "",
    landmark: value.landmark || "",
  });
  const [formErr, setFormErr] = useState(error);
  useEffect(() => {
    setAddress({
      house_address: value.house_address || "",
      city: value.city || "",
      state: value.state || "",
      pin_code: value.pin_code || "",
      year_at_present_address: value.year_at_present_address || "",
      landmark: value.landmark || "",
    });
  }, [ischanged]);

  useEffect(() => {
    setFormErr(error);
  }, [error]);

  useEffect(() => {
    onChange({
      target: { name, value: address },
    });
  }, [address]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormErr((prev) => ({ ...prev, [name]: "" }));
    setAddress((prev) => ({ ...prev, [name]: value }));
  };

  const stateList = [
    { value: "", label: "Select one" },
    { value: "ANDHRA PRADESH", label: "Andhra Pradesh" },
    { value: "ARUNACHAL PRADESH", label: "Arunachal Pradesh" },
    { value: "ASSAM", label: "Assam" },
    { value: "BIHAR", label: "Bihar" },
    { value: "CHHATTISGARH", label: "Chhattisgarh" },
    { value: "GOA", label: "Goa" },
    { value: "GUJARAT", label: "Gujarat" },
    { value: "HARYANA", label: "Haryana" },
    { value: "HIMACHAL PRADESH", label: "Himachal Pradesh" },
    { value: "JHARKHAND", label: "Jharkhand" },
    { value: "KARNATAKA", label: "Karnataka" },
    { value: "KERALA", label: "Kerala" },
    { value: "MADHYA PRADESH", label: "Madhya Pradesh" },
    { value: "MAHARASHTRA", label: "Maharashtra" },
    { value: "MANIPUR", label: "Manipur" },
    { value: "MEGHALAYA", label: "Meghalaya" },
    { value: "MIZORAM", label: "Mizoram" },
    { value: "NAGALAND", label: "Nagaland" },
    { value: "ODISHA", label: "Odisha" },
    { value: "PUNJAB", label: "Punjab" },
    { value: "RAJASTHAN", label: "Rajasthan" },
    { value: "SIKKIM", label: "Sikkim" },
    { value: "TAMIL NADU", label: "Tamil Nadu" },
    { value: "TELANGANA", label: "Telangana" },
    { value: "TRIPURA", label: "Tripura" },
    { value: "UTTAR PRADESH", label: "Uttar Pradesh" },
    { value: "UTTARAKHAND", label: "Uttarakhand" },
    { value: "WEST BENGAL", label: "West Bengal" },
    {
      value: "ANDAMAN AND NICOBAR ISLANDS",
      label: "Andaman and Nicobar Islands",
    },
    { value: "CHANDIGARH", label: "Chandigarh" },
    {
      value: "DADRA AND NAGAR HAVELI AND DAMAN AND DIU",
      label: "Dadra and Nagar Haveli and Daman and Diu",
    },
    { value: "DELHI", label: "Delhi" },
    { value: "JAMMU AND KASHMIR", label: "Jammu and Kashmir" },
    { value: "LADAKH", label: "Ladakh" },
    { value: "LAKSHADWEEP", label: "Lakshadweep" },
    { value: "PUDUCHERRY", label: "Puducherry" },
  ];

  return (
    <>
      <div className="mb-4 flex gap-1 sm:gap-2 md:gap-3">
        <p className="flex items-center justify-end text-black w-1/2 md:w-1/3 text-right text-lg">
          Address
          {isrequired && <span className="text-base pl-1 text-red-600">*</span>}
        </p>
        <div className="w-1/2 md:w-2/3">
          <input
            type="text"
            name="house_address"
            value={address.house_address}
            onChange={handleChange}
            className={`p-2 border rounded-lg w-full uppercase items-start text-xs md:text-sm focus:outline-none h-10 focus:border-blue-600 ${
              formErr.house_address ? "border-red-500" : "border-gray-300"
            }`}
            placeholder="Address Line 1"
          />
          {formErr.house_address && (
            <p className="text-red-500 text-sm mt-1">{formErr.house_address}</p>
          )}
        </div>
      </div>

      <div className="mb-4 flex gap-1 sm:gap-2 md:gap-3">
        <p className="flex items-center justify-end text-black w-1/2 md:w-1/3 text-right text-lg">
          City
          {isrequired && <span className="text-base pl-1 text-red-600">*</span>}
        </p>
        <div className="w-1/2 md:w-2/3">
          <input
            type="text"
            name="city"
            value={address.city}
            onChange={handleChange}
            className={`p-2 border rounded-lg w-full uppercase items-start text-xs md:text-sm focus:outline-none h-10 focus:border-blue-600 ${
              formErr.city ? "border-red-500" : "border-gray-300"
            }`}
            placeholder="Enter your city"
          />
          {formErr.city && (
            <p className="text-red-500 text-sm mt-1">{formErr.city}</p>
          )}
        </div>
      </div>

      <div className="mb-4 flex gap-1 sm:gap-2 md:gap-3">
        <p className="flex items-center justify-end text-black w-1/2 md:w-1/3 text-right text-lg">
          State
          {isrequired && <span className="text-base pl-1 text-red-600">*</span>}
        </p>
        <div className="w-1/2 md:w-2/3">
          <select
            name="state"
            value={address.state}
            onChange={handleChange}
            className={`p-2 border rounded-lg w-full items-start text-xs md:text-sm focus:outline-none h-10 focus:border-blue-600 ${
              formErr.state ? "border-red-500" : "border-gray-300"
            }`}
          >
            {stateList.map((option, index) => (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            ))}
          </select>
          {formErr.state && (
            <p className="text-red-500 text-sm mt-1">{formErr.state}</p>
          )}
        </div>
      </div>

      <div className="mb-4 flex gap-1 sm:gap-2 md:gap-3">
        <p className="flex items-center justify-end text-black w-1/2 md:w-1/3 text-right text-lg">
          PIN code
          {isrequired && <span className="text-base pl-1 text-red-600">*</span>}
        </p>
        <div className="w-1/2 md:w-2/3">
          <input
            type="number"
            name="pin_code"
            value={address.pin_code}
            onChange={handleChange}
            className={`p-2 border rounded-lg w-full uppercase text-gray-600 font-medium items-start text-xs md:text-sm focus:outline-none h-10 focus:border-blue-600 ${
              formErr.pin_code ? "border-red-500" : "border-gray-300"
            }`}
            placeholder="Enter your pin code"
          />
          {formErr.pin_code && (
            <p className="text-red-500 text-sm mt-1">{formErr.pin_code}</p>
          )}
        </div>
      </div>

      {isCurrent && (
        <div className="mb-4 flex gap-1 sm:gap-2 md:gap-3">
          <p className="flex items-center justify-end text-black w-1/2 md:w-1/3 text-right text-lg">
            Time at Current Residence (months)
          </p>
          <div className="w-1/2 md:w-2/3">
            <input
              type="number"
              name="year_at_present_address"
              value={address.year_at_present_address}
              onChange={handleChange}
              className={`p-2 border rounded-lg w-full uppercase items-start text-xs md:text-sm focus:outline-none h-10 focus:border-blue-600 ${
                formErr.year_at_present_address
                  ? "border-red-500"
                  : "border-gray-300"
              }`}
              placeholder="Enter years at present address"
            />
            {formErr.year_at_present_address && (
              <p className="text-red-500 text-sm mt-1">
                {formErr.year_at_present_address}
              </p>
            )}
          </div>
        </div>
      )}

      <div className="mb-4 flex gap-1 sm:gap-2 md:gap-3">
        <p className="flex items-center justify-end text-black w-1/2 md:w-1/3 text-right text-lg">
          Landmark
        </p>
        <div className="w-1/2 md:w-2/3">
          <input
            type="text"
            name="landmark"
            value={address.landmark}
            onChange={handleChange}
            className={`p-2 border rounded-lg w-full uppercase items-start text-xs md:text-sm focus:outline-none h-10 focus:border-blue-600 ${
              formErr.landmark ? "border-red-500" : "border-gray-300"
            }`}
            placeholder="Enter landmark"
          />
          {formErr.landmark && (
            <p className="text-red-500 text-sm mt-1">{formErr.landmark}</p>
          )}
        </div>
      </div>
    </>
  );
};

export default FormAddressInput;

const YesOrNo = ({ label, value, onChange, isrequired }) => {
  const [isYes, setIsYes] = useState(false);

  const options = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  return (
    <div>
      <div className="mb-4 flex gap-1 sm:gap-2 md:gap-3  items-centre">
        <p className="flex items-center justify-end text-black w-1/2 md:w-1/3  text-right text-lg">
          {label}
          {isrequired && <span className="text-base pl-1 text-red-600">*</span>}
        </p>
        <div className="w-3/4 md:w-2/3 ">
          <div className=" w-full grid grid-cols-1 md:grid-cols-2 gap-1 items-center">
            {options.map((option, index) => (
              <div
                className={`border flex items-center rounded-md h-10 px-1 text-sm sm:text-base  ${
                  isYes === option.value
                    ? "bg-blue-100 border-blue-200 font-semibold  text-gray-700 "
                    : "font-medium  text-gray-500 "
                }`}
                key={index}
                onClick={() => {
                  setIsYes(option.value);
                  onChange(option.value);
                }}
              >
                <div className="w-full h-full flex flex-row  items-center gap-2">
                  <div
                    className={`rounded-full h-3 w-3 uppercase border ${
                      isYes === option.value
                        ? " bg-slate-400 border-blue-200 outline outline-slate-400 "
                        : " "
                    }`}
                  ></div>
                  {option.label}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// to build normal form

export const TextInput = ({ label, type, name, value, onChange, error }) => {
  return (
    <div className="mb-4">
      <label htmlFor={name} className="block text-gray-600">
        {label}
      </label>
      <input
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        className={`w-full p-2 border rounded-lg ${
          error ? "border-red-500" : "border-gray-300 focus:border-blue-500"
        }`}
        placeholder={`Enter your ${label.toLowerCase()}`}
      />
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};

export const IntegerInput = ({ label, name, value, onChange, error }) => {
  return (
    <div className="mb-4">
      <label htmlFor={name} className="block text-gray-600">
        {label}
      </label>
      <input
        type="number"
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        className={`w-full p-2 border rounded-lg ${
          error ? "border-red-500" : "border-gray-300 focus:border-blue-500"
        }`}
        placeholder={`Enter your ${label.toLowerCase()}`}
      />
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};

export const SelectInput = ({ label, name, value, onChange, options }) => {
  return (
    <div className="mb-4">
      <label htmlFor={name} className="block text-gray-600">
        {label}
      </label>
      <select
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        className="w-full p-2 border rounded-lg border-gray-300 focus:border-blue-500"
      >
        {console.log(options)}
        {options.map((option, key) => (
          <option value={option.value} key={key}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export const CheckboxInput = ({ label, name, checked, onChange }) => {
  return (
    <div className="mb-4 flex items-center justify-start gap-2">
      <input
        type="checkbox"
        id={name}
        name={name}
        checked={checked}
        onChange={onChange}
        className="w-4 h-4 border border-gray-300 rounded-md"
      />
      <label htmlFor={name} className="text-gray-600">
        {label}
      </label>
    </div>
  );
};
export const InputTypeRendering = (props) => {
  return (
    <div className="w-full flex flex-row items-center justify-center gap-1 sm:gap-2 md:gap-3">
      <div className="w-1/2 md:w-1/3  "></div>
      <div className="w-3/4 md:w-2/3 flex justify-start items-center gap-4">
        {props.children}
      </div>
    </div>
  );
};

const FileUploadInput = ({
  label,
  code,
  name,
  onChange,
  validations,
  isrequired,
  value,
  onlyId,
  error,
}) => {
  const [isFile, setIsfile] = useState(value); //file is already  uploaded or not
  const [fileError, setFileError] = useState(error);
  const [loading, setLoading] = useState(false); // set loading icon
  const [uploaded, setUploaded] = useState(value != null && value !== "");
  useEffect(() => {
    setFileError(error);
  }, [error]);
  const HandleSucessUpload = (res) => {
    setLoading(false);
    setUploaded(true);
    if (onlyId) {
      const value = res.id;
      onChange({ target: { name, value: value } });
    } else {
      const value = { file_path: res.file_path, id: res.id };
      onChange({ target: { name, value: value } });
    }
  };
  const HandleErrorUpload = (res) => {
    setFileError("Error In file Uploading");
  };

  const handleChange = (event) => {
    setFileError(false);
    setUploaded(false);
    setIsfile(null);
    const isValid = validateImageInput(event.target.files[0], validations);
    if (!isValid.isValidated) {
      setFileError(isValid.errorMessages);
      event.target.value = "";
    } else {
      setLoading(true);
      const url = GenerateURL({}, properties.api.fileUpload);
      const formdata = new FormData();
      formdata.append("file_path", event.target.files[0]);
      formdata.append("file_type_code", code);
      PostFormData(url, formdata, HandleSucessUpload, HandleErrorUpload, false);
    }
  };

  return (
    <div className="mb-6 flex gap-1 sm:gap-2 md:gap-3 ">
      <p className="flex items-center justify-end text-black w-1/2 md:w-1/3 text-right text-sm md:text-lg">
        {label}
        {isrequired && <span className="text-base pl-1 text-red-600">*</span>}
      </p>
      <div className="w-1/2 md:w-2/3 flex flex-col justify-center items-start">
        {isFile !== "" && isFile !== null && isFile !== undefined ? (
          <label
            htmlFor={name}
            className="text-xs pl-1 text-indigo-600 capitalize"
          >
            file is already uploaded
          </label>
        ) : (
          <label
            htmlFor={name}
            className="text-xs pl-1 text-slate-500 capitalize"
          >
            <div className="w-full">
              <p>
                accepts
                {validations &&
                  validations.file_type.map((data, key) => {
                    return <span key={key}> {data}</span>;
                  })}
              </p>
            </div>
          </label>
        )}

        <div
          className={`${
            value !== "" && isFile !== null && isFile !== undefined
              ? "opacity-20"
              : " "
          } flex flex-row items-center justify-start gap-2 `}
        >
          <input
            type="file"
            id={name}
            name={name}
            onChange={handleChange}
            className={`p-2 border rounded-lg w-full   items-start text-xs  md:text-sm focus:outline-none h-10 focus:border-blue-600  ${
              fileError ? "border-red-500" : "border-gray-300 "
            }`}
          />
          {loading && (
            <img className="h-8 animate-spin" src={lodingImage} alt="loadin" />
          )}
          {uploaded && (
            <img
              className="h-8"
              src="https://cdn-icons-png.flaticon.com/128/4436/4436481.png"
              alt="done"
            />
          )}
        </div>

        {fileError && (
          <label
            htmlFor={name}
            className="text-xs pl-1 text-slate-500 capitalize   "
          >
            <div className="w-full">
              <p className="text-red-500 text-sm mt-1 ">{fileError}</p>
            </div>
          </label>
        )}
      </div>
    </div>
  );
};
