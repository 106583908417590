export const BasicInfoStageAL1val = (data) => {
  const errors = {};
  const isValidName = (name) => /^[A-Za-z ]{2,}$/.test(name);
  const isValidMobileNumber = (number) => /^\d{10}$/.test(number);
  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isValidDate = (date) =>
    /^\d{4}-\d{2}-\d{2}$/.test(date) && !isNaN(new Date(date).getTime());
  const isValidPAN = (pan) => /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/.test(pan);

  const nameFields = ["first_name", "last_name", "father_name", "mother_name"];
  nameFields.forEach((field) => {
    if (!data[field]) {
      errors[field] = `${field.replace("_", " ")} is required`;
    } else if (!isValidName(data[field])) {
      errors[field] = `${field.replace("_", " ")} should contain only letters`;
    }
  });

  if (!data.mobile_number) {
    errors.mobile_number = "Mobile number is required";
  } else if (!isValidMobileNumber(data.mobile_number)) {
    errors.mobile_number = "Mobile number should be a 10-digit number";
  }

  if (!data.email) {
    errors.email = "Email address is required";
  } else if (!isValidEmail(data.email)) {
    errors.email = "Invalid email address";
  }

  if (!data.dob) {
    errors.dob = "Date of birth is required";
  } else if (!isValidDate(data.dob)) {
    errors.dob = "Date of birth should be in YYYY-MM-DD format";
  }

  if (!data.pan_number) {
    errors.pan_number = "PAN number is required";
  } else if (!isValidPAN(data.pan_number)) {
    errors.pan_number = "PAN number should be in the format AAAAA9999A";
  }

  if (!data.gender) {
    errors.gender = "Gender is required";
  }

  if (Object.keys(errors).length === 0) {
    return { valid: true, errors: {} };
  } else {
    return { valid: false, errors: errors };
  }
};

export const BasicInfoStageAL2val = (data) => {
  const initializeErrors = () => ({
    house_address: "",
    city: "",
    state: "",
    pin_code: "",
    year_at_present_address: "",
    landmark: "",
  });
  const errors = {
    personal_address: initializeErrors(),
    current_address: initializeErrors(),
  };

  const addressFields = ["house_address", "city", "state", "pin_code"];
  let isValid = true;
  const isValidPinCode = (pin_code) => /^\d{6}$/.test(pin_code.trim());
  const isValidYear = (year) => /^\d{1,2}$/.test(year.trim());

  const validateAddressFields = (address, addressErrors) => {
    addressFields.forEach((field) => {
      if (!address[field]) {
        addressErrors[field] = `${field.replace("_", " ")} is required`;
        isValid = false;
      }
    });
    if (address.pin_code && !isValidPinCode(address.pin_code)) {
      addressErrors.pin_code = "Invalid pin code";
      isValid = false;
    }
    if (
      address.year_at_present_address &&
      !isValidYear(address.year_at_present_address)
    ) {
      addressErrors.year_at_present_address = "Invalid year";
      isValid = false;
    }
  };

  validateAddressFields(data.personal_address, errors.personal_address);
  validateAddressFields(data.current_address, errors.current_address);

  const hasErrors = (errors) =>
    Object.values(errors).some((error) => error !== "");

  const overallValid =
    !hasErrors(errors.personal_address) && !hasErrors(errors.current_address);

  return {
    valid: overallValid,
    errors: overallValid ? {} : errors,
  };
};

export const BasicInfoStageAl3val = (data) => {
  const errors = {};
  if (!data.is_salaried) {
    errors.is_salaried = "this  is required";
  }

  const numberRegex = /^\d*\.?\d+$/;

  if (!numberRegex.test(data.loan_amount)) {
    errors.loan_amount = "Loan amount should be a number";
  }
  if (!numberRegex.test(data.loan_tenure)) {
    errors.loan_tenure = "Loan tenure should be a number";
  }
  if (!data.vehicle_brand) {
    errors.vehicle_brand = "this  is required";
  }
  if (!data.vehicle_model) {
    errors.vehicle_model = "this  is required";
  }
  if (!numberRegex.test(data.vehicle_price)) {
    errors.vehicle_price = "vehicle price be a number";
  }
  if (Object.keys(errors).length === 0) {
    return { valid: true, errors: {} };
  } else {
    return { valid: false, errors };
  }
};

export const validateCompanyInfo = (data) => {
  const errors = {};
  const isValidSalary = (salary) => /^\d+(\.\d+)?$/.test(salary);
  const isValidWorkingDuration = (duration) => /^\d+(\.\d+)?$/.test(duration);
  const isValidPinCode = (pin) => /^\d{6}$/.test(String(pin));

  if (!data.company_name) {
    errors.company_name = "Company name is required";
  }

  if (!data.salary || !isValidSalary(data.salary)) {
    errors.salary = "Salary should be a valid number";
  }

  if (
    !data.working_duration ||
    !isValidWorkingDuration(data.working_duration)
  ) {
    errors.working_duration = "Working duration should be a valid number";
  }

  const officeAddressErrors = {};
  if (!data.office_address || typeof data.office_address !== "object") {
    errors.office_address =
      "Office address is required and should be an object";
  } else {
    if (!data.office_address.house_address) {
      officeAddressErrors.house_address = "House address is required";
    }

    if (!data.office_address.city) {
      officeAddressErrors.city = "City is required";
    }

    if (!data.office_address.state) {
      officeAddressErrors.state = "State is required";
    }

    if (
      !data.office_address.pin_code ||
      !isValidPinCode(data.office_address.pin_code)
    ) {
      officeAddressErrors.pin_code = "Pin code should be a 6-digit number";
    }

    if (
      data.office_address.year_at_present_address &&
      !isValidWorkingDuration(data.office_address.year_at_present_address)
    ) {
      officeAddressErrors.year_at_present_address =
        "Year at present address should be a valid number";
    }
  }

  if (
    Object.keys(errors).length === 0 &&
    Object.keys(officeAddressErrors).length === 0
  ) {
    return { valid: true, errors: {} };
  } else {
    return {
      valid: false,
      errors: { ...errors, office_address: officeAddressErrors },
    };
  }
};

export const validateReferralData = (data) => {
  const errors = {};
  const isValidName = (name) => /^[A-Za-z ]{2,}$/.test(name);
  const isValidMobileNumber = (number) => /^\d{10}$/.test(String(number));
  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  // Validate name
  if (!data.name) {
    errors.name = "Name is required";
  } else if (!isValidName(data.name)) {
    errors.name = "Name should contain only letters";
  }

  // Validate email
  if (data.email && !isValidEmail(data.email)) {
    errors.email = "Invalid email address";
  }

  // Validate mobile_no
  if (!data.mobile_no) {
    errors.mobile_no = "Mobile number is required";
  } else if (!isValidMobileNumber(data.mobile_no)) {
    errors.mobile_no = "Mobile number should be a 10-digit number";
  }

  // Validate relationship
  if (!data.relationship) {
    errors.relationship = "Relationship is required";
  }

  // const addressErrors = {};
  // const addressFields = ["house_address", "city", "state", "pin_code"];

  // // Validate required fields
  // addressFields.forEach((field) => {
  //   if (!data.reffery_address || !data.reffery_address[field]) {
  //     addressErrors[field] = `${field.replace("_", " ")} is required`;
  //   }
  // });

  // // Validate pin_code specifically for 6 digits
  // const pinCode = data.reffery_address && data.reffery_address.pin_code;
  // if (pinCode && !/^\d{6}$/.test(pinCode)) {
  //   addressErrors.pin_code = "PIN code must be exactly 6 digits";
  // }

  // // Combine address errors with the pin code validation
  // if (Object.keys(addressErrors).length > 0) {
  //   errors.reffery_address = addressErrors;
  // }

  // Check if any errors occurred
  if (Object.keys(errors).length === 0) {
    return { valid: true, errors: {} };
  } else {
    return { valid: false, errors };
  }
};

export const validateBusiess1Info = (data) => {
  const errors = {};
  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isValidGST = (gst) =>
    /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}Z[0-9A-Za-z]{1}$/.test(
      gst
    );
  const isValidUdyam = (udyam) => /^UDYAM-[A-Z]{2}-\d{2}-\d{7}$/.test(udyam);
  const isValidNumber = (number) => /^\d+$/.test(number);

  // Validate firm_name
  // if (!data.firm_name) {
  //   errors.firm_name = "Firm name is required";
  // }

  // Validate trade_name
  // if (!data.trade_name) {
  //   errors.trade_name = "Trade name is required";
  // }

  // Validate gst_no
  if (data.gst_no && !isValidGST(data.gst_no.toUpperCase())) {
    errors.gst_no = "Invalid GST number format";
  }

  // Validate udyam_no
  if (data.udyam_no && !isValidUdyam(data.udyam_no.toUpperCase())) {
    errors.udyam_no = "Invalid Udyam number format";
  }

  // Validate official_email
  if (!data.official_email) {
    errors.official_email = "Official email is required";
  } else if (!isValidEmail(data.official_email)) {
    errors.official_email = "Invalid email address";
  }

  // Validate business_run_by
  if (!data.business_run_by) {
    errors.business_run_by = "Business run by is required";
  }

  // Validate nature_of_business
  if (!data.nature_of_business) {
    errors.nature_of_business = "Nature of business is required";
  }

  // Validate product_category
  if (!data.product_category) {
    errors.product_category = "Product category is required";
  }

  // Validate business_vintage
  if (!data.business_vintage) {
    errors.business_vintage = "Business vintage is required";
  } else if (!isValidNumber(data.business_vintage)) {
    errors.business_vintage = "Business vintage should be a valid number";
  }

  // Validate description
  // if (!data.description) {
  //   errors.description = "Description is required";
  // }

  if (Object.keys(errors).length === 0) {
    return { valid: true, errors: {} };
  } else {
    return { valid: false, errors: errors };
  }
};

export const validateBusinessAddress = (data) => {
  const errors = {
    business_address: {
      house_address: "",
      city: "",
      state: "",
      pin_code: "",
      year_at_present_address: "",
      landmark: "",
    },
  };

  const addressFields = ["house_address", "city", "state", "pin_code"];
  let isValid = true;

  // Function to validate pin code
  const isValidPinCode = (pinCode) => /^\d{6}$/.test(pinCode);

  // Function to validate year
  const isValidYear = (year) => /^\d{1,2}$/.test(year);

  addressFields.forEach((field) => {
    if (!data.business_address[field]) {
      errors.business_address[field] = `${field.replace("_", " ")} is required`;
      isValid = false;
    }
  });

  // Validate pin code
  if (
    data.business_address.pin_code &&
    !isValidPinCode(data.business_address.pin_code)
  ) {
    errors.business_address.pin_code = "Invalid pin code";
    isValid = false;
  }

  // Validate year_at_present_address if provided
  if (
    data.business_address.year_at_present_address &&
    !isValidYear(data.business_address.year_at_present_address)
  ) {
    errors.business_address.year_at_present_address = "Invalid year";
    isValid = false;
  }

  if (isValid) {
    return { valid: true, errors: {} };
  } else {
    return { valid: false, errors: errors };
  }
};
