import React, { useState } from "react";
import "../../index.css";
import Footer from "../Components/Home/Footer";

function CalculatorApp() {
  const [principal, setPrincipal] = useState(0);
  const [interestRate, setInterestRate] = useState(0);
  const [tenure, setTenure] = useState(0);
  const [emi, setEMI] = useState(0);
  const [amortizationSchedule, setAmortizationSchedule] = useState([]);

  const calculateEMI = () => {
    const monthlyInterestRate = interestRate / 12 / 100;
    const emi =
      (principal *
        monthlyInterestRate *
        Math.pow(1 + monthlyInterestRate, tenure)) /
      (Math.pow(1 + monthlyInterestRate, tenure) - 1);
    setEMI(emi);

    const schedule = [];
    let remainingPrincipal = principal;
    for (let month = 1; month <= tenure; month++) {
      const interestPayment = remainingPrincipal * monthlyInterestRate;
      const principalPayment = emi - interestPayment;
      remainingPrincipal -= principalPayment;
      schedule.push({
        month,
        emi,
        interestPayment,
        principalPayment,
        remainingPrincipal,
      });
    }
    setAmortizationSchedule(schedule);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded shadow-lg">
        <h1 className="text-2xl font-semibold mb-4">
          Personal Loan EMI Calculator
        </h1>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Principal Loan Amount (INR):
          </label>
          <input
            type="number"
            className="w-full p-2 border border-gray-300"
            value={principal}
            onChange={(e) => setPrincipal(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Annual Interest Rate (%):
          </label>
          <input
            type="number"
            className="w-full p-2 border border-gray-300"
            value={interestRate}
            onChange={(e) => setInterestRate(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Loan Tenure (months):
          </label>
          <input
            type="number"
            className="w-full p-2 border border-gray-300"
            value={tenure}
            onChange={(e) => setTenure(e.target.value)}
          />
        </div>
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
          onClick={calculateEMI}
        >
          Calculate EMI
        </button>
        {emi > 0 && (
          <div className="mt-6">
            <h2 className="text-xl font-semibold mb-2">Monthly EMI:</h2>
            <p className="text-lg text-blue-700">
              {emi.toFixed(2)} INR per month
            </p>
            <h2 className="text-xl font-semibold mt-4 mb-2">
              Amortization Schedule:
            </h2>
            <table className="w-full">
              <thead>
                <tr className="bg-gray-100">
                  <th className="py-2 text-left px-4">Month</th>
                  <th className="py-2 text-left px-4">EMI</th>
                  <th className="py-2 text-left px-4">Interest Payment</th>
                  <th className="py-2 text-left px-4">Principal Payment</th>
                  <th className="py-2 text-left px-4">Remaining Principal</th>
                </tr>
              </thead>
              <tbody>
                {amortizationSchedule.map((entry) => (
                  <tr key={entry.month}>
                    <td className="py-2 px-4">{entry.month}</td>
                    <td className="py-2 px-4">{entry.emi.toFixed(2)}</td>
                    <td className="py-2 px-4">
                      {entry.interestPayment.toFixed(2)}
                    </td>
                    <td className="py-2 px-4">
                      {entry.principalPayment.toFixed(2)}
                    </td>
                    <td className="py-2 px-4">
                      {entry.remainingPrincipal.toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default CalculatorApp;
