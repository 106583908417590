import React from "react";

const AmortizationTable = ({ data }) => {
  return (
    <div>
      <table className="w-full rounded-lg p-4">
        <thead>
          <tr>
            {data.headings.map((heading, index) => (
              <th
                key={index}
                className="border-b border-gray-300 py-2 text-xs md:text-lg"
              >
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((data, dataIndex) => (
                <td
                  key={dataIndex}
                  className="border-b border-gray-300 py-2 text-xs md:text-base"
                >
                  {data}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AmortizationTable;
