import React from "react";
import { useNavigate } from "react-router-dom";

const MissingDataImage = ({ imgUrl, message, btnurl }) => {
  const navigate = useNavigate();
  return (
    <div className="w-full flex flex-col items-center justify-center gap-1">
      <img
        className="h-24 opacity-40 drop-shadow-md"
        src="https://cdn-icons-png.flaticon.com/128/13982/13982857.png"
        alt="udharibazaar"
      />
      <p className="text-logo-blue capitalize font-semibold">{message}</p>
      {btnurl && (
        <button
          onClick={() => navigate(btnurl)}
          className="border px-3 rounded-xl bg-logo-blue text-white"
        >
          Add
        </button>
      )}
    </div>
  );
};

export default MissingDataImage;
