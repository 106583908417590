import React from "react";
import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";

const CareerPage = () => {
  return (
    <div className="flex flex-col items-center">
      <Navbar />

      <div className="w-full ">
        <img
          src="https://cdn.staropstech.com/clients/udharibazaar/assets/extra-images/job-banner.webp"
          alt="Banner"
          className="w-full h-60 object-cover object-center mb-5  "
        />
      </div>
      <div className="w-3/4 flex gap-5 mt-10">
        <div className=" flex flex-col justify-center">
          <h1 className="text-4xl font-bold  text-logo-blue border-b-2 border-logo-orange max-w-max ">
            Join our Team
          </h1>
          <p className="mt-5  text-justify ">
            Udharibazaar stands as a trusted and versatile financial
            institution, dedicated to facilitating comprehensive loan provisions
            for individuals and businesses. Founded with a commitment to
            addressing diverse financial needs, we specialize in an extensive
            spectrum of loan offerings, including personal loans, business
            funding, home mortgages, and other tailored financial solutions. Our
            approach is centered on providing accessible and reliable lending
            options, leveraging a seamless application process, competitive
            interest rates, and personalized assistance to ensure our clients
            receive the financial support they require promptly and efficiently.
          </p>
        </div>
        <div className="w-full ">
          <img
            className="w-full h-full object-cover object-center "
            src="https://img.freepik.com/free-vector/flat-design-join-us-concept_23-2148948804.jpg?w=826&t=st=1704695305~exp=1704695905~hmac=dcebab8f290ec27b16828130a54177534adec667cc104e899295f0a5193ccd6c"
            alt=""
          />
        </div>
      </div>

      <div className=" w-3/4 flex flex-col mb-10">
        <div className="flex flex-col gap-2">
          <h3 className="text-lg font-medium capitalize text-logo-blue max-w-max">
            Drop Your CV at:
          </h3>
          <p className="w-full">careers@udharibazaar.com</p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default CareerPage;
