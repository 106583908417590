import React, { useEffect } from "react";
import {
  IsAssociatePartner,
  IsAuthenticated,
  IsNormalUser,
  IsPartnerUser,
  IsSuperUser,
} from "./AccessTokenStore";
import { useNavigate } from "react-router-dom";

const AuthenticatedRoutes = (props) => {
  const navigate = useNavigate();
  const openroutes = [
    "/",
    "/test",
    "/health",
    "/calculator",
    "/partner/login",
    "/partner/register",
    "/admin/login",
    "/personal-loan",
    "/business-loan",
    "/home-loan",
    "/car-loan",
    "/health-insurance",
    "/term-insurance",
    "/credit-loan",
    "/support",
    "/faq",
    "/privacy-policy",
    "/term-and-conditions",
    "/blogs",
    "/feedback",
    "/career",
    "/about-us",
    "/auto-insurance",
    "/news",
  ];

  const checkAuth = () => {
    const url = window.location.pathname;
    if (IsAuthenticated()) {
      if (IsSuperUser()) {
        if (
          url.startsWith("/admin/login") ||
          url.startsWith("/admin/register") ||
          url.startsWith("/partner") ||
          url.startsWith("/user")
        ) {
          navigate("/admin/dashboard");
        } else if (url.startsWith("/admin")) {
          return;
        }
      } else if (IsPartnerUser() || IsAssociatePartner()) {
        if (
          url.startsWith("/partner/login") ||
          url.startsWith("/partner/register") ||
          url.startsWith("/admin") ||
          url.startsWith("/user")
        ) {
          navigate("/partner/dashboard");
        } else if (url.startsWith("/partner")) {
          return;
        }
      } else if (IsNormalUser()) {
        if (url.startsWith("/partner") || url.startsWith("/admin")) {
          navigate("/user/dashboard");
        } else if (url.startsWith("/user")) {
          return;
        }
      }
    } else if (!IsAuthenticated()) {
      if (url.startsWith("/partner")) {
        if (url === "/partner/register") {
          navigate("/partner/register", { replace: true });
        } else {
          navigate("/partner/login", { replace: true });
        }
      } else if (url.startsWith("/admin")) {
        navigate("/admin/login", { replace: true });
      } else {
        if (!openroutes.includes(url)) {
          navigate("/", { replace: true });
        }
      }
    } else {
      navigate("/");
    }
  };
  useEffect(() => {
    checkAuth();
  }, [navigate]);
  return IsAuthenticated() || openroutes.includes(window.location.pathname) ? (
    <div>{props.children}</div>
  ) : (
    <></>
  );
};

export default AuthenticatedRoutes;
