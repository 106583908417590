import React, { useState } from "react";

const PartnerCard = ({ iconUrl }) => {
  return (
    <div className="border h-16 w-36 rounded-md px-2  md:w-44 shadow-md hover:scale-110 hover:shadow-lg border-logo-blue border-opacity-30">
      <img className="h-full w-full p-3" src={iconUrl} alt="partner_logo" />
    </div>
  );
};

const Partners = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");

  const LoanIcons = [
    "https://bonds.rurashfin.com/wp-content/uploads/2021/10/email-logo.png",
    "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/axis-bank.webp",
    "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/bajaj.webp",
    "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/faircent.png",
    "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/finnable.png.webp",
    "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/hdfc-bank.webp",
    "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/hero-fincorp.webp",
    "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/icici-bank.webp",
    "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/idfc.webp",
    "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/iifl.webp",
    "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/incred-finance.webp",
    "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/indifi.png",
    "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/kotak-mahindra.webp",
    "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/MoneyWide.webp",
    "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/Paysence_service.webp",
    // "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/piramal.png",
    // "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/scb.png",
    // "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/tata.webp",
    // "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/yes.webp",
    // "https://logos-world.net/wp-content/uploads/2023/08/Hinduja-Group-Logo.jpg",
    // "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Bandhan_Bank_Svg_Logo.svg/2560px-Bandhan_Bank_Svg_Logo.svg.png",
    // "https://www.myshubhlife.com/img/logo-horizontal-black.webp",
    // "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/hdfc-sales.webp",
    // "https://companieslogo.com/img/orig/ABCAPITAL.NS_BIG-e60c51c6.png?t=1604067027",
  ];

  const InsuranceIcons = [
    "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/icici-bank.webp",
    "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/hero-fincorp.webp",
    "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/hdfc-bank.webp",
    "https://companieslogo.com/img/orig/ABCAPITAL.NS_BIG-e60c51c6.png?t=1604067027",
  ];

  const CreditIcons = [
    "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/axis-bank.webp",
    "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/hdfc-bank.webp",
    "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/icici-bank.webp",
    "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/scb.png",
    "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/kotak-mahindra.webp",
  ];
  const InvestmentIcons = [
    "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/kotak-mahindra.webp",
    "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/icici-bank.webp",
    "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/axis-bank.webp",
    "https://cdn.staropstech.com/clients/udharibazaar/assets/bank-logo/hdfc-bank.webp",
  ];

  const categoryIcons = {
    All: LoanIcons,
    Insurance: InsuranceIcons,
    Credit: CreditIcons,
    InvestmentIcons: InvestmentIcons,
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const categories = [
    { label: "Loan", value: "All" },
    { label: "Insurance", value: "Insurance" },
    { label: "Credit Card", value: "Credit" },
    { label: "Investment", value: "InvestmentIcons" },
  ];

  const renderIcons = () => {
    const icons = categoryIcons[selectedCategory] || LoanIcons;
    return icons.map((iconUrl, index) => (
      <PartnerCard key={index} iconUrl={iconUrl} />
    ));
  };

  return (
    <div className="mt-10 flex items-center flex-col text-logo-blue w-10/12 m-auto">
      <h1 className="text-4xl font-bold capitalize text-center py-2">
        Our Partners across the Industries
      </h1>
      <p className="w-32 border-b-2 border-logo-orange"></p>
      <p className="text-sm text-logo-blue text-opacity-90 p-2 font-semibold">
        Know why did they choose UdhariBazaar!
      </p>

      <div className="my-5 flex justify-center space-x-4">
        {categories.map((category) => (
          <button
            key={category.value}
            onClick={() => handleCategoryChange(category.value)}
            className={` px-1 text-base  md:text-lg font-semibold mb-4 text-gray-700 ${
              selectedCategory === category.value &&
              " border-b-2 border-logo-orange text-logo-blue"
            }`}
          >
            {category.label}
          </button>
        ))}
      </div>

      <div className="mb-10 w-full  flex flex-row flex-wrap gap-5 justify-center">
        {renderIcons()}
      </div>
    </div>
  );
};

export default Partners;
