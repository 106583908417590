import React, { useEffect, useState } from "react";
import { renderInput } from "../../../../Common/FormComponents";
import { GetData, PostData, PutData } from "../../../../../utils/ApiInvoker";
import GenerateURL from "../../../../../utils/ApiUrlProvider";
import { useLoadingContext } from "../../../../../Context/LoadingContext";
import properties from "../../../../../Properties/Properties";
import { useNavigate, useParams } from "react-router-dom";
import { validateCompanyInfo } from "../../../../Common/FormValidation/PersonalLoanServVal";
import { toast } from "react-toastify";
import { PartnerOrAssociate } from "../../../../../utils/Healpers";

const SalaryInfoPersonalLoan = () => {
  const { id, secid, type } = useParams();
  const navigate = useNavigate();
  const totalStage = 2;
  const [stage, setStage] = useState(1);
  const { showLoader, hideLoader } = useLoadingContext();

  const initialBasicInfoData = {
    company_name: "",
    salary: "",
    working_duration: "",
    office_address: {
      house_address: "",
      city: "",
      state: "",
      pin_code: "",
      year_at_present_address: "",
      landmark: "",
    },
    last_three_month_salary_slip: "",
    form_sixteen_document: "",
    bank_statement: "",
  };
  const [salaryInfoData, setSalaryInfoData] = useState(null);
  const [salaryInfoError, setSalaryInfoError] = useState(initialBasicInfoData);

  const HandleSalaryInfoChange = (e) => {
    const { name, value } = e.target;
    setSalaryInfoError({ ...salaryInfoError, [name]: "" });
    setSalaryInfoData({ ...salaryInfoData, [name]: value });
  };

  const HandleSubmitSucess = (e) => {
    hideLoader();
    if (PartnerOrAssociate()) {
      navigate(`/partner/loan/personalloan/${id}/detail`);
    } else {
      navigate(`/user/loan/personalloan/${id}/detail`);
    }
    toast.success("Salariedinfo added sucessfully", {
      autoClose: 1000,
    });
  };
  const HandleSubmitError = (e) => {
    hideLoader();
    toast.error("Something went wrong", {
      autoClose: 1000,
    });
  };
  const HandleSubmitResponse = (e) => {
    e.preventDefault();
    showLoader();
    if (type === "edit") {
      const url = GenerateURL(
        { id: id, secid: secid },
        properties.api.PersonalLoanServices.EditUrl.SalaryDetailPersonalLoanEdit
      );
      PutData(
        url,
        salaryInfoData,
        HandleSubmitSucess,
        HandleSubmitError,
        false
      );
    } else {
      const url = GenerateURL(
        { id: id },
        properties.api.PersonalLoanServices.SalaryDetailPersonalLoan
      );
      showLoader();
      PostData(
        url,
        salaryInfoData,
        HandleSubmitSucess,
        HandleSubmitError,
        false
      );
    }
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    if (stage === 1) {
      const valid = validateCompanyInfo(salaryInfoData);
      console.log(salaryInfoData);
      if (valid.valid) {
        setStage(2);
      } else {
        setSalaryInfoError((prev) => ({ ...prev, ...valid.errors }));
      }
    } else {
      HandleSubmitResponse(e);
    }
  };
  const HandlePrevClick = (e) => {
    e.preventDefault();
    setStage((prev) => prev - 1);
  };

  const HandleGetSucess = (res) => {
    setSalaryInfoData(res);
  };
  const HandleGetError = (res) => {
    toast.error("Something went wrong", {
      autoClose: 1000,
    });
    navigate("/user/dashboard");
  };

  const GetInitialData = () => {
    const url = GenerateURL(
      { id: id, secid: secid },
      properties.api.PersonalLoanServices.EditUrl.SalaryDetailPersonalLoanEdit
    );
    GetData(url, HandleGetSucess, HandleGetError, false, false);
  };

  useEffect(() => {
    if (type === undefined) {
      setSalaryInfoData(initialBasicInfoData);
    } else {
      GetInitialData();
    }
  }, []);
  return (
    <div className="p-5 w-full md:w-8/12 h-full pt-20  ">
      <h1 className="text-2xl md:text-3xl pl-2 my-2 border-l-4  text-slate-800 font-sans font-bold  border-logo-blue mb-5  rounded-sm  ">
        Personal Loan
      </h1>
      <div className="flex items-center w-full border shadow-md flex-col justify-center rounded-lg  bg-white  ">
        <div className="flex items-start w-full flex-col  px-6 py-3  bg-logo-blue bg-opacity-90 rounded-t-lg ">
          <p className="w-11/12 py-3 text-xl text-left capitalize text-white ">
            Salary Detail
          </p>
          <div
            className="bg-logo-orange h-1.5 rounded-full transition-width duration-800"
            style={{
              width: "0%",
              transition: "width 1s ease-in-out",
              width: `${(stage / totalStage) * 100}%`,
            }}
          ></div>
        </div>
        <div className="flex items-center w-full flex-row justify-center rounded-lg  bg-white">
          {salaryInfoData && (
            <form className="w-full h-full  p-4 ">
              {stage === 1 && (
                <>
                  <p className="text-xl rounded-md px-10 py-1 bg-logo-blue bg-opacity-10 my-2  font-medium text-logo-orange">
                    Personal Info
                  </p>
                  {renderInput("STRING", {
                    label: "Company Name",
                    name: "company_name",
                    onChange: HandleSalaryInfoChange,
                    value: salaryInfoData.company_name,
                    error: salaryInfoError.company_name,
                    isrequired: true,
                  })}
                  {renderInput("INTEGER", {
                    label: "Salary",
                    name: "salary",
                    onChange: HandleSalaryInfoChange,
                    value: salaryInfoData.salary,
                    error: salaryInfoError.salary,
                    isrequired: true,
                  })}
                  {renderInput("STRING", {
                    label: "Working Since (months)",
                    name: "working_duration",
                    onChange: HandleSalaryInfoChange,
                    value: salaryInfoData.working_duration,
                    error: salaryInfoError.working_duration,
                    isrequired: true,
                  })}
                  <p className="text-xl rounded-md px-10 py-1 bg-logo-blue bg-opacity-10 my-2  font-medium text-logo-orange">
                    Office Address
                  </p>
                  {renderInput("ADDRESS", {
                    label: "Address",
                    name: "office_address",
                    onChange: HandleSalaryInfoChange,
                    value: salaryInfoData.office_address,
                    error: salaryInfoError.office_address,
                    isrequired: true,
                  })}
                </>
              )}

              {stage === 2 && (
                <>
                  {renderInput("FILE_UPLOAD", {
                    label: "Form Sixteen",
                    name: "form_sixteen_document",
                    code: "form_sixteen_document",
                    onChange: HandleSalaryInfoChange,
                    value: salaryInfoData.form_sixteen_document,
                    error: salaryInfoError.form_sixteen_document,
                    onlyId: true,
                    isrequired: false,
                    validations: {
                      file_size: "10MB",
                      file_type: ["jpg", "png", "pdf", "heic", "jpeg"],
                      is_required: false,
                    },
                  })}
                  {renderInput("FILE_UPLOAD", {
                    label: "Bank Statement",
                    name: "bank_statement",
                    code: "bank_statement",
                    onChange: HandleSalaryInfoChange,
                    value: salaryInfoData.bank_statement,
                    error: salaryInfoError.bank_statement,
                    onlyId: true,
                    isrequired: false,
                    validations: {
                      file_size: "10MB",
                      file_type: ["jpg", "png", "pdf", "heic", "jpeg"],
                      is_required: false,
                    },
                  })}
                  {renderInput("FILE_UPLOAD", {
                    label: "Salary Slip (last 3 months)",
                    name: "last_three_month_salary_slip",
                    code: "last_three_month_salary_slip",
                    onChange: HandleSalaryInfoChange,
                    value: salaryInfoData.last_three_month_salary_slip,
                    error: salaryInfoError.last_three_month_salary_slip,
                    onlyId: true,
                    isrequired: false,
                    validations: {
                      file_size: "10MB",
                      file_type: ["jpg", "png", "pdf", "heic", "jpeg"],
                      is_required: false,
                    },
                  })}
                </>
              )}
              <div className="mt-10 flex  flex-row-reverse gap-3 w-full items-center justify-between">
                <button
                  onClick={handleNextClick}
                  className={`${
                    stage === totalStage ? "bg-green-500" : "bg-blue-600"
                  } text-white px-4 py-2 rounded-md`}
                >
                  {stage === totalStage ? "Submit" : "Next"}
                </button>
                {stage !== 1 && (
                  <button
                    className="bg-blue-600 text-white px-4 py-2 rounded"
                    onClick={HandlePrevClick}
                  >
                    Prev
                  </button>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default SalaryInfoPersonalLoan;
