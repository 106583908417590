export const ProfileEditVal = (formData) => {
  const errors = {};
  if (!formData.first_name.trim()) {
    errors.first_name = "First name is required";
  }
  if (!formData.email.trim()) {
    errors.email = "Email is Required";
  } else if (!/\S+@\S+\.\S+/.test(formData.email.trim())) {
    errors.email = "Invalid Email Adress type";
  }

  let isValid = Object.keys(errors).length === 0;
  return { is_valid: isValid, error: errors };
};
