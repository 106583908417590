import React, { useEffect, useState } from "react";
import GenerateURL from "../../../utils/ApiUrlProvider";
import { GetData } from "../../../utils/ApiInvoker";
import properties from "../../../Properties/Properties";
import { useParams } from "react-router-dom";
import RMDetailCard from "../../Common/RMDetailsCard";

const RMCard = () => {
  const [RMData, setRMData] = useState();
  const params = useParams();
  const HandleSuccess = (response) => {
    setRMData(response);
  };
  const HandleError = (response) => {
    console.log(response);
  };

  const UserInfo = () => {
    const url = GenerateURL({ id: params.id }, properties.api.adminSingleRM);
    GetData(url, HandleSuccess, HandleError, false, false);
  };

  useEffect(() => {
    UserInfo();
  }, []);

  return (
    <>{RMData ? <RMDetailCard RMData={RMData} RMId={params.id} /> : <> </>}</>
  );
};

export default RMCard;
