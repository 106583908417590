import React from "react";
import { toast } from "react-toastify";

const DocumentDisplay = ({
  label,
  name,
  putapi,
  Detail,
  setImgEditDetail,
  setFileMoadlvisible,
  GlobalFileDownlad,
  ShowChange,
}) => {
  const documentExists = Detail[name];
  // console.log(Detail, "yaha pe hai");

  return (
    <div className="flex justify-between w-full">
      <p className="font-medium">{label}</p>
      <div className="flex space-x-2">
        {ShowChange && (
          <button
            onClick={() => {
              setImgEditDetail({
                filedata: { label, name },
                api: {
                  url: putapi.url,
                  id: putapi.id,
                  secid: putapi.secid,
                },
              });
              setFileMoadlvisible((prev) => !prev);
            }}
            className={`rounded-md shadow-md transition-all duration-500 ease-in-out text-sm p-1 px-2 ${
              documentExists ? "bg-green-300 hover:scale-105" : "bg-gray-200"
            }`}
          >
            {documentExists ? "Change" : "Upload"}
          </button>
        )}
        <button
          disabled={!documentExists}
          onClick={() => {
            toast.info("Wait while your file is being downloaded", {
              autoClose: 1000,
            });
            GlobalFileDownlad(Detail[name]);
          }}
          className={`rounded-md shadow-md transition-all duration-500 ease-in-out text-sm p-1 px-2 ${
            documentExists ? "bg-green-300 hover:scale-105" : "bg-gray-200"
          }`}
        >
          Download
        </button>
      </div>
    </div>
  );
};

export default DocumentDisplay;
