import React from "react";
import DashboardHeader from "../../../Hoc/DashboardHeader";
import { Outlet } from "react-router-dom";
import { GetAuth } from "../../../utils/AccessTokenStore";

const DashboardWithoutSidebar = () => {
  return (
    <div>
      <DashboardHeader
        isProfileDisabled={!GetAuth().activated}
        sidebarOpen={false}
        toggleSidebar={() => {}}
      />
      <div className="flex min-h-screen">
        <div className="mt-16 w-full  bg-slate-100 flex  justify-center border-2  ">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardWithoutSidebar;
