import React, { useState } from "react";
import DashboardBanner from "../../../../Common/DashboardBanner";
import "react-toastify/dist/ReactToastify.css";
import CommonLoanList from "./CommonLoanList";

const LeadList = () => {
  const [loanType, setLoanType] = useState(0);
  return (
    <>
      <div className="w-11/12 flex flex-col gap-2 items-center">
        <DashboardBanner />
        <div className="flex flex-row w-full gap-2 px-2 pt-2 border bg-logo-blue bg-opacity-10 rounded-md">
          <button
            onClick={() => setLoanType(0)}
            className={`px-2 py-1 transition-all ease-in-out duration-1000  ${
              loanType === 0
                ? "rounded-t-md bg-logo-blue bg-opacity-20 text-logo-orange"
                : ""
            }`}
          >
            Personal Loan
          </button>
          <button
            onClick={() => setLoanType(1)}
            className={`px-2 py-1 transition-all ease-in-out duration-1000  ${
              loanType === 1
                ? "rounded-t-md bg-logo-blue bg-opacity-20 text-logo-orange"
                : ""
            }`}
          >
            Business Loan
          </button>
          <button
            onClick={() => setLoanType(2)}
            className={`px-2 py-1 transition-all ease-in-out duration-1000  ${
              loanType === 2
                ? "rounded-t-md bg-logo-blue bg-opacity-20 text-logo-orange"
                : ""
            }`}
          >
            Auto Loan
          </button>
        </div>
        {loanType === 0 && <CommonLoanList loanType={"PL"} />}
        {loanType === 1 && <CommonLoanList loanType={"BL"} />}
        {loanType === 2 && <CommonLoanList loanType={"AL"} />}
      </div>
    </>
  );
};

export default LeadList;
