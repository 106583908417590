import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  IsAuthenticated,
  IsSuperUser,
  RemoveLoginCreds,
} from "../../../utils/AccessTokenStore";
import { GetData } from "../../../utils/ApiInvoker";
import LoginModal from "../Login/LoginModal";
import ModalWindow from "../../Common/Modal/ModalWindow";
import GenerateURL from "../../../utils/ApiUrlProvider";
import properties from "../../../Properties/Properties";
import { StatusTag } from "../../../utils/Healpers";

const ApplicationStatus = () => {
  const [value, setValue] = useState({ application: "" });
  const [err, setErr] = useState("");
  const [status, setStatus] = useState(null);
  const handleInputChange = (e) => {
    setErr("");
    setStatus(null);
    const { name, value } = e.target;
    setValue({ ...value, [name]: value });
  };
  const handleSucess = (res) => {
    setStatus(res.status);
    console.log(res);
  };
  const handleError = (res) => {
    setErr("application Not Found");
  };

  const HandleSubmit = (e) => {
    setStatus(null);
    e.preventDefault();
    if (!value.application) {
      setErr("Please enter the application number");
      setStatus(null);
      return;
    }
    setErr("");
    const url = GenerateURL(
      { application_number: value.application },
      properties.api.applicationStatus
    );
    GetData(url, handleSucess, handleError, false, true);
  };
  return (
    <form className="flex flex-col  w-full  gap-10 items-center ">
      <div className=" flex flex-col  gap-2 sm:flex-row sm:gap-2 justify-center  items-center w-10/12">
        <label className="text-lg font-medium text-left w-full text-gray-900  sm:w-1/3  sm:text-right sm:py-3">
          Application No:
        </label>
        <div className="w-full  sm:w-2/3">
          <input
            type="text"
            name="application"
            className="border-logo-orange border-opacity-50 border w-full px-3 py-2 rounded-md text-base focus:outline-none focus:ring-2 focus:ring-logo-orange"
            placeholder="Enter your Application Number (eg: UDB-XXXX)"
            value={value.application}
            onChange={handleInputChange}
            required
          />
          {err !== "" ? (
            <label className="text-xs   text-red-600 lowercase">{err}</label>
          ) : (
            ""
          )}
        </div>
      </div>
      <button
        onClick={HandleSubmit}
        className=" bg-logo-blue h-10  w-max text-white px-4 rounded  focus:outline-none focus:ring "
      >
        Submit
      </button>
      {status && <div>Current Status {StatusTag(status)} </div>}
    </form>
  );
};

const Navbar = ({ isloginModalOpen, openLoginModel, closeLoginModel }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [state, setState] = useState({});
  const [isApplicationModalOpen, setIsApplicationModalOpen] = useState(false);
  const navigate = useNavigate();
  const navbarRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  const handleMenuItemClick = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    if (IsAuthenticated()) {
      const userData = JSON.parse(localStorage.getItem("authData")).userDetails;
      setUserName(userData ? userData.name || userData.mobile_number : "");
    }
  }, []);

  const handleLogout = () => {
    RemoveLoginCreds(
      () => {
        setState({
          ...state,
          logoutStatus: "done",
        });
        navigate("/");

        window.location.reload();
      },
      (error) => {
        setState({
          ...state,
          logoutStatus: "failed",
        });
        console.error("Logout failed:", error);
      }
    );
  };

  const redirectToEditProfile = () => {
    if (IsAuthenticated()) {
      if (IsSuperUser()) {
        navigate(`/admin/profile`);
      } else {
        navigate(`/user/profile`);
      }
    } else {
      navigate(`/user/profile`);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      window.addEventListener("click", handleClickOutside);
    } else {
      window.removeEventListener("click", handleClickOutside);
    }
    return () => window.removeEventListener("click", handleClickOutside);
  }, [isMenuOpen]);

  return (
    <nav className="bg-white border-gray-200 shadow-md w-full" ref={navbarRef}>
      <div className="w-full bg-logo-blue text-white">
        <marquee className="h-5" direction="left" loop="true">
          <a href="tel:+919810200971" target="_blank" className="text-sm">
            Help Line No. 9810200971{" "}
          </a>
          &emsp;&emsp;&emsp;
          <a href="tel:+916393803009" target="_blank" className="text-sm">
            Complaint No. 6393803009
          </a>
          &emsp;&emsp;&emsp;
          <a
            href="mailto:info@udharibazaar.com"
            title="send email"
            target="_blank"
            className="text-sm"
          >
            Email: info@udharibazaar.com
          </a>
        </marquee>
      </div>
      <div className="max-w-screen-xl   flex flex-wrap items-center justify-between mx-auto p-4  ">
        <a href="/" className="flex items-center">
          <img
            src="https://cdn.staropstech.com/clients/udharibazaar/assets/extra-images/logo.webp"
            className="h-9 mr-3 "
            alt="codewithfaraz Logo"
          />
        </a>
        <button
          id="navbar-toggle"
          onClick={toggleMenu}
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
          aria-controls="navbar-dropdown"
          aria-expanded={isMenuOpen}
        >
          <span className="sr-only">Open main menu</span>
          {isMenuOpen ? (
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          )}
        </button>
        <div
          className={`w-full md:block md:w-auto  ${isMenuOpen ? "" : "hidden"}`}
          id="navbar-dropdown"
        >
          <ul className="flex flex-col  font-medium p-4 md:p-0 mt-4  border-gray-100 rounded-lg bg-gray-50 md:flex-row  md:items-center md:space-x-2 lg:space-x-6 md:mt-0  md:border-0 md:bg-white border">
            <li>
              <a
                href="/"
                className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-600 md:p-0 "
                aria-current="page"
              >
                Home
              </a>
            </li>
            <li className="group">
              <button
                id="dropdownAboutLink"
                className="flex items-center justify-between w-full py-2 pl-3 pr-4 text-gray-900 rounded  md:hover-bg-transparent md:border-0 md:hover-text-blue-600 md:p-0 md:w-auto group-hover:text-blue-600"
              >
                About
                <svg
                  className="w-2.5 h-2.5 ml-2.5 rotate-element transform group-hover:rotate-180 transition duration-300"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>

              <div
                id="dropdownAbout"
                className="absolute z-10 hidden group-hover:block font-normal bg-white divide-y divide-gray-100 rounded-lg shadow  group"
              >
                <div className="flex flex-row w-max">
                  <ul className="py-2 text-sm text-gray-700 ">
                    <li>
                      <div
                        onClick={() => {
                          navigate("/about-us");
                          handleMenuItemClick();
                        }}
                        className="block px-4 py-2  hover:bg-gray-50 hover:text-blue-600 cursor-pointer"
                      >
                        About Us
                      </div>
                    </li>
                    <li>
                      <a
                        href="https://mytaxaa.com/"
                        className="block px-4 py-2  hover:bg-gray-50 hover:text-blue-600 cursor-pointer"
                        target="_blank"
                        onClick={() => {
                          handleMenuItemClick();
                        }}
                      >
                        MyTaxaa
                      </a>
                    </li>
                    <li>
                      <div
                        onClick={() => {
                          navigate("/news");
                          handleMenuItemClick();
                        }}
                        className="block px-4 py-2  hover:bg-gray-50 hover:text-blue-600 cursor-pointer"
                      >
                        News & Updates
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li className="group">
              <button
                id="dropdownNavbarLink"
                className="flex items-center justify-between w-full py-2 pl-3 pr-4 text-gray-900 rounded  md:hover-bg-transparent md:border-0 md:hover-text-blue-600 md:p-0 md:w-auto hover:text-blue-600 group-hover:text-blue-600 "
              >
                Services
                <svg
                  className="w-2.5 h-2.5 ml-2.5 rotate-element transform group-hover:rotate-180 transition duration-300"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>

              <div
                id="dropdownNavbar"
                className="absolute z-10 hidden group-hover:block font-normal bg-white divide-y divide-gray-100 rounded-lg shadow  group "
              >
                <div className="flex flex-row w-max">
                  <ul className="py-2 text-sm text-gray-700 ">
                    <li>
                      <div
                        onClick={() => {
                          navigate("/personal-loan");
                          handleMenuItemClick();
                        }}
                        className="block px-4 py-2  hover:bg-gray-50 hover:text-blue-600 cursor-pointer"
                      >
                        Personal Loan
                      </div>
                    </li>
                    <li>
                      <div
                        onClick={() => {
                          navigate("/home-loan");
                          handleMenuItemClick();
                        }}
                        className="block px-4 py-2  hover:bg-gray-50 hover:text-blue-600 cursor-pointer"
                      >
                        Home Loan
                      </div>
                    </li>
                    <li>
                      <div
                        onClick={() => {
                          navigate("/car-loan");
                          handleMenuItemClick();
                        }}
                        className="block px-4 py-2  hover:bg-gray-50 hover:text-blue-600 cursor-pointer"
                      >
                        Auto Loan
                      </div>
                    </li>
                    <li>
                      <div
                        onClick={() => {
                          navigate("/business-loan");
                          handleMenuItemClick();
                        }}
                        className="block px-4 py-2  hover:bg-gray-50 hover:text-blue-600 cursor-pointer"
                      >
                        Business Loan
                      </div>
                    </li>
                  </ul>
                  <ul
                    className="py-2 text-sm text-gray-700"
                    aria-labelledby="dropdownLargeButton"
                  >
                    <li>
                      <div
                        onClick={() => {
                          navigate("/car-loan");
                          handleMenuItemClick();
                        }}
                        className="block px-4 py-2 hover:bg-gray-50 hover:text-blue-600 cursor-pointer"
                      >
                        Car Insurance
                      </div>
                    </li>
                    <li>
                      <div
                        onClick={() => {
                          navigate("/car-loan");
                          handleMenuItemClick();
                        }}
                        className="block px-4 py-2 hover:bg-gray-50 hover:text-blue-600 cursor-pointer"
                      >
                        Bike Insurance
                      </div>
                    </li>
                    <li>
                      <div
                        onClick={() => {
                          navigate("/health-insurance");
                          handleMenuItemClick();
                        }}
                        className="block px-4 py-2 hover:bg-gray-50 hover:text-blue-600 cursor-pointer"
                      >
                        Health Insurance
                      </div>
                    </li>
                    <li>
                      <div
                        onClick={() => {
                          navigate("/term-insurance");
                          handleMenuItemClick();
                        }}
                        className="block px-4 py-2 hover:bg-gray-50 hover:text-blue-600 cursor-pointer"
                      >
                        Term Insurance
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li className="group">
              <a
                href="#calculator"
                id="dropdownNavbarLink"
                className="flex items-center justify-between w-full py-2 pl-3 pr-4 text-gray-900 rounded  md:hover-bg-transparent md:border-0 md:hover-text-blue-600 md:p-0 md:w-auto group-hover:text-blue-600"
              >
                Calculator
              </a>
            </li>
            <li className="group ">
              <button
                onClick={() => setIsApplicationModalOpen(true)}
                className="flex items-center justify-between w-full py-2 pl-3 pr-4 text-gray-900 rounded    md:hover-bg-transparent md:border-0 md:hover-text-blue-600 md:p-0 md:w-auto group-hover:text-blue-600"
              >
                Track Your Application
              </button>
            </li>
            <li>
              <a
                href="/partner/login"
                target="_blank"
                className="block py-2 pl-3 pr-4 bg-[#F47C34] h-10  text-white px-4 rounded hover:bg-[#f47e34e5] focus:outline-none focus:ring focus:ring-blue-300"
              >
                Become a Partner
              </a>
            </li>
            <li>
              {userName ? (
                <li className="group">
                  <button
                    id="dropdownAboutLink"
                    className={`flex items-center py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-600 md:p-0`}
                  >
                    {userName}
                    <svg
                      className="w-2.5 h-2.5 ml-2.5 rotate-element transform group-hover:rotate-180 transition duration-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 4 4 4-4"
                      />
                    </svg>
                  </button>
                  <div
                    id="dropdownAbout"
                    className="absolute z-10 hidden group-hover:block font-normal bg-white divide-y divide-gray-100 rounded-lg shadow  group"
                  >
                    <div className="flex flex-row w-max">
                      <ul
                        className="py-2 text-sm text-gray-700"
                        aria-labelledby="dropdownLargeButton"
                      >
                        <li>
                          <Link
                            to="/user/dashboard"
                            className="block px-4 py-2 hover:bg-gray-50 hover:text-blue-600 rounded-md"
                          >
                            Dashboard
                          </Link>
                        </li>
                        <li>
                          <button
                            onClick={redirectToEditProfile}
                            className="block px-4 py-2 hover:bg-gray-50 hover:text-blue-600 rounded-md cursor-pointer"
                          >
                            Edit Profile
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={handleLogout}
                            className="block px-4 py-2 hover:bg-gray-50 hover:text-blue-600 rounded-md cursor-pointer"
                          >
                            Logout
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              ) : (
                <>
                  <div className="text-center flex flex-row justify-between align-middle ">
                    <button
                      className="bg-[#143C74] h-10  text-white px-4 rounded hover:bg-[#143c74e7] focus:outline-none focus:ring focus:ring-blue-300"
                      onClick={openLoginModel}
                    >
                      Login
                    </button>
                  </div>
                  <LoginModal
                    isloginModalOpen={isloginModalOpen}
                    closeLoginModel={closeLoginModel}
                  />
                </>
              )}
            </li>
          </ul>
        </div>
      </div>
      {isApplicationModalOpen && (
        <ModalWindow
          title="Track Your Application"
          onClose={() => setIsApplicationModalOpen(false)}
        >
          <ApplicationStatus />
        </ModalWindow>
      )}
    </nav>
  );
};

export default Navbar;
