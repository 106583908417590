import React, { useRef } from "react";
import { useEffect, useState } from "react";

import EditPartnerModal from "./PartnerEditModel";

import {
  IsAuthenticated,
  IsNormalUser,
  IsPartnerUser,
  IsSuperUser,
} from "../../../utils/AccessTokenStore";

const PartnerEditCard = ({ partnerData }) => {
  const [isvisible, setIsVisible] = useState(false);
  const editButtonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const editProfileModal = document.getElementById("edit-profile-modal");
      if (
        editButtonRef.current &&
        !editButtonRef.current.contains(event.target) &&
        !editProfileModal?.contains(event.target)
      ) {
        setIsVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleEditModal = () => {
    setIsVisible(!isvisible);
  };

  function checkUserType(isSuperUser, isPartner) {
    if (isSuperUser) {
      return "Admin";
    } else if (isPartner) {
      return "Partner";
    } else {
      return "User";
    }
  }

  return (
    <div className="w-full flex flex-col ml-5 mt-9 p-4 ">
      <div className=" mt-3 rounded-2xl ">
        <div className=" w-full p-5 rounded-t-2xl bg-white flex flex-row justify-between border-b-2">
          <h1 className="font-medium text-xl ">Basic User Information</h1>
          <div className="flex flex-row relative ">
            <button
              ref={editButtonRef}
              className=" mr-4 hover:scale-110"
              onClick={() => {
                if (
                  IsAuthenticated() &&
                  (IsSuperUser() || IsNormalUser() || IsPartnerUser())
                ) {
                  setIsVisible(!isvisible);
                }
              }}
            >
              <img
                className="w-5"
                src="https://cdn.staropstech.com/clients/udharibazaar/assets/extralogo/editPencil.webp"
                alt=""
              />
            </button>
            {isvisible ? (
              IsPartnerUser() ? (
                <EditPartnerModal
                  onClose={toggleEditModal}
                  initialpartnerData={partnerData}
                />
              ) : (
                <EditPartnerModal
                  onClose={toggleEditModal}
                  initialpartnerData={partnerData}
                />
              )
            ) : (
              <div></div>
            )}
          </div>
        </div>
        <div className="w-full p-8 gap-5 flex flex-col shadow-lg rounded-b-2xl bg-white ">
          {partnerData && (
            <>
              <div className="w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4">
                <div className="flex flex-col">
                  <h3 className="font-medium  ">First name</h3>
                  <h3 className=" text-gray-500 font-semibold">
                    {partnerData.first_name || "N/A"}
                  </h3>
                </div>
                <div className="flex flex-col">
                  <h3 className="font-medium">Middle name</h3>
                  <h3 className="text-gray-500 font-semibold">
                    {partnerData.middle_name || "N/A"}
                  </h3>
                </div>
                <div className="flex flex-col">
                  <h3 className="font-medium ">Last Name</h3>
                  <h3 className="text-gray-500 font-semibold">
                    {partnerData.last_name || "N/A"}
                  </h3>
                </div>

                <div className="flex flex-col  ">
                  <h3 className="font-medium ">Email</h3>
                  <h3 className=" text-gray-500 break-all max-w-xs sm:max-w-full">
                    {partnerData.email || "N/A"}
                  </h3>
                </div>

                <div className="flex flex-col">
                  <h3 className="font-medium">Mobile Number</h3>
                  <h3 className="text-gray-500 font-semibold">
                    {partnerData.mobile_number || "N/A"}
                  </h3>
                </div>
                <div className="flex flex-col">
                  <h3 className="font-medium ">User Type</h3>
                  <h3 className="text-gray-500 font-semibold">
                    {checkUserType(
                      partnerData.is_superuser,
                      partnerData.is_partner
                    )}
                  </h3>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PartnerEditCard;
