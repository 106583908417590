import React, { useRef, useState } from "react";
import ModalWindow from "../../Common/Modal/ModalWindow";
import GenerateURL from "../../../utils/ApiUrlProvider";
import { useParams } from "react-router-dom";
import { PutData } from "../../../utils/ApiInvoker";
import { renderInput } from "../FormComponents";

const LoanBankOfferModal = ({ onClose, putUrl, initialValue }) => {
  const params = useParams();
  const initialvalue = {
    is_accepted: "true",
    response_description: "",
  };
  const [bankOffer, setBankOffer] = useState(null);
  const handleBankOfferChange = (e) => {
    const { name, value } = e.target;
    setBankOffer({ ...bankOffer, [name]: value });
  };
  const HandleChangeStatusSucess = () => {
    onClose();
  };
  const HandleChangeStatusError = () => {};

  const UpdateBankOffer = (e) => {
    e.preventDefault();
    // console.log(bankOffer);
    const url = GenerateURL({ id: params.id, secid: initialValue.id }, putUrl);
    PutData(
      url,
      { ...bankOffer },
      HandleChangeStatusSucess,
      HandleChangeStatusError
    );
  };
  useState(() => {
    if (initialValue === null) {
      setBankOffer(initialValue);
    } else {
      setBankOffer({
        is_accepted: initialValue.is_accepted ? "true" : "false",
        response_description: initialValue.response_description,
      });
    }
  }, []);
  return (
    <ModalWindow title="Bank Offer" onClose={onClose}>
      {bankOffer && (
        <form className="flex flex-col w-full gap-1 items-center">
          <div className="w-full">
            {renderInput("BOOLEAN", {
              label: "Do you want to accept",
              name: "is_accepted",
              onChange: handleBankOfferChange,
              value: bankOffer.is_accepted,
              //   error: .first_name,
              isrequired: true,
            })}
            {renderInput("STRING", {
              label: "Description",
              name: "response_description",
              onChange: handleBankOfferChange,
              value: bankOffer.response_description,
              //   error: .first_name,
              isrequired: false,
            })}
          </div>

          <button
            onClick={UpdateBankOffer}
            className=" w-max bg-blue-500 text-white px-1 py-1 md:px-4 md:py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300  "
          >
            save
          </button>
        </form>
      )}
    </ModalWindow>
  );
};

export default LoanBankOfferModal;
