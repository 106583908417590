import React, { useState } from "react";
import CalculatorEnquiry from "../Home/CalculatorEnquiry";
import Footer from "../Home/Footer";
import AmortizationTable from "../../Common/AmortizationTable";

const PersonalLoan = () => {
  const [currentNav, setCurrentNav] = useState(0);
  const [amortizationTableData, setAmortizationTableData] = useState(null);

  const pageData = {
    titles: [
      "Amortization Schedule",
      "Check Your Eligibility",
      "Benefits For You",
      "Documents Required",
    ],
    1: {
      title: "Check Your Eligibility",
      points: [
        "Minimum age of applicant should be 21 years and Maximum age of applicant should be 65 years.",
        "The applicant must be an individual with a minimum monthly income of ₹15,000.",
        "Must have 3 months salary slip.",
        "Receive Salary in a bank account.",
        "Last 6 months bank statement.",
        "Have minimum work experience of 6 months in total and more than 3 months in their current organisation.",
        "Available to both salaried and self-employed individuals.",
      ],
    },
    2: {
      title: "Benefits For You",
      points: [
        "Easy and Paperless documentation.",
        "No need to provide collaterals against the loan.",
        "Can help build your credit score.",
        "Quick disbursal process (Same Day).",
        "Competitive low-interest rates.",
        "Flexible repayment options.",
        "Easy and hassle-free application process.",
      ],
    },
    3: {
      title: "Documents Required",
      points: [
        "Proof of Identity (PAN Card & Passport Size Photo).",
        "Proof of Address (Aadhaar card, Voter ID, Passport or Driving License, E-Bill).",
        "Rent Agreement (If Rented).",
        "Proof of Income (6 months bank statements in PDF format or Net Banking).",
      ],
    },
  };

  const updateAmortizationTableData = (data) => {
    setAmortizationTableData(data);
  };

  return (
    <div className="bg-gray-100 min-h-screen mt-24">
      <div className="container mx-auto mb-10">
        <div className="flex">
          <a
            href="/"
            className="text-sm text-logo-blue font-normal  hover:text-logo-orange border-blue-900 mt-5 ml-3"
          >
            Home{" "}
            <span className="text-sm text-logo-blue font-medium  hover:text-logo-blue ">
              /{" "}
            </span>
          </a>
          <a
            href=""
            className="text-sm text-logo-blue font-normal hover:text-logo-orange  mt-5"
          >
            Personal Loan
          </a>
        </div>
        <div className="text-logo-blue text-center p-4">
          <h1 className="text-4xl font-bold capitalize sm:text-4xl ">
            Personal Loan
          </h1>
          <p className="text-xs font-normal sm:text-base md:mt-4">
            Embark on your aspirations with ease – our personal loans at
            UdhariBazaar offer swift financial solutions tailored to your needs,
            ensuring you realize your dreams without compromise.
          </p>
        </div>

        <div className="flex justify-center items-center mt-10">
          <div className="w-full">
            <CalculatorEnquiry
              updateAmortizationTableData={updateAmortizationTableData}
            />
          </div>
        </div>
        <div className="bg-gray-200 rounded-lg w-full mx-auto mt-8 text-center">
          <ul className="flex items-center justify-start flex-wrap">
            {pageData.titles.map((menue, key) => {
              return (
                <li
                  key={key}
                  onClick={() => {
                    setCurrentNav(key);
                  }}
                  className={`cursor-pointer p-4 ${
                    key === currentNav
                      ? "text-orange-400 border-b-2 border-orange-400"
                      : ""
                  }`}
                >
                  {menue}
                </li>
              );
            })}
          </ul>
          <div className="w-full  py-2 bg-white rounded-b-lg">
            {currentNav === 0 && amortizationTableData && (
              <AmortizationTable data={amortizationTableData} />
            )}
            {currentNav !== 0 && (
              <div className="section-content mx-auto bg-white px-4  flex flex-col items-start">
                <h3 className="text-2xl font-bold text-logo-blue">
                  {pageData.titles[currentNav]}
                </h3>

                <ul className="list-disc list-inside mt-4 text-left text-logo-blue">
                  {pageData[currentNav].points.map((point, index) => (
                    <li key={index} className="mb-2">
                      {point}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PersonalLoan;
