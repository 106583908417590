import React from "react";
import Footer from "../Home/Footer";

const AboutUsPage = () => {
  return (
    <div className="mt-24 flex flex-col items-center">
      <div className="w-full ">
        <img
          src="https://cdn.staropstech.com/clients/udharibazaar/assets/Banners/Get.webp"
          alt="Banner"
          className="w-full h-auto object-cover object-center mb-5  "
        />
      </div>
      <div className="w-3/4 flex gap-5 sm:mt-10">
        <div className=" flex flex-col justify-center">
          <h1 className="text-4xl font-bold capitalize text-logo-blue border-b-2 border-logo-orange max-w-max ">
            About Us
          </h1>
          <p className="mt-5  text-justify ">
            Udharibazaar stands as a trusted and versatile financial
            institution, dedicated to facilitating comprehensive loan provisions
            for individuals and businesses. Founded with a commitment to
            addressing diverse financial needs, we specialise in an extensive
            spectrum of loan offerings, including personal loans, business
            funding, home mortgages, and other tailored financial solutions. Our
            approach is centered on providing accessible and reliable lending
            options, leveraging a seamless application process, competitive
            interest rates, and personalized assistance to ensure our clients
            receive the financial support they require promptly and efficiently.
          </p>
          <p className="mt-5  text-justify  ">
            With a customer-centric ethos at our core, Udharibazaar prides
            itself on fostering enduring relationships by prioritizing
            transparency, integrity, and responsiveness. Our team comprises
            seasoned financial experts who not only comprehend the nuances of
            the lending landscape but also strive to navigate our clients
            through their financial journey with expertise and empathy. Whether
            it's facilitating quick approvals or offering guidance through
            complex financial decisions, we stand committed to delivering
            superior service that aligns with the diverse aspirations and goals
            of our clientele.
          </p>
          <p className="mt-5  text-justify  ">
            Moreover, Udharibazaar champions innovation and technological
            integration within the lending sphere, continually seeking to refine
            and elevate our services. By staying abreast of market trends and
            leveraging cutting-edge tools, we ensure a forward-thinking approach
            that keeps us at the forefront of the financial industry. Our
            unwavering dedication to excellence, combined with a genuine passion
            for empowering individuals and businesses, distinguishes
            Udharibazaar as the go-to destination for reliable, trustworthy, and
            client-focused financial solutions.
          </p>
        </div>
        {/* <div className="w-full hidden lg:block">
          <img
            className="w-full h-auto object-cover object-center "
            src="https://img.freepik.com/free-vector/group-concept-illustration_114360-8541.jpg?w=826&t=st=1704685451~exp=1704686051~hmac=a7e33dd25b5af239eb9caa9ea2dc65895d8e44fd5c17499c901fe88fb1c29369"
            alt=""
          />
        </div> */}
      </div>

      <div className="w-3/4 flex gap-2 lg-10">
        {/* <div className="w-ful hidden md:block">
          <img
            className="w-full h-auto object-cover object-center"
            src="https://img.freepik.com/free-vector/staff-management-perspective-definition-target-orientation-teamwork-organization-business-coach-company-executive-personnel-cartoon-characters_335657-2967.jpg?size=626&ext=jpg&ga=GA1.1.923863604.1702044673&semt=ais"
            alt=""
          />
        </div> */}

        <div className=" flex flex-col justify-center  items-end">
          <h1 className=" text-4xl font-bold text-logo-blue border-b-2 border-logo-orange max-w-max   ">
            Mission and Vision
          </h1>
          <p className="mt-5 text-justify">
            Udharibazaar's mission revolves around empowering individuals and
            businesses by offering flexible, innovative, and responsible
            financial solutions. We aim to be a dependable partner in our
            clients' journeys, providing them with the means to achieve their
            goals through tailored lending products and personalized services.
            Our commitment to ethical conduct, transparency, and customer
            satisfaction forms the cornerstone of our mission, enabling us to
            make a meaningful impact on the financial well-being of those we
            serve.
          </p>
          <p className="mt-5 text-justify ">
            Our vision at Udharibazaar is to be the trailblazer in the lending
            industry, setting the standard for excellence and pioneering
            advancements in financial services. We strive to continuously evolve
            and adapt, harnessing cutting-edge technology and market insights to
            deliver unparalleled solutions. By upholding principles of
            integrity, inclusivity, and superior customer service, we aim to
            become the preferred choice for individuals and businesses seeking
            trustworthy and reliable financial support.
          </p>
        </div>
      </div>
      {/* Icons */}
      {/* <div className="flex gap-10 w-3/4  text-center mt-10">
        <div className="flex flex-col gap-2 hover:bg-orange-400 hover:bg-opacity-10 rounded-lg  m-auto p-3 ">
          <img
            className="h-20 w-20 "
            src="https://cdn-icons-png.flaticon.com/128/11337/11337416.png"
            alt=""
          />
          <p>Mission and Vision</p>
        </div>
        <div className="flex flex-col gap-2 hover:bg-orange-400 hover:bg-opacity-10 rounded-lg  m-auto p-3 ">
          <img
            className="h-20 w-20 "
            src="https://cdn-icons-png.flaticon.com/128/11337/11337416.png"
            alt=""
          />
          <p>Mission and Vision</p>
        </div>
        <div className="flex flex-col gap-2 hover:bg-orange-400 hover:bg-opacity-10 rounded-lg  m-auto p-3 ">
          <img
            className="h-20 w-20 "
            src="https://cdn-icons-png.flaticon.com/128/11337/11337416.png"
            alt=""
          />
          <p>Mission and Vision</p>
        </div>
        <div className="flex flex-col gap-2 hover:bg-orange-400 hover:bg-opacity-10 rounded-lg  m-auto p-3 ">
          <img
            className="h-20 w-20 "
            src="https://cdn-icons-png.flaticon.com/128/11337/11337416.png"
            alt=""
          />
          <p>Mission and Vision</p>
        </div>
      </div> */}
      {/* Disclaimer */}
      <div className="w-3/4 flex gap-2 mt-10 mb-10">
        <div className="flex flex-col gap-2">
          <h1 className="text-xs font-medium capitalize text-logo-blue border-b-2 border-logo-orange max-w-max ">
            Disclaimer
          </h1>
          <p className=" text-xs font-normal  text-gray-400">
            The information provided by Udharibazaar is for general
            informational purposes only and should not be considered as
            financial advice. While we strive to ensure the accuracy and
            reliability of the information presented, we do not warrant or
            guarantee the completeness, suitability, or availability of our
            services for any particular purpose. Clients are advised to seek
            professional financial guidance and thoroughly review all terms and
            conditions before making any financial decisions. Udharibazaar shall
            not be held liable for any losses or damages arising from the use of
            our services or reliance on the information provided.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AboutUsPage;
