import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IsAssociatePartner,
  IsAuthenticated,
  IsNormalUser,
  IsPartnerUser,
  IsSuperUser,
} from "../utils/AccessTokenStore";

const DashboardHeader = (props) => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [isActive, setIsActive] = useState(false);

  const GetProfile = () => {
    const data = JSON.parse(localStorage.getItem("authData")).userDetails;
    if (data !== null || data !== undefined) {
      setProfile(data);
    }
  };
  const redirectToHomePage = () => {
    if (IsAuthenticated()) {
      if (IsNormalUser()) {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    GetProfile();
  }, []);

  const initiateLogout = () => {
    if (IsAuthenticated()) {
      if (IsSuperUser()) {
        localStorage.removeItem("authData");
        navigate("/admin/login");
      } else if (IsPartnerUser() || IsAssociatePartner()) {
        localStorage.removeItem("authData");
        navigate("/partner/login");
      } else {
        localStorage.removeItem("authData");
        navigate("/");
      }
    } else {
      navigate("/");
    }
  };

  const redirectToEditProfile = () => {
    if (IsAuthenticated()) {
      if (IsSuperUser()) {
        navigate(`/admin/profile`);
      } else if (IsPartnerUser() || IsAssociatePartner()) {
        navigate(`/partner/profile`);
      } else {
        navigate(`/user/profile`);
      }
    } else {
      navigate(`/user/profile`);
    }
    setIsActive(false);
  };

  const toggleDropdown = () => {
    setIsActive((prevState) => !prevState);
  };

  const handleMouseLeave = () => {
    if (!isActive) {
      setIsActive(false);
    }
  };
  return (
    <>
      <div className="fixed w-full z-50">
        <div className="flex-col flex">
          <div className="w-full border-b-2 border-gray-200">
            <div className="bg-white h-16 justify-between items-center mx-auto px-2 md:px-4 flex">
              <button
                className="inline-flex items-center py-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden focus:outline-none"
                onClick={() => {
                  props.toggleSidebar();
                }}
              >
                {props.sidebarOpen ? (
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      fillRule="evenodd"
                      d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                    ></path>
                  </svg>
                )}
              </button>

              <div>
                <img
                  src="https://cdn.staropstech.com/clients/udharibazaar/assets/extra-images/logo.webp"
                  className="block h-9 w-auto cursor-pointer"
                  alt="udharibazar"
                  onClick={() => redirectToHomePage()}
                />
              </div>
              <div className="group relative py-10">
                <div
                  className="md:space-x-6 justify-end items-center ml-auto flex space-x-3 cursor-pointer  min-w-[150px]"
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="justify-center items-center flex relative">
                    {profile && (
                      <p className="block py-2 pl-3 pr-4 text-gray-900 rounded font-semibold  hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-600 md:p-0">
                        {profile.name ? profile.name : profile.mobile_number}
                      </p>
                    )}
                    <svg
                      className="w-2.5 h-2.5 ml-2.5 rotate-element transform group-hover:rotate-180 transition duration-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                      onClick={toggleDropdown}
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 4 4 4-4"
                      />
                    </svg>
                  </div>

                  <div
                    className={`absolute ${
                      isActive ? "block" : "hidden"
                    } group-hover:block  hover:block  w-full bottom-0 translate-y-16 bg-white rounded-md`}
                  >
                    <div className="container rounded-md text-left md:w-auto">
                      <ul className="py-1 text-sm text-gray-700 w-full ">
                        <li className="flex flex-row align-items-center w-full">
                          <button
                            disabled={props.isProfileDisabled}
                            className={`${
                              props.isProfileDisabled
                                ? " hover:bg-gray-50 hover:text-gray-400 hover:cursor-not-allowed"
                                : "hover:bg-gray-50 hover:text-blue-600"
                            } block px-4 py-2  w-full text-left`}
                            onClick={redirectToEditProfile}
                          >
                            Profile
                          </button>
                        </li>

                        <li className="flex flex-row align-items-center ">
                          <button
                            className="block px-4 py-2 hover:bg-gray-50 hover:text-blue-600 w-full text-left"
                            onClick={initiateLogout}
                          >
                            Logout
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardHeader;
