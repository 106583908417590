import React, { useState, useEffect } from "react";
import "../../index.css";

const DashboardBanner = () => {
  const images = [
    "https://cdn.staropstech.com/clients/udharibazaar/assets/extra-images/banner1.webp",
    "https://cdn.staropstech.com/clients/udharibazaar/assets/extra-images/banner2.webp",
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const changeImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const interval = setInterval(changeImage, 3000);

    return () => clearInterval(interval);
  }, [currentImageIndex, images.length]);

  return (
    <div className="carousel-container">
      <div
        className="carousel-wrapper"
        style={{ transform: `translateX(-${currentImageIndex * 100}%)` }}
      >
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Banner ${index}`}
            className="carousel-image object-center mb-5 rounded-2xl mt-10"
          />
        ))}
      </div>
    </div>
  );
};

export default DashboardBanner;
