import React, { useEffect, useRef } from "react";

const SupportChatModal = ({ onClose, title, children }) => {
  const modalRef = useRef();
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };
  const handleInteractionInsideModal = (event) => {
    event.stopPropagation();
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50"
      onClick={onClose}
    >
      <div
        className="w-[80%] sm:w-[55%] md:w-full sm:ml-60 lg:w-1/2  bg-white rounded-lg shadow-lg max-h-[90vh] -mt-20 p-4"
        onClick={handleInteractionInsideModal}
        ref={modalRef}
      >
        <div className="flex justify-between border-b-2 font-bold pb-1">
          <h1 className="font-medium text-xl ">{title}</h1>
          <button className="rounded-lg px-1 hover:scale-110">
            <img
              src="https://cdn-icons-png.flaticon.com/128/2997/2997911.png"
              alt="close"
              className="h-4"
              onClick={onClose}
            />
          </button>
        </div>
        <>{children}</>
      </div>
    </div>
  );
};

export default SupportChatModal;
