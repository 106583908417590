import React, { useEffect, useState } from "react";
import GenerateURL from "../../../utils/ApiUrlProvider";
import { GetData } from "../../../utils/ApiInvoker";
import properties from "../../../Properties/Properties";
import DashboardBanner from "../../Common/DashboardBanner";
import ListTable from "../../Common/ListTable";

const RecentLoggedUserList = () => {
  const [pagination, setPagination] = useState(null);
  const [tableData, setTableData] = useState(null);
  const convertDataToTableFormat = (data) => {
    const tableHeaders = ["User Profile", "Username", "Mobile Number", "Email"];
    const tableContent = data.map((item) => {
      return {
        Username: item.username,
        "User Profile": item.user_profile,
        "Mobile Number": item.mobile_number,
        Email: item.email,
      };
    });
    return {
      tableHeaders,
      tableContent,
    };
  };

  const HandleSucess = (res) => {
    console.log(res);
    setPagination(res);
    const data = convertDataToTableFormat(res.results);

    setTableData(data);
  };
  const HandleError = (res) => {
    console.log(res);
  };
  const GetNextRecentLoginedUsers = (nexturl) => {
    const url = GenerateURL({}, nexturl);
    GetData(url, HandleSucess, HandleError, false, false);
  };

  const GetPrevRecentLoginedUsers = (prevurl) => {
    const url = GenerateURL({}, prevurl);
    GetData(url, HandleSucess, HandleError, false, false);
  };
  const GetRecentUserData = () => {
    const url = GenerateURL({}, properties.api.adminRecentUser);
    GetData(url, HandleSucess, HandleError, false, false);
  };

  useEffect(() => {
    GetRecentUserData();
  }, []);

  return (
    <div className="w-11/12">
      <DashboardBanner />
      {tableData && (
        <div className="w-full flex flex-col  gap-2 ">
          <ListTable
            heading="Total Users"
            tableData={tableData}
            pagination={Boolean(pagination.next || pagination.previous)}
            next={pagination.next}
            prev={pagination.previous}
            onNextClick={() => GetNextRecentLoginedUsers(pagination.next)}
            onPrevClick={() => GetPrevRecentLoginedUsers(pagination.previous)}
          />
        </div>
      )}
    </div>
  );
};

export default RecentLoggedUserList;
