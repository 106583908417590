export function ValidateInput(validations, inputString) {
  if (!inputString && !validations.is_required) {
    return { isValid: true, message: "Validated" };
  }
  if (typeof inputString === "object") {
    return { isValid: true, message: "Input is an object." };
  }
  const maxLength = parseInt(validations.max_length);
  const minLength = parseInt(validations.min_length);
  const isRequired = validations.is_required;
  const regexPattern = validations.regex_pattern;

  let isValid = true;
  let message = "Input is validated.";

  // Check if input is required and empty or whitespace
  // if (isRequired && inputString.trim() === "") {
  //   return {
  //     isValid: false,
  //     message: "Input is required but empty.",
  //   };
  // }
  if (isRequired && inputString === "") {
    return {
      isValid: false,
      message: "Input is required but empty.",
    };
  }

  // Check input length
  if (inputString.length < minLength || inputString.length > maxLength) {
    isValid = false;
    if (inputString.length < minLength) {
      return {
        isValid: false,
        message: `Input length is less than ${minLength} characters.`,
      };
    } else {
      return {
        isValid: false,
        message: `Input length exceeds ${maxLength} characters.`,
      };
    }
  }
  // Optionally check regex pattern
  if (regexPattern !== "*") {
    const regex = new RegExp(regexPattern);
    if (!regex.test(inputString)) {
      isValid = false;
      message = "Input does not match the specified pattern.";
    }
  }

  return { isValid, message };
}

export function validateImageInput(inputData, validationRules) {
  let errorMessages = "";
  if (!validationRules) {
    return {
      isValidated: errorMessages === "",
      errorMessages,
    };
  }
  if (inputData === undefined || inputData === null) {
    errorMessages = "Please select the file";
    return { isValidated: false, errorMessages };
  }

  // Check if data is required and present
  if (validationRules.is_required && !inputData) {
    errorMessages = "This field is required.";
    return { isValidated: false, errorMessages };
  }

  // Check file size if specified
  if (validationRules.file_size && inputData.size) {
    const maxSize = parseInt(validationRules.file_size) * 1024 * 1024; // Convert MB to Bytes
    if (inputData.size > maxSize) {
      errorMessages = `File size should be less than ${validationRules.file_size}.`;
    }
  }

  // Check file type if specified
  if (validationRules.file_type && inputData.name) {
    const fileType = inputData.name.split(".").pop().toLowerCase();
    if (!validationRules.file_type.includes(fileType)) {
      errorMessages = `File type should be ${validationRules.file_type.join(
        ", "
      )}.`;
    }
  }

  return {
    isValidated: errorMessages === "",
    errorMessages,
  };
}
