import React, { useEffect, useRef, useState } from "react";
import GenerateURL, { GenerateSearchURL } from "../../../utils/ApiUrlProvider";
import properties from "../../../Properties/Properties";
import { GetData, PutData } from "../../../utils/ApiInvoker";
import { useLoadingContext } from "../../../Context/LoadingContext";
import { renderInput } from "../../Common/FormComponents";
import { toast } from "react-toastify";

const AssignRMModal = ({ onClose, userData }) => {
  const [formData, setFormData] = useState({
    rm_name: "",
    relational_manager: "",
  });
  const [formErrors, setFormErrors] = useState({
    rm_name: "",
  });
  const modalRef = useRef();
  const { showLoader, hideLoader } = useLoadingContext();
  const [relationManagers, setRelationManagers] = useState([]);
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };
  const handleInteractionInsideModal = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleSaveClick = () => {
    if (!userData.partner_profile) {
      toast.error("Partner Profile is not Present");
      onClose();
      return;
    }
    updateRm();
    onClose();
  };

  const validateForm = () => {
    let valid = true;
    const errors = {};

    if (!formData.rm_name || formData.rm_name === "Select") {
      errors.rm_name = "Please select a Relation Manager";
      valid = false;
    }
    setFormErrors(errors);
    return { valid, errors };
  };

  function saveResponseSuccess(response) {
    window.location.reload();
    hideLoader();
  }
  function saveResponseFailure(response) {
    onClose();
    hideLoader();
  }

  function getDataSuccess(res) {
    setRelationManagers(res.results);
  }
  function getDataFailure(res) {
    console.log("Error fetching relation managers:", res);
  }

  useEffect(() => {
    const url = GenerateSearchURL(
      { all: true },
      properties.api.adminRelationManager
    );
    GetData(url, getDataSuccess, getDataFailure, false, false);
  }, []);

  function updateRm(e) {
    const validation = validateForm();

    if (validation.valid) {
      const url = GenerateURL(
        { id: userData.partner_profile.id },
        properties.api.adminaddrm
      );
      showLoader();
      PutData(url, formData, saveResponseSuccess, saveResponseFailure, false);
    }
  }

  const handlechange = (e) => {
    const { name, value } = e.target;
    const selectedRM = relationManagers.find((rm) => rm.name === value);
    setFormData({
      ...formData,
      [name]: value,
      relational_manager: selectedRM ? selectedRM.id : "",
    });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60"
      onClick={onClose}
      style={{ zIndex: 1000 }}
    >
      <div
        className="w-[80%] sm:w-[55%] md:1/2 sm:ml-60 p-6 bg-white rounded-lg shadow-lg mt-48 mb-80 lg:w-1/2"
        onClick={handleInteractionInsideModal}
        style={{ zIndex: 1001 }}
        ref={modalRef}
      >
        <div className="flex justify-between border-b-2 pb-4 text-2xl md:text-3xl  font-bold text-gray-800 mb-4 md:mb-6">
          <h1 className="font-medium text-xl ">Assign Relational Manager</h1>
          <div className="flex flex-row relative">
            <button className="rounded-lg px-1  hover:scale-110">
              <img
                src="https://cdn-icons-png.flaticon.com/128/2997/2997911.png"
                alt=""
                className="h-5"
                onClick={onClose}
              />
            </button>
          </div>
        </div>
        <div className="mb-4 flex gap-1 sm:gap-2 md:gap-3">
          <div className="w-full flex flex-col">
            {Array.isArray(relationManagers) && relationManagers.length > 0 ? (
              renderInput("DROP_DOWN", {
                label: "Relation Manager",
                name: "rm_name",
                value: formData.rm_name,
                onChange: handlechange,
                options: [
                  { value: "", label: "Select" },
                  ...relationManagers.map((rm) => ({
                    value: rm.name,
                    label: rm.name,
                  })),
                ],
                error: formErrors.rm_name,
              })
            ) : (
              <p className="text-center">Loading relation managers...</p>
            )}

            <div className="flex justify-center items-center mt-4">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
                onClick={handleSaveClick}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignRMModal;
