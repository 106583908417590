export const BasicInfoBLStage1val = (data) => {
  const errors = {};
  const isValidName = (name) => /^[A-Za-z ]+$/.test(name);
  const isValidMobileNumber = (number) => /^\d{10}$/.test(number);
  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isValidDate = (date) =>
    /^\d{4}-\d{2}-\d{2}$/.test(date) && !isNaN(new Date(date).getTime());
  const isValidPAN = (pan) => /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/.test(pan);

  const nameFields = ["first_name", "last_name", "father_name", "mother_name"];
  nameFields.forEach((field) => {
    if (!data[field]) {
      errors[field] = `${field.replace("_", " ")} is required`;
    } else if (!isValidName(data[field])) {
      errors[field] = `${field.replace("_", " ")} should contain only letters`;
    }
  });

  if (!data.mobile_number) {
    errors.mobile_number = "Mobile number is required";
  } else if (!isValidMobileNumber(data.mobile_number)) {
    errors.mobile_number = "Mobile number should be a 10-digit number";
  }

  if (!data.email) {
    errors.email = "Email address is required";
  } else if (!isValidEmail(data.email)) {
    errors.email = "Invalid email address";
  }

  if (!data.dob) {
    errors.dob = "Date of birth is required";
  } else if (!isValidDate(data.dob)) {
    errors.dob = "Date of birth should be in YYYY-MM-DD format";
  }

  if (!data.pan_number) {
    errors.pan_number = "PAN number is required";
  } else if (!isValidPAN(data.pan_number)) {
    errors.pan_number = "PAN number should be in the format AAAAA9999A";
  }

  if (!data.gender) {
    errors.gender = "Gender is required";
  }

  if (Object.keys(errors).length === 0) {
    return { valid: true, errors: {} };
  } else {
    return { valid: false, errors: errors };
  }
};

export const BasicInfoBLStage2val = (data) => {
  const errors = {
    personal_address: {
      house_address: "",
      city: "",
      state: "",
      pin_code: "",
      year_at_present_address: "",
      landmark: "",
    },
    current_address: {
      house_address: "",
      city: "",
      state: "",
      pin_code: "",
      year_at_present_address: "",
      landmark: "",
    },
  };

  const addressFields = ["house_address", "city", "state", "pin_code"];
  let isValid = true;
  let errorCount = 0;

  function validateAddressFields(address, addressErrors) {
    addressFields.forEach((field) => {
      if (!address[field]) {
        addressErrors[field] = `${field.replace("_", " ")} is required`;
        isValid = false;
        errorCount++;
      }
    });

    if (address.pin_code && !/^\d{6}$/.test(address.pin_code)) {
      addressErrors.pin_code = "Invalid pin code";
      isValid = false;
      errorCount++;
    }

    if (
      address.year_at_present_address &&
      !/^\d{1,2}$/.test(address.year_at_present_address)
    ) {
      addressErrors.year_at_present_address = "Invalid year";
      isValid = false;
      errorCount++;
    }
  }

  validateAddressFields(data.personal_address, errors.personal_address);

  validateAddressFields(data.current_address, errors.current_address);

  if (
    errorCount === 0 ||
    (errorCount === 1 &&
      Object.keys(errors.personal_address).filter(
        (field) => errors.personal_address[field] !== ""
      ).length === 1)
  ) {
    return {
      valid: true,
      errors: {},
    };
  }

  return {
    valid: isValid,
    errors: isValid ? {} : errors,
  };
};

export const BasicInfoBLStage3val = (data) => {
  const errors = {};
  if (!data.registered_as) {
    errors.registered_as = "Select any one";
  }

  const numberRegex = /^\d*\.?\d+$/;

  if (!numberRegex.test(data.loan_amount)) {
    errors.loan_amount = "Loan amount should be a number";
  }
  if (!numberRegex.test(data.tenure_in_year)) {
    errors.tenure_in_year = "Loan tenure should be a number";
  }
  if (Object.keys(errors).length === 0) {
    return { valid: true, errors: {} };
  } else {
    return { valid: false, errors };
  }
};

export const FirmValBLstage1 = (data) => {
  const errors = {};
  const isValidName = (name) => /^[A-Za-z0-9 ]+$/.test(name); // Updated to allow alphanumeric and spaces
  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isValidPAN = (pan) => /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/.test(pan);
  const isValidGST = (gst) =>
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[A-Z0-9]{1}$/.test(gst);
  const isValidUdyam = (udyam) => /^[A-Z0-9]{12}$/.test(udyam);
  const isValidNumber = (number) => /^\d+(\.\d{1,2})?$/.test(number); // Allows numbers with up to 2 decimal places

  // Validate firm_name
  if (data.firm_name && !isValidName(data.firm_name)) {
    errors.firm_name =
      "Firm name should contain only letters, numbers, and spaces";
  }

  // Validate firm_pan_no
  // if (!data.firm_pan_no) {
  //   errors.firm_pan_no = "Firm PAN number is required";
  if (data.firm_pan_no && isValidPAN(data.firm_pan_no.toUpperCase())) {
    errors.firm_pan_no = "Firm PAN number should be in the format AAAAA9999A";
  }

  // Validate gst_no
  // if (!data.gst_no) {
  //   errors.gst_no = "GST number is required";
  if (data.gst_no && !isValidGST(data.gst_no.toUpperCase())) {
    errors.gst_no = "Invalid GST number format";
  }

  // Validate udyam_no
  // if (!data.udyam_no) {
  //   errors.udyam_no = "Udyam registration number is required";
  // } else if (!isValidUdyam(data.udyam_no.toUpperCase())) {
  //   errors.udyam_no = "Udyam registration number should be 12 characters long";
  // }

  // Validate official_email
  if (!data.official_email) {
    errors.official_email = "Official email address is required";
  } else if (!isValidEmail(data.official_email)) {
    errors.official_email = "Invalid email address";
  }

  // Validate business_turnover
  if (!data.business_turnover) {
    errors.business_turnover = "Business turnover is required";
  } else if (!isValidNumber(data.business_turnover)) {
    errors.business_turnover = "Invalid business turnover format";
  }

  // Validate business_vintage
  if (!data.business_vintage) {
    errors.business_vintage = "Business vintage is required";
  } else if (!isValidNumber(data.business_vintage)) {
    errors.business_vintage = "Invalid business vintage format";
  }

  // Validate nature_of_business
  if (!data.nature_of_business) {
    errors.nature_of_business = "Nature of business is required";
  } else if (!isValidName(data.nature_of_business)) {
    errors.nature_of_business =
      "Nature of business should contain only letters, numbers, and spaces";
  }

  // Validate product_category
  if (!data.product_category) {
    errors.product_category = "Product category is required";
  }

  if (Object.keys(errors).length === 0) {
    return { valid: true, errors: {} };
  } else {
    return { valid: false, errors: errors };
  }
};
export const FirmValBLstage2 = (data) => {
  const errors = {
    firm_address: {
      house_address: "",
      city: "",
      state: "",
      pin_code: "",
      year_at_present_address: "",
      landmark: "",
    },
  };

  const addressFields = ["house_address", "city", "state", "pin_code"];

  // Function to validate address fields
  function validateAddressFields(address) {
    let isValid = true;

    addressFields.forEach((field) => {
      if (!address[field]) {
        errors.firm_address[field] = `${field.replace("_", " ")} is required`;
        isValid = false;
      }
    });

    if (address.pin_code && !/^\d{6}$/.test(address.pin_code)) {
      errors.firm_address.pin_code = "Invalid pin code";
      isValid = false;
    }

    if (
      address.year_at_present_address &&
      !/^\d{1,2}$/.test(address.year_at_present_address)
    ) {
      errors.firm_address.year_at_present_address = "Invalid year";
      isValid = false;
    }

    return isValid;
  }

  // Validate firm_address
  const isValid = validateAddressFields(data.firm_address);

  return {
    valid: isValid,
    errors: isValid ? {} : errors,
  };
};
