import React, { useEffect, useRef, useState } from "react";
import { renderInput } from "../FormComponents";
import { PutData } from "../../../utils/ApiInvoker";
import GenerateURL from "../../../utils/ApiUrlProvider";
import { toast } from "react-toastify";

const FileUploadModel = ({ onClose, filedata, api }) => {
  const modalRef = useRef();
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };
  const handleInteractionInsideModal = (event) => {
    event.stopPropagation();
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const [file, setFile] = useState();

  const HandleFileChange = (e) => {
    setFile(e.target.value);
  };

  const HandleSubmitSucess = (e) => {
    console.log(e);
    toast.success("File Uploaded Sucessfully", {
      autoClose: 1000,
    });
    onClose();
  };
  const HandleSubmitError = (e) => {
    toast.error("something went wrong", {
      autoClose: 1000,
    });
  };
  const HandleSubmitResponse = (e) => {
    e.preventDefault();
    const url = GenerateURL({ id: api.id, secid: api.secid }, api.url);
    PutData(
      url,
      { [filedata.name]: file },
      HandleSubmitSucess,
      HandleSubmitError,
      false
    );
  };

  return (
    <div
      className="fixed inset-0  flex items-center justify-center bg-black bg-opacity-60  z-40"
      onClick={onClose}
    >
      <div
        className="w-[99%] sm:w-[55%]  md:w-[40%] sm:ml-60 p-6 bg-white rounded-lg shadow-lg  "
        onClick={handleInteractionInsideModal}
        ref={modalRef}
      >
        <div className="p-8 gap-5 flex flex-col items-center justify-center">
          {renderInput("FILE_UPLOAD", {
            label: filedata.label,
            name: filedata.name,
            code: filedata.name,
            value: file,
            onChange: HandleFileChange,
            onlyId: true,
            isrequired: false,
            validations: {
              file_size: "10MB",
              file_type: ["jpg", "png", "pdf", "heic", "jpeg"],
              is_required: false,
            },
          })}
        </div>
        <div className="flex items-center justify-center mt-4">
          <button
            onClick={onClose}
            className="mr-4 px-4 py-2 text-gray-600 bg-gray-200 rounded hover:bg-gray-300 focus:outline-none focus:bg-gray-300"
          >
            Cancel
          </button>
          <button
            disabled={!file}
            onClick={HandleSubmitResponse}
            className="px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600 focus:outline-none focus:bg-green-600"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default FileUploadModel;
