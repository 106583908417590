import React, { useEffect, useState } from "react";
import ListTable from "../../Common/ListTable";
import GenerateURL, { GenerateSearchURL } from "../../../utils/ApiUrlProvider";
import properties from "../../../Properties/Properties";
import { GetData } from "../../../utils/ApiInvoker";
import { useNavigate } from "react-router-dom";
import { GetTimeAgo } from "../../../utils/Healpers";
import MissingDataImage from "../../Common/MissingDataImage";
import DashboardBanner from "../../Common/DashboardBanner";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchBar from "../../Common/SearchBar";

const InsuranceList = () => {
  const [tableData, setTableData] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [rawdata, setRawdata] = useState(null);

  const navigate = useNavigate();

  const getIdFromApplicationNumber = (applicationNumber) => {
    console.log(rawdata);
    for (let i = 0; i < rawdata.length; i++) {
      if (rawdata[i].application_number === applicationNumber) {
        const { id } = rawdata[i];
        return {
          id,
        };
      }
    }
    return null;
  };

  const clickOn = (e) => {
    const loaninfo = getIdFromApplicationNumber(e["Application Number"]);
    navigate(`/admin/insurance/${loaninfo.id}`);
  };

  const clickableOn = {
    on: ["Application Number"],
    funct: { "Application Number": clickOn },
  };

  const convertDataToTableFormat = (data) => {
    const tableHeaders = [
      "Application Number",
      "Disbursement Amount",
      "Applied By",
      "Status",
      "Time",
    ];

    const tableContent = data.map((user) => {
      return {
        "Application Number": user.application_number || "--",
        "Disbursement Amount": user.disbursement_amount || "--",
        // "Applied By": user.user.mobile_number,
        "Applied By": (
          <span className="relative group">
            <span className="group-hover:underline cursor-pointer text-blue-500 -z-50">
              {user.user.user_profile.username}
            </span>
            <div className="absolute hidden left-5 bottom-3 bg-blue-100 border border-blue-300 p-2 rounded shadow-md group-hover:block w-52 z-50">
              <div className="text-black font-bold text-sm">
                Name:{user.user.first_name || ""}
              </div>
              <div className="text-black text-sm">
                Mobile:{user.user.mobile_number || ""}
              </div>
            </div>
          </span>
        ),
        applied_by_user: user.user.id,
        Status: user.status || "N/A",
        Time: GetTimeAgo(user.created_at),
        id: user.id,
      };
    });
    return {
      tableHeaders,
      tableContent,
    };
  };

  const HandleSearchSucess = (res) => {
    // console.log(res.results);
    if (res.results.length === 0) {
      toast.error("No Data found");
      GetInsuranceList();
    } else {
      HandleSucess(res);
    }
  };

  const searchParameters = [
    { name: "Application No", parm: "application_number" },
    { name: "Applied By", parm: "user_code" },
    { name: "Status", parm: "status" },
  ];

  const SearchClick = (e) => {
    const url = GenerateSearchURL(e, properties.api.adminInsurance);
    GetData(url, HandleSearchSucess, HandleError, false, false);
  };
  const EmptyCall = () => {
    GetInsuranceList();
  };

  const HandleSucess = (response) => {
    const data = convertDataToTableFormat(response.results);
    setTableData(data);
    setPagination(response);
    setRawdata(response.results);
  };

  const HandleError = (response) => {
    console.log(response);
  };

  const GetNextLoanList = (nexturl) => {
    const url = GenerateURL({}, nexturl);
    GetData(url, HandleSucess, HandleError, false, false);
  };

  const GetPrevLoanList = (prevurl) => {
    const url = GenerateURL({}, prevurl);
    GetData(url, HandleSucess, HandleError, false, false);
  };

  const GetInsuranceList = () => {
    const url = GenerateURL({}, properties.api.adminInsurance);
    GetData(url, HandleSucess, HandleError, false, false);
  };

  useEffect(() => {
    GetInsuranceList();
  }, []);

  const view = (e) => {
    navigate(`/admin/insurance/${e.id}`);
  };

  return (
    <>
      <div className="w-11/12">
        <DashboardBanner />
        {tableData && tableData.tableContent.length === 0 ? (
          <div className="flex flex-col gap-2">
            <MissingDataImage message={"No Insurance Applications Found"} />
          </div>
        ) : (
          tableData && (
            <div className="w-full flex flex-col  gap-2 ">
              <SearchBar
                searchParameters={searchParameters}
                SearchClick={SearchClick}
                EmptyCall={EmptyCall}
              />
              <ListTable
                heading="Loan Lead"
                tableData={tableData}
                pagination={Boolean(pagination.next || pagination.previous)}
                next={pagination.next}
                prev={pagination.previous}
                onNextClick={() => GetNextLoanList(pagination.next)}
                onPrevClick={() => GetPrevLoanList(pagination.previous)}
                actions={{ view: true, edit: false, delete: false }}
                onView={view}
                clickabaleOn={clickableOn}
              />
            </div>
          )
        )}
      </div>
    </>
  );
};

export default InsuranceList;
