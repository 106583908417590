import React from "react";
import DashboardBanner from "../../Common/DashboardBanner";
import MissingDataImage from "../../Common/MissingDataImage";

const InsurnaceAmountList = () => {
  return (
    <>
      <div className="w-11/12">
        <DashboardBanner />
        <div className="flex flex-col gap-2">
          <MissingDataImage message={"No Total Insurance Amount Record"} />
        </div>
      </div>
    </>
  );
};

export default InsurnaceAmountList;
