import React, { useState } from "react";
import DashboardHeader from "../../../Hoc/DashboardHeader";
import SidePannel from "../../../Hoc/SidePannel";
import { Outlet } from "react-router-dom";
import {
  PartnersMenuItems,
  AssociatePartnersMenuItems,
} from "../../../helper/MenuItems";
import { IsAssociatePartner } from "../../../utils/AccessTokenStore";

const PartnerDashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div>
      <DashboardHeader
        sidebarOpen={sidebarOpen}
        toggleSidebar={toggleSidebar}
      />

      <div className="flex min-h-screen">
        <SidePannel
          sidebarOpen={sidebarOpen}
          item={
            IsAssociatePartner()
              ? AssociatePartnersMenuItems
              : PartnersMenuItems
          }
        />
        <div className="sm:ml-64 mt-7  w-full  bg-slate-100 flex  justify-center border-2  ">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default PartnerDashboard;
