import React from "react";
import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";

const PrivacyPage = () => {
  return (
    <div>
      <Navbar />
      <h1 className="text-4xl text-logo-blue text-center font-bold capitalize sm:text-4xl border-blue-900 mt-4">
        Privacy and Policies Of UdhariBazaar
      </h1>
      <div className="flex justify-center mt-10">
        <div className=" w-3/4 flex flex-col bg-gray-100 p-10 mb-10 rounded-lg">
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">Privacy Policy</p>
            <p>
              This document ("Privacy Policy") is published in compliance with
              the Information Technology Act, 2000, and RBI Guidelines on
              Digital Lending dated September 2, 2022. These regulations mandate
              intermediaries to disclose rules, regulations, privacy policies,
              and terms for accessing or using their website/mobile app. This
              policy is primarily governed by Rule 3(1) of the Information
              Technology (Intermediaries Guidelines) Rules, 2011, and the
              Information Technology (Reasonable Security Practices and
              Procedures and Sensitive Personal Data or Information) Rules,
              2011.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">Commitment</p>
            <p>
              UdhariBazaar is committed to safeguarding the privacy of its users
              and members on its Website and ensuring the privacy of the data
              they provide. For the purpose of this Privacy Policy, the term
              "Website" encompasses the website (www.udharibazaar.com) and the
              mobile application (UdhariBazaar Business Solutions) of
              UdhariBazaar.
              <br />
              The privacy of all visitors and registered users on our Website is
              crucial to us. We are dedicated to upholding your right to privacy
              and ensuring the security of your personal and other information.
              We encourage you to review our privacy statement to comprehend the
              types of personally identifiable information we collect and how we
              utilize this information.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">
              What type of information does UdhariBazaar.com collect from you?
              <br />
              Personal Information
            </p>
            <p>
              To provide our services, registration on our Website is required.
              When you become a registered member, it is necessary to provide
              your name, email address, gender, date of birth, postal address,
              phone number, a unique login name, password, income tax details,
              marital status, family details, business information, and other
              details shared through application forms, emails, or any other
              medium (Personally Identifiable Information).
              <br />
              Additionally, UdhariBazaar may collect other information, such as
              bank statements, KYC documents, personal details, business
              details, marital status, family details, and more, via application
              forms, emails, or other electronic or printed forms (Service
              Information). The compilation of Personally Identifiable
              Information and Service Information is collectively referred to as
              "Information."
              <br />
              In certain instances, with your consent, UdhariBazaar may request
              access to your email account to collect information like bank
              statement details, subject to your approval. This access is
              authorized through the email provider's mechanism, and we solely
              access financial service provider emails without intrusion into
              personal correspondence. UdhariBazaar does not access any other
              personal information without explicit consent.
              <br />
              Moreover, UdhariBazaar collects User Information, including
              feedback, suggestions, opinions, etc., shared on our Website's
              discussion forums or other pages. Such details, being in the
              public domain, are accessible to all visitors. We advise caution
              before making such information public, as it might be vulnerable
              to misuse.
            </p>
            <p className="ml-4">
              1. Mobile Device Information: Technical data about your device,
              operating system, application software, etc., gathered
              periodically to facilitate software updates and product support.
            </p>
            <p className="ml-4">
              2. App Usage Data: Transaction history, log information, IP
              address, crashes, etc., utilized to identify and resolve technical
              glitches and enhance the app's functionality.
            </p>
            <p className="ml-4">
              3. SMS Information: Collection of non-personal SMS from 6-digit
              alphanumeric senders used for credit assessment and improving app
              experience.
            </p>
            <p className="ml-4">
              4. Location, Camera, Microphone, and Device Storage Access: Access
              to device features for address verification, KYC, two-way
              communication, and document uploads for loan processing.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">
              Non-Personal Information
            </p>
            <p>
              UdhariBazaar also collects non-personal information about
              visitors' operating systems, browser types, previous website URLs,
              third-party applications used, internet service providers, and IP
              addresses. This information, when aggregated, helps us analyze
              trends, improve website content and performance, and ensure
              compliance with applicable laws.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">Cookies</p>
            <p>
              Similar to other commercial websites, our Website uses "Cookies"
              and Web server logs to track usage. Cookies help in recognizing
              your device, facilitating your navigation, and suggesting relevant
              products or services. Most web browsers accept cookies by default,
              although settings can be adjusted to control acceptance.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">Session Data</p>
            <p>
              We automatically log generic session data about visitors' Internet
              connections, including IP addresses, operating systems, browsers
              used, and activities conducted on our Website. Although this
              information doesn't personally identify visitors, it assists in
              analyzing visitor preferences and diagnosing server issues.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">
              Sharing of Information
            </p>
            <p>
              UdhariBazaar may share your Information with its service
              providers, affiliates, agents, partners, and other third parties
              in accordance with applicable laws, for various purposes such as
              providing services, marketing new products, or intimating you
              about offers or promotions. It is explicitly declared that third
              parties/service partners shall be responsible for the use of your
              Information by them.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">
              Third Party Products and Services
            </p>
            <p>
              Third-party products and/or services offered on the Website are
              governed by their respective privacy policies. UdhariBazaar shall
              not be responsible for their use of your Information or their
              privacy policies.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">Other</p>
            <p>
              We may periodically add or enhance products/services on our
              Website and use the information provided by you to facilitate
              these products/services. For instance, if you contact us with a
              query, we may use your information to respond to your query and
              improve our Website.
              <br />
              UdhariBazaar's commitment to data received from Google APIs will
              adhere to Google API Services User Data Policy, including Limited
              Use requirements.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">
              Promotional Initiatives
            </p>
            <p>
              UdhariBazaar may have a presence on social networking websites and
              blogs as promotional initiatives to connect with a larger
              audience. UdhariBazaar disclaims any liabilities arising from the
              use/misuse of feedback, suggestions, views, etc., shared on any of
              these networking websites or blogs by any third party.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">
              Links to Other Sites
            </p>
            <p>
              Our Website may contain links to other websites. We recommend
              reviewing the privacy policies of these external sites as we have
              no control over their content, privacy policies, or practices.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">
              Protection of Personal Information
            </p>
            <p>
              UdhariBazaar may have a presence on social networking
              wUdhariBazaar is committed to protecting the privacy and
              confidentiality of your Personal Information. We implement
              industry-standard measures to protect against unauthorized access
              or disclosure.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">
              Usage of Information
            </p>
            <p>
              Information shared by you is used by us and/or authorized third
              parties/ service partners/ affiliates to support your interaction
              with us, offer you services, and contact you regarding other
              services and products. By submitting your Information to
              UdhariBazaar, you expressly acknowledge and consent to
              UdhariBazaar's use of such Information as specified in this
              Privacy Policy, in compliance with applicable laws.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">
              Retention and Destruction of Information
            </p>
            <p>
              UdhariBazaar retains your Information for a period of five (05)
              years from the date of the last processing of your Information.
              Upon expiration of this retention period, all deletion of
              Information will be performed within a reasonable period (not
              exceeding ninety (90) days). Physical records will be disposed of
              in accordance with applicable laws, while electronic data will be
              responsibly disposed of with prior approval from UdhariBazaar's
              CTO/CISO.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">
              Changes to Privacy Policy
            </p>
            <p>
              UdhariBazaar reserves the right to update this Privacy Policy in
              compliance with applicable laws. The revised Privacy Policy shall
              be effective immediately upon posting on our Website unless
              otherwise stated.
            </p>
          </div>
          <div className="w-full mt-3">
            <p className="text-logo-blue font-bold text-xl">
              Grievance Officer Contact
            </p>
            <p>
              For any concerns or complaints related to this Privacy Policy,
              please contact our Grievance Redressal Officer:
            </p>
            <div className="flex ">
              <p>Name: </p>
              <p>Mrs Priya </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPage;
