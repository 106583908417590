import React, { useState } from "react";

const SearchBar = ({ searchParameters, SearchClick, EmptyCall }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectParms, setSelectParms] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleParameterSelect = (parameter) => {
    setSearchInput(parameter + "=");
    setDropdownOpen(false);
  };

  const handleInputChange = (event) => {
    if (event.target.value === "") {
      setDropdownOpen(true);
      EmptyCall();
    } else {
      setDropdownOpen(false);
    }
    setSearchInput(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const value = searchInput.split("=")[1];
    const data = { [selectParms]: value };
    if (value) {
      SearchClick(data);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="relative inline-block text-left w-full"
    >
      <div className="flex flex-row gap-5">
        <input
          type="text"
          value={searchInput}
          onChange={handleInputChange}
          onFocus={toggleDropdown}
          onBlur={() => {
            setTimeout(() => setDropdownOpen(false), 200);
            if (searchInput === "") {
              setTimeout(() => EmptyCall(), 200);
            }
          }}
          className="border rounded-lg w-full bg-white h-10 px-5 pr-10 text-xs text-gray-600 font-medium focus:outline-none"
          placeholder="Search..."
        />
        <button type="submit" className="px-2 rounded bg-logo-blue text-white">
          Search
        </button>
      </div>
      {isDropdownOpen && (
        <div
          className={`${
            searchParameters ? "" : "hidden"
          } absolute mt-1 z-50 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5`}
        >
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {searchParameters &&
              searchParameters.map((value, key) => (
                <div
                  key={key}
                  onClick={() => {
                    setSelectParms(value.parm);
                    handleParameterSelect(value.name);
                  }}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                >
                  {value.name}
                </div>
              ))}
          </div>
        </div>
      )}
    </form>
  );
};

export default SearchBar;
