import React, { useEffect, useRef } from "react";

const ModalWindow = ({ onClose, title, children }) => {
  const modalRef = useRef();
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };
  const handleInteractionInsideModal = (event) => {
    event.stopPropagation();
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      className="fixed inset-0  flex items-center justify-center bg-black bg-opacity-60  z-40"
      onClick={onClose}
    >
      <div
        className="w-[80%] sm:w-[55%] md:1/2  sm:ml-60 p-6 bg-white rounded-lg shadow-lg  lg:w-1/2  "
        onClick={handleInteractionInsideModal}
        ref={modalRef}
      >
        <div className="flex justify-between border-b-2 pb-4 text-2xl md:text-3xl  font-bold text-gray-800 mb-4 md:mb-6">
          <h1 className="font-medium text-xl ">{title}</h1>
          <div className="flex flex-row relative">
            <button className="rounded-lg px-1  hover:scale-110">
              <img
                src="https://cdn-icons-png.flaticon.com/128/2997/2997911.png"
                alt=""
                className="h-5"
                onClick={onClose}
              />
            </button>
          </div>
        </div>
        <div className="p-8 gap-5 flex flex-col items-center justify-center">
          <>{children}</>
        </div>
      </div>
    </div>
  );
};

export default ModalWindow;
