import React, { useEffect, useState } from "react";
import { renderInput } from "../../../../Common/FormComponents";
import { GetData, PostData, PutData } from "../../../../../utils/ApiInvoker";
import GenerateURL from "../../../../../utils/ApiUrlProvider";
import { useLoadingContext } from "../../../../../Context/LoadingContext";
import properties from "../../../../../Properties/Properties";
import { useNavigate, useParams } from "react-router-dom";
import {
  BasicInfoStage1val,
  BasicInfoStage2val,
} from "../../../../Common/FormValidation/PersonalLoanServVal";
import { toast } from "react-toastify";
import { PartnerOrAssociate } from "../../../../../utils/Healpers";

const CoApplicantPersonalLoan = () => {
  const { id, secid, type } = useParams();
  const navigate = useNavigate();
  const totalStage = 3;
  const [stage, setStage] = useState(1);
  const { showLoader, hideLoader } = useLoadingContext();
  const initialcoaaplicantData = {
    first_name: "",
    last_name: "",
    dob: "",
    mother_name: "",
    father_name: "",
    spouse_name: "",
    marital_status: "false",
    mobile_number: "",
    email: "",
    pan_number: "",
    gender: "",
    personal_address: {
      house_address: "",
      city: "",
      state: "",
      pin_code: "",
      year_at_present_address: "",
      landmark: "",
    },
    current_address: {
      house_address: "",
      city: "",
      state: "",
      pin_code: "",
      year_at_present_address: "",
      landmark: "",
    },
    pan_card: "",
    poi: "",
    poa: "",
    photo: "",
    rent_agrrement: "",
  };
  const [coapplicantData, setCoapplicantData] = useState(null);
  const [coapplicantError, setCoapplicantError] = useState({
    ...initialcoaaplicantData,
    marital_status: false,
  });
  const HandleCoapplicantData = (e) => {
    const { name, value } = e.target;
    setCoapplicantError({ ...coapplicantError, [name]: "" });
    setCoapplicantData({ ...coapplicantData, [name]: value });
  };

  const [isCurrenntsame, setIsCurrentsame] = useState(false);
  const HandleAddresscopy = (e) => {
    setIsCurrentsame(!isCurrenntsame);
    if (e) {
      setCoapplicantData({
        ...coapplicantData,
        current_address: coapplicantData.personal_address,
      });
    } else {
      setCoapplicantData({
        ...coapplicantData,
        current_address: initialcoaaplicantData.current_address,
      });
    }
  };

  const HandleSubmitSucess = (e) => {
    hideLoader();
    if (PartnerOrAssociate()) {
      navigate(`/partner/loan/personalloan/${id}/detail`);
    } else {
      navigate(`/user/loan/personalloan/${id}/detail`);
    }
    toast.success("Co applicant added sucessfully", {
      autoClose: 1000,
    });
  };
  const HandleSubmitError = (e) => {
    hideLoader();
    toast.error("Something went wrong", {
      autoClose: 1000,
    });
  };
  const HandleSubmitResponse = (e) => {
    e.preventDefault();
    showLoader();
    if (type === "edit") {
      const url = GenerateURL(
        { id: id, secid: secid },
        properties.api.PersonalLoanServices.EditUrl.CoapplicantPersonalLoanEdit
      );
      PutData(
        url,
        coapplicantData,
        HandleSubmitSucess,
        HandleSubmitError,
        false
      );
    } else {
      const url = GenerateURL(
        { id: id },
        properties.api.PersonalLoanServices.CoApplicantPersonalLoan
      );
      PostData(
        url,
        coapplicantData,
        HandleSubmitSucess,
        HandleSubmitError,
        false
      );
    }
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    if (stage <= 2) {
      if (stage === 1) {
        const valid = BasicInfoStage1val(coapplicantData);
        if (valid.valid) {
          setStage(2);
        } else {
          setCoapplicantError((prev) => ({ ...prev, ...valid.errors }));
        }
      } else {
        const valid = BasicInfoStage2val(coapplicantData);
        if (valid.valid) {
          setStage(3);
        } else {
          console.log(valid.errors);
          setCoapplicantError((prev) => ({ ...prev, ...valid.errors }));
        }
      }
    } else {
      HandleSubmitResponse(e);
    }
  };
  const HandlePrevClick = (e) => {
    e.preventDefault();
    setStage((prev) => prev - 1);
  };

  const HandleGetSucess = (res) => {
    setCoapplicantData({
      ...res,
      personal_address: res.personal_address
        ? res.personal_address
        : {
            house_address: "",
            city: "",
            state: "",
            pin_code: "",
            year_at_present_address: "",
            landmark: "",
          },
      current_address: res.current_address
        ? res.current_address
        : {
            house_address: "",
            city: "",
            state: "",
            pin_code: "",
            year_at_present_address: "",
            landmark: "",
          },
      is_salaried: res.is_salaried ? "true" : "false",
      marital_status: res.marital_status ? "true" : "false",
    });
  };
  const HandleGetError = (res) => {
    toast.error("Something went wrong", {
      autoClose: 1000,
    });
    navigate("/user/dashboard");
  };

  const GetInitialData = () => {
    const url = GenerateURL(
      { id: id, secid: secid },
      properties.api.PersonalLoanServices.EditUrl.CoapplicantPersonalLoanEdit
    );

    GetData(url, HandleGetSucess, HandleGetError, false, false);
  };

  useEffect(() => {
    if (type === undefined) {
      setCoapplicantData(initialcoaaplicantData);
    } else {
      GetInitialData();
    }
  }, []);

  return (
    <div className="p-5 w-full md:w-8/12 h-full pt-20  ">
      <h1 className="text-2xl md:text-3xl pl-2 my-2 border-l-4  text-slate-800 font-sans font-bold  border-logo-blue mb-5  rounded-sm  ">
        Personal Loan
      </h1>
      <div className="flex items-center w-full border shadow-md flex-col justify-center rounded-lg  bg-white  ">
        <div className="flex items-start w-full flex-col  px-6 py-3  bg-logo-blue bg-opacity-90 rounded-t-lg ">
          <p className="w-11/12 py-3 text-xl text-left capitalize text-white ">
            Co-Applicant Details
          </p>
          <div className="flex items-center justify-between w-full flex-row ">
            <div className="w-full bg-white rounded-full h-1.5 overflow-hidden">
              <div
                className="bg-logo-orange h-1.5 rounded-full transition-width duration-800"
                style={{
                  width: "0%",
                  transition: "width 1s ease-in-out",
                  width: `${(stage / totalStage) * 100}%`,
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="flex items-center w-full flex-row justify-center rounded-lg  bg-white">
          {coapplicantData && (
            <form className="w-full h-full  p-4 ">
              {stage === 1 && (
                <>
                  <p className="text-xl rounded-md px-10 py-1 bg-logo-blue bg-opacity-10 my-2  font-medium text-logo-orange">
                    Personal Info
                  </p>
                  {renderInput("STRING", {
                    label: "First Name",
                    name: "first_name",
                    onChange: HandleCoapplicantData,
                    value: coapplicantData.first_name,
                    error: coapplicantError.first_name,
                    isrequired: true,
                  })}
                  {renderInput("STRING", {
                    label: "Last Name",
                    name: "last_name",
                    onChange: HandleCoapplicantData,
                    value: coapplicantData.last_name,
                    error: coapplicantError.last_name,
                    isrequired: true,
                  })}
                  {renderInput("DOB", {
                    label: "Date of Birth",
                    name: "dob",
                    onChange: HandleCoapplicantData,
                    value: coapplicantData.dob,
                    error: coapplicantError.dob,
                    isrequired: true,
                  })}

                  {renderInput("STRING", {
                    label: "Mother Name",
                    name: "mother_name",
                    onChange: HandleCoapplicantData,
                    value: coapplicantData.mother_name,
                    error: coapplicantError.mother_name,
                    isrequired: true,
                  })}
                  {renderInput("STRING", {
                    label: "Father Name",
                    name: "father_name",
                    onChange: HandleCoapplicantData,
                    value: coapplicantData.father_name,
                    error: coapplicantError.father_name,
                    isrequired: true,
                  })}
                  {renderInput("INTEGER", {
                    label: "Mobile Number",
                    name: "mobile_number",
                    onChange: HandleCoapplicantData,
                    value: coapplicantData.mobile_number,
                    error: coapplicantError.mobile_number,
                    isrequired: true,
                  })}
                  {renderInput("STRING", {
                    label: "Email",
                    name: "email",
                    onChange: HandleCoapplicantData,
                    value: coapplicantData.email,
                    error: coapplicantError.email,
                    isrequired: true,
                  })}
                  {renderInput("STRING", {
                    label: "Pan Number",
                    name: "pan_number",
                    onChange: HandleCoapplicantData,
                    value: coapplicantData.pan_number,
                    error: coapplicantError.pan_number,
                    isrequired: true,
                  })}
                  {renderInput("SELECT", {
                    label: "Gender",
                    name: "gender",
                    options: [
                      { label: "Male", value: "male" },
                      { label: "Female", value: "female" },
                      { label: "Others", value: "others" },
                    ],
                    onChange: HandleCoapplicantData,
                    value: coapplicantData.gender,
                    error: coapplicantError.gender,
                    isrequired: true,
                  })}
                  {renderInput("BOOLEAN", {
                    label: "Are You Married",
                    name: "marital_status",
                    onChange: HandleCoapplicantData,
                    value: coapplicantData.marital_status,
                    error: coapplicantError.marital_status,
                    isrequired: true,
                  })}
                  {coapplicantData.marital_status === "true" && (
                    <>
                      {renderInput("STRING", {
                        label: "Spouse/Husband Name",
                        name: "spouse_name",
                        onChange: HandleCoapplicantData,
                        value: coapplicantData.spouse_name,
                        error: coapplicantError.spouse_name,
                        isrequired: true,
                      })}
                    </>
                  )}
                </>
              )}
              {stage === 2 && (
                <>
                  <p className="text-xl rounded-md px-10 py-1 bg-logo-blue bg-opacity-10 my-2  font-medium text-logo-orange">
                    Personal Address
                  </p>
                  {renderInput("ADDRESS", {
                    label: "Address",
                    name: "personal_address",
                    onChange: HandleCoapplicantData,
                    value: coapplicantData.personal_address,
                    error: coapplicantError.personal_address,
                    isrequired: true,
                  })}
                  {renderInput("YESNO", {
                    label: "Current address is same as Permanent",
                    onChange: HandleAddresscopy,
                    isrequired: true,
                  })}
                  <p className="text-xl rounded-md px-10 py-1 bg-logo-blue bg-opacity-10 my-2  font-medium text-logo-orange">
                    Current Address
                  </p>
                  {renderInput("ADDRESS", {
                    label: "Address",
                    name: "current_address",
                    onChange: HandleCoapplicantData,
                    value: coapplicantData.current_address,
                    error: coapplicantError.current_address,
                    isrequired: true,
                    ischanged: isCurrenntsame,
                    isCurrent: true,
                  })}
                </>
              )}
              {stage === 3 && (
                <>
                  <p className="text-xl rounded-md px-10 py-1 bg-logo-blue bg-opacity-10 my-2  font-medium text-logo-orange">
                    Basic Documents
                  </p>
                  {renderInput("FILE_UPLOAD", {
                    label: "Pan Card",
                    name: "pan_card",
                    code: "pan_card",
                    value: coapplicantData.pan_card,
                    onChange: HandleCoapplicantData,
                    error: coapplicantError.pan_card,
                    onlyId: true,
                    isrequired: false,
                    validations: {
                      file_size: "10MB",
                      file_type: ["jpg", "png", "pdf", "heic", "jpeg"],
                      is_required: false,
                    },
                  })}
                  {renderInput("FILE_UPLOAD", {
                    label: "Proof Of Identity",
                    name: "poi",
                    code: "poi",
                    value: coapplicantData.poi,
                    onChange: HandleCoapplicantData,
                    error: coapplicantError.poi,
                    onlyId: true,
                    isrequired: false,
                    validations: {
                      file_size: "10MB",
                      file_type: ["jpg", "png", "pdf", "heic", "jpeg"],
                      is_required: false,
                    },
                  })}{" "}
                  {renderInput("FILE_UPLOAD", {
                    label: "Proof of Address",
                    name: "poa",
                    code: "poa",
                    value: coapplicantData.poa,
                    onChange: HandleCoapplicantData,
                    error: coapplicantError.poa,
                    onlyId: true,
                    isrequired: false,
                    validations: {
                      file_size: "10MB",
                      file_type: ["jpg", "png", "pdf", "heic", "jpeg"],
                      is_required: false,
                    },
                  })}
                  {renderInput("FILE_UPLOAD", {
                    label: "Photo",
                    name: "photo",
                    code: "photo",
                    value: coapplicantData.photo,
                    onChange: HandleCoapplicantData,
                    error: coapplicantError.photo,
                    onlyId: true,
                    isrequired: false,
                    validations: {
                      file_size: "10MB",
                      file_type: ["jpg", "png", "pdf", "heic", "jpeg"],
                      is_required: false,
                    },
                  })}
                  {renderInput("FILE_UPLOAD", {
                    label: "Rent Agreement",
                    name: "rent_agrrement",
                    code: "rent_agrrement",
                    value: coapplicantData.rent_agrrement,
                    onChange: HandleCoapplicantData,
                    error: coapplicantError.rent_agrrement,
                    onlyId: true,
                    isrequired: false,
                    validations: {
                      file_size: "10MB",
                      file_type: ["jpg", "png", "pdf", "heic", "jpeg"],
                      is_required: false,
                    },
                  })}
                </>
              )}
              <div className="mt-10 flex  flex-row-reverse gap-3 w-full items-center justify-between">
                <button
                  onClick={handleNextClick}
                  className={`${
                    stage === totalStage ? "bg-green-500" : "bg-blue-600"
                  } text-white px-4 py-2 rounded-md`}
                >
                  {stage === totalStage ? "Submit" : "Next"}
                </button>
                {stage !== 1 && (
                  <button
                    className="bg-blue-600 text-white px-4 py-2 rounded"
                    onClick={HandlePrevClick}
                  >
                    Prev
                  </button>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default CoApplicantPersonalLoan;
