import React, { useEffect, useState } from "react";
import GenerateURL from "../../../utils/ApiUrlProvider";
import { GetData } from "../../../utils/ApiInvoker";
import properties from "../../../Properties/Properties";
import { useParams } from "react-router-dom";
import PartnerEditCard from "./PartnerEditCard";

const PartnerData = () => {
  const [partnerData, setpartnerData] = useState();
  const params = useParams();

  const HandleSuccess = (response) => {
    setpartnerData(response);
  };
  const HandleError = (response) => {
    console.log(response);
  };

  const UserInfo = () => {
    const authData = JSON.parse(localStorage.getItem("authData"));
    const url = GenerateURL(
      { id: authData.userDetails.id },
      properties.api.partnerInfoUpdate
    );
    GetData(url, HandleSuccess, HandleError, false, false);
  };

  useEffect(() => {
    UserInfo();
  }, []);

  return (
    <>
      {partnerData ? (
        <PartnerEditCard partnerData={partnerData} userId={params.id} />
      ) : (
        <> </>
      )}
    </>
  );
};

export default PartnerData;
