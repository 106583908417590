import React, { useEffect, useState } from "react";
import properties from "../../../../Properties/Properties";
import { GetData } from "../../../../utils/ApiInvoker";
import GenerateURL from "../../../../utils/ApiUrlProvider";
import {
  GlobalFileDownlad,
  PartnerOrAssociate,
  StatusTag,
  containerStyle,
} from "../../../../utils/Healpers";
import { useNavigate, useParams } from "react-router-dom";
import { useLoadingContext } from "../../../../Context/LoadingContext";
import FileUploadModel from "../../../Common/Modal/FileUploadModel";
import DocumentDisplay from "../../../Common/DocumentDisplay";
import { PencilIcon } from "../../../../utils/SVGicons";
import { GetAuth } from "../../../../utils/AccessTokenStore";
import MissingDataImage from "../../../Common/MissingDataImage";
import LoanBankOfferModal from "../../../Common/Modal/LoanBankOfferModal";
import ListTable from "../../../Common/ListTable";

const BusinessLoanDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const UserData = GetAuth();
  const PutUrls = properties.api.BusinessLoanServices.EditUrl;
  const { showLoader, hideLoader } = useLoadingContext();
  const [loanDetail, setLoanDetail] = useState(null);
  const [bankOfferTableData, setbankOfferTableData] = useState(null);
  const [fileMoadlvisible, setFileMoadlvisible] = useState(false);
  const [imgEditDetail, setImgEditDetail] = useState({
    filedata: { label: "", name: "" },
    api: {
      url: "",
      id: "",
      secid: "",
    },
  });

  const [navtype, setNavType] = useState(0);
  const HandleSucess = (res) => {
    setLoanDetail(res);
    hideLoader();
  };
  const HandleError = (res) => {
    console.log(res);
    hideLoader();
  };
  const LoadPersonalLoanData = () => {
    const url = GenerateURL(
      { id: params.id },
      properties.api.BusinessLoanServices.GetAllDetailsBusinessLoanById
    );

    showLoader();
    GetData(url, HandleSucess, HandleError, false, false);
  };

  const navigatetoUrl = (url) => {
    if (PartnerOrAssociate()) {
      const navigateurl = `/partner${url}`;
      return navigateurl;
    } else {
      const navigateurl = `/user${url}`;
      return navigateurl;
    }
  };
  const convertDataToTableFormat = (data) => {
    const tableHeaders = [
      "Bank Name",
      "Rate Of Intrest",
      "Loan Tenure",
      "offer Amount",
      "Is Accepted",
      "Response Description",
    ];
    const tableContent = data.map((item) => {
      return {
        "Bank Name": item.bank_name.toUpperCase(),
        "Rate Of Intrest": item.rate_of_interest,
        "Loan Tenure": item.loan_tenure,
        "offer Amount": item.offer_amount,
        "Is Accepted": item.is_accepted
          ? StatusTag("TRUE")
          : StatusTag("FALSE"),
        "Response Description": item.response_description,
        bankoffer: item,
      };
    });
    return {
      tableHeaders,
      tableContent,
    };
  };
  const HandleBankSucess = (res) => {
    const data = convertDataToTableFormat(res);
    setbankOfferTableData(data);
  };
  const HandleBankError = () => {};
  const LoadBankOfferData = () => {
    const url = GenerateURL(
      { id: params.id },
      properties.api.Common.Loan.GetBankOffer
    );
    showLoader();
    GetData(url, HandleBankSucess, HandleBankError, false, false);
  };

  const [bankOfferModal, setBankOfferModal] = useState(false);
  const [bankOfferData, setBankOfferData] = useState(null);
  const ActionBtn = {
    name: ["Accept or Reject"],
    disabled: false,
    onClick: (e) => {
      setBankOfferData(e.bankoffer);
      setBankOfferModal(true);
    },
  };
  useEffect(() => {
    if (!fileMoadlvisible) {
      LoadPersonalLoanData();
      LoadBankOfferData();
    }
  }, [fileMoadlvisible, bankOfferModal]);
  return (
    <>
      {loanDetail && (
        <div className="w-full flex flex-col ml-5 mt-9 p-4 ">
          <div
            style={containerStyle}
            className="w-full border rounded-t-md p-4 py-10 text-3xl text-left capitalize text-white  "
          >
            <p className="shadow-sm">Business Loan</p>
          </div>

          {bankOfferModal && (
            <LoanBankOfferModal
              onClose={() => setBankOfferModal(false)}
              initialValue={bankOfferData}
              putUrl={properties.api.Common.Loan.GetBankOfferEdit}
            />
          )}

          <div className="flex flex-col sm:flex-row items-center w-full gap-2 px-2 pt-2 border bg-logo-blue bg-opacity-10  rounded-b-md">
            <button
              onClick={() => setNavType(0)}
              className={`px-2 py-1 transition-all ease-in-out duration-200 ${
                navtype === 0
                  ? "text-logo-orange underline underline-offset-8"
                  : ""
              }`}
            >
              Personal Info
            </button>
            <button
              onClick={() => setNavType(1)}
              className={`px-2 py-1 transition-all ease-in-out duration-200 ${
                navtype === 1
                  ? "text-logo-orange underline underline-offset-8"
                  : ""
              }`}
            >
              Firm Details
            </button>
            {loanDetail.has_additional_partners && (
              <button
                onClick={() => setNavType(2)}
                className={`px-2 py-1 transition-all ease-in-out duration-200 ${
                  navtype === 2
                    ? "text-logo-orange underline underline-offset-8"
                    : ""
                }`}
              >
                Co-Applicant
              </button>
            )}
            <button
              onClick={() => setNavType(3)}
              className={`px-2 py-1 transition-all ease-in-out duration-200 ${
                navtype === 3
                  ? "text-logo-orange underline underline-offset-8"
                  : ""
              }`}
            >
              Reference
            </button>
            <button
              onClick={() => setNavType(4)}
              className={`px-2 py-1 transition-all ease-in-out duration-200 ${
                navtype === 4
                  ? "text-logo-orange underline underline-offset-8"
                  : ""
              }`}
            >
              Documents
            </button>
            <button
              onClick={() => setNavType(5)}
              className={`px-2 py-1 transition-all ease-in-out duration-200 ${
                navtype === 5
                  ? "text-logo-orange underline underline-offset-8"
                  : ""
              }`}
            >
              Bank Offer
            </button>
          </div>
          {navtype === 0 && (
            <div className="w-full flex flex-col shadow-lg rounded-2xl bg-white mt-5">
              <div className="p-4 flex justify-between items-center">
                <h1 className="text-2xl text-logo-orange">Personal Info</h1>
                {UserData.id === loanDetail.applied_by.id && (
                  <button
                    onClick={() =>
                      navigate(
                        navigatetoUrl(
                          `/loan/businessloan/${loanDetail.id}/edit`
                        )
                      )
                    }
                  >
                    <PencilIcon styles={"h-5"} />
                  </button>
                )}
              </div>
              <div className="w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 p-4 capitalize">
                <div className="flex flex-col">
                  <p className="font-medium">Application Number</p>
                  <p className="text-gray-500 text-sm">
                    {loanDetail.application_number}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium">Status</p>
                  <p className="text-gray-500 text-sm">
                    {StatusTag(loanDetail.status)}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium">Description</p>
                  <p className="text-gray-500 text-sm">
                    {loanDetail.description || "-"}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium ">Disbursement Amount</p>
                  <p className="text-gray-500 text-sm">
                    {loanDetail.disbursement_amount || "--"}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium">Name</p>
                  <p className="text-gray-500 text-sm">
                    {loanDetail.first_name}
                    <span> </span>
                    {loanDetail.last_name}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium">Father's Name</p>
                  <p className="text-gray-500 text-sm">
                    {loanDetail.father_name}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium">Mother's Name</p>
                  <p className="text-gray-500 text-sm">
                    {loanDetail.mother_name}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium">Spouse/Husband's Name</p>
                  <p className="text-gray-500 text-sm">
                    {loanDetail.spouse_name || "--"}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium">Date Of Birth</p>
                  <p className="text-gray-500 text-sm">{loanDetail.dob}</p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium ">Pan Number</p>
                  <p className="text-gray-500 text-sm uppercase">
                    {loanDetail.pan_number}
                  </p>
                </div>

                <div className=" w-full flex flex-row justify-between">
                  <div className="flex flex-col">
                    <p className="font-medium">Permanent Address</p>
                    <p className="text-gray-500 text-sm">
                      {loanDetail.personal_address.house_address},<span> </span>
                      {loanDetail.personal_address.city}
                      <br />
                      {loanDetail.personal_address.state},<span> </span>
                      {loanDetail.personal_address.pin_code}
                    </p>
                  </div>
                </div>
                {loanDetail.current_address && (
                  <div className=" w-full flex flex-row justify-between">
                    <div className="flex flex-col">
                      <p className="font-medium">Current Address</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.current_address.house_address},
                        <span> </span>
                        {loanDetail.current_address.city}
                        <br />
                        {loanDetail.current_address.state},<span> </span>
                        {loanDetail.current_address.pin_code}
                      </p>
                    </div>
                  </div>
                )}
                <div className="flex flex-col">
                  <p className="font-medium ">Gender</p>
                  <p className="text-gray-500 text-sm">{loanDetail.gender}</p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium ">Mobile No</p>
                  <p className="text-gray-500 text-sm overflow-clip">
                    {loanDetail.mobile_number}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-medium ">Email</p>
                  <p className="text-gray-500 text-sm overflow-clip lowercase">
                    {loanDetail.email}
                  </p>
                </div>
              </div>
            </div>
          )}

          {navtype === 1 && (
            <div className="w-full flex flex-col shadow-lg rounded-2xl bg-white mt-5">
              {loanDetail.firm_details === null ? (
                <div className="p-4 flex flex-row justify-between items-center">
                  <MissingDataImage
                    message={"No Firm Details Added"}
                    btnurl={navigatetoUrl(
                      `/loan/businessloan/${loanDetail.id}/firmdetail/`
                    )}
                  />
                </div>
              ) : (
                <>
                  <div className="p-4  flex justify-between items-center">
                    <h1 className="text-2xl text-logo-orange">
                      Firm Details Info
                    </h1>
                    {UserData.id === loanDetail.applied_by.id && (
                      <button
                        onClick={() =>
                          navigate(
                            navigatetoUrl(
                              `/loan/businessloan/${loanDetail.id}/firmdetail/${loanDetail.firm_details.id}/edit`
                            )
                          )
                        }
                      >
                        <PencilIcon styles={"h-5"} />
                      </button>
                    )}
                  </div>
                  <div className="w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 p-4 capitalize">
                    <div className="flex flex-col">
                      <p className="font-medium">Firm Name</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.firm_details.firm_name || "--"}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-medium">Gst Number</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.firm_details.gst_no || "--"}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-medium ">Pan Number</p>
                      <p className="text-gray-500 text-sm uppercase">
                        {loanDetail.firm_details.firm_pan_no || "--"}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-medium">Udyam Number</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.firm_details.udyam_no || "--"}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-medium">Email</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.firm_details.official_email || "--"}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-medium">Business Turnover</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.firm_details.business_turnover || "--"}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-medium">Business Age</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.firm_details.business_vintage || "--"}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-medium">Business nature</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.firm_details.nature_of_business || "--"}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-medium">Product Category</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.firm_details.product_category || "--"}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-medium">Description</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.firm_details.description || "--"}
                      </p>
                    </div>

                    <div className=" w-full flex flex-row justify-between">
                      <div className="flex flex-col">
                        <p className="font-medium">Firm Address</p>
                        <p className="text-gray-500 text-sm">
                          {loanDetail.firm_details.firm_address.house_address},
                          <span> </span>
                          {loanDetail.firm_details.firm_address.city}
                          <br />
                          {loanDetail.firm_details.firm_address.state},
                          <span> </span>
                          {loanDetail.firm_details.firm_address.pin_code}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {navtype === 2 && (
            <div className="w-full flex flex-col shadow-lg rounded-2xl bg-white mt-5">
              {loanDetail.co_applicant_details === null ? (
                <div className="p-4 flex flex-row justify-between items-center">
                  <MissingDataImage
                    message={"No Co-Applicant Details"}
                    btnurl={navigatetoUrl(
                      `/loan/businessloan/${loanDetail.id}/coapplicant/`
                    )}
                  />
                </div>
              ) : (
                <>
                  <div className="p-4  flex justify-between items-center">
                    <h1 className="text-2xl text-logo-orange">
                      Co Applicant Info
                    </h1>
                    {UserData.id === loanDetail.applied_by.id && (
                      <button
                        onClick={() =>
                          navigate(
                            navigatetoUrl(
                              `/loan/businessloan/${loanDetail.id}/coapplicant/${loanDetail.co_applicant_details.id}/edit`
                            )
                          )
                        }
                      >
                        <PencilIcon styles={"h-5"} />
                      </button>
                    )}
                  </div>
                  <div className="w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 p-4 capitalize">
                    <div className="flex flex-col">
                      <p className="font-medium">Name</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.co_applicant_details.first_name}
                        <span> </span>
                        {loanDetail.co_applicant_details.last_name}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-medium">Father's Name</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.co_applicant_details.father_name}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-medium">Mother's Name</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.co_applicant_details.mother_name}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-medium">Spouse/Husband's Name</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.co_applicant_details.spouse_name || "--"}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-medium">Date Of Birth</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.co_applicant_details.dob}
                      </p>
                    </div>

                    <div className="flex flex-col">
                      <p className="font-medium ">Pan Number</p>
                      <p className="text-gray-500 text-sm uppercase">
                        {loanDetail.co_applicant_details.pan_number}
                      </p>
                    </div>

                    <div className=" w-full flex flex-row justify-between">
                      <div className="flex flex-col">
                        <p className="font-medium">Permanent Address</p>
                        <p className="text-gray-500 text-sm">
                          {
                            loanDetail.co_applicant_details.personal_address
                              .house_address
                          }
                          ,<span> </span>
                          {
                            loanDetail.co_applicant_details.personal_address
                              .city
                          }
                          <br />
                          {
                            loanDetail.co_applicant_details.personal_address
                              .state
                          }
                          ,<span> </span>
                          {
                            loanDetail.co_applicant_details.personal_address
                              .pin_code
                          }
                        </p>
                      </div>
                    </div>
                    {loanDetail.co_applicant_details.current_address && (
                      <div className=" w-full flex flex-row justify-between">
                        <div className="flex flex-col">
                          <p className="font-medium">Current Address</p>
                          <p className="text-gray-500 text-sm">
                            {
                              loanDetail.co_applicant_details.current_address
                                .house_address
                            }
                            ,<span> </span>
                            {
                              loanDetail.co_applicant_details.current_address
                                .city
                            }
                            <br />
                            {
                              loanDetail.co_applicant_details.current_address
                                .state
                            }
                            ,<span> </span>
                            {
                              loanDetail.co_applicant_details.current_address
                                .pin_code
                            }
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="flex flex-col">
                      <p className="font-medium ">Gender</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.co_applicant_details.gender}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-medium ">Mobile No</p>
                      <p className="text-gray-500 text-sm overflow-clip">
                        {loanDetail.co_applicant_details.mobile_number}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-medium ">Email</p>
                      <p className="text-gray-500 text-sm overflow-clip lowercase">
                        {loanDetail.co_applicant_details.email}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}

          {navtype === 3 && (
            <div className="w-full flex flex-col shadow-lg rounded-2xl bg-white mt-5">
              {loanDetail.first_reference === null ? (
                <div className="p-4 flex flex-row justify-between items-center">
                  <MissingDataImage
                    message={"No reference Details"}
                    btnurl={navigatetoUrl(
                      `/loan/businessloan/${loanDetail.id}/reference/`
                    )}
                  />
                </div>
              ) : (
                <>
                  <div className="p-4  flex justify-between items-center">
                    <h1 className="text-2xl text-logo-orange">
                      First Reference Info
                    </h1>
                    {UserData.id === loanDetail.applied_by.id && (
                      <button
                        onClick={() =>
                          navigate(
                            navigatetoUrl(
                              `/loan/businessloan/${loanDetail.id}/reference/${loanDetail.first_reference.id}/edit`
                            )
                          )
                        }
                      >
                        <PencilIcon styles={"h-5"} />
                      </button>
                    )}
                  </div>
                  <div className="w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 p-4 capitalize">
                    <div className="flex flex-col">
                      <p className="font-medium">Name</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.first_reference.name}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-medium">Email</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.first_reference.email || "--"}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-medium">Mobile No</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.first_reference.mobile_no}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-medium">Relation</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.first_reference.relationship}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-medium">Reference Address</p>
                      <p className="text-gray-500 text-sm">
                        {loanDetail.first_reference.reffery_address
                          .house_address || ""}
                        ,<span> </span>
                        {loanDetail.first_reference.reffery_address.city || ""}
                        <br />
                        {loanDetail.first_reference.reffery_address.state || ""}
                        ,<span> </span>
                        {loanDetail.first_reference.reffery_address.pin_code ||
                          ""}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {navtype === 3 && loanDetail.first_reference != null && (
            <>
              <div className="w-full flex flex-col shadow-lg rounded-2xl bg-white mt-5">
                {loanDetail.second_reference === null ? (
                  <div className="p-4 flex flex-row justify-between items-center">
                    <MissingDataImage
                      message={"No Reference Details"}
                      btnurl={navigatetoUrl(
                        `/loan/businessloan/${loanDetail.id}/reference/`
                      )}
                    />
                  </div>
                ) : (
                  <>
                    <div className="p-4  flex justify-between items-center ">
                      <h1 className="text-2xl text-logo-orange">
                        Second Reference
                      </h1>
                      {UserData.id === loanDetail.applied_by.id && (
                        <button
                          onClick={() =>
                            navigate(
                              navigatetoUrl(
                                `/loan/businessloan/${loanDetail.id}/reference/${loanDetail.second_reference.id}/edit`
                              )
                            )
                          }
                        >
                          <PencilIcon styles={"h-5"} />
                        </button>
                      )}
                    </div>
                    <div className="w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 p-4 capitalize">
                      <div className="flex flex-col">
                        <p className="font-medium">Name</p>
                        <p className="text-gray-500 text-sm">
                          {loanDetail.second_reference.name}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className="font-medium">Email</p>
                        <p className="text-gray-500 text-sm">
                          {loanDetail.second_reference.email}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className="font-medium">Mobile No</p>
                        <p className="text-gray-500 text-sm">
                          {loanDetail.second_reference.mobile_no}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className="font-medium">Relation</p>
                        <p className="text-gray-500 text-sm">
                          {loanDetail.second_reference.relationship}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className="font-medium">Reference Address</p>
                        <p className="text-gray-500 text-sm">
                          {loanDetail.second_reference.reffery_address
                            .house_address || ""}
                          ,<span> </span>
                          {loanDetail.second_reference.reffery_address.city ||
                            ""}
                          <br />
                          {loanDetail.second_reference.reffery_address.state ||
                            ""}
                          ,<span> </span>
                          {loanDetail.second_reference.reffery_address
                            .pin_code || ""}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
          {navtype === 4 && (
            <div className="w-full flex flex-col shadow-lg rounded-2xl bg-white mt-5">
              {fileMoadlvisible && (
                <FileUploadModel
                  filedata={imgEditDetail.filedata}
                  api={imgEditDetail.api}
                  onClose={() => setFileMoadlvisible((prev) => !prev)}
                />
              )}
              <h1 className="p-4 text-2xl text-logo-orange">
                Personal Detail Files
              </h1>
              <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 p-4 capitalize">
                <DocumentDisplay
                  label={"Proof Of Identity"}
                  name="poi"
                  putapi={{
                    url: PutUrls.UserBusinessLoanEdit,
                    id: loanDetail.id,
                    secid: "",
                  }}
                  ShowChange={Boolean(UserData.id === loanDetail.applied_by.id)}
                  Detail={loanDetail}
                  GlobalFileDownlad={GlobalFileDownlad}
                  setImgEditDetail={setImgEditDetail}
                  setFileMoadlvisible={setFileMoadlvisible}
                />
                <DocumentDisplay
                  label={"Proof Of Address"}
                  name="poa"
                  putapi={{
                    url: PutUrls.UserBusinessLoanEdit,
                    id: loanDetail.id,
                    secid: "",
                  }}
                  Detail={loanDetail}
                  ShowChange={Boolean(UserData.id === loanDetail.applied_by.id)}
                  GlobalFileDownlad={GlobalFileDownlad}
                  setImgEditDetail={setImgEditDetail}
                  setFileMoadlvisible={setFileMoadlvisible}
                />
                <DocumentDisplay
                  label={"Rent Agreement"}
                  name="rent_aggrement"
                  putapi={{
                    url: PutUrls.UserBusinessLoanEdit,
                    id: loanDetail.id,
                    secid: "",
                  }}
                  Detail={loanDetail}
                  ShowChange={Boolean(UserData.id === loanDetail.applied_by.id)}
                  GlobalFileDownlad={GlobalFileDownlad}
                  setImgEditDetail={setImgEditDetail}
                  setFileMoadlvisible={setFileMoadlvisible}
                />
                <DocumentDisplay
                  label={"Pan Card"}
                  name="pan_card"
                  putapi={{
                    url: PutUrls.UserBusinessLoanEdit,
                    id: loanDetail.id,
                    secid: "",
                  }}
                  Detail={loanDetail}
                  ShowChange={Boolean(UserData.id === loanDetail.applied_by.id)}
                  GlobalFileDownlad={GlobalFileDownlad}
                  setImgEditDetail={setImgEditDetail}
                  setFileMoadlvisible={setFileMoadlvisible}
                />
                <DocumentDisplay
                  label={"Photo"}
                  name="photo"
                  putapi={{
                    url: PutUrls.UserBusinessLoanEdit,
                    id: loanDetail.id,
                    secid: "",
                  }}
                  Detail={loanDetail}
                  ShowChange={Boolean(UserData.id === loanDetail.applied_by.id)}
                  GlobalFileDownlad={GlobalFileDownlad}
                  setImgEditDetail={setImgEditDetail}
                  setFileMoadlvisible={setFileMoadlvisible}
                />
                <DocumentDisplay
                  label={"Aadhaar card"}
                  name="aadhar_card"
                  putapi={{
                    url: PutUrls.UserBusinessLoanEdit,
                    id: loanDetail.id,
                    secid: "",
                  }}
                  Detail={loanDetail}
                  ShowChange={Boolean(UserData.id === loanDetail.applied_by.id)}
                  GlobalFileDownlad={GlobalFileDownlad}
                  setImgEditDetail={setImgEditDetail}
                  setFileMoadlvisible={setFileMoadlvisible}
                />
              </div>
              {loanDetail.firm_details && (
                <>
                  <h1 className="p-4 text-2xl text-logo-orange">
                    Firm Detail files
                  </h1>
                  <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 p-4 capitalize">
                    <DocumentDisplay
                      label={"Firm Pan Card"}
                      name="firm_pan_card"
                      putapi={{
                        url: PutUrls.FirmdetailBusinessloanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.firm_details.id,
                      }}
                      Detail={loanDetail.firm_details}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"Firm GST certificate"}
                      name="firm_gst_certificate"
                      putapi={{
                        url: PutUrls.FirmdetailBusinessloanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.firm_details.id,
                      }}
                      Detail={loanDetail.firm_details}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"Udyam Certificate"}
                      name="firm_udyam_certificate"
                      putapi={{
                        url: PutUrls.FirmdetailBusinessloanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.firm_details.id,
                      }}
                      Detail={loanDetail.firm_details}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"Memorandum of Association (MOA)"}
                      name="moa"
                      putapi={{
                        url: PutUrls.FirmdetailBusinessloanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.firm_details.id,
                      }}
                      Detail={loanDetail.firm_details}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"Articles of Association (AOA)"}
                      name="aoa"
                      putapi={{
                        url: PutUrls.FirmdetailBusinessloanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.firm_details.id,
                      }}
                      Detail={loanDetail.firm_details}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"Certificate of Insurance (COI)"}
                      name="coi"
                      putapi={{
                        url: PutUrls.FirmdetailBusinessloanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.firm_details.id,
                      }}
                      Detail={loanDetail.firm_details}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"Rent Aggrement (if rented)"}
                      name="rent_aggremnet"
                      putapi={{
                        url: PutUrls.FirmdetailBusinessloanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.firm_details.id,
                      }}
                      Detail={loanDetail.firm_details}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"Industry License"}
                      name="industry_license"
                      putapi={{
                        url: PutUrls.FirmdetailBusinessloanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.firm_details.id,
                      }}
                      Detail={loanDetail.firm_details}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"Bank Statement (one year)"}
                      name="one_year_bank_statement"
                      putapi={{
                        url: PutUrls.FirmdetailBusinessloanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.firm_details.id,
                      }}
                      Detail={loanDetail.firm_details}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"Ownership Proof"}
                      name="ownership_proof"
                      putapi={{
                        url: PutUrls.FirmdetailBusinessloanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.firm_details.id,
                      }}
                      Detail={loanDetail.firm_details}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"ITR (last 2 years)"}
                      name="last_2_year_itr_with_financial_computation_gst_returns"
                      putapi={{
                        url: PutUrls.FirmdetailBusinessloanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.firm_details.id,
                      }}
                      Detail={loanDetail.firm_details}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"Partnership Deed"}
                      name="patnership_deed"
                      putapi={{
                        url: PutUrls.FirmdetailBusinessloanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.firm_details.id,
                      }}
                      Detail={loanDetail.firm_details}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                  </div>
                </>
              )}
              {loanDetail.co_applicant_details && (
                <>
                  <h1 className="p-4 text-2xl text-logo-orange">
                    CoApplicant Files
                  </h1>
                  <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 p-4 capitalize">
                    <DocumentDisplay
                      label={"Proof Of Identity"}
                      name="poi"
                      putapi={{
                        url: PutUrls.CoapplicantBusinessLoanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.co_applicant_details.id,
                      }}
                      Detail={loanDetail.co_applicant_details}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"Proof Of Address"}
                      name="poa"
                      putapi={{
                        url: PutUrls.CoapplicantBusinessLoanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.co_applicant_details.id,
                      }}
                      Detail={loanDetail.co_applicant_details}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"Rent Agreement"}
                      name="rent_aggrement"
                      putapi={{
                        url: PutUrls.CoapplicantBusinessLoanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.co_applicant_details.id,
                      }}
                      Detail={loanDetail.co_applicant_details}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"Pan Card"}
                      name="pan_card"
                      putapi={{
                        url: PutUrls.CoapplicantBusinessLoanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.co_applicant_details.id,
                      }}
                      Detail={loanDetail.co_applicant_details}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"Photo"}
                      name="photo"
                      putapi={{
                        url: PutUrls.CoapplicantBusinessLoanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.co_applicant_details.id,
                      }}
                      Detail={loanDetail.co_applicant_details}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                    <DocumentDisplay
                      label={"Aadhaar card"}
                      name="aadhar_card"
                      putapi={{
                        url: PutUrls.CoapplicantBusinessLoanEdit,
                        id: loanDetail.id,
                        secid: loanDetail.co_applicant_details.id,
                      }}
                      Detail={loanDetail.co_applicant_details}
                      ShowChange={Boolean(
                        UserData.id === loanDetail.applied_by.id
                      )}
                      GlobalFileDownlad={GlobalFileDownlad}
                      setImgEditDetail={setImgEditDetail}
                      setFileMoadlvisible={setFileMoadlvisible}
                    />
                  </div>
                </>
              )}
            </div>
          )}
          {navtype === 5 && (
            <>
              {bankOfferTableData ? (
                <div className="w-full py-4">
                  <ListTable
                    heading="Total Users"
                    tableData={bankOfferTableData}
                    pagination={false}
                    actions={{
                      view: false,
                      edit: false,
                      delete: false,
                      other: ActionBtn,
                    }}
                  />
                </div>
              ) : (
                <div className="w-full flex flex-col shadow-lg rounded-2xl bg-white mt-5">
                  <MissingDataImage message={"No bannk offer added "} />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default BusinessLoanDetail;
