import React, { useEffect, useState } from "react";
import GenerateURL from "../../../utils/ApiUrlProvider";
import { GetData } from "../../../utils/ApiInvoker";
import properties from "../../../Properties/Properties";
import { useParams } from "react-router-dom";
import InsuranceDetailCard from "../../Common/InsuranceDetailCard";

const InsuranceCard = () => {
  const [userData, setUserData] = useState();
  const params = useParams();
  const HandleSucess = (res) => {
    setUserData(res);
  };
  const HandleError = (res) => {
    console.log(res);
  };
  const LoadLoanData = () => {
    const url = GenerateURL(
      { id: params.id },
      properties.api.adminInsuranceData
    );
    GetData(url, HandleSucess, HandleError, false, false);
  };
  useEffect(() => {
    LoadLoanData();
  }, []);

  return <>{userData ? <InsuranceDetailCard userData={userData} /> : <> </>}</>;
};

export default InsuranceCard;
