export const AdminMenuItems = [
  {
    name: "Dashboard",
    icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/first-menu-icons/more.webp",
    link: "/admin/dashboard",
  },
  {
    name: "Loan",
    icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/admin-sidebar/leads.webp",
    link: "/admin/personalloan",
  },
  {
    name: "Payouts",
    icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/admin-sidebar/commisions.png",
    link: "/admin/payouts",
  },
  {
    name: "Relation Manager",
    icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/admin-sidebar/manager.webp",
    link: "/admin/relation-manager",
  },

  {
    name: "Users",
    icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/admin-sidebar/users.webp",
    link: "/admin/users",
  },

  {
    name: "Premium Partners",
    icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/admin-sidebar/partners.webp",
    link: "/admin/user-partner",
  },
  {
    name: "Associate Partners",
    icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/admin-sidebar/manager.webp",
    link: "/admin/associated_partner",
  },
  {
    name: "Enquiry",
    icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/admin-sidebar/question.webp",
    link: "/admin/user-enquiery",
  },
  {
    name: "Support",
    icon: "https://cdn-icons-png.flaticon.com/128/1067/1067566.png",
    link: "/admin/support",
  },
];

export const PartnersMenuItems = [
  {
    name: "Dashboard",
    icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/first-menu-icons/more.webp",
    link: "/partner/dashboard",
  },
  {
    name: "Leads",
    icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/admin-sidebar/leads.webp",
    link: "",
    children: [
      {
        name: "Personal Loan",
        icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/first-menu-icons/personalloan.png",
        link: "/partner/loan/personalloan/",
      },
      {
        name: "Business Loan",
        icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/first-menu-icons/business.png",
        link: "/partner/loan/businessloan/",
      },
      {
        name: "Auto Loan",
        icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/first-menu-icons/carloan.webp",
        link: "/partner/loan/autoloan/",
      },
    ],
  },
  {
    name: "Payouts",
    icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/admin-sidebar/commisions.png",
    link: "/partner/commisions/",
  },
  {
    name: "Profile",
    icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/admin-sidebar/profile.webp",
    link: "/partner/profile/",
  },
  {
    name: "Associate Partners",
    icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/admin-sidebar/profile.webp",
    link: "/partner/associate-partner/",
  },
  {
    name: "Support",
    icon: "https://cdn-icons-png.flaticon.com/128/1067/1067566.png",
    link: "/partner/support",
  },
];
export const AssociatePartnersMenuItems = [
  {
    name: "Dashboard",
    icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/first-menu-icons/more.webp",
    link: "/partner/dashboard",
  },
  {
    name: "Leads",
    icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/admin-sidebar/leads.webp",
    link: "",
    children: [
      {
        name: "Personal Loan",
        icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/first-menu-icons/personalloan.png",
        link: "/partner/loan/personalloan/",
      },
      {
        name: "Business Loan",
        icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/first-menu-icons/business.png",
        link: "/partner/loan/businessloan/",
      },
      {
        name: "Auto Loan",
        icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/first-menu-icons/carloan.webp",
        link: "/partner/loan/autoloan/",
      },
    ],
  },

  {
    name: "Payouts",
    icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/admin-sidebar/commisions.png",
    link: "/partner/commisions/",
  },
  {
    name: "Profile",
    icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/admin-sidebar/profile.webp",
    link: "/partner/profile/",
  },
  {
    name: "Support",
    icon: "https://cdn-icons-png.flaticon.com/128/1067/1067566.png",
    link: "/partner/support",
  },
];

export const UserMenueItems = [
  {
    name: "Dashboard",
    icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/first-menu-icons/more.webp",
    link: "/partner/dashboard",
  },

  {
    name: "Loan",
    icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/admin-sidebar/leads.webp",
    link: "",
    children: [
      {
        name: "Personal Loan",
        icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/first-menu-icons/personalloan.png",
        link: "/user/loan/personalloan/",
      },
      {
        name: "Business Loan",
        icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/first-menu-icons/business.png",
        link: "/user/loan/businessloan/",
      },
      {
        name: "Auto Loan",
        icon: "https://cdn.staropstech.com/clients/udharibazaar/assets/first-menu-icons/carloan.webp",
        link: "/user/loan/autoloan/",
      },
    ],
  },
  {
    name: "Support",
    icon: "https://cdn-icons-png.flaticon.com/128/1067/1067566.png",
    link: "/user/support",
  },
];
