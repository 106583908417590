import React, { useEffect, useState } from "react";
import DashboardBanner from "../../Common/DashboardBanner";
import MissingDataImage from "../../Common/MissingDataImage";
import ListTable from "../../Common/ListTable";
import GenerateURL from "../../../utils/ApiUrlProvider";
import properties from "../../../Properties/Properties";
import { GetData } from "../../../utils/ApiInvoker";
import { GetDate } from "../../../utils/Healpers";
import { GetAuth } from "../../../utils/AccessTokenStore";

const Commisions = () => {
  const [pagination, setPagination] = useState(null);
  const [tableData, setTableData] = useState(null);
  const HandleSucess = (response) => {
    const data = convertDataToTableFormat(response.results);
    setTableData(data);
    setPagination(response);
  };
  const HandleError = (response) => {
    console.log(response);
  };

  const GetNextFilledServices = (nexturl) => {
    const url = GenerateURL({}, nexturl);
    GetData(url, HandleSucess, HandleError, false, false);
  };
  const GetPrevFilledServices = (prevurl) => {
    const url = GenerateURL({}, prevurl);
    GetData(url, HandleSucess, HandleError, false, false);
  };

  const convertDataToTableFormat = (data) => {
    const tableHeaders = [
      "Bank Name",
      "Account No",
      "Reference No",
      "Transaction Type",
      "Amount",
      "Status",
      "Transiction Date",
    ];
    const tableContent = data.map((item) => {
      return {
        "Reference No": item.reference_number || "N/A",
        "Transaction Type": item.transaction_type || "N/A",
        Amount: item.amount || "N/A",
        Status: item.status || "N/A",
        "Transiction Date": GetDate(item.transaction_date),
        "Bank Name": item.user_bank_detail.bank_name || "N/A",
        "Account No": item.user_bank_detail.account_number || "N/A",
        // Time: GetTimeAgo(item.created_at) || "N/A",
        user_bank_detail: item.user_bank_detail,
      };
    });
    return {
      tableHeaders,
      tableContent,
    };
  };

  const CustomClick = (e) => {
    console.log(e);
  };
  const custombtn = {
    show: true,
    mapby: true, //to be true and false only
    name: ["Invoice"],
    customClick: CustomClick,
  };

  const GetCommisionData = () => {
    const user = GetAuth();
    const url = GenerateURL(
      { user_id: user.id },
      properties.api.partenerCommision
    );
    GetData(url, HandleSucess, HandleError, false, false);
  };

  useEffect(() => {
    GetCommisionData();
  }, []);
  return (
    <div className="w-11/12 flex flex-col items-center gap-4">
      <DashboardBanner />
      {tableData && tableData.tableContent.length !== 0 ? (
        <ListTable
          tableData={tableData}
          pagination={Boolean(pagination.next || pagination.previous)}
          next={pagination.next}
          prev={pagination.previous}
          onNextClick={() => GetNextFilledServices(pagination.next)}
          onPrevClick={() => GetPrevFilledServices(pagination.previous)}
          customButton={custombtn}
          actions={{ delete: false, download: false }}
        />
      ) : (
        <div className="w-11/12 flex flex-col items-center gap-4 ">
          <MissingDataImage message={"OOPS! You haven't any Payout"} />
        </div>
      )}
    </div>
  );
};

export default Commisions;
