import React, { useState } from "react";
import { DropdownItem } from "../helper/DropdownSidebar";
import { Link } from "react-router-dom";
import {
  IsAssociatePartner,
  IsPartnerUser,
  IsSuperUser,
} from "../utils/AccessTokenStore";

const SidePannel = (props) => {
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null);


  const handleDropdownClick = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
    setOpenSubMenuIndex(null);
  };

  const handleSubMenuClick = (index) => {
    setOpenSubMenuIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      {props.item && (
        <aside
          className={`fixed top-10 left-0 z-40 w-64 sidebar-scrollbar min-h-screen max-h-full overflow-y-auto bg-white transition-transform pt-5 pb-16 ${
            props.sidebarOpen ? "translate-x-0" : "-translate-x-full"
          } sm:translate-x-0`}
          aria-label="Sidebar"
        >
          <div className="h-full min-h-[88vh]  px-3 py-4  flex flex-col justify-between">
            <ul className=" space-y-2 font-medium flex flex-col justify-between h-full">
              <div className="li">
                {props.item.map((item, index) => (
                  <DropdownItem
                    key={index}
                    name={item.name}
                    icon={item.icon}
                    children={item.children}
                    link={item.link}
                    isMenuOpen={index === openDropdownIndex}
                    isSubMenuOpen={index === openSubMenuIndex}
                    onDropdownClick={() => handleDropdownClick(index)}
                    onSubMenuClick={() => handleSubMenuClick(index)}
                  />
                ))}
              </div>
            </ul>
          </div>
        </aside>
      )}
    </>
  );
};

export default SidePannel;
