export const ValidateBankForm = (formData) => {
  let valid = true;
  const errors = {};

  // Validate bank_name
  if (!formData.bank_name.trim()) {
    errors.bank_name =
      "Bank name is required and should contain only alphabetic characters";
    valid = false;
  } else if (!/^[a-zA-Z\s]+$/.test(formData.bank_name.trim())) {
    errors.bank_name = "Bank name should contain only alphabetic characters";
    valid = false;
  }

  // Validate ifsc_code
  if (!formData.ifsc_code.trim()) {
    errors.ifsc_code = "IFSC code is required and should be 11 characters long";
    valid = false;
  } else if (!/^[a-zA-Z0-9]{11}$/.test(formData.ifsc_code.trim())) {
    errors.ifsc_code =
      "IFSC code should be 11 characters long and can contain alphabets and numbers";
    valid = false;
  }

  // Validate account_number
  if (!formData.account_number.trim()) {
    errors.account_number = "Account number is required";
    valid = false;
  } else if (!/^[0-9]+$/.test(formData.account_number.trim())) {
    errors.account_number =
      "Account number should contain only numeric characters";
    valid = false;
  }
  // Validate branch_name
  if (!formData.branch_name.trim()) {
    errors.branch_name =
      "Branch name is required and should contain only alphabetic characters";
    valid = false;
  } else if (!/^[a-zA-Z\s]+$/.test(formData.branch_name.trim())) {
    errors.branch_name =
      "Branch name should contain only alphabetic characters";
    valid = false;
  }
  // Validate Account Holder Name
  if (!formData.account_holder_name.trim()) {
    errors.account_holder_name = "Account Holder name is required ";
    valid = false;
  } else if (!/^[a-zA-Z\s]+$/.test(formData.account_holder_name.trim())) {
    errors.account_holder_name =
      "Account Holder name should contain only alphabetic characters";
    valid = false;
  }

  if (!formData.account_type || formData.account_type === "") {
    errors.account_type = "Please select an Account Type";
    valid = false;
  }

  let isValid = Object.keys(errors).length === 0;
  return { is_valid: isValid, error: errors };
};

export const ValidateAssociatePartnerForm = (formData) => {
  let valid = true;
  const errors = {};

  // Validate first_name
  if (!formData.first_name.trim()) {
    errors.first_name =
      "First name is required and should contain only alphabetic characters";
    valid = false;
  } else if (!/^[a-zA-Z\s]+$/.test(formData.first_name.trim())) {
    errors.first_name = "First name should contain only alphabetic characters";
    valid = false;
  }

  // Validate last_name
  if (!formData.last_name.trim()) {
    errors.last_name =
      "Last name is required and should contain only alphabetic characters";
    valid = false;
  } else if (!/^[a-zA-Z\s]+$/.test(formData.last_name.trim())) {
    errors.last_name = "Last name should contain only alphabetic characters";
    valid = false;
  }

  // Validate email
  if (!formData.email.trim()) {
    errors.email = "Email is required";
    valid = false;
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email.trim())) {
    errors.email = "Invalid email format";
    valid = false;
  }

  const mobileNumber = formData.mobile_number.trim();
  if (!mobileNumber) {
    errors.mobile_number = "Mobile number is required";
    valid = false;
  } else if (!/^\d{10}$/.test(mobileNumber)) {
    errors.mobile_number =
      "Mobile number should contain exactly 10 numeric characters";
    valid = false;
  }

  let isValid = Object.keys(errors).length === 0;
  return { is_valid: isValid, error: errors };
};
