import React, { useEffect, useRef } from "react";
import html2pdf from "html2pdf.js";
import logo from "../../assets/logo.png";
import { GetTimeAgo } from "../../utils/Healpers";

const PrintableIMetaInformation = ({ userData, onClose, bankDetails }) => {
  const handleDownload = () => {
    const options = {
      margin: 10,
      filename: `${userData.user_profile.username}-Information.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    const element = document.getElementById("printable-document");
    html2pdf(element, options);
  };

  const modalRef = useRef();
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  const handleInteractionInsideModal = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
      <div
        className=" bg-white sm:w-11/12  lg:w-8/12 h-[85%] lg:h-[95%] md:flex md:flex-row justify-between sm:flex-col gap-2 overflow-y-auto"
        onClick={handleInteractionInsideModal}
        ref={modalRef}
      >
        <div className="border-gray-700 bg-gray-500 px-4  py-2 sm:w-8/12 flex justify-center items-center h-full">
          <div className="p-4 sm:w-full  lg:w-9/12 h-full bg-white shadow-2xl">
            <div id="printable-document" className="p-4 h-full bg-white">
              <div className="flex justify-center">
                <img className="text-center h-10" src={logo} alt="" />
              </div>
              <div>
                <div>
                  <h2
                    className="text-logo-blue font-medium text-xl mb-2"
                    style={{
                      borderBottom: "2px solid #F47C34",
                      paddingBottom: "0.35rem",
                    }}
                  >
                    Basic Information
                  </h2>
                  <p className="flex justify-between font-medium md:text-sm text-xs">
                    <strong className="font-medium md:text-sm text-xs">
                      Name
                    </strong>{" "}
                    {userData.first_name}
                    {userData.middle_name} {userData.last_name || "N/A"}
                  </p>
                  <p className="flex justify-between font-medium md:text-sm text-xs">
                    <strong className="font-medium md:text-sm text-xs">
                      Email
                    </strong>{" "}
                    {userData.email || "N/A"}{" "}
                  </p>
                  <p className="flex justify-between font-medium md:text-sm text-xs">
                    <strong className="font-medium md:text-sm text-xs">
                      Phone Number{" "}
                    </strong>
                    {userData.mobile_number || "N/A"}
                  </p>
                  <p className="flex justify-between font-medium md:text-sm text-xs">
                    <strong className="font-medium md:text-sm text-xs">
                      Days Ago
                    </strong>
                    {GetTimeAgo(userData.user_profile.created_at) || "N/A"}
                  </p>
                </div>
                <div>
                  <h2
                    className="text-logo-blue font-medium text-xl mb-2"
                    style={{
                      borderBottom: "2px solid #F47C34",
                      paddingBottom: "0.35rem",
                    }}
                  >
                    Meta Information
                  </h2>
                  {userData.partner_profile ? (
                    <>
                      <p className="flex justify-between font-medium md:text-sm text-xs">
                        <strong className="font-medium md:text-sm text-xs">
                          Aadhaar Number
                        </strong>{" "}
                        {userData.partner_profile.aadhar_number || "--"}
                      </p>
                      <p className="flex justify-between font-medium md:text-sm text-xs">
                        <strong className="font-medium md:text-sm text-xs">
                          Pan Number
                        </strong>{" "}
                        {userData.partner_profile.pan_number || "--"}
                      </p>
                      <p className="flex justify-between font-medium md:text-sm text-xs">
                        <strong className="font-medium md:text-sm text-xs">
                          Gst Number{" "}
                        </strong>
                        {userData.partner_profile.gst_number || "--"}
                      </p>
                      <p className="flex justify-between font-medium md:text-sm text-xs">
                        <strong className="font-medium md:text-sm text-xs">
                          Relation Manager
                        </strong>
                        {userData.partner_profile.relational_manager?.name ||
                          "--"}
                      </p>
                    </>
                  ) : (
                    <p className="font-medium md:text-sm text-xs">
                      No Meta information available
                    </p>
                  )}
                </div>
              </div>
              <div>
                <h2
                  className="text-logo-blue font-medium text-xl mb-2"
                  style={{
                    borderBottom: "2px solid #F47C34",
                    paddingBottom: "0.35rem",
                  }}
                >
                  Bank Information
                </h2>
                {bankDetails && bankDetails.length > 0 ? (
                  <div>
                    {bankDetails.map((bank, index) => (
                      <div key={index} className="mb-4">
                        <div className="flex justify-between font-medium md:text-sm text-xs">
                          <strong className="font-medium md:text-sm text-xs">
                            Account Number:
                          </strong>{" "}
                          {bank.account_number || "N/A"}
                        </div>
                        <div className="flex justify-between font-medium md:text-sm text-xs">
                          <strong className="font-medium md:text-sm text-xs">
                            Bank Name:
                          </strong>{" "}
                          {bank.bank_name || "N/A"}
                        </div>
                        <div className="flex justify-between font-medium md:text-sm text-xs">
                          <strong className="font-medium md:text-sm text-xs">
                            Branch Name:
                          </strong>{" "}
                          {bank.branch_name || "N/A"}
                        </div>
                        <div className="flex justify-between font-medium md:text-sm text-xs">
                          <strong className="font-medium md:text-sm text-xs">
                            IFSC Code:
                          </strong>{" "}
                          {bank.ifsc_code || "N/A"}
                        </div>
                        <div className="flex justify-between font-medium md:text-sm text-xs">
                          <strong className="font-medium md:text-sm text-xs">
                            Account Holder:
                          </strong>{" "}
                          {bank.account_holder_name || "N/A"}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>No bank details available</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-end  md:mr-4">
          <button
            className="bg-red-500 w-24 text-white px-1 py-1 rounded-md hover:bg-red-700 mb-2 focus:outline-none md:text-sm text-xs"
            onClick={onClose}
          >
            Close
          </button>
          <button
            className="bg-blue-500 w-24 text-white px-1 py-1 rounded-md hover:bg-blue-700 mb-2 focus:outline-none md:text-sm text-xs"
            onClick={handleDownload}
          >
            Download
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrintableIMetaInformation;
