import React, { useEffect, useState } from "react";
import GenerateURL, { GenerateSearchURL } from "../../../utils/ApiUrlProvider";
import properties from "../../../Properties/Properties";
import { GetData } from "../../../utils/ApiInvoker";
import { GetTimeAgo } from "../../../utils/Healpers";
import ListTable from "../../Common/ListTable";
import { useNavigate } from "react-router-dom";
import DashboardBanner from "../../Common/DashboardBanner";
import MissingDataImage from "../../Common/MissingDataImage";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchBar from "../../Common/SearchBar";
import { useLoadingContext } from "../../../Context/LoadingContext";

const PersonalLoanListAdmin = () => {
  const { showLoader, hideLoader } = useLoadingContext();
  const [tableData, setTableData] = useState(null);
  const [pagination, setPagination] = useState(null);

  const navigate = useNavigate();

  const clickOn = (e) => {
    navigate(`/admin/loan/${e.id}`);
  };

  const clickableOn = {
    on: ["Lead Number", "User Code"],
    funct: {
      "Lead Number": clickOn,
      "User Code": (e) => navigate(`/admin/user/${e.user_id}`),
    },
  };

  const convertDataToTableFormat = (data) => {
    const tableHeaders = [
      "Lead Number",
      "Disbursement Amount",
      "Applied By",
      "Status",
      "Time",
    ];

    const tableContent = data.map((loan) => {
      return {
        // ...loan,
        "Lead Number": loan.application_number || "--",
        "Disbursement Amount": loan.disbursement_amount || "--",
        "Applied By": (
          <span className="relative group">
            <span className="group-hover:underline cursor-pointer text-blue-500 -z-50">
              {loan.applied_by.user_profile.username || "--"}
            </span>
            <div className="absolute hidden left-5 bottom-3 bg-blue-100 border border-blue-300 p-2 rounded shadow-md group-hover:block w-52 z-50">
              <div className="text-black font-bold text-sm">
                Name: {loan.applied_by.first_name || "--"}
              </div>
              <div className="text-black text-sm">
                Mobile: {loan.applied_by.mobile_number || "--"}
              </div>
            </div>
          </span>
        ),
        Status: loan.status || "N/A",
        Time: GetTimeAgo(loan.created_at),
        id: loan.id,
        // user_id: user.user.id,
      };
    });

    return {
      tableHeaders,
      tableContent,
      clickableOn,
    };
  };

  const HandleSearchSucess = (res) => {
    console.log(res.results);
    if (res.results.length === 0) {
      toast.error("No Data found");
      GetLoanList();
    } else {
      HandleSuccess(res);
    }
  };

  const searchParameters = [
    { name: "Application No", parm: "application_number" },
    { name: "Applied By", parm: "user_code" },
    { name: "Status", parm: "status" },
  ];
  const SearchClick = (e) => {
    const url = GenerateSearchURL(e, properties.api.adminLoans);
    GetData(url, HandleSearchSucess, HandleError, false, false);
  };

  const EmptyCall = () => {
    GetLoanList();
  };

  const HandleSuccess = (response) => {
    hideLoader();
    const data = convertDataToTableFormat(response.results);
    setTableData(data);
    setPagination(response);
  };

  const HandleError = (response) => {
    hideLoader();
    console.log(response);
  };

  const GetNextLoanList = (nexturl) => {
    const url = GenerateURL({}, nexturl);
    GetData(url, HandleSuccess, HandleError, false, false);
  };

  const GetPrevLoanList = (prevurl) => {
    const url = GenerateURL({}, prevurl);
    GetData(url, HandleSuccess, HandleError, false, false);
  };

  const GetLoanList = () => {
    const url = GenerateURL(
      {},
      properties.api.PersonalLoanServices.adminUrls.AdminPersonalLoan
    );
    showLoader();
    GetData(url, HandleSuccess, HandleError, false, false);
  };

  useEffect(() => {
    GetLoanList();
  }, []);

  const view = (e) => {
    console.log(e);
    navigate(`/admin/personalloan/${e.id}`);
  };

  return (
    <div className="w-11/12">
      <DashboardBanner />
      {tableData && tableData.tableContent.length === 0 ? (
        <div className="flex flex-col gap-2">
          <MissingDataImage message={"No Loan Applications Found"} />
        </div>
      ) : (
        tableData && (
          <div className="w-full flex flex-col  gap-2 ">
            <SearchBar
              searchParameters={searchParameters}
              SearchClick={SearchClick}
              EmptyCall={EmptyCall}
            />
            <ListTable
              heading="Loan Applications"
              tableData={tableData}
              pagination={Boolean(pagination.next || pagination.previous)}
              next={pagination.next}
              prev={pagination.previous}
              onNextClick={() => GetNextLoanList(pagination.next)}
              onPrevClick={() => GetPrevLoanList(pagination.previous)}
              actions={{ view: true, edit: false, delete: false }}
              onView={view}
              //   clickabaleOn={clickableOn}
            />
          </div>
        )
      )}
    </div>
  );
};

export default PersonalLoanListAdmin;
