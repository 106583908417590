import React, { useEffect, useState } from "react";
import ModalWindow from "../../Common/Modal/ModalWindow";
import { renderInput } from "../../Common/FormComponents";
import { PostData, PutData } from "../../../utils/ApiInvoker";
import GenerateURL from "../../../utils/ApiUrlProvider";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BankOfferAdminVal } from "../../Common/FormValidation/CommanValidation";
import { useLoadingContext } from "../../../Context/LoadingContext";

const BankOfferModal = ({ onClose, loanId, Url, action, initialData }) => {
  const params = useParams();
  const initialValue = {
    bank_name: "",
    offer_amount: "",
    rate_of_interest: "",
    loan_tenure: "",
  };
  const [bankOffer, setBankOffer] = useState(null);
  const [bankOfferErr, setBankOfferErr] = useState(initialValue);
  const { showLoader, hideLoader } = useLoadingContext();
  const handleBankOfferChange = (e) => {
    const { name, value } = e.target;
    setBankOfferErr({ ...bankOfferErr, [name]: "" });
    setBankOffer({ ...bankOffer, [name]: value });
  };
  const HandleSuccess = () => {
    onClose();
    hideLoader();
    toast.success("Bank detail added", {
      autoClose: 1000,
    });
  };
  const HandleSuccessEdit = () => {
    onClose();
    hideLoader();
    toast.success("Bank detail Edited", {
      autoClose: 1000,
    });
  };
  const HandleError = () => {
    hideLoader();
    toast.error("Something went wrong", {
      autoClose: 1000,
    });
  };

  const UpdateBankOffer = (e) => {
    e.preventDefault();
    const { is_valid, error } = BankOfferAdminVal(bankOffer);
    if (is_valid) {
      if (action === "add") {
        const url = GenerateURL({ id: params.id }, Url);
        showLoader();
        PostData(
          url,
          {
            ...loanId,
            ...bankOffer,
          },
          HandleSuccess,
          HandleError
        );
      } else {
        const url = GenerateURL({ id: params.id, secid: initialData.id }, Url);
        showLoader();
        PutData(
          url,
          {
            ...loanId,
            ...bankOffer,
          },
          HandleSuccessEdit,
          HandleError
        );
      }
    } else {
      setBankOfferErr(error);
    }
  };
  useEffect(() => {
    if (action === "edit") {
      setBankOffer(initialData);
    } else {
      setBankOffer(initialValue);
    }
  }, []);
  return (
    <ModalWindow title="Bank Offer" onClose={onClose}>
      {bankOffer && (
        <form className="flex flex-col w-full gap-1 items-center">
          <div className="w-full">
            {renderInput("STRING", {
              label: "Bank Name",
              name: "bank_name",
              onChange: handleBankOfferChange,
              value: bankOffer.bank_name,
              error: bankOfferErr.bank_name,
              isrequired: true,
            })}
            {renderInput("INTEGER", {
              label: "Offer Amount",
              name: "offer_amount",
              onChange: handleBankOfferChange,
              value: bankOffer.offer_amount,
              error: bankOfferErr.offer_amount,
              isrequired: true,
            })}
            {renderInput("INTEGER", {
              label: "Rate of Intrest",
              name: "rate_of_interest",
              onChange: handleBankOfferChange,
              value: bankOffer.rate_of_interest,
              error: bankOfferErr.rate_of_interest,
              isrequired: true,
            })}
            {renderInput("INTEGER", {
              label: "Loan Tenure",
              name: "loan_tenure",
              onChange: handleBankOfferChange,
              value: bankOffer.loan_tenure,
              error: bankOfferErr.loan_tenure,
              isrequired: true,
            })}
          </div>

          <button
            onClick={UpdateBankOffer}
            className=" w-max bg-blue-500 text-white px-1 py-1 md:px-4 md:py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300  "
          >
            save
          </button>
        </form>
      )}
    </ModalWindow>
  );
};

export default BankOfferModal;
