import React, { useState } from "react";
import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";

const FAQPage = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const handleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const accordionData = [
    {
      title: "What is UdhariBazaar and its services?",
      content:
        "UdhariBazaar is a digital platform providing various loans like personal, business, and microloans through an online application process.",
    },
    {
      title: "How to apply for a loan on UdhariBazaar?",
      content:
        "Apply for a loan by filling out the online form on the UdhariBazaar website or mobile app with required details.",
    },
    {
      title: "Eligibility criteria for UdhariBazaar loans?",
      content:
        "Eligibility factors include credit history, income, employment status, and financial stability, varying for each loan type.",
    },
    {
      title: "Security measures during UdhariBazaar's loan application?",
      content:
        "UdhariBazaar ensures data security through encryption and robust measures to protect user information.",
    },
    {
      title: "Types of loans offered by UdhariBazaar?",
      content:
        "UdhariBazaar offers personal, business, and microloans with flexible terms and application processes.",
    },
    {
      title: "Repayment options for loans from UdhariBazaar?",
      content:
        "Loan repayments can be made through various methods such as EMI, auto-debit, or online transfers on scheduled dates.",
    },
    {
      title: "Are there any penalties for late loan repayments?",
      content:
        "UdhariBazaar may charge penalties or fees for late loan repayments; refer to the loan agreement for specific details.",
    },
    {
      title: "How long does it take to receive a loan approval?",
      content:
        "Loan approval times may vary based on application details, verification, and the specific loan type; expect timely updates.",
    },
    {
      title: "What documents are required for loan applications?",
      content:
        "Typically, documents like ID proofs, income statements, bank statements, and business details are needed for loan applications.",
    },
    {
      title: "Is there customer support available for loan inquiries?",
      content:
        "Yes, UdhariBazaar provides customer support services to address queries or concerns regarding loans and applications.",
    },
    // You can continue adding more questions here...
  ];

  return (
    <div>
      <Navbar />
      <h1 className="text-4xl text-logo-blue text-center font-bold capitalize sm:text-4xl border-blue-900 mt-4">
        Frequently Asked Questions
      </h1>
      <div className="flex justify-center mt-10">
        <div className="w-3/4 flex flex-col bg-gray-100 p-10 mb-10 rounded-lg">
          {accordionData.map((item, index) => (
            <div key={index} className="w-full mt-3">
              <div
                className={`flex justify-between items-center cursor-pointer bg-white rounded-t-lg px-4 py-2 ${
                  activeAccordion === index ? "shadow-md" : "shadow-sm"
                } transition duration-1000`}
                onClick={() => handleAccordion(index)}
              >
                <p
                  className={`text-xl font-semibold ${
                    activeAccordion === index
                      ? "text-logo-orange" // Grey color for heading
                      : "text-logo-blue"
                  }`}
                >
                  {item.title}
                </p>
                <span>{activeAccordion === index ? "-" : "+"}</span>
              </div>
              {activeAccordion === index && (
                <div className="bg-white rounded-b-lg p-4 transition duration-1000">
                  <p>{item.content}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FAQPage;
