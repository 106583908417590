import React, { useRef } from "react";
import { useEffect, useState } from "react";
import RMEditModal from "../Admin/Dashboard/RMEditModal";

import {
  IsAuthenticated,
  IsNormalUser,
  IsSuperUser,
} from "../../utils/AccessTokenStore";
import { GetTimeAgo } from "../../utils/Healpers";

const RMDetailCard = ({ RMData }) => {
  const [isvisible, setIsVisible] = useState(false);
  const editButtonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const editProfileModal = document.getElementById("edit-profile-modal");
      if (
        editButtonRef.current &&
        !editButtonRef.current.contains(event.target) &&
        !editProfileModal?.contains(event.target)
      ) {
        setIsVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleEditModal = () => {
    setIsVisible(!isvisible);
  };

  return (
    <div className="w-full flex flex-col ml-5 mt-9 p-4 ">
      <div className=" mt-3 rounded-2xl ">
        <div className=" w-full p-5 rounded-t-2xl bg-white flex flex-row justify-between border-b-2">
          <h1 className="font-medium text-xl ">Basic User Information</h1>
          <div className="flex flex-row relative ">
            <button
              ref={editButtonRef}
              className=" mr-4 hover:scale-110"
              onClick={() => {
                if (IsAuthenticated() && (IsSuperUser() || IsNormalUser())) {
                  setIsVisible(!isvisible);
                }
              }}
            >
              <img
                className="w-5"
                src="https://cdn.staropstech.com/clients/udharibazaar/assets/extralogo/editPencil.webp"
                alt=""
              />
            </button>
            {isvisible ? (
              IsNormalUser() ? (
                <RMEditModal onClose={toggleEditModal} initialRMData={RMData} />
              ) : (
                <RMEditModal onClose={toggleEditModal} initialRMData={RMData} />
              )
            ) : (
              <div></div>
            )}
          </div>
        </div>
        <div className="w-full p-8 gap-5 flex flex-col shadow-lg rounded-b-2xl bg-white ">
          {RMData && (
            <>
              <div className="w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4">
                <div className="flex flex-col">
                  <h3 className="font-medium  ">Name</h3>
                  <h3 className=" text-gray-500 font-semibold">
                    {RMData.name || "N/A"}
                  </h3>
                </div>
                <div className="flex flex-col">
                  <h3 className="font-medium">Mobile Number</h3>
                  <h3 className="text-gray-500 font-semibold">
                    {RMData.mobile_number || "N/A"}
                  </h3>
                </div>
                <div className="flex flex-col  ">
                  <h3 className="font-medium ">Email</h3>
                  <h3 className=" text-gray-500 break-all max-w-xs sm:max-w-full">
                    {RMData.email || "N/A"}
                  </h3>
                </div>
                <div className="flex flex-col  ">
                  <h3 className="font-medium ">Created</h3>
                  <h3 className=" text-gray-500 break-all max-w-xs sm:max-w-full">
                    {GetTimeAgo(RMData.created_at) || "N/A"}
                  </h3>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="mt-3 rounded-2xl">
        <div className="w-full p-5 rounded-t-2xl bg-white flex flex-row justify-between border-b-2">
          <h1 className="font-medium text-xl ">Your Partners</h1>
          <div className="flex flex-row relative "></div>
        </div>
        <div className="w-full p-8 gap-5 flex flex-col shadow-lg rounded-b-2xl bg-white ">
          {RMData &&
          RMData.relational_manager_partner &&
          RMData.relational_manager_partner.length > 0 ? (
            <div className="w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4">
              {RMData.relational_manager_partner.map((partner, index) => (
                <div key={index} className="flex flex-col">
                  <h3 className="font-medium">Partner Name</h3>
                  <h3 className="text-gray-500 font-semibold">
                    {partner.user.first_name || "N/A"}
                  </h3>
                  <h3 className="font-medium">Partner Mobile No.</h3>
                  <h3 className="text-gray-500 font-semibold">
                    {partner.user.mobile_number || "N/A"}
                  </h3>
                  <h3 className="font-medium">Partner Email</h3>
                  <h3 className="text-gray-500 font-semibold">
                    {partner.user.email || "N/A"}
                  </h3>
                </div>
              ))}
            </div>
          ) : (
            <p>No partner assigned right now</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RMDetailCard;
