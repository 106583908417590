import GenerateURL from "./ApiUrlProvider";
import properties from "../Properties/Properties";
import { PostData } from "../utils/ApiInvoker";

var secondsInMillis = 1000;
var hoursInMillis = 60 * 60 * 1000;
export function PersistLoginCreds(userData) {
  userData.assumed_role_superadmin = userData.is_superuser;
  var authData = {
    authenticated: true,
    userDetails: userData,
    accessTokenExpiresOn: new Date().getTime() + 24 * hoursInMillis,
    expiresOn: new Date().getTime() + 720 * hoursInMillis,
  };
  localStorage.setItem("authData", JSON.stringify(authData));
}

export function RemoveLoginCreds(handleLogoutResponse, handleLogoutFailure) {
  var logoutURL = GenerateURL({}, properties.api.logoutURL);

  var data = {
    token: GetAuth().refresh,
  };

  const handleLogoutResponseLocal = (data) => {
    localStorage.removeItem("authData");
    handleLogoutResponse(data);
  };
  PostData(
    logoutURL,
    data,
    handleLogoutResponseLocal,
    handleLogoutFailure,
    false
  );
}

export function IsAuthenticated() {
  const authDataString = localStorage.getItem("authData");

  if (authDataString !== null && authDataString !== "null") {
    var now = new Date();
    var authData = JSON.parse(authDataString);
    if (authData.accessTokenExpiresOn - now.getTime() > 20 * secondsInMillis) {
      return true;
    }
  }
  return false;
}

export function IsNormalUser() {
  const user_data = GetAuth();

  if (user_data) {
    if (user_data.is_superuser || user_data.is_partner) {
      return false;
    }
    return true;
  }
  return false;
}

export function IsSuperUser() {
  const user_data = GetAuth();
  return user_data ? user_data.is_superuser : null;
}

export function IsPartnerUser() {
  const user_data = GetAuth();
  return user_data ? user_data.is_partner : null;
}
export function IsAssociatePartner() {
  const user_data = GetAuth();
  return user_data ? user_data.is_associated_partner : null;
}

export function IsUserActualSuperUser() {
  const user_data = GetAuth();
  return user_data ? user_data.is_superuser : null;
}

export function GetAuth() {
  var authData = JSON.parse(localStorage.getItem("authData"));
  return authData ? authData.userDetails : null;
}

export function RegenerateRefreshAndAccessToken(
  handleRefreshResponse,
  handleRefreshFailure
) {
  var authData = JSON.parse(localStorage.getItem("authData"));
  console.log("refreshTOken", authData.userDetails.refresh);
  var data = {
    refresh: authData.userDetails.refresh,
  };
  var url = GenerateURL({}, properties.api.refreshTokenURL);
  const handleRefreshResponseLocal = (data) => {
    console.log(data, "opop");
    authData.userDetails.refresh = data.refresh;
    authData.userDetails.access = data.access;
    authData.expiresOn = new Date().getTime() + 6.5 * hoursInMillis;
    authData.accessTokenExpiresOn = new Date().getTime() + 5.5 * hoursInMillis;
    localStorage.setItem("authData", JSON.stringify(authData));
    handleRefreshResponse(data);
  };

  const handleRefreshFailureLocal = (data) => {
    handleRefreshFailure(data);
  };

  PostData(
    url,
    data,
    handleRefreshResponseLocal,
    handleRefreshFailureLocal,
    true
  );
}

export function IsRefreshTokenValid() {
  var now = new Date();
  const authDataString = localStorage.getItem("authData");
  if (authDataString !== null && authDataString !== "null") {
    var authData = JSON.parse(authDataString);
    if (authData.expiresOn - now.getTime() > 20 * secondsInMillis) {
      return true;
    }
  }

  return false;
}

export function UpdateAuthUserData(userData) {
  var authData = JSON.parse(localStorage.getItem("authData"));
  authData.userDetails = {
    ...authData.userDetails,
    ...userData,
  };
  localStorage.setItem("authData", JSON.stringify(authData));
}

export function SwitchToSuperAdminRole() {
  var authData = JSON.parse(localStorage.getItem("authData"));
  if (authData) {
    var user_details = authData.userDetails;
    user_details.assumed_role_superadmin = user_details.is_superuser;
    authData.userDetails = user_details;
    localStorage.setItem("authData", JSON.stringify(authData));
  }
  return (
    authData.userDetails.is_superuser &&
    authData.userDetails.assumed_role_superadmin
  );
}

export function SwitchToNormalUserRole() {
  var authData = JSON.parse(localStorage.getItem("authData"));
  if (authData) {
    var user_details = authData.userDetails;
    user_details.assumed_role_superadmin = false;
    authData.userDetails = user_details;
    localStorage.setItem("authData", JSON.stringify(authData));
  }
  return (
    !authData.userDetails.is_superuser &&
    !authData.userDetails.assumed_role_superadmin
  );
}
