import React from "react";

const PreLoader = () => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-white bg-opacity-70 backdrop-filter backdrop-blur-lg z-50 flex justify-center items-center">
      <div className="loader relative">
        <div className="w-12 h-12  bg-logo-orange rounded-full absolute top-0 left-0 animate-ping"></div>
        <div
          className="w-8 h-8  bg-logo-orange rounded-full absolute top-2 left-2 animate-pulse"
          style={{ animationDelay: "-1s" }}
        ></div>
      </div>
    </div>
  );
};

export default PreLoader;
