import { GetAuth } from "./AccessTokenStore";
import axios from "axios";
import {
  RegenerateRefreshAndAccessToken,
  IsAuthenticated,
  IsRefreshTokenValid,
} from "./AccessTokenStore";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * Perform API calls, either validating the access token or not, and handle GET requests.
 * @param {Object} requestInfo - An object containing information about the HTTP request.
 * @param {Function} callBackFunction - The callback function to be executed upon a successful response.
 * @param {Function} errorCallBackFunction - The callback function to be executed upon an error.
 * @param {boolean} parseText - Set to true to parse the response as plain text.
 * @param {string} requestInfo.httpMethod - The HTTP method to be used for the request (e.g., "GET").
 * @param {Object} requestInfo.httpHeaders - An object with HTTP headers to include in the request.
 * @param {string} requestInfo.endPoint - The URL or endpoint for the request.
 * @param {Function} callBackFunction - The callback function to be executed upon a successful response.
 * @param {Function} errorCallBackFunction - The callback function to be executed upon an error.
 * @param {boolean} doNotValidateToken - Set to true to skip token validation.
 * @param {boolean} parseText - Set to true to parse the response as plain text.
 */
//////////////
// GetRequestWithBearerHeaders
// PostRequestWithBearerHeaders
// DeleteRequestWithBearerHeaders
// GetRequest
// PostRequest
// Function = > FirstLetterCapital then camel case
// Global Variable => All Capital
// LC => FirstLetterLower then camel case
/////////////////////////////////////////

export const GetData = (
  url,
  callBackFunction,
  errorCallBackFunction,
  parseText,
  doNotAttachBearerToken
) => {
  const callBackFunctionLocal = () => {
    InvokeGetRequest(
      url,
      callBackFunction,
      errorCallBackFunction,
      parseText,
      doNotAttachBearerToken
    );
  };
  if (!doNotAttachBearerToken) {
    if (!IsAuthenticated()) {
      if (IsRefreshTokenValid()) {
        RegenerateRefreshAndAccessToken(
          callBackFunctionLocal,
          errorCallBackFunction
        );
      } else {
        errorCallBackFunction("Session expired. Login again");
      }
    } else {
      InvokeGetRequest(
        url,
        callBackFunction,
        errorCallBackFunction,
        parseText,
        doNotAttachBearerToken
      );
    }
  } else {
    InvokeGetRequest(
      url,
      callBackFunction,
      errorCallBackFunction,
      parseText,
      doNotAttachBearerToken
    );
    // console.log("abcdef");
  }
};

export const PostData = (
  url,
  data,
  callBackFunction,
  errorCallBackFunction,
  doNotAttachBearerToken
) => {
  const callBackFunctionLocal = () => {
    InvokePostRequest(
      url,
      data,
      callBackFunction,
      errorCallBackFunction,
      doNotAttachBearerToken
    );
  };
  if (!doNotAttachBearerToken) {
    if (!IsAuthenticated()) {
      if (IsRefreshTokenValid()) {
        RegenerateRefreshAndAccessToken(
          callBackFunctionLocal,
          errorCallBackFunction
        );
      } else {
        errorCallBackFunction("Session expired. Login again");
      }
    } else {
      InvokePostRequest(
        url,
        data,
        callBackFunction,
        errorCallBackFunction,
        doNotAttachBearerToken
      );
    }
  } else {
    InvokePostRequest(
      url,
      data,
      callBackFunction,
      errorCallBackFunction,
      doNotAttachBearerToken
    );
  }
};

export const PutData = (
  url,
  data,
  callBackFunction,
  errorCallBackFunction,
  doNotAttachBearerToken
) => {
  const callBackFunctionLocal = () => {
    InvokePutRequest(
      url,
      data,
      callBackFunction,
      errorCallBackFunction,
      doNotAttachBearerToken
    );
  };
  if (!doNotAttachBearerToken) {
    if (!IsAuthenticated()) {
      if (IsRefreshTokenValid()) {
        RegenerateRefreshAndAccessToken(
          callBackFunctionLocal,
          errorCallBackFunction
        );
      } else {
        errorCallBackFunction("Session expired. Login again");
      }
    } else {
      InvokePutRequest(
        url,
        data,
        callBackFunction,
        errorCallBackFunction,
        doNotAttachBearerToken
      );
    }
  } else {
    InvokePutRequest(
      url,
      data,
      callBackFunction,
      errorCallBackFunction,
      doNotAttachBearerToken
    );
  }
};

export const DeleteData = (
  url,
  data,
  callBackFunction,
  errorCallBackFunction,
  doNotAttachBearerToken
) => {
  const callBackFunctionLocal = () => {
    InvokeDeleteRequest(
      url,
      data,
      callBackFunction,
      errorCallBackFunction,
      doNotAttachBearerToken
    );
  };
  if (!doNotAttachBearerToken) {
    if (!IsAuthenticated()) {
      if (IsRefreshTokenValid()) {
        RegenerateRefreshAndAccessToken(
          callBackFunctionLocal,
          errorCallBackFunction
        );
      } else {
        errorCallBackFunction("Session expired. Login again");
      }
    } else {
      InvokeDeleteRequest(
        url,
        data,
        callBackFunction,
        errorCallBackFunction,
        doNotAttachBearerToken
      );
    }
  } else {
    InvokeDeleteRequest(
      url,
      data,
      callBackFunction,
      errorCallBackFunction,
      doNotAttachBearerToken
    );
  }
};

function InvokePostRequest(
  url,
  data,
  callBackFunction,
  errorCallBackFunction,
  doNotAttachBearerToken
) {
  var status = null;
  var fetchOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  // console.log(fetchOptions.headers);
  if (process.env.REACT_APP_ENV !== "PROD") {
    fetchOptions.headers = {
      ...fetchOptions.headers,
      "ngrok-skip-browser-warning": true,
    };
  }
  if (!doNotAttachBearerToken) {
    var auth = GetAuth();
    fetchOptions.headers.Authorization = auth
      ? "Bearer " + GetAuth().access
      : null;
  }
  fetch(url, fetchOptions)
    .then((response) => {
      status = response.status;
      var response_json = response.json();
      return response_json;
    })
    .then((data) => {
      if (status === 201 || status === 200) {
        callBackFunction(data);
      } else {
        var error_status = resolveErrorStatus(status, data);
        handleErrorResponse(status, error_status);
        errorCallBackFunction(error_status);
      }
    })
    .catch((exception) => {
      handleException(exception);
      // errorCallBackFunction(exception,true);
    });
}
function InvokePutRequest(
  url,
  data,
  callBackFunction,
  errorCallBackFunction,
  doNotAttachBearerToken
) {
  var status = null;
  var fetchOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  if (process.env.REACT_APP_ENV !== "PROD") {
    fetchOptions.headers = {
      ...fetchOptions.headers,
      "ngrok-skip-browser-warning": true,
    };
  }
  if (!doNotAttachBearerToken) {
    var auth = GetAuth();
    fetchOptions.headers.Authorization = auth
      ? "Bearer " + GetAuth().access
      : null;
  }
  fetch(url, fetchOptions)
    .then((response) => {
      status = response.status;
      var response_json = response.json();
      return response_json;
    })
    .then((data) => {
      if (status === 201 || status === 200) {
        callBackFunction(data);
      } else {
        var error_status = resolveErrorStatus(status, data);
        handleErrorResponse(status, error_status);
        errorCallBackFunction(error_status);
      }
    })
    .catch((exception) => {
      handleException(exception);
      // errorCallBackFunction(exception,true);
    });
}

function InvokeGetRequest(
  url,
  callBackFunction,
  errorCallBackFunction,
  parseText,
  doNotAttachBearerToken
) {
  var status = null;
  var fetchOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (process.env.REACT_APP_ENV !== "PROD") {
    fetchOptions.headers = {
      ...fetchOptions.headers,
      "ngrok-skip-browser-warning": true,
    };
  }
  if (!doNotAttachBearerToken) {
    var auth = GetAuth();
    fetchOptions.headers.Authorization = auth
      ? "Bearer " + GetAuth().access
      : null;
  }

  fetch(url, fetchOptions)
    .then((response) => {
      const contentType = response.headers.get("content-type");
      if (contentType === "application/octet-stream") {
        return response.blob();
      }
      status = response.status;
      var response_json = parseText ? response.text() : response.json();
      return response_json;
    })
    .then((data) => {
      if (data.type === "application/octet-stream") {
        callBackFunction(data);
      } else if (status === 200) {
        callBackFunction(data);
      } else {
        var error_status = resolveErrorStatus(status, data);
        handleErrorResponse(status, error_status);
        errorCallBackFunction(error_status);
      }
    })
    .catch((exception) => {
      handleException(exception);
    });
}

function InvokeDeleteRequest(
  url,
  data,
  callBackFunction,
  errorCallBackFunction,
  doNotAttachBearerToken
) {
  var status = null;
  var fetchOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      //"ngrok-skip-browser-warning": true,
    },
    body: JSON.stringify(data),
  };
  if (process.env.REACT_APP_ENV !== "PROD") {
    fetchOptions.headers = {
      ...fetchOptions.headers,
      "ngrok-skip-browser-warning": true,
    };
  }
  if (!doNotAttachBearerToken) {
    var auth = GetAuth();
    fetchOptions.headers.Authorization = auth
      ? "Bearer " + GetAuth().access
      : null;
  }

  fetch(url, fetchOptions)
    .then((response) => {
      status = response.status;
      var response_json = response.json();
      return response_json;
    })
    .then((data) => {
      if (status === 204) {
        callBackFunction(data);
      } else {
        var error_status = resolveErrorStatus(status, data);
        handleErrorResponse(status, error_status);
        errorCallBackFunction(error_status);
      }
    })
    .catch((exception) => {
      handleException(exception);
      // errorCallBackFunction(exception,true);
    });
}

function resolveErrorStatus(status, data) {
  switch (status) {
    case 401:
      toast.error("Unauthorized: " + data.detail, {
        autoClose: 3000,
      });
      return data.detail;
    case 500:
      toast.error("Internal Server Error: " + data.detail, {
        autoClose: 3000,
      });
      return data.detail;
    case 501:
      toast.error("Method Not Supported: " + data.detail, {
        autoClose: 3000,
      });
      return data.error;
    case 404:
      toast.error("Not Found: " + data.detail, {
        autoClose: 3000,
      });
      return data.detail;
    case 405:
      toast.error("Method Not Allowed: " + data.detail, {
        autoClose: 3000,
      });
      return data.detail;
    case 403:
      toast.error("Forbidden: " + data.detail, {
        autoClose: 3000,
      });
      return data.detail;
    case 400:
      if (data.non_field_errors) {
        return data.non_field_errors[0];
      }
      if (data.detail) {
        toast.error(data.detail, {
          autoClose: 3000,
        });
      }
      return data;
    default:
      return data;
  }
}

export const GetFormData = (
  url,
  callBackFunction,
  errorCallBackFunction,
  parseText,
  doNotAttachBearerToken
) => {
  const callBackFunctionLocal = () => {
    InvokeGetFormRequest(
      url,
      callBackFunction,
      errorCallBackFunction,
      parseText,
      doNotAttachBearerToken
    );
  };
  if (!doNotAttachBearerToken) {
    if (!IsAuthenticated()) {
      if (IsRefreshTokenValid()) {
        RegenerateRefreshAndAccessToken(
          callBackFunctionLocal,
          errorCallBackFunction
        );
      } else {
        errorCallBackFunction("Session expired. Login again");
      }
    } else {
      InvokeGetFormRequest(
        url,
        callBackFunction,
        errorCallBackFunction,
        parseText,
        doNotAttachBearerToken
      );
    }
  } else {
    InvokeGetFormRequest(
      url,
      callBackFunction,
      errorCallBackFunction,
      parseText,
      doNotAttachBearerToken
    );
    // console.log("abcdef");
  }
};

export const PostFormData = (
  url,
  data,
  callBackFunction,
  errorCallBackFunction,
  doNotAttachBearerToken
) => {
  const callBackFunctionLocal = () => {
    InvokePostFormRequest(
      url,
      data,
      callBackFunction,
      errorCallBackFunction,
      doNotAttachBearerToken
    );
  };
  if (!doNotAttachBearerToken) {
    if (!IsAuthenticated()) {
      if (IsRefreshTokenValid()) {
        RegenerateRefreshAndAccessToken(
          callBackFunctionLocal,
          errorCallBackFunction
        );
      } else {
        errorCallBackFunction("Session expired. Login again");
      }
    } else {
      InvokePostFormRequest(
        url,
        data,
        callBackFunction,
        errorCallBackFunction,
        doNotAttachBearerToken
      );
    }
  } else {
    InvokePostFormRequest(
      url,
      data,
      callBackFunction,
      errorCallBackFunction,
      doNotAttachBearerToken
    );
  }
};

export const PutFormData = (
  url,
  data,
  callBackFunction,
  errorCallBackFunction,
  doNotAttachBearerToken
) => {
  const callBackFunctionLocal = () => {
    InvokePutFormRequest(
      url,
      data,
      callBackFunction,
      errorCallBackFunction,
      doNotAttachBearerToken
    );
  };
  if (!doNotAttachBearerToken) {
    if (!IsAuthenticated()) {
      if (IsRefreshTokenValid()) {
        RegenerateRefreshAndAccessToken(
          callBackFunctionLocal,
          errorCallBackFunction
        );
      } else {
        errorCallBackFunction("Session expired. Login again");
      }
    } else {
      InvokePutFormRequest(
        url,
        data,
        callBackFunction,
        errorCallBackFunction,
        doNotAttachBearerToken
      );
    }
  } else {
    InvokePutFormRequest(
      url,
      data,
      callBackFunction,
      errorCallBackFunction,
      doNotAttachBearerToken
    );
  }
};

function InvokePostFormRequest(
  url,
  data,
  callBackFunction,
  errorCallBackFunction,
  doNotAttachBearerToken
) {
  var headers = { "content-type": "multipart/form-data" };
  if (process.env.REACT_APP_ENV !== "PROD") {
    headers = {
      ...headers,
      "ngrok-skip-browser-warning": true,
    };
  }

  if (!doNotAttachBearerToken) {
    var auth = GetAuth();
    headers.Authorization = auth ? "Bearer " + GetAuth().access : null;
  }

  axios
    .post(url, data, { headers })
    .then((response) => {
      const status = response.status;
      const response_json = response.data;
      return { status, data: response_json };
    })
    .then(({ status, data }) => {
      if (status === 201 || status === 200) {
        callBackFunction(data);
      } else {
        var error_status = resolveErrorStatus(status, data);
        handleErrorResponse(status, error_status);
        errorCallBackFunction(error_status);
      }
    })
    .catch((exception) => {
      handleException(exception);
    });
}
function InvokePutFormRequest(
  url,
  data,
  callBackFunction,
  errorCallBackFunction,
  doNotAttachBearerToken
) {
  var status = null;
  var headers = { "content-type": "multipart/form-data" };
  if (process.env.REACT_APP_ENV !== "PROD") {
    headers = {
      ...headers,
      "ngrok-skip-browser-warning": true,
    };
  }

  if (!doNotAttachBearerToken) {
    var auth = GetAuth();
    headers.Authorization = auth ? "Bearer " + GetAuth().access : null;
  }
  axios
    .put(url, data, { headers })
    .then((response) => {
      status = response.status;
      var response_json = response.json();
      return response_json;
    })
    .then((data) => {
      if (status === 201 || status === 200) {
        callBackFunction(data);
      } else {
        var error_status = resolveErrorStatus(status, data);
        handleErrorResponse(status, error_status);
        errorCallBackFunction(error_status);
      }
    })
    .catch((exception) => {
      handleException(exception);
      // errorCallBackFunction(exception,true);
    });
}

function InvokeGetFormRequest(
  url,
  callBackFunction,
  errorCallBackFunction,
  parseText,
  doNotAttachBearerToken
) {
  var status = null;
  var headers = { "content-type": "multipart/form-data" };
  if (process.env.REACT_APP_ENV !== "PROD") {
    headers = {
      ...headers,
      "ngrok-skip-browser-warning": true,
    };
  }

  if (!doNotAttachBearerToken) {
    var auth = GetAuth();
    headers.Authorization = auth ? "Bearer " + GetAuth().access : null;
  }

  axios
    .get(url, { headers })
    .then((response) => {
      const contentType = response.headers.get("content-type");
      if (contentType === "application/zip") {
        return response.blob();
      }
      status = response.status;
      var response_json = parseText ? response.text() : response.json();
      return response_json;
    })
    .then((data) => {
      if (data.type === "application/zip") {
        callBackFunction(data);
      } else if (status === 200) {
        callBackFunction(data);
      } else {
        var error_status = resolveErrorStatus(status, data);
        handleErrorResponse(status, error_status);
        errorCallBackFunction(error_status);
      }
    })
    .catch((exception) => {
      handleException(exception);
    });
}

var handleErrorResponse = () => {};
var handleException = () => {};
